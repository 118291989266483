
export class CarBidFee {
  #carBidFee

  constructor(carBidFee) {
    this.#carBidFee = carBidFee;
  }

  get() {
    return this.#carBidFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
