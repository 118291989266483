import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { findById as getProperty } from 'domain/Property/services/Property.js';
import { serviceBreadcrumbs as getCategories } from 'domain/Category/services/PropertyCategory.js';

import { AuctionHeaderContext } from '../entities/AuctionHeaderContext.js';

const AuctionHeaderProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const pathname = useLocation().pathname;

  const governmentExp = /gov\/[0-9]+/;
  const itemExp = /items\/[0-9]+/;

  useEffect(() => {
    if (pathname === '/gov') {
      setBreadcrumbs([
        { title: 'トップ', to: '/' },
        { title: '行政機関一覧', to: '/gov' },
      ]);
    } else if (governmentExp.test(pathname)) {
      setBreadcrumbs([
        { title: 'トップ', to: '/' },
        { title: '行政機関一覧', to: '/gov' },
      ]);
    } else if (itemExp.test(pathname)) {
      const pathnameSplit = pathname.split('/');
      const id = pathnameSplit[2];

      let breadcrumbs = [];

      getProperty('SERVICE', id).then((property) => {
        getCategories('SERVICE', property?.categoryId).then((categories) => {
          categories.forEach((category) => {
            breadcrumbs.push({
              title: category.name,
              to: `/search?searchPropertyCategoryId=${category.id}`,
            });
          });

          breadcrumbs.push({
            title: '物件詳細',
          });

          setBreadcrumbs(breadcrumbs);
        });
      });
    } else {
      setBreadcrumbs([]);
    }
  }, [pathname]);

  return (
    <AuctionHeaderContext.Provider value={{ breadcrumbs }}>
      {children}
    </AuctionHeaderContext.Provider>
  );
};

export default AuctionHeaderProvider;
