import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  background-color: var(--white);
  margin: 8px 0;
  box-shadow:
    0 0 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  border-radius: 5px;

  @media screen and (min-width: 960px) {
    height: 60px;
    display: flex;
    justify-content: center;
    padding: 0 30px;
  }

  @media screen and (max-width: 959px) {
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 30px;
  }

  .kservice & {
    border: none;
    cursor: pointer;
    margin-right: 14px;
  }

  .kservice & svg {
    font-size: 2.125rem;
  }

  @media (max-width: 719px) {
    .kservice & {
      margin-right: 14px;
    }

    .kservice &:last-child {
      margin-right: 0;
    }
  }
`;

const Icon = styled.div`
  height: 36px;
  width: 44px;
  color: var(--primary-base);
`;

const Text = styled.div`
  line-height: 1rem;
  margin: 0 auto;

  @media screen and (min-width: 960px) {
    color: var(--text-lighten1);
  }

  @media screen and (max-width: 959px) {
    color: var(--primary-base);
  }
`;

const Label = styled.p`
  font-weight: 700;
  letter-spacing: 0.2em;

  .kservice & {
    font-size: 0.875rem;
    letter-spacing: 0.0892857143em;
  }
`;

const Count = styled.p`
  font-size: 0.9rem;
`;

const RectangleButton = ({ className, label, count, svg, onClick }) => {
  return (
    <Button
      className={className}
      name={label}
      onClick={onClick}
      data-testid={`${label}-rectangle-button`}>
      {svg && <Icon data-testid={`${label}-rectangle-button-icon`}>{svg}</Icon>}
      <Text>
        {label && <Label data-testid={`${label}-rectangle-button-label`}>{label}</Label>}
        {!(!count && count !== 0) && (
          <Count data-testid={`${label}-rectangle-button-count`}>{count}件</Count>
        )}
      </Text>
    </Button>
  );
};

export default RectangleButton;
