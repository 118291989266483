import Button from 'interfaces/components/common/Button';
import {
  checkbox_label,
  checkbox_label_recommend,
} from 'interfaces/components/common/CheckboxLabel';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoEstateFormSketch from 'interfaces/components/common/PropertyInfoForm/PropertyInfoEstateFormSketch';
import PropertyInfoGeneralFormImage from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormImage';
import PropertyInfoGeneralFormInputDate from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputDate';
import {
  estate_radio_label1,
  estate_radio_label2,
  procedure_pub,
  radio_label,
  radio_label2,
  radio_label3,
  radio_label4,
} from 'interfaces/components/common/RadioLabel';
import {
  property_info_form_pub,
  property_info_form_asset,
} from 'interfaces/components/common/TableLabels';
import { useContext } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  caution_description1,
  caution_description2,
  caution_description10,
  caution_description11,
  caution_description12,
  caution_description13,
  caution_description15,
  caution_description17,
  caution_description18,
  caution_description19,
  //caution_description2,
  caution_description20,
  caution_description21,
  caution_description22,
  caution_description23,
  caution_description_char_100,
  caution_description3,
  caution_description4,
  caution_description5,
  caution_description6,
  caution_description7,
  caution_description8,
  caution_description9,
  caution_description_char_2000,
  caution_description_char_400,
  estate_caution_description3,
  estate_caution_description5,
  caution_description_char_250,
  caution_description_char_30,
  caution_description_char_200,
  caution_description_char_1000,
  caution_description_char_50,
  resolution_date,
} from 'shared/utils/helper/DummyEditFormData';

import {
  estate_structure_types,
  estate_types,
  pulldown_prefecture,
} from '../../../../../shared/utils/helper/Pulldown';
import { PropertyInfoEstateContext } from '../../../../pages/PropertyInfoEstate/entities/PropertyInfoEstateContext';
import FormCategory from '../../../Form/navi/FormCategory';
import FormCheckboxWithCaution from '../../../Form/navi/FormCheckboxWithCaution';
import FormMarkDownWithCaution from '../../../Form/navi/FormMarkDownWithCaution';
import FormNumberWithCaution from '../../../Form/navi/FormNumberWithCaution';
import FormPullDownWithCaution from '../../../Form/navi/FormPullDownWithCaution';
import FormRadioWithCaution from '../../../Form/navi/FormRadioWithCaution';
import FormTelWithCaution from '../../../Form/navi/FormTelWithCaution';
import FormTextAreaWithCaution from '../../../Form/navi/FormTextAreaWithCaution';
import FormTextWithCaution from '../../../Form/navi/FormTextWithCaution';
import GeneralStateInputCheck from '../../../NewEntry/GeneralStateInputCheck';
import FormDatePullDown from '../../navi/Car/FormDatePullDown';
import FormLandArea from '../../navi/Estate/FormLandArea';
import FormLandEquity from '../../navi/Estate/FormLandEquity';
import FormPrefecturePullDownAndTextWithCaution from '../../navi/Estate/FormPrefecturePullDownAndTextWithCaution';
import FormDateTimeWithCaution from '../../../Form/navi/FormDateTimeWithCaution';
import { useNavigate } from 'react-router-dom';
import { ProcedureTypes } from 'shared/utils/constants/government';

const PropertyInfoEstateForm = ({
  formData,
  handleConfirm,
  openWay,
  isValid,
}) => {
  const navigate = useNavigate(); 
  const labels = property_info_form_pub;
  const asset_labels = property_info_form_asset;
  const radio_labels = procedure_pub;

  const { divisions, selectedCategory } = useContext(PropertyInfoEstateContext);

  const handlePaymentMethod = (value) => {
    formData.setters.handleChangePaymentMethod({
      ...formData.data.paymentMethod,
      ...value,
    });
  };

  const { localStorageProcedureType } = useLocalStorage();

  const handleClear = (e) => {
    e.preventDefault();
    Object.values(formData.setters).forEach((setter) => {
      if (setter.name === "handleChangeDeposit") {
        setter({ target: { value: 0 } }, "BOTH")
      } else if (setter.name === "handleChangeContactTel") {
        setter({
          telNum1: '',
          telNum2: '',
          telNum3: '',
        })
      } else if (setter.name === "handleChangeDescription") {
        setter('')
      } else {
        setter({ target: { value: '' } })
      }
    });

  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/navi/');
  };

  return (
    <form>
      <div className="property-info-general-form-table">
        <FormRadioWithCaution
          caution_description={caution_description1}
          label={'公開設定'}
          radio_label={radio_label}
          data={formData.data.isDraft}
        />
      </div>

      <div className="pt-9">
        <Heading3 text="1. 画像の選択" />
        <PropertyInfoGeneralFormImage label={'画像データ'} />
      </div>

      <div className="pt-9">
        <Heading3 text="2. 物件情報" />
        <div className="property-info-general-form-table">
          {openWay !== 'AUCTION' ? (
            <FormRadioWithCaution
              caution_description={caution_description22}
              data={formData.data.publicSaleType}
              exhibitData={formData.data.isNextBuyApl}
              handler1={formData.setters.handleChangePublicSaleType}
              handler2={formData.setters.handleChangeIsNextBuyApl}
              label={'出品形式'}
              procedureType={localStorageProcedureType}
              radio_label={radio_label2}
              isEstate={true}
            />
          ) : (
            <></>
          )}
          <FormNumberWithCaution
            //caution_description={caution_description2}
            caution_description={caution_description2}
            handler1={formData.setters.handleChangePrintOrder}
            invalidMessage={formData.validationMessage.printOrderValid}
            label={'掲載順位'}
            min={1}
            state={formData.data.printOrder}
            width={'6em'}
          />
          <FormCategory
            data={selectedCategory}
            handler={formData.setters.handleChangeCategoryId}
            invalidMessage={formData.validationMessage.categoryIdValid}
            label={'カテゴリ'}
            propertyType={'ESTATE'}
          />
          <GeneralStateInputCheck
            checkbox_label={checkbox_label_recommend}
            caution_description={caution_description3}
            label={'イチ押し設定'}
            data={formData.data.recommendProperties}
            stateHandler={formData.setters.handleChangeRecommendProperties}
          />
          <FormPullDownWithCaution
            caution_description={caution_description4}
            formStateHandler={formData.setters.handleChangeDivisionId}
            label={'執行機関名称'}
            listItemData={divisions}
            selectedData={
              formData.data.divisionId === '' ||
              formData.data.divisionId === undefined
                ? divisions?.length === 0
                  ? ''
                  : divisions[0]
                : divisions.find(
                    (data) =>
                      formData.data.divisionId.toString() ===
                      data.id.toString(),
                  )
            }
          />
          <FormTextWithCaution
            caution_description={caution_description5}
            data={formData.data.saleNumber}
            handler1={formData.setters.handleChangeSaleNumber}
            invalidMessage={formData.validationMessage.saleNumberValid}
            label={'売却区分番号'}
            maxCharacters={30}
            required={true}
            width={'80'}
          />
          <FormTextWithCaution
            caution_description={caution_description6}
            data={formData.data.name}
            handler1={formData.setters.handleChangeName}
            invalidMessage={formData.validationMessage.nameValid}
            label={'タイトル'}
            maxCharacters={50}
            required={true}
            //validationType={['ZenKatakanaOnly']}
          />
          <FormCheckboxWithCaution
            caution_description={caution_description7}
            checkbox_label={checkbox_label}
            class_name={'payment-method'}
            data={formData.data.paymentMethod}
            handler={handlePaymentMethod}
            invalidMessage={formData.validationMessage.paymentMethodValid}
            label={'支払い方法'}
            required={true}
          />

          {localStorageProcedureType === ProcedureTypes.PUBLIC_SALE.string ? (
            <FormDateTimeWithCaution
              caution_description={caution_description19}
              date={formData.data.paymentLimit}
              handleChange={formData.setters.handleChangePaymentLimit}
              label={'買受代金納付期限'}
              required={true}
              invalidMessage={formData.validationMessage.paymentLimitValid}
            />
          ) : (
            <>
              <FormTextWithCaution
                caution_description={resolution_date}
                data={formData.data.resolutionDate}
                handler1={formData.setters.handleChangeResolutionDate}
                invalidMessage={formData.validationMessage.resolutionDateValid}
                label={'議決日'}
                maxCharacters={30}
              />
              <PropertyInfoGeneralFormInputDate
                label={'売払代金納付期限'}
                handleChange={formData.setters.handleChangePaymentLimit}
                caution_description={caution_description23}
                date={formData.data.paymentLimit}
                required={true}
              />
              <PropertyInfoGeneralFormInputDate
                label={'契約締結期限'}
                handleChange={formData.setters.handleChangeContractedLimit}
                date={formData.data.contractedLimit}
              />
            </>
          )}

          <FormTextWithCaution
            caution_description={caution_description8}
            data={formData.data.videoUrl}
            handler1={formData.setters.handleChangeVideoUrl}
            invalidMessage={formData.validationMessage.videoUrlValid}
            label={'動画URL'}
          />
          <FormPullDownWithCaution
            label={'物件の種類'}
            listItemData={estate_types}
            selectedData={
              formData.data.type === undefined
                ? { value: '', text: '' }
                : estate_types.find((type) => type.value === formData.data.type)
            }
            formStateHandler={formData.setters.handleChangeType}
            invalidMessage={formData.validationMessage.typeValid}
          />
          <FormPullDownWithCaution
            label={'物件の所在'}
            listItemData={pulldown_prefecture}
            selectedData={
              formData.data.governmentSubCategoryId === undefined
                ? { value: '', text: '都道府県を選択' }
                : pulldown_prefecture.find(
                    (prefecture) =>
                      prefecture.value.toString() ===
                      formData.data.governmentSubCategoryId.toString(),
                  )
            }
            formStateHandler={
              formData.setters.handleChangeGovernmentSubCategoryId
            }
            invalidMessage={
              formData.validationMessage.governmentSubCategoryIdValid
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="3. 基本情報" />
        <div className="property-info-general-form-table">
          <FormTextAreaWithCaution
            caution_description={caution_description_char_2000}
            data={formData.data.propertyPrint}
            handler1={formData.setters.handleChangePropertyPrint}
            invalidMessage={formData.validationMessage.propertyPrintValid}
            label={'登記簿の表示'}
            maxCharacters={2000}
          />
          <FormPrefecturePullDownAndTextWithCaution
            caution_description={caution_description_char_100}
            dataSelected={formData.data.locationGovernmentSubCategoryId}
            dataText={formData.data.locationText}
            handlerPrefecture={
              formData.setters.handleChangeLocationGovernmentSubCategoryId
            }
            handlerText={formData.setters.handleChangeLocationText}
            invalidTextMessage={formData.validationMessage.locationTextValid}
            label={'所在地'}
            maxCharacters={100}
            required={true}
          />
          <FormPrefecturePullDownAndTextWithCaution
            caution_description={caution_description_char_100}
            dataSelected={formData.data.residenceGovernmentSubCategoryId}
            dataText={formData.data.residenceLocation}
            handlerPrefecture={
              formData.setters.handleChangeResidenceGovernmentSubCategoryId
            }
            handlerText={formData.setters.handleChangeResidenceLocation}
            invalidTextMessage={
              formData.validationMessage.residenceLocationValid
            }
            label={'住居表示'}
            maxCharacters={100}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_400}
            data={formData.data.nearestStation}
            handler1={formData.setters.handleChangeNearestStation}
            invalidMessage={formData.validationMessage.nearestStationValid}
            label={'交通、最寄りの駅など'}
            maxCharacters={400}
          />
          <FormTextWithCaution
            caution_description={estate_caution_description3}
            data={formData.data.locationUrl}
            handler1={formData.setters.handleChangeLocationUrl}
            invalidMessage={formData.validationMessage.locationUrlValid}
            label={'所在図'}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_400}
            data={formData.data.estateStatus}
            handler1={formData.setters.handleChangeEstateStatus}
            invalidMessage={formData.validationMessage.estateStatusValid}
            label={'現況'}
            maxCharacters={400}
          />
          <PropertyInfoEstateFormSketch label={'見取り図など'} />
          <FormTextWithCaution
            caution_description={caution_description_char_200}
            data={formData.data.other}
            handler1={formData.setters.handleChangeOther}
            invalidMessage={formData.validationMessage.otherValid}
            label={'その他事項'}
            maxCharacters={200}
          />
          <FormMarkDownWithCaution
            caution_description={caution_description20}
            data={formData.data.description}
            handler1={formData.setters.handleChangeDescription}
            invalidMessage={formData.validationMessage.descriptionValid}
            label={'説明'}
            number_remaining_characters={
              formData.data.description !== undefined
                ? 15000 - formData.data.description.length
                : 15000
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="4. 土地の情報" />
        <div className="property-info-general-form-table">
          <FormLandArea
            caution_description={estate_caution_description5}
            dataText={formData.data.landSpace}
            dataRadio={formData.data.landSpaceType}
            handlerRadio={formData.setters.handleChangeLandSpaceType}
            handlerText={formData.setters.handleChangeLandSpace}
            label={'土地面積'}
            radio_label={estate_radio_label2}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_30}
            data={formData.data.landRights}
            handler1={formData.setters.handleChangeLandRights}
            invalidMessage={formData.validationMessage.landRightsValid}
            label={'土地権利'}
            maxCharacters={30}
            width={80}
          />
          <FormLandEquity
            label={'土地持分'}
            dataLandShare={formData.data.landShare}
            handlerLandShare={formData.setters.handleChangeLandShare}
            formData={formData}
            invalidMessage={formData.validationMessage.landShareValid}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_30}
            data={formData.data.landCategory}
            handler1={formData.setters.handleChangeLandCategory}
            invalidMessage={formData.validationMessage.landCategoryValid}
            label={'地目'}
            maxCharacters={30}
            width={80}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_30}
            data={formData.data.cityPlanningArea}
            handler1={formData.setters.handleChangeCityPlanningArea}
            invalidMessage={formData.validationMessage.cityPlanningAreaValid}
            label={'都市計画地域'}
            maxCharacters={30}
            width={80}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_100}
            data={formData.data.restrictedArea}
            handler1={formData.setters.handleChangeRestrictedArea}
            invalidMessage={formData.validationMessage.restrictedAreaValid}
            label={'用途地域'}
            maxCharacters={100}
          />
          <FormNumberWithCaution
            afterFormWord={'%'}
            label={'建ぺい率'}
            handler1={formData.setters.handleChangeBuildRatio}
            invalidMessage={formData.validationMessage.buildRatioValid}
            state={formData.data.buildRatio}
            width={'12em'}
          />
          <FormNumberWithCaution
            afterFormWord={'%'}
            label={'容積率'}
            handler1={formData.setters.handleChangeFloorAreaRatio}
            invalidMessage={formData.validationMessage.floorAreaRatioValid}
            state={formData.data.floorAreaRatio}
            width={'12em'}
          />
          <FormNumberWithCaution
            afterFormWord={'種'}
            label={'高度地区'}
            handler1={formData.setters.handleChangeHighDistrict}
            invalidMessage={formData.validationMessage.highDistrictValid}
            state={formData.data.highDistrict}
            width={'12em'}
          />
          <FormRadioWithCaution
            label={'防火地域'}
            data={formData.data.firebreakArea}
            handler1={formData.setters.handleChangeFirebreakArea}
            radio_label={estate_radio_label1}
            required={false}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_250}
            data={formData.data.shadeRegulation}
            handler1={formData.setters.handleChangeShadeRegulation}
            invalidMessage={formData.validationMessage.shadeRegulationValid}
            label={'日影規制'}
            maxCharacters={250}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_1000}
            data={formData.data.lawRegulation}
            handler1={formData.setters.handleChangeLawRegulation}
            invalidMessage={formData.validationMessage.lawRegulationValid}
            label={'その他法令などの規制'}
            maxCharacters={1000}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_200}
            data={formData.data.geographical}
            handler1={formData.setters.handleChangeGeographical}
            invalidMessage={formData.validationMessage.geographicalValid}
            label={'地勢など'}
            number_remaining_characters={200}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_400}
            data={formData.data.neighboringRoad}
            handler1={formData.setters.handleChangeNeighboringRoad}
            invalidMessage={formData.validationMessage.neighboringRoadValid}
            label={'幅員、接道状況'}
            maxCharacters={400}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_400}
            data={formData.data.privateRoad}
            handler1={formData.setters.handleChangePrivateRoad}
            invalidMessage={formData.validationMessage.privateRoadValid}
            label={'私道の負担など'}
            maxCharacters={400}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_1000}
            data={formData.data.provideEquipment}
            handler1={formData.setters.handleChangeProvideEquipment}
            invalidMessage={formData.validationMessage.provideEquipmentValid}
            label={'供給処理施設'}
            maxCharacters={1000}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_2000}
            data={formData.data.landOther}
            handler1={formData.setters.handleChangeLandOther}
            invalidMessage={formData.validationMessage.landOtherValid}
            label={'その他土地に関する物件情報'}
            maxCharacters={2000}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="5. 建物の情報" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            caution_description={caution_description_char_100}
            data={formData.data.buildName}
            handler1={formData.setters.handleChangeBuildName}
            invalidMessage={formData.validationMessage.buildNameValid}
            label={'建物の名称'}
            maxCharacters={100}
          />
          <FormNumberWithCaution
            caution_description={estate_caution_description5}
            afterFormWord={'m²'}
            label={'建物面積'}
            handler1={formData.setters.handleChangeBuildSpace}
            invalidMessage={formData.validationMessage.buildSpaceValid}
            state={formData.data.buildSpace}
            width={'12em'}
          />
          <FormNumberWithCaution
            caution_description={estate_caution_description5}
            afterFormWord={'m²'}
            label={'専有面積'}
            handler1={formData.setters.handleChangeExclusiveSpace}
            invalidMessage={formData.validationMessage.exclusiveSpaceValid}
            state={formData.data.exclusiveSpace}
            width={'12em'}
          />
          <FormNumberWithCaution
            caution_description={estate_caution_description5}
            afterFormWord={'m²'}
            label={'延床面積'}
            handler1={formData.setters.handleChangeFloorSpace}
            invalidMessage={formData.validationMessage.floorSpaceValid}
            state={formData.data.floorSpace}
            width={'12em'}
          />
          <FormPullDownWithCaution
            label={'構造'}
            listItemData={estate_structure_types}
            required={false}
            selectedData={
              formData.data.structureType === undefined
                ? estate_structure_types[0]
                : formData.data.structureType
            }
          />
          <FormDatePullDown
            data={formData.data.buildDate}
            label={'建築年月'}
            noDay={true}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_30}
            data={formData.data.floor}
            handler1={formData.setters.handleChangeFloor}
            invalidMessage={formData.validationMessage.floorValid}
            label={'階建/階'}
            maxCharacters={30}
            width={70}
          />
          <FormNumberWithCaution
            afterFormWord={'戸'}
            label={'総戸数'}
            handler1={formData.setters.handleChangeTotalHouses}
            invalidMessage={formData.validationMessage.totalHousesValid}
            state={formData.data.totalHouses}
            width={'16em'}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_30}
            data={formData.data.layout}
            handler1={formData.setters.handleChangeLayout}
            invalidMessage={formData.validationMessage.layoutValid}
            label={'間取り'}
            maxCharacters={30}
            width={70}
          />
          <FormTextAreaWithCaution
            data={formData.data.buildOther}
            handler1={formData.setters.handleChangeBuildOther}
            invalidMessage={formData.validationMessage.buildOtherValid}
            label={'その他建物に関する物件情報'}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="6. 物件に関するお問い合わせ先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            caution_description={caution_description9}
            data={formData.data.inquiryName}
            handler1={formData.setters.handleChangeInquiryName}
            invalidMessage={formData.validationMessage.inquiryNameValid}
            label={'出品行政機関名'}
            maxCharacters={30}
            width={'80'}
          />
          <FormTextWithCaution
            caution_description={caution_description10}
            data={formData.data.inquiryOffice}
            handler1={formData.setters.handleChangeInquiryOffice}
            invalidMessage={formData.validationMessage.inquiryOfficeValid}
            label={'担当部署名'}
            maxCharacters={50}
          />
          <FormTextWithCaution
            caution_description={caution_description11}
            data={formData.data.inquiryEmail}
            handler1={formData.setters.handleChangeInquiryEmail}
            invalidMessage={formData.validationMessage.inquiryEmailValid}
            label={'メールアドレス'}
            maxCharacters={50}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.inquiryExtension}
            extensionMessage={formData.validationMessage.inquiryExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.inquiryTelValid}
            invalid1Message={
              formData.validationMessage.auctionInquiryTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionInquiryTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionInquiryTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeInquiryExtension}
            setTelData={formData.setters.handleChangeInquiryTel}
            setTel1Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum1(tel)
            }
            setTel2Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum2(tel)
            }
            setTel3Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum3(tel)
            }
            telData={formData.data.inquiryTel}
            telInvalidMessage={
              formData.validationMessage.auctionInquiryTelValid
            }
          />
          <FormTextWithCaution
            caution_description={caution_description13}
            data={formData.data.inquiryAccept}
            handler1={formData.setters.handleChangeInquiryAccept}
            invalidMessage={formData.validationMessage.inquiryAcceptValid}
            label={'電話受付時間'}
            maxCharacters={100}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.inquiryNote}
            handler1={formData.setters.handleChangeInquiryNote}
            invalidMessage={formData.validationMessage.inquiryNoteValid}
            label={'備考'}
            maxCharacters={50}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="7. 物件の詳細設定" />
        <div className="pt-3">
          <FormRadioWithCaution
            class_name={'procedure'}
            data={formData.data.depositPayment}
            handler1={(e) => {
              formData.setters.handleChangeDepositPayment(e);
              if (e.target.value === 'NONE') {
                formData.setters.handleChangeDeposit({ target: { value: 0 } }, 'NONE');
              } else {
                formData.setters.handleChangeDeposit({ target: { value: 0 } }, e.target.value);
                if (e.target.value === "CREDIT_CARD" || e.target.value === "BOTH") {
                  formData.setters.handleChangeIsApprovalRequired({ target: { value: 'TRUE' } });
                }
              }
            }}
            label={'保証金納付手続き'}
            radio_label={radio_labels}
          />
          {localStorageProcedureType === ProcedureTypes.PUBLIC_SALE.string && (formData.data.depositPayment === "BOTH" || formData.data.depositPayment === "CREDIT_CARD") ? (
            <>
              <FormRadioWithCaution
                caution_description={caution_description15}
                data={formData.data.isApprovalRequired}
                handler1={formData.setters.handleChangeIsApprovalRequired}
                label={'参加申し込み承認'}
                radio_label={radio_label3}
              />
              <FormNumberWithCaution
                afterFormWord={'円'}
                caution_description={caution_description12}
                handler1={formData.setters.handleChangeEstimatePrice}
                invalidMessage={
                  formData.validationMessage.estimatePriceRequiredValid
                }
                label={labels[0]}
                required={true}
                state={formData.data.estimatePrice}
                width={'16em'}
              />
              <FormNumberWithCaution
                afterFormWord={'円'}
                caution_description={caution_description17}
                handler1={(e) =>
                  formData.setters.handleChangeDeposit(
                    e,
                    formData.data.depositPayment,
                  )
                }
                invalidMessage={formData.validationMessage.depositRequiredValid}
                isDisabled={
                  formData.data.depositPayment === 'NONE' ? true : false
                }
                label={labels[1]}
                required={true}
                state={formData.data.deposit}
                width={'16em'}
              />
            </>
          ) : (
            <>
              <FormNumberWithCaution
                afterFormWord={'円'}
                caution_description={caution_description12}
                handler1={formData.setters.handleChangeEstimatePrice}
                invalidMessage={
                  formData.validationMessage.estimatePriceRequiredValid
                }
                label={asset_labels[0]}
                required={true}
                state={formData.data.estimatePrice}
                width={'16em'}
              />
              <FormNumberWithCaution
                afterFormWord={'円'}
                caution_description={caution_description21}
                handler1={(e) =>
                  formData.setters.handleChangeDeposit(
                    e,
                    formData.data.depositPayment,
                  )
                }
                invalidMessage={formData.validationMessage.depositRequiredValid}
                label={asset_labels[1]}
                required={true}
                state={formData.data.deposit}
                width={'16em'}
              />
            </>
          )}
          <FormRadioWithCaution
            caution_description={caution_description18}
            data={formData.data.qualifiedInvoice}
            handler1={formData.setters.handleChangeQualifiedInvoice}
            label={'適格請求書'}
            radio_label={radio_label4}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="8. 落札後の連絡先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.contactName}
            handler1={formData.setters.handleChangeContactName}
            invalidMessage={formData.validationMessage.contactNameValid}
            label={'連絡先機関名'}
            maxCharacters={50}
            required={true}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.contactOffice}
            handler1={formData.setters.handleChangeContactOffice}
            invalidMessage={formData.validationMessage.contactOfficeValid}
            label={'担当部署名'}
            maxCharacters={50}
          />
          <FormTextWithCaution
            caution_description={caution_description11}
            data={formData.data.contactEmail}
            handler1={formData.setters.handleChangeContactEmail}
            invalidMessage={formData.validationMessage.contactEmailValid}
            label={'メールアドレス'}
            maxCharacters={50}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.contactExtension}
            extensionMessage={formData.validationMessage.contactExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.contactTelValid}
            invalid1Message={
              formData.validationMessage.auctionInquiryTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionInquiryTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionInquiryTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeContactExtension}
            setTelData={formData.setters.handleChangeContactTel}
            setTel1Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum1(tel)
            }
            setTel2Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum2(tel)
            }
            setTel3Data={(tel) =>
              formData.setters.handleChangeInquiryTelNum3(tel)
            }
            telData={formData.data.contactTel}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.contactAccept}
            handler1={formData.setters.handleChangeContactAccept}
            invalidMessage={formData.validationMessage.contactAcceptValid}
            label={'電話受付時間'}
            maxCharacters={50}
          />
        </div>
      </div>

      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'確認する'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleConfirm}
              className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleCancel}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'クリア'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleClear}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default PropertyInfoEstateForm;
