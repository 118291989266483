import { DashboardContext } from 'interfaces/pages/Dashboard/entities/DashboardContext';
import { useContext } from 'react';

import UpcomingAuctionTableColumn from './UpcomingAuctionTableColumn.jsx';
import UpcomingAuctionTableRecord from './UpcomingAuctionTableRecord.jsx';

import 'interfaces/css/Dashboard/upcoming-auction-table.css';

const UpcomingAuctionTable = () => {
  const { invitations } = useContext(DashboardContext);

  return (
    <div className="upcoming-auction-table-wrap">
      <table
        className="upcoming-auction-table"
        data-testid="upcoming-auction-table">
        <UpcomingAuctionTableColumn />
        <tbody>
          {invitations?.map((invitation) => (
            <UpcomingAuctionTableRecord
              key={invitation.auctionId}
              invitation={invitation}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UpcomingAuctionTable;
