import { useState } from 'react';
import {
  validateTelRangeLength,
  validateTelPartRangeLength,
  validateExtensionRangeLength,
  validateHasNoSpace,
  validateUrlFormatCheck,
  validateUrlLength,
  validateRequired,
  validateEmailLength,
  validateEmailFormatCheck,
  validateRangeLength,
  validateNumber,
  hasEmoji,
  validateSelected,
  validateNumberRange,
  validateSomeSelected,
  validateDeposit,
  validateAlphanum,
  validateDecimal,
} from 'application/validations';

// import {logInput} from "../../utils/log/logInputs.js";

export const UseCarInputsValidation = ({ isEdit = false }) => {
  //contractedLimitValid, setContractedLimitValid,
  //paymentDeadlineRequiredValid, setPaymentDeadlineRequiredValid,

  // 2. 物件情報
  const [printOrderValid, setPrintOrderValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [isNextBuyAplValid, setIsNextBuyAplValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [categoryIdValid, setCategoryIdValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [divisionNameRequiredValid, setDivisionNameRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [saleNumberRequiredValid, setSaleNumberRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [titleRequiredValid, setTitleRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [paymentMethodRequiredValid, setPaymentMethodRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [paymentLimitValid, setPaymentLimitValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contractedLimitValid, setContractedLimitValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [resolutionDateValid, setResolutionDateValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [videoUrlValid, setVideoUrlValid] = useState(isEdit ? 'TRUE' : null);

  // 3. 登録事項など証明書の記載内容
  const [effectiveDateValid, setEffectiveDateValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 証明書発行日
  const [registerNumberValid, setRegisterNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 自動車登録番号
  const [displayRegisterNumberValid, setDisplayRegisterNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 自動車登録番号の表示の可否
  const [registerDateValid, setRegisterDateValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 登録年月日
  const [firstRegisterDateValid, setFirstRegisterDateValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 初度登録年月
  const [carTypeValid, setCarTypeValid] = useState(isEdit ? 'TRUE' : null); // 自動車の種別
  const [purposeValid, setPurposeValid] = useState(isEdit ? 'TRUE' : null); // 用途
  const [purposeOtherValid, setPurposeOtherValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 用途
  const [privateOrBusinessValid, setPrivateOrBusinessValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 自家用、事業用の別
  const [shapeValid, setShapeValid] = useState(isEdit ? 'TRUE' : null); // 車体の形状
  const [manufactureValid, setManufactureValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車名（メーカー）
  const [manufactureOtherValid, setManufactureOtherValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車名（メーカー）その他
  const [modelValid, setModelValid] = useState(isEdit ? 'TRUE' : null); // 型式
  const [seatingCapacityValid, setSeatingCapacityValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 乗車定員
  const [weightValid, setWeightValid] = useState(isEdit ? 'TRUE' : null); // 車両重量
  const [totalWeightValid, setTotalWeightValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車両総重量
  const [productNumberValid, setProductNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車台番号
  const [displayProductNumberValid, setDisplayProductNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車台番号の表示の可否
  const [engineModelValid, setEngineModelValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 原動機の型式
  const [lengthValid, setLengthValid] = useState(isEdit ? 'TRUE' : null); // 長さ
  const [widthValid, setWidthValid] = useState(isEdit ? 'TRUE' : null); // 幅
  const [heightValid, setHeightValid] = useState(isEdit ? 'TRUE' : null); // 高さ
  const [displacementValid, setDisplacementValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 総排気量
  const [fuelTypeValid, setFuelTypeValid] = useState(isEdit ? 'TRUE' : null); // 燃料の種類
  const [frontAxelWeightValid, setFrontAxelWeightValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 前前軸重
  const [rearAxelWeightValid, setRearAxelWeightValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 後後軸重
  const [safetyTermValid, setSafetyTermValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 自動車検査証有効期限
  const [statusSafetyTermValid, setStatusSafetyTermValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 自動車検査証有効期限
  const [formalNumberValid, setFormalNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 型式指定番号
  const [classificationNumberValid, setClassificationNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 類型区分番号

  // 4. 基本情報
  const [transmissionTypeValid, setTransmissionTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // ミッション
  const [handleTypeValid, setHandleTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // ハンドル
  const [existsCarMaintenanceSheetValid, setExistsCarMaintenanceSheetValid] =
    useState(isEdit ? 'TRUE' : null); // 定期点検記録簿
  const [carBodyColorValid, setcarBodyColorValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 車体の色
  const [carMileageValid, setCarMileageValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 走行距離
  const [statusCarMileageValid, setStatusCarMileageValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 走行距離・不明チェック
  const [inventoryLocationValid, setInventoryLocationValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 引渡時保管場所
  const [mapUrlValid, setMapUrlValid] = useState(isEdit ? 'TRUE' : null); // 引渡時保管場所・地図URL
  const [carStatusValid, setCarStatusValid] = useState(isEdit ? 'TRUE' : null); // 車両等の状況
  const [deliveryTermsValid, setDeliveryTermsValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 引き渡し条件
  const [otherValid, setOtherValid] = useState(isEdit ? 'TRUE' : null); // その他
  const [descriptionValid, setDescriptionValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 説明

  // 5. 物件に関する音尾合わせ先
  const [inquiryNameValid, setInquiryNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryOfficeValid, setInquiryOfficeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryEmailValid, setInquiryEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelValid, setInquiryTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum1Valid, setInquiryTelNum1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum2Valid, setInquiryTelNum2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum3Valid, setInquiryTelNum3Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryExtensionValid, setInquiryExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryAcceptValid, setInquiryAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryNoteValid, setInquiryNoteValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 6. 物件の詳細設定
  const [estimatePriceRequiredValid, setEstimatePriceRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [depositRequiredValid, setDepositRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [qualifiedInvoiceValid, setQualifiedInvoiceValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 7. 落札後の連絡先
  const [contactNameValid, setContactNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactOfficeValid, setContactOfficeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactEmailValid, setContactEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelValid, setContactTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum1Valid, setContactTelNum1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum2Valid, setContactTelNum2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum3Valid, setContactTelNum3Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactExtensionValid, setContactExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactAcceptValid, setContactAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 2. 物件情報
  function handlePrintOrderValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumberRange(e.target.value);
    if (invalid !== 'TRUE') {
      setPrintOrderValid(invalid);
      return;
    }
    setPrintOrderValid(invalid); // エラーメッセージのリセット
  }

  function handleisNextBuyAplValid() {
    let invalid = null;
    setIsNextBuyAplValid(invalid); // エラーメッセージのリセット
  }

  function handleCategoryIdValid(id) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSelected(id);
    if (invalid !== 'TRUE') {
      setCategoryIdValid(invalid);
      return;
    }
    setCategoryIdValid(invalid); // エラーメッセージのリセット
  }

  function handleDivisionIdValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setDivisionNameRequiredValid(invalid);
      return;
    }
    setDivisionNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleSaleNumberValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setSaleNumberRequiredValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setSaleNumberRequiredValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setSaleNumberRequiredValid(invalid);
      return;
    }
    setSaleNumberRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleTitleValid(e) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      console.log('emoji');
      setTitleRequiredValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setTitleRequiredValid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setTitleRequiredValid(invalid);
      return;
    }
    setTitleRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handlePaymentMethodValid(e) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSomeSelected([e.target.value]);
    if (invalid !== 'TRUE') {
      setPaymentMethodRequiredValid(invalid);
      return;
    }
    setPaymentMethodRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handlePaymentLimitValid(value) {
    let invalid = 'TRUE';
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setPaymentLimitValid(invalid);
      return;
    }
    setPaymentLimitValid(invalid);
  } // 証明書発行日

  function handleContractedLimitValid() {
    let invalid = null;
    setContractedLimitValid(invalid);
  } // 証明書発行日

  function handleResolutionDateValid(e) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    setResolutionDateValid(invalid); // エラーメッセージのリセット
  }

  function handleVideoUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (e.target.value.length === 0) {
      setVideoUrlValid(invalid);
      return;
    }
    invalid = validateUrlFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    invalid = validateUrlLength(e.target.value);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    setVideoUrlValid(invalid); // エラーメッセージのリセット
  }

  // 3 登録事項など証明書の記載内容
  function handleEffectiveDateValid() {
    let invalid = 'TRUE';
    setEffectiveDateValid(invalid);
  } // 証明書発行日

  function handleRegisterNumberValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setRegisterNumberValid(invalid);
      return;
    }
    setRegisterNumberValid(invalid);
  } // 自動車登録番号

  function handleRegisterNumberFirstValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setRegisterNumberValid(invalid);
      return;
    }
    setRegisterNumberValid(invalid);
  } // 自動車登録番号
  function handleRegisterNumberLatterValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setRegisterNumberValid(invalid);
      return;
    }
    setRegisterNumberValid(invalid);
  } // 自動車登録番号

  function handleDisplayRegisterNumberValid() {
    let invalid = 'TRUE';
    setDisplayRegisterNumberValid(invalid);
  } // 自動車登録番号の表示の可否

  function handleRegisterDateValid() {
    let invalid = 'TRUE';
    setRegisterDateValid(invalid);
  } // 登録年月日

  function handleFirstRegisterDateValid() {
    let invalid = 'TRUE';
    setFirstRegisterDateValid(invalid);
  } // 初度登録年月

  function handleCarTypeValid(e) {
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCarTypeValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setCarTypeValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setCarTypeValid(invalid);
      return;
    }
    setCarTypeValid(invalid);
  } // 自動車の種別

  function handlePurposeValid(e) {
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setPurposeValid(invalid);
      return;
    }
    setPurposeValid(invalid);
  } // 用途

  function handlePurposeOtherValid(e) {
    let invalid = 'TRUE';
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setPurposeOtherValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setPurposeOtherValid(invalid);
      return;
    }
    setPurposeOtherValid(invalid);
  } // 用途

  function handlePrivateOrBusinessValid(e) {
    console.log(e.target.value);
    let invalid = 'TRUE';
    setPrivateOrBusinessValid(invalid);
  } // 自家用、事業用の別

  function handleShapeValid(e) {
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setShapeValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setShapeValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setShapeValid(invalid);
      return;
    }
    setShapeValid(invalid);
  } // 車体の形状

  function handleManufactureValid(e) {
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setManufactureValid(invalid);
      return;
    }
    setManufactureValid(invalid);
  } // 車名（メーカー）

  function handleManufactureOtherValid(e) {
    let invalid = 'TRUE';
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setManufactureOtherValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setManufactureOtherValid(invalid);
      return;
    }
    setManufactureOtherValid(invalid);
  } // 車名（メーカー）その他

  function handleModelValid(e) {
    let invalid = null;
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setModelValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setModelValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setModelValid(invalid);
      return;
    }
    setModelValid(invalid);
  } // 形式

  function handleSeatingCapacityValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setSeatingCapacityValid(invalid);
        return;
      }
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setSeatingCapacityValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 2);
      if (invalid !== 'TRUE') {
        setSeatingCapacityValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setSeatingCapacityValid(invalid);
        return;
      }
    }
    setSeatingCapacityValid(invalid);
  } // 乗車定員

  function handleWeightValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setWeightValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 5);
      if (invalid !== 'TRUE') {
        setWeightValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setWeightValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setWeightValid(invalid);
        return;
      }
    }
    setWeightValid(invalid);
  } // 車両重量

  function handleTotalWeightValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setTotalWeightValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 5);
      if (invalid !== 'TRUE') {
        setTotalWeightValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setTotalWeightValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setTotalWeightValid(invalid);
        return;
      }
    }
    setTotalWeightValid(invalid);
  } // 車両総重量

  function handleProductNumberValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setProductNumberValid(invalid);
      return;
    }
    setProductNumberValid(invalid);
  } // 車台番号

  function handleProductNumberFirstValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setProductNumberValid(invalid);
      return;
    }
    console.log(value);
    invalid = validateNumber(value);
    if (invalid !== 'TRUE') {
      setProductNumberValid(invalid);
      return;
    }
    setProductNumberValid(invalid);
  } // 車台番号
  function handleProductNumberLatterValid(value) {
    let invalid = null;
    invalid = validateRequired(value);
    if (invalid !== 'TRUE') {
      setProductNumberValid(invalid);
      return;
    }
    console.log(value);
    invalid = validateNumber(value);
    if (invalid !== 'TRUE') {
      setProductNumberValid(invalid);
      return;
    }
    setProductNumberValid(invalid);
  } // 車台番号

  function handleDisplayProductNumberValid() {
    let invalid = 'TRUE';
    setDisplayProductNumberValid(invalid);
  } // 車台番号

  function handleEngineModelValid(e) {
    let invalid = null;
    invalid = validateAlphanum(e.target.value);
    if (invalid !== 'TRUE') {
      setEngineModelValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setEngineModelValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setEngineModelValid(invalid);
      return;
    }
    setEngineModelValid(invalid);
  } // 原動機の型式

  function handleLengthValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setLengthValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 4);
      if (invalid !== 'TRUE') {
        setLengthValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setLengthValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setLengthValid(invalid);
        return;
      }
    }
    setLengthValid(invalid);
  } // 長さ

  function handleWidthValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setWidthValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 4);
      if (invalid !== 'TRUE') {
        setWidthValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setWidthValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setWidthValid(invalid);
        return;
      }
    }
    setWidthValid(invalid);
    return;
  } // 幅

  function handleHeightValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setHeightValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 4);
      if (invalid !== 'TRUE') {
        setHeightValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setHeightValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setHeightValid(invalid);
        return;
      }
    }
    setHeightValid(invalid);
  } // 高さ

  function handleDisplacementValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setDisplacementValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 4);
      if (invalid !== 'TRUE') {
        setDisplacementValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setDisplacementValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setDisplacementValid(invalid);
        return;
      }
    }
    setDisplacementValid(invalid);
  } // 総排気量

  function handleFuelTypeValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setFuelTypeValid(invalid);
      return;
    }
    invalid = validateAlphanum(e.target.value);
    if (invalid !== 'TRUE') {
      setFuelTypeValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFuelTypeValid(invalid);
      return;
    }
    setFuelTypeValid(invalid);
  } // 燃料の種類

  function handleFrontAxelWeightValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setFrontAxelWeightValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 5);
      if (invalid !== 'TRUE') {
        setFrontAxelWeightValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setFrontAxelWeightValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setFrontAxelWeightValid(invalid);
        return;
      }
    }
    setFrontAxelWeightValid(invalid);
  } // 前前軸重

  function handleRearAxelWeightValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setRearAxelWeightValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 0, 5);
      if (invalid !== 'TRUE') {
        setRearAxelWeightValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setRearAxelWeightValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setRearAxelWeightValid(invalid);
        return;
      }
    }
    setRearAxelWeightValid(invalid);
  } // 後後軸重

  function handleSafetyTermValid() {
    let invalid = null;
    setSafetyTermValid(invalid);
  } // 自動車検査証有効期限

  function handleStatusSafetyTermValid() {
    let invalid = null;
    setStatusSafetyTermValid(invalid);
  } // 自動車検査証有効かどうか

  function handleFormalNumberValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setFormalNumberValid(invalid);
      return;
    }
    invalid = validateAlphanum(e.target.value);
    if (invalid !== 'TRUE') {
      setFormalNumberValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setFormalNumberValid(invalid);
      return;
    }
    setFormalNumberValid(invalid);
  } // 型式指定番号

  function handleClassificationNumberValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setClassificationNumberValid(invalid);
      return;
    }
    invalid = validateAlphanum(e.target.value);
    if (invalid !== 'TRUE') {
      setClassificationNumberValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setClassificationNumberValid(invalid);
      return;
    }
    setClassificationNumberValid(invalid);
  } // 類型区分番号

  // 4. 基本情報
  function handleTransmissionTypeValid() {
    let invalid = 'TRUE';
    setTransmissionTypeValid(invalid);
  } // ミッション

  function handleHandleTypeValid() {
    let invalid = 'TRUE';
    setHandleTypeValid(invalid);
  } // ハンドル

  function handleExistsCarMaintenanceSheetValid() {
    let invalid = 'TRUE';
    setExistsCarMaintenanceSheetValid(invalid);
  } // 定期点検記録簿

  function handleCarBodyColorValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 20);
    if (invalid !== 'TRUE') {
      setcarBodyColorValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setcarBodyColorValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setcarBodyColorValid(invalid);
      return;
    }
    setcarBodyColorValid(invalid);
  } // 車体の色

  function handleCarMileageValid(e, status = false) {
    let invalid = null;
    if (status) {
      invalid = 'TRUE';
    } else {
      invalid = validateNumber(e.target.value);
      if (invalid !== 'TRUE') {
        setCarMileageValid(invalid);
        return;
      }
      invalid = validateRangeLength(e.target.value, 1, 10);
      if (invalid !== 'TRUE') {
        setCarMileageValid(invalid);
        return;
      }
      invalid = validateDecimal(e.target.value, 0);
      if (invalid !== 'TRUE') {
        setCarMileageValid(invalid);
        return;
      }
      invalid = validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setCarMileageValid(invalid);
        return;
      }
    }
    setCarMileageValid(invalid);
  } // 走行距離

  function handleStatusCarMileageValid() {
    let invalid = 'TRUE';
    setStatusCarMileageValid(invalid);
  } // 走行距離・不明チェック

  function handleInventoryLocationValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 60);
    if (invalid !== 'TRUE') {
      setInventoryLocationValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setInventoryLocationValid(invalid);
      return;
    }
    setInventoryLocationValid(invalid);
  } // 引渡時保管場所

  function handleMapUrlValid(e) {
    let invalid = null;
    invalid = validateUrlFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setMapUrlValid(invalid);
      return;
    }
    invalid = validateUrlLength(e.target.value, 0, 2048);
    if (invalid !== 'TRUE') {
      setMapUrlValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setMapUrlValid(invalid);
      return;
    }
    setMapUrlValid(invalid);
  } // 引渡時保管場所・地図URL

  function handleCarStatusValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 100);
    if (invalid !== 'TRUE') {
      setCarStatusValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCarStatusValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setCarStatusValid(invalid);
      return;
    }
    setCarStatusValid(invalid);
  } // 車両等の状況

  function handleDeliveryTermsValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 100);
    if (invalid !== 'TRUE') {
      setDeliveryTermsValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setDeliveryTermsValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDeliveryTermsValid(invalid);
      return;
    }
    setDeliveryTermsValid(invalid);
  } // 引き渡し条件

  function handleOtherValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 500);
    if (invalid !== 'TRUE') {
      setOtherValid(invalid);
      return;
    }
    setOtherValid(invalid);
  } // その他

  function handleDescriptionValid(text) {
    let invalid = null;
    invalid = validateRangeLength(text, 0, 15000);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    invalid = hasEmoji(text);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    setDescriptionValid(invalid); // エラーメッセージのリセット
  }

  // 5. 物件に関する音尾合わせ先
  function handleInquiryNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    setInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    setInquiryOfficeValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid);
      return;
    }
    invalid = validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid);
      return;
    }
    setInquiryEmailValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryTelValid(telData) {
    //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateTelRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum2Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum3Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryExtensionValid(e) {
    // //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateExtensionRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    setInquiryExtensionValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    setInquiryAcceptValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryNoteValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setInquiryNoteValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryNoteValid(invalid);
      return;
    }
    setInquiryNoteValid(invalid); // エラーメッセージのリセット
  }

  // 6. 物件の詳細設定
  function handleEstimatePriceValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 11);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 11);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    setEstimatePriceRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleDepositValid(e, payment) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    console.log(e.target.value);
    console.log(payment);
    invalid = validateDeposit(e.target.value, payment);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    setDepositRequiredValid(invalid); // エラーメッセージのリセット
  }
  function handleQualifiedInvoiceValid() {
    // logInput(e.target.name, e.target.value);
    setQualifiedInvoiceValid('TRUE');
  }

  // 7. 落札後の連絡先
  function handleContactNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    setContactNameValid(invalid); // エラーメッセージのリセット
  }

  function handleContactOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid);
      return;
    }
    setContactOfficeValid(invalid); // エラーメッセージのリセット
  }

  function handleContactEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelValid(telData) {
    //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    //invalid = Validation.validateTelLength(e.target.value);
    //if (invalid !== "TRUE") {
    //    setContactTelValid(invalid);
    //    return
    //}
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateTelRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setContactTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelNum2Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setContactTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelNum3Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setContactTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactExtensionValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateExtensionRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    setContactExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleContactAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    setContactAcceptValid(invalid); // エラーメッセージのリセット
  }

  //function handlePaymentDeadlineValid(e) {
  //    // logInput(e.target.name, e.target.value);
  //    let invalid = null;
  //    invalid = Validation.validatePaymentDeadlineRequiredRequired1(e.target.value);
  //    if (invalid !== "TRUE") {
  //        setPaymentDeadlineRequiredValid(invalid);
  //        return
  //    }
  //    setPaymentDeadlineRequiredValid(invalid); // エラーメッセージのリセット
  //}

  //function handlePaymentScheduledDateValid(e) {
  //    // logInput(e.target.name, e.target.value);
  //    let invalid = null;
  //    invalid = Validation.validateCcontractedLimitRequired(e.target.value);
  //    if (invalid !== "TRUE") {
  //        setContractedLimitValid(invalid);
  //        return
  //    }
  //    setContractedLimitValid(invalid); // エラーメッセージのリセット
  //}

  const validations = {
    // validationの処理
    setters: {
      // 2. 物件情報
      handlePrintOrderValid,
      handleisNextBuyAplValid,
      handleCategoryIdValid,
      handleDivisionIdValid,
      handleSaleNumberValid,
      handleTitleValid,
      handlePaymentMethodValid,
      handlePaymentLimitValid,
      handleContractedLimitValid,
      handleResolutionDateValid,
      handleVideoUrlValid,

      // 3 登録事項など証明書の記載内容
      handleEffectiveDateValid,
      handleRegisterNumberValid,
      handleRegisterNumberFirstValid,
      handleRegisterNumberLatterValid,
      handleDisplayRegisterNumberValid,
      handleRegisterDateValid,
      handleFirstRegisterDateValid,
      handleCarTypeValid,
      handlePurposeValid,
      handlePurposeOtherValid,
      handlePrivateOrBusinessValid,
      handleShapeValid,
      handleManufactureValid, // 車名（メーカー）
      handleManufactureOtherValid, // 車名（メーカー）その他
      handleModelValid, // 型式
      handleSeatingCapacityValid,
      handleWeightValid,
      handleTotalWeightValid,
      handleProductNumberValid,
      handleProductNumberFirstValid,
      handleProductNumberLatterValid,
      handleDisplayProductNumberValid,
      handleEngineModelValid,
      handleLengthValid,
      handleWidthValid,
      handleHeightValid,
      handleDisplacementValid,
      handleFuelTypeValid,
      handleFrontAxelWeightValid,
      handleRearAxelWeightValid,
      handleSafetyTermValid,
      handleStatusSafetyTermValid,
      handleFormalNumberValid,
      handleClassificationNumberValid,

      // 4. 基本情報
      handleTransmissionTypeValid,
      handleHandleTypeValid,
      handleExistsCarMaintenanceSheetValid,
      handleCarBodyColorValid,
      handleCarMileageValid,
      handleStatusCarMileageValid,
      handleInventoryLocationValid,
      handleMapUrlValid,
      handleCarStatusValid,
      handleDeliveryTermsValid,
      handleOtherValid,
      handleDescriptionValid,

      // 5. 物件に関する音尾合わせ先
      handleInquiryNameValid,
      handleInquiryOfficeValid,
      handleInquiryEmailValid,
      handleInquiryTelValid,
      handleInquiryTelNum1Valid,
      handleInquiryTelNum2Valid,
      handleInquiryTelNum3Valid,
      handleInquiryExtensionValid,
      handleInquiryAcceptValid,
      handleInquiryNoteValid,

      // 6. 物件の詳細設定
      handleDepositValid,
      handleEstimatePriceValid,
      handleQualifiedInvoiceValid,
      // 7. 落札後の連絡先
      handleContactNameValid,
      handleContactOfficeValid,
      handleContactEmailValid,
      handleContactAcceptValid,
      handleContactTelValid,
      handleContactTelNum1Valid,
      handleContactTelNum2Valid,
      handleContactTelNum3Valid,
      handleContactExtensionValid,

      //handlePaymentDeadlineValid,
      //handlePaymentScheduledDateValid,
      //handleResolutionDateValid
    },
    // invalidの時のメッセージ
    validationMessage: {
      // 2. 物件情報
      printOrderValid,
      isNextBuyAplValid,
      categoryIdValid,
      divisionNameRequiredValid,
      saleNumberRequiredValid,
      titleRequiredValid,
      paymentMethodRequiredValid,
      paymentLimitValid,
      contractedLimitValid,
      resolutionDateValid, // 議決日
      videoUrlValid,

      // 3. 登録事項など証明書の記載内容
      effectiveDateValid,
      registerNumberValid,
      displayRegisterNumberValid,
      registerDateValid,
      firstRegisterDateValid,
      carTypeValid,
      purposeValid,
      purposeOtherValid,
      privateOrBusinessValid,
      shapeValid,
      manufactureValid, // 車名（メーカー）
      manufactureOtherValid, // 車名（メーカー）その他
      modelValid, // 型式
      seatingCapacityValid,
      weightValid,
      totalWeightValid,
      productNumberValid,
      displayProductNumberValid,
      engineModelValid,
      lengthValid,
      widthValid,
      heightValid,
      displacementValid,
      fuelTypeValid,
      frontAxelWeightValid,
      rearAxelWeightValid,
      safetyTermValid,
      statusSafetyTermValid,
      formalNumberValid,
      classificationNumberValid,

      // 4. 基本情報
      transmissionTypeValid,
      handleTypeValid,
      existsCarMaintenanceSheetValid,
      carBodyColorValid,
      carMileageValid,
      statusCarMileageValid,
      inventoryLocationValid,
      mapUrlValid,
      carStatusValid,
      deliveryTermsValid,
      otherValid,
      descriptionValid,

      // 5. 物件に関する音尾合わせ先
      inquiryAcceptValid,
      inquiryEmailValid,
      inquiryOfficeValid,
      inquiryNameValid,
      inquiryTelValid,
      inquiryTelNum1Valid,
      inquiryTelNum2Valid,
      inquiryTelNum3Valid,
      inquiryExtensionValid,
      inquiryNoteValid,

      // 6. 物件の詳細設定
      estimatePriceRequiredValid,
      depositRequiredValid,
      qualifiedInvoiceValid,
      // 7. 落札後の連絡先
      contactAcceptValid,
      contactEmailValid,
      contactOfficeValid,
      contactNameValid,
      contactTelValid,
      contactTelNum1Valid,
      contactTelNum2Valid,
      contactTelNum3Valid,
      contactExtensionValid,

      //contractedLimitValid,
      //paymentDeadlineRequiredValid,
    },
  };

  return validations;
};
