import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import { API_BASE_URL } from '../../config/envConstants.js';

export const createInvitationData = async (body) => {
  return await axios.put(
    `${API_BASE_URL}/api/v1/management/invitations`,
    body,
    { headers: apiHeaders() },
  );
};

export const updateInvitationData = async (body, id) => {
  return await axios.put(
    `${API_BASE_URL}/api/v1/management/invitations/${id}`,
    body,
    { headers: apiHeaders() },
  );
};

export const deleteInvitationData = async (id) => {
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/invitations/${id}`,
    {
      headers: apiHeaders(),
    },
  );
};
