import { useEffect, useState } from 'react';

import SelectBox from './SelectBox';
import CautionDescription from '../../../Form/navi/CautionDescription.jsx';
import FormText from '../../../Form/navi/FormText';
import Label from '../../../Form/navi/Label.jsx';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';

const FormPullDownAndOtherTextWithCaution = ({
  label,
  listItemData = [],
  caution_description,
  handler1,
  required = true,
  selectedData = listItemData[0].value, // DataBaseに保存されている値が取得される
  otherData = '',
  otherHandler,
  otherInvalidMessage,
  otherWidth = 70,
  invalidMessage,
  width = '100%',
}) => {
  const [selectText, setSelectText] = useState('');

  const handleSelect = (e) => {
    handler1(e);
    if (e.target.value === 'OTHER') {
      otherHandler({ target: { value: '' } });
    }
  };

  useEffect(() => {
    let item = listItemData?.find((item) => item.value === selectedData);
    setSelectText(item?.text);
  }, [listItemData, selectedData]);

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <SelectBox
          width={width}
          handler={handleSelect}
          listItemData={listItemData}
          selectedTextData={selectText}
          isInvalid={invalidMessage !== 'TRUE' && invalidMessage !== null}
        />
        {selectedData === 'OTHER' ? (
          <div style={{ marginTop: '10px' }}>
            <FormText
              className={`property-input-text-size-${otherWidth}`}
              invalidMessage={otherInvalidMessage}
              data={otherData}
              handler1={otherHandler}
              maxCharacters={50}
            />
          </div>
        ) : (
          <></>
        )}
        <ValidationMessage invalidMessage={invalidMessage} />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormPullDownAndOtherTextWithCaution;
