// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviCreditCardPagesData,
  deleteNaviCreditCardPagesData,
  updateNaviCreditCardPagesData,
} from '../../../infrastructure/api/NaviCreditCardPages.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { SettingCreditPageCreate } from '../entities/Setting.js';
import { getCreditCardPage } from '../repositories/CreditCardPage.js';
import { bodyToSnake, getValues } from 'shared/utils/helper/convertData.js';

export async function get(type) {
  return await getCreditCardPage(type);
}

export async function findById(type, id) {
  const CreditCardPage = await getCreditCardPage(type);
  return CreditCardPage.filter(
    (data) => data.governmentId.toString() === id.toString(),
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (type === NAVI) {
    const creditCardPage = new SettingCreditPageCreate(data);
    response = await createNaviCreditCardPagesData(
      bodyToSnake(getValues(creditCardPage)),
    );
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create CreditCardPage', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (type === NAVI) {
    const creditCardPage = new SettingCreditPageCreate(data);
    response = await updateNaviCreditCardPagesData(bodyToSnake(getValues(creditCardPage)));
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update CreditCardPage', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (type === NAVI) {
    response = await deleteNaviCreditCardPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (CreditCardPage)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete CreditCardPage', response);
  }
  return response;
}

// function createCreditCardPageArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new CreditCardPage(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
