import { validatePrefecture } from '../../../../application/validations.js';

export class GovernmentSubCategoryId {
  #governmentSubCategoryId;

  constructor(governmentSubCategoryId) {
    this.#governmentSubCategoryId = governmentSubCategoryId;
  }

  get() {
    return parseInt(this.#governmentSubCategoryId);
  }

  get isValid() {
    return [this.validatePrefecture].every((result) => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#governmentSubCategoryId);
  }
}
