import { styled } from 'styled-components';

const AuctionButton = styled.button`
  flex: 1;
`;

export const AuctionBtn = ({ label, disabled, onClick }) => {
  let btn_color = '';
  if (disabled) {
    btn_color = 'v-btn--disabled';
  } else {
    btn_color = 'v-btn--able';
  }
  return (
    <AuctionButton
      data-testid="auction-button"
      disabled={disabled}
      className={`items-action-area-btn--appli btn--shadow-deep v-btn v-btn--contained ${btn_color} theme--light v-size--x-large auction-button`}
      onClick={onClick}>
      <span className="v-btn__content" data-testid="auction-button-label">
        {label}
      </span>
    </AuctionButton>
  );
};
