import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import AfterWinningAssetSaleBidCautionForm from 'interfaces/components/LocalGovernmentManagement/AfterWinningAssetSaleBidCautionForm';
import AfterWinningPublicSaleBidCautionForm from 'interfaces/components/LocalGovernmentManagement/AfterWinningPublicSaleBidCautionForm';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementAfterWinningBid/entities/breadcrumbs';
import { useState } from 'react';

import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';

import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';

import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';

const AfterWinningBid = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();

  return (
    <div
      className="new-style_navi_local-government-management-top-form new-style_navi_local-government-management_after-winning-public_edit"
      data-testid="after-winning-bid-caution">
      {isOpen ? (
        <LocalGovernmentManagementModal
          handleClose={() => setIsOpen(false)}
          procedureType={localStorageProcedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：落札後の注意事項'}
        content={
          '落札後の注意事項を作成します。落札後の注意事項は入札者が落札後の手続きを確認するためのページです。\n' +
          '入力後に「登録」ボタンをクリックしてください。HTMLタグの使用はできません。落札後の注意事項作成の注意事項を必ずご確認ください。\n\n' +
          `${localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' ? '「せり売形式」と「入札形式」で個別にガイドラインを設定することも可能です。' : ''}`
        }
        displayRequired={true}
      />
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />

      <ul className="new-style_navi_no-list-style ma-0 pt-4 pb-0 pl-0 pr-0">
        <li>
          <a
            href="/navi/faq/sample-after-winning-bid"
            target="_blank"
            rel="noopener noreferrer">
            落札後の注意事項サンプル
          </a>
        </li>
        <li>
          <a
            href="/navi/faq/sample-after-winning-bid-caution?from=sample-after-winning-bid"
            target="_blank"
            rel="noopener noreferrer">
            落札後の注意事項サンプル作成の注意事項
          </a>
        </li>
      </ul>
      {localStorageProcedureType === 'ASSET_SALE' ? (
        <AfterWinningAssetSaleBidCautionForm setIsOpen={setIsOpen} />
      ) : (
        <AfterWinningPublicSaleBidCautionForm setIsOpen={setIsOpen} />
      )}
    </div>
  );
};
export default AfterWinningBid;
