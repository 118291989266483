
export class MovableBidFee {
  #movableBidFee

  constructor(movableBidFee) {
    this.#movableBidFee = movableBidFee;
  }

  get() {
    return this.#movableBidFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
