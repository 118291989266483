import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';

import { styled } from 'styled-components';

const BidHistoryTable = styled.table`
  text-align: left;
  width: 100%;
  border-spacing: 0;

  tr:first-child th {
    border-top: 1px solid #e8e6e6;
    border-bottom: 1px solid #f78c23;
  }

  th {
    color: #f78c23;
    font-weight: 500;
    position: relative;
    vertical-align: top;
    padding: 12px 8px 12px 12px;
    width: auto;
    font-size: 0.9rem;
  }

  th:not(:first-child):before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: calc(100% - 16px);
    top: 8px;
    left: 0;
    border-right: 1px solid #f78c23;
  }

  td {
    position: relative;
    vertical-align: top;
    padding: 12px 8px 12px 12px;
    font-weight: 400;
    width: auto;
    border-bottom: 1px solid #e8e6e6;
    font-size: 0.9rem;
  }

  td:not(:first-child):before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: calc(100% - 16px);
    top: 8px;
    left: 0;
    border-right: 1px solid #f78c23;
  }

  td span {
    display: inline-block;
    white-space: nowrap;
    margin-left: 8px;
  }

  .warning-text {
    color: #ec0803;
    caret-color: #ec0803;
  }

  .text-no-wrap {
    white-space: nowrap;
  }

  .text-right {
    text-align: right;
  }
`;

const BidHistory = ({ data }) => {
  return (
    <div>
      <ArticleDefaultTitle title="入札履歴" />
      <ArticleDefaultBody>
        {!data.bidHistory || data.bidHistory.length === 0 ? (
          <div className="text-center">入札はまだありません</div>
        ) : (
          <BidHistoryTable>
            <tbody>
              <tr>
                <th>最終手動入札時間</th>
                <th>入札者</th>
                <th>入札価額</th>
              </tr>
              {
                //data.bidHistory.map((item, index) => (
                data.bidHistory.map((item) => (
                  <tr key={item.id}>
                    <td>{item.time}</td>
                    <td>
                      {item.bidStatus === 'CANCEL' ? (
                        <span className="warning-text">取り消されました。</span>
                      ) : (
                        item.membershipId
                      )}
                      {item.bidStatus === 'TOP_PRICE' ||
                      item.bidStatus === 'LOT_TOP_PRICE' ||
                      item.bidStatus === 'ADD_LOT_TOP_PRICE' ? (
                        <span className="warning-text">最高額入札者</span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="text-no-wrap text-right">
                      {item.price}
                      <small>円</small>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </BidHistoryTable>
        )}
      </ArticleDefaultBody>
    </div>
  );
};

export default BidHistory;
