import camelizeKeys from './camelize.js';
import CustomError from './customError.js';

export const fetchData = async (path) => {
  try {
    const response = await fetch(path);
    const jsonData = await response.json();

    const normalizedData = Array.isArray(jsonData)
      ? jsonData // すでに配列の場合はそのまま
      : Object.entries(jsonData).map(([id, value]) => ({
          id,
          ...value,
        }));

    return normalizedData.map((data) => camelizeKeys(data));
  } catch (error) {
    throw new CustomError(`Failed to fetch or parse JSON:  ${path}`);
  }
};

export const allFetch = async (paths) => {
  const promises = Object.values(paths).map(fetchData);
  return await Promise.all(promises);
};
