//import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DATA_EMPTY } from 'shared/utils/helper/constants.js';
import { AuctionHeaderContext } from 'interfaces/pages/Settings/entities/AuctionHeaderContext.js';

const Breadcrumb = () => {
  const { breadcrumbs } = useContext(AuctionHeaderContext);

  const crumbsSize = breadcrumbs?.length;

  if (breadcrumbs === DATA_EMPTY) {
    return <div className="breadcrumb" data-testid="breadcrumb"></div>;
  }

  return (
    <div className="breadcrumb" data-testid="breadcrumb">
      {breadcrumbs?.map((breadcrumb, index) => {
        return (
          <div key={index} className="flex">
            {breadcrumb?.to ? (
              <Link to={breadcrumb.to} className="crumb-link">
                {breadcrumb.title}
              </Link>
            ) : (
              <div>{breadcrumb.title}</div>
            )}
            {index === crumbsSize - 1 ? null : (
              <span className="crumb-separator" data-testid="crumb-separator">
                &gt;
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
