import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsAfterWinningPublicSaleBidCaution } from 'interfaces/components/common/validateInputs/validateInputsAfterWinningAssetSaleBidCaution';
import BothForm from 'interfaces/components/LocalGovernmentManagement/after_winning/asset/BothForm';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/after_winning/asset/IndividualForm';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssetSaleCautionPage } from 'shared/hooks/useAssetSaleCautionPage';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest';

const AfterWinningAssetSaleBidCautionForm = ({ setIsOpen }) => {
  const auction = validateInputsAfterWinningPublicSaleBidCaution();
  const bid = validateInputsAfterWinningPublicSaleBidCaution();
  const [individualSetting, setIndividualSetting] = useState(false);

  const { localStorageGovernmentId, localStorageGovernmentType } =
    useLocalStorage();

  // eslint-disable-next-line no-unused-vars
  const { auctionAssetSaleCautionPages, bidAssetSaleCautionPages, loading } =
    useAssetSaleCautionPage(Number(localStorageGovernmentId));

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      const validationObject = Object.entries(validationMessage).reduce(
        (acc, [key, value]) => {
          acc[key] = value;
          return acc;
        },
        {},
      );

      return Object.entries(validationObject).every(
        ([key, validationMessage]) => {
          const requiredKeys = [
            data?.auctionInquiryDisplay === 'DISPLAY' &&
              'auctionInquiryNameValid',
            data?.auctionDocumentPageDisplay === 'DISPLAY' &&
              'auctionDocumentPageNameValid',
            data?.auctionDocumentPageDisplay === 'DISPLAY' &&
              'auctionDocumentPageUrlValid',
          ].filter(Boolean);
          if (requiredKeys.includes(key)) {
            return validationMessage === 'TRUE';
          }
          return validationMessage === null || validationMessage === 'TRUE';
        },
      );
    };

    if (individualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, individualSetting]);

  const performUpdate = async () => {
    // TODO まだ疎通してない,URL直す
    const url = `${API_BASE_URL}/api/v1/navi/credit_card_page`;
    
    console.log(auction);
    try {
      await apiRequest('put', url, ...auction);
      if (individualSetting) {
        await apiRequest('put', url, ...bid);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    auction.init.setAuctionTransRightDescription(
      auctionAssetSaleCautionPages[0]?.transRightDescription,
    );
    auction.init.setAuctionCostContractTitle1(
      auctionAssetSaleCautionPages[0]?.costContractTitle1,
    );
    auction.init.setAuctionCostContractDescription1(
      auctionAssetSaleCautionPages[0]?.costContractDescription1,
    );
    auction.init.setAuctionCostContractTitle2(
      auctionAssetSaleCautionPages[0]?.costContractTitle2,
    );
    auction.init.setAuctionCostContractDescription2(
      auctionAssetSaleCautionPages[0]?.costContractDescription2,
    );
    auction.init.setAuctionCostContractTitle3(
      auctionAssetSaleCautionPages[0]?.costContractTitle3,
    );
    auction.init.setAuctionCostContractDescription3(
      auctionAssetSaleCautionPages[0]?.costContractDescription3,
    );
    auction.init.setAuctionCostPaymentTitle1(
      auctionAssetSaleCautionPages[0]?.costPaymentTitle1,
    );
    auction.init.setAuctionCostPaymentDescription1(
      auctionAssetSaleCautionPages[0]?.costPaymentDescription1,
    );
    auction.init.setAuctionCostPaymentTitle2(
      auctionAssetSaleCautionPages[0]?.costPaymentTitle2,
    );
    auction.init.setAuctionCostPaymentDescription2(
      auctionAssetSaleCautionPages[0]?.costPaymentDescription2,
    );
    auction.init.setAuctionCostPaymentTitle3(
      auctionAssetSaleCautionPages[0]?.costPaymentTitle3,
    );
    auction.init.setAuctionCostPaymentDescription3(
      auctionAssetSaleCautionPages[0]?.costPaymentDescription3,
    );
    auction.init.setAuctionCostPaymentTitle4(
      auctionAssetSaleCautionPages[0]?.costPaymentTitle4,
    );
    auction.init.setAuctionCostPaymentDescription4(
      auctionAssetSaleCautionPages[0]?.costPaymentDescription4,
    );
    auction.init.setAuctionCostPaymentTitle5(
      auctionAssetSaleCautionPages[0]?.costPaymentTitle5,
    );
    auction.init.setAuctionCostPaymentDescription5(
      auctionAssetSaleCautionPages[0]?.costPaymentDescription5,
    );
    auction.init.setAuctionCostAttention(
      auctionAssetSaleCautionPages[0]?.costAttention,
    );
    auction.init.setAuctionDocumentPageDisplay(
      auctionAssetSaleCautionPages[0]?.documentPageDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionDocumentPageName(
      auctionAssetSaleCautionPages[0]?.documentPageName ?? '',
    );
    auction.init.setAuctionDocumentPageUrl(
      auctionAssetSaleCautionPages[0]?.documentPageUrl ?? '',
    );
    auction.init.setAuctionDocumentDescription(
      auctionAssetSaleCautionPages[0]?.documentDescription,
    );
    auction.init.setAuctionOtherTransRightDescription(
      auctionAssetSaleCautionPages[0]?.otherTransRightDescription,
    );
    auction.init.setAuctionOtherTransRightAttention(
      auctionAssetSaleCautionPages[0]?.otherTransRightAttention,
    );
    auction.init.setAuctionTransRightDate(
      auctionAssetSaleCautionPages[0]?.transRightDate,
    );
    auction.init.setAuctionTransRightCompletedTime(
      auctionAssetSaleCautionPages[0]?.transRightCompletedTime,
    );
    auction.init.setAuctionImportantRiskBearing(
      auctionAssetSaleCautionPages[0]?.importantRiskBearing,
    );
    auction.init.setAuctionImportantTermsDelivery(
      auctionAssetSaleCautionPages[0]?.importantTermsDelivery,
    );
    auction.init.setAuctionImportantHandlingBidDeposit(
      auctionAssetSaleCautionPages[0]?.importantHandlingBidDeposit,
    );
    auction.init.setAuctionImportantHandlingContractDeposit(
      auctionAssetSaleCautionPages[0]?.importantHandlingContractDeposit,
    );
    auction.init.setAuctionImportantRestrictionUse(
      auctionAssetSaleCautionPages[0]?.importantRestrictionUse,
    );
    auction.init.setAuctionImportantRestrictionUseBefore(
      auctionAssetSaleCautionPages[0]?.importantRestrictionUseBefore,
    );
    auction.init.setAuctionImportantOther1(
      auctionAssetSaleCautionPages[0]?.importantOther1,
    );
    auction.init.setAuctionImportantDescription1(
      auctionAssetSaleCautionPages[0]?.importantDescription1,
    );
    auction.init.setAuctionImportantOther2(
      auctionAssetSaleCautionPages[0]?.importantOther2,
    );
    auction.init.setAuctionImportantDescription2(
      auctionAssetSaleCautionPages[0]?.importantDescription2,
    );
    auction.init.setAuctionImportantOther3(
      auctionAssetSaleCautionPages[0]?.importantOther3,
    );
    auction.init.setAuctionImportantDescription3(
      auctionAssetSaleCautionPages[0]?.importantDescription3,
    );
    auction.init.setAuctionImportantAttention(
      auctionAssetSaleCautionPages[0]?.importantAttention,
    );
    auction.init.setAuctionInquiryDisplay(
      auctionAssetSaleCautionPages[0]?.inquiryDisplay ?? 'DISPLAY',
    );
    auction.init.setAuctionInquiryName(
      auctionAssetSaleCautionPages[0]?.inquiryName ?? '',
    );
    auction.init.setAuctionInquiryEmail(
      auctionAssetSaleCautionPages[0]?.inquiryEmail,
    );
    const splitAuctionInquiryTel =
      auctionAssetSaleCautionPages[0]?.inquiryTel?.split('-');
    auction.init.setAuctionInquiryTel({
      telNum1: splitAuctionInquiryTel ? splitAuctionInquiryTel[0] : '',
      telNum2: splitAuctionInquiryTel ? splitAuctionInquiryTel[1] : '',
      telNum3: splitAuctionInquiryTel ? splitAuctionInquiryTel[2] : '',
    });
    auction.init.setAuctionInquiryExtension(
      auctionAssetSaleCautionPages[0]?.inquiryExtension,
    );
    auction.init.setAuctionInquiryAccept(
      auctionAssetSaleCautionPages[0]?.inquiryAccept,
    );

    bid.init.setAuctionTransRightDescription(
      bidAssetSaleCautionPages[0]?.transRightDescription,
    );
    bid.init.setAuctionCostContractTitle1(
      bidAssetSaleCautionPages[0]?.costContractTitle1,
    );
    bid.init.setAuctionCostContractDescription1(
      bidAssetSaleCautionPages[0]?.costContractDescription1,
    );
    bid.init.setAuctionCostContractTitle2(
      bidAssetSaleCautionPages[0]?.costContractTitle2,
    );
    bid.init.setAuctionCostContractDescription2(
      bidAssetSaleCautionPages[0]?.costContractDescription2,
    );
    bid.init.setAuctionCostContractTitle3(
      bidAssetSaleCautionPages[0]?.costContractTitle3,
    );
    bid.init.setAuctionCostContractDescription3(
      bidAssetSaleCautionPages[0]?.costContractDescription3,
    );
    bid.init.setAuctionCostPaymentTitle1(
      bidAssetSaleCautionPages[0]?.costPaymentTitle1,
    );
    bid.init.setAuctionCostPaymentDescription1(
      bidAssetSaleCautionPages[0]?.costPaymentDescription1,
    );
    bid.init.setAuctionCostPaymentTitle2(
      bidAssetSaleCautionPages[0]?.costPaymentTitle2,
    );
    bid.init.setAuctionCostPaymentDescription2(
      bidAssetSaleCautionPages[0]?.costPaymentDescription2,
    );
    bid.init.setAuctionCostPaymentTitle3(
      bidAssetSaleCautionPages[0]?.costPaymentTitle3,
    );
    bid.init.setAuctionCostPaymentDescription3(
      bidAssetSaleCautionPages[0]?.costPaymentDescription3,
    );
    bid.init.setAuctionCostPaymentTitle4(
      bidAssetSaleCautionPages[0]?.costPaymentTitle4,
    );
    bid.init.setAuctionCostPaymentDescription4(
      bidAssetSaleCautionPages[0]?.costPaymentDescription4,
    );
    bid.init.setAuctionCostPaymentTitle5(
      bidAssetSaleCautionPages[0]?.costPaymentTitle5,
    );
    bid.init.setAuctionCostPaymentDescription5(
      bidAssetSaleCautionPages[0]?.costPaymentDescription5,
    );
    bid.init.setAuctionCostAttention(
      bidAssetSaleCautionPages[0]?.costAttention,
    );
    bid.init.setAuctionDocumentPageDisplay(
      bidAssetSaleCautionPages[0]?.documentPageDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionDocumentPageName(
      bidAssetSaleCautionPages[0]?.documentPageName ?? '',
    );
    bid.init.setAuctionDocumentPageUrl(
      bidAssetSaleCautionPages[0]?.documentPageUrl ?? '',
    );
    bid.init.setAuctionDocumentDescription(
      bidAssetSaleCautionPages[0]?.documentDescription,
    );
    bid.init.setAuctionOtherTransRightDescription(
      bidAssetSaleCautionPages[0]?.otherTransRightDescription,
    );
    bid.init.setAuctionOtherTransRightAttention(
      bidAssetSaleCautionPages[0]?.otherTransRightAttention,
    );
    bid.init.setAuctionTransRightDate(
      bidAssetSaleCautionPages[0]?.transRightDate,
    );
    bid.init.setAuctionTransRightCompletedTime(
      bidAssetSaleCautionPages[0]?.transRightCompletedTime,
    );
    bid.init.setAuctionImportantRiskBearing(
      bidAssetSaleCautionPages[0]?.importantRiskBearing,
    );
    bid.init.setAuctionImportantTermsDelivery(
      bidAssetSaleCautionPages[0]?.importantTermsDelivery,
    );
    bid.init.setAuctionImportantHandlingBidDeposit(
      bidAssetSaleCautionPages[0]?.importantHandlingBidDeposit,
    );
    bid.init.setAuctionImportantHandlingContractDeposit(
      bidAssetSaleCautionPages[0]?.importantHandlingContractDeposit,
    );
    bid.init.setAuctionImportantRestrictionUse(
      bidAssetSaleCautionPages[0]?.importantRestrictionUse,
    );
    bid.init.setAuctionImportantRestrictionUseBefore(
      bidAssetSaleCautionPages[0]?.importantRestrictionUseBefore,
    );
    bid.init.setAuctionImportantOther1(
      bidAssetSaleCautionPages[0]?.importantOther1,
    );
    bid.init.setAuctionImportantDescription1(
      bidAssetSaleCautionPages[0]?.importantDescription1,
    );
    bid.init.setAuctionImportantOther2(
      bidAssetSaleCautionPages[0]?.importantOther2,
    );
    bid.init.setAuctionImportantDescription2(
      bidAssetSaleCautionPages[0]?.importantDescription2,
    );
    bid.init.setAuctionImportantOther3(
      bidAssetSaleCautionPages[0]?.importantOther3,
    );
    bid.init.setAuctionImportantDescription3(
      bidAssetSaleCautionPages[0]?.importantDescription3,
    );
    bid.init.setAuctionImportantAttention(
      bidAssetSaleCautionPages[0]?.importantAttention,
    );
    bid.init.setAuctionInquiryDisplay(
      bidAssetSaleCautionPages[0]?.inquiryDisplay ?? 'DISPLAY',
    );
    bid.init.setAuctionInquiryName(
      bidAssetSaleCautionPages[0]?.inquiryName ?? '',
    );
    bid.init.setAuctionInquiryEmail(bidAssetSaleCautionPages[0]?.inquiryEmail);
    const splitBidInquiryTel =
      bidAssetSaleCautionPages[0]?.inquiryTel?.split('-');
    bid.init.setAuctionInquiryTel({
      telNum1: splitBidInquiryTel ? splitBidInquiryTel[0] : '',
      telNum2: splitBidInquiryTel ? splitBidInquiryTel[1] : '',
      telNum3: splitBidInquiryTel ? splitBidInquiryTel[2] : '',
    });
    bid.init.setAuctionInquiryExtension(
      bidAssetSaleCautionPages[0]?.inquiryExtension,
    );
    bid.init.setAuctionInquiryAccept(
      bidAssetSaleCautionPages[0]?.inquiryAccept,
    );
  }, [auctionAssetSaleCautionPages, bidAssetSaleCautionPages]);

  const navigate = useNavigate();

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  return (
    <div className="after-winning-bid-caution">
      {localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY' && (
        <Toggle
          message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
          handler={() => setIndividualSetting(!individualSetting)}
        />
      )}
      {!individualSetting && <BothForm validations={auction} />}
      {individualSetting && (
        <IndividualForm auctionData={auction} bidData={bid} />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={'property-info-general-form-button'}
              isDisabled={!isValid}
              onClick={performUpdate}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AfterWinningAssetSaleBidCautionForm;
