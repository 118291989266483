
export class EstateBidFeeType {
  #estateBidFeeType

  constructor(estateBidFeeType) {
    this.#estateBidFeeType = estateBidFeeType;
  }

  get() {
    return this.#estateBidFeeType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
