import { useEffect, useState } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { PropertyDetailContext } from '../entities/PropertyDetailContext.js';
import { findById as findProperty } from 'domain/Property/services/Property.js';
import { findById as findAuction } from 'domain/Auction/services/Auction.js';
import { findByIds as findAuctionUsers } from 'domain/User/services/ServiceUser.js';
import { findById as findGovernment } from 'domain/Government/services/Government.js';
import { findByIdAndUserId as findProposal } from 'domain/Applicant/services/Proposal.js';
import { findUserMaxBidPrice as findMaxBidderHistory } from 'domain/Bid/services/BidderHistory.js';
import { findById as findInvitation } from 'domain/Auction/services/Invitation.js';
import { findByPropertyId as findPaymentMethods } from 'domain/Property/services/PaymentMethod.js';
import { findById as findCarProperty } from 'domain/Property/services/CarProperty.js';
import { findByPropertyId as findEstateProperty } from 'domain/Property/services/EstateProperty.js';
import { useParams } from 'react-router-dom';
import { findByGovernmentId as findGuidelines } from 'domain/Guidelines/services/Guidelines.js';
import { findByGovernmentId as findPublicSaleCautionPages } from 'domain/Setting/services/PublicSaleCaution.js';
import { findByGovernmentId as findAssetSaleCautionPages } from 'domain/Setting/services/AssetSaleCaution.js';
import { findByGovernmentIdAndUserId as findBlockList } from 'domain/List/services/BlockList.js';

const PropertyDetailProvider = ({ type, children }) => {
  const [property, setProperty] = useState(undefined);
  const [guidelines, setGuidelines] = useState([]);
  const [cautionPages, setCautionPages] = useState([]);
  const [isBlocklist, setIsBlocklist] = useState(false);

  const { id } = useParams();
  const { localStorageUserId } = useLocalStorage();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      (async () => {
        const property = await findProperty(type, id);
        const retCarProperty = await findCarProperty(type, property.id);
        const retEstateProperty = await findEstateProperty(type, property.id);
        const paymentMethods = await findPaymentMethods(type, id);
        const auction = await findAuction(type, property.auctionId);
        const government = await findGovernment(type, property.governmentId);
        const invitation = await findInvitation(
          type,
          property.auctionId,
          property.governmentId,
        );
        const proposal = await findProposal(type, id, localStorageUserId);
        const maxBidderHistories = await findMaxBidderHistory(
          type,
          id,
          localStorageUserId,
        );
        let tmpUserId = maxBidderHistories.bidderHistories.map(
          (history) => history.auctionUserId,
        );
        let userIds = [...new Set(tmpUserId)];

        const auctionUsers = await findAuctionUsers(type, userIds);
        const blockList = await findBlockList(
          type,
          government.id,
          localStorageUserId,
          auction?.procedureType,
        );
        const guidelines = await findGuidelines(
          type,
          government.id,
          auction?.procedureType,
        );

        let myBidder = {
          myBidPrice: maxBidderHistories?.maxPriceUserBidder?.price,
          myAdditionalPrice: maxBidderHistories?.maxPriceUserAddBidder?.price,
          bidCount: maxBidderHistories?.bidderCount,
        };

        let myAuctionUser = {
          membershipId: auctionUsers.find(
            (user) => user.id === localStorageUserId,
          )?.membershipId,
        };

        // 入札期間中の最高額の入札者
        let currentTopUser = {
          currentTopUser: auctionUsers.find(
            (user) =>
              user.id === maxBidderHistories.maxPriceBidder.auctionUserId,
          )?.membershipId,
          currentTopPrice: maxBidderHistories?.maxPriceBidder?.price,
        };

        // 入札後の最高額の入札者
        let biddedUser = {
          bidderUser: auctionUsers.find(
            (user) => user.id === property.auctionUserId,
          )?.membershipId,
        };

        // 入札後の最高額の次順位の入札者
        let nextWinner = {
          nextWinner: auctionUsers.find(
            (user) => user.id === property.nextAuctionUserId,
          )?.membershipId,
        };

        let paymentMethod = {
          payments: paymentMethods,
        };

        if (property.status === 'APPLYING') {
          property.remainingLabel = '申込締切まであと';
          property.deadline = auction.aplEnd;
        } else if (property.status === 'DURING_BIDDING') {
          property.remainingLabel = '入札締切まであと';
          property.deadline = auction.bidEnd;
        } else if (property.status === 'WAIT_START_BID') {
          property.remainingLabel = '入札開始まであと';
          property.deadline = auction.bidStart;
        } else if (property.status === 'ADDITIONAL_DURING_BIDDING') {
          property.remainingLabel = '追加入札締切まであと';
          property.deadline = auction.bidEnd;
        }

        let carProperty = {
          carProperty: retCarProperty,
        };
        let estateProperty = {
          estateProperty: retEstateProperty,
        };

        let bidHistories = [];
        maxBidderHistories.bidderHistories?.forEach((history) => {
          history.membershipId = auctionUsers.find(
            (user) => user.id === history.auctionUserId,
          )?.membershipId;
          bidHistories.push(history);
        });

        let bidderHistories = {
          bidHistory: bidHistories,
        };

        government.governmentName = government.name;

        let tags = [];
        tags.push(
          auction.procedureType === 'ASSET_SALE'
            ? '公有財産売却'
            : 'インターネット公売',
        );
        tags.push(
          property.publicSaleType === 'AUCTION' ? 'せり売形式' : '入札形式',
        );

        if (invitation) {
          let tag = '';
          if (
            invitation.paymentCreditCard === 'TRUE' ||
            invitation.paymentBankTransfer === 'TRUE'
          ) {
            tag = '保証金あり';
            if (invitation.paymentCreditCard === 'TRUE') {
              tag += '(クレカ納付OK)';
            }
          } else {
            tag = '保証金なし';
          }
          tags.push(tag);
        }

        let mergedProperty = {
          ...government,
          ...auction,
          ...invitation,
          ...currentTopUser,
          ...myAuctionUser,
          ...myBidder,
          ...proposal,
          ...property,
          ...carProperty,
          ...estateProperty,
          ...paymentMethod,
          ...biddedUser,
          ...nextWinner,
          ...bidderHistories,
          ...{ tags: tags },
        };
        setProperty(mergedProperty);

        let guideline = guidelines.filter((guideline) => {
          return guideline.publicSaleType === property.publicSaleType;
        });
        let retGuidelines = [guideline];
        setGuidelines(retGuidelines);

        if (blockList !== undefined) {
          setIsBlocklist(true);
        }

        if (auction.procedureType === 'PUBLIC_SALE') {
          const publicSaleCautionPages = await findPublicSaleCautionPages(
            type,
            government.id,
          );
          let cautionPage = publicSaleCautionPages.find((cautionPage) => {
            return cautionPage.publicSaleType === property.publicSaleType;
          });
          let retCautionPages = [];
          if (cautionPage) {
            retCautionPages.push(cautionPage);
          }
          setCautionPages(retCautionPages);
        } else {
          const assetSaleCautionPages = await findAssetSaleCautionPages(
            type,
            government.id,
          );
          let cautionPage = assetSaleCautionPages.find((cautionPage) => {
            return cautionPage.publicSaleType === property.publicSaleType;
          });
          let retCautionPages = [];
          if (cautionPage) {
            retCautionPages.push(cautionPage);
          }
          setCautionPages(retCautionPages);
        }
      })();

      // 物件情報取得
      //findProperty(type, id).then((property) => {
      //  // オークション情報取得
      //  findCarProperty(type, property.id).then((retCarProperty) => {
      //    findEstateProperty(type, property.id).then((retEstateProperty) => {
      //      findPaymentMethods(type, id).then((paymentMethods) => {
      //        findAuction(type, property.auctionId).then((auction) => {
      //          findGovernment(type, property.governmentId).then(
      //            (government) => {
      //              findInvitation(
      //                type,
      //                property.auctionId,
      //                property.governmentId,
      //              ).then((invitation) => {
      //                // 入札申込情報取得（申込ステータス確認のため）
      //                findProposal(type, id, localStorageUserId).then(
      //                  (proposal) => {
      //                    // 入札履歴情報取得（物件の最大入札額・ユーザが入札した最大入札額・ユーザが入札した追加入札の最大入札額）
      //                    findMaxBidderHistory(
      //                      type,
      //                      id,
      //                      localStorageUserId,
      //                    ).then((maxBidderHistories) => {
      //                      let tmpUserId =
      //                        maxBidderHistories.bidderHistories.map(
      //                          (history) => history.auctionUserId,
      //                        );
      //                      let userIds = [...new Set(tmpUserId)];

      //                      findAuctionUsers(type, userIds).then(
      //                        (auctionUsers) => {

      //                          findBlockList(type, government.id, localStorageUserId, auction.procedureType).then((blockList) => {
      //                            if (blockList !== undefined) {
      //                            setIsBlocklist(true);
      //                            }

      //                            let myBidder = {
      //                              myBidPrice:
      //                                maxBidderHistories?.maxPriceUserBidder
      //                                  ?.price,
      //                              myAdditionalPrice:
      //                                maxBidderHistories?.maxPriceUserAddBidder
      //                                  ?.price,
      //                              bidCount: maxBidderHistories?.bidderCount,
      //                            };

      //                            let myAuctionUser = {
      //                              membershipId: auctionUsers.find(
      //                                (user) => user.id === localStorageUserId,
      //                              )?.membershipId,
      //                            };

      //                            // 入札期間中の最高額の入札者
      //                            let currentTopUser = {
      //                              currentTopUser: auctionUsers.find(
      //                                (user) =>
      //                                  user.id ===
      //                                  maxBidderHistories.maxPriceBidder
      //                                    .auctionUserId,
      //                              )?.membershipId,
      //                              currentTopPrice:
      //                                maxBidderHistories?.maxPriceBidder?.price,
      //                            };

      //                            // 入札後の最高額の入札者
      //                            let biddedUser = {
      //                              bidderUser: auctionUsers.find(
      //                                (user) =>
      //                                  user.id === property.auctionUserId,
      //                              )?.membershipId,
      //                            };

      //                            // 入札後の最高額の次順位の入札者
      //                            let nextWinner = {
      //                              nextWinner: auctionUsers.find(
      //                                (user) =>
      //                                  user.id === property.nextAuctionUserId,
      //                              )?.membershipId,
      //                            };

      //                            let paymentMethod = {
      //                              payments: paymentMethods,
      //                            };

      //                            if (property.status === 'APPLYING') {
      //                              property.remainingLabel = '申込締切まであと';
      //                              property.deadline = auction.aplEnd;
      //                            } else if (
      //                              property.status === 'DURING_BIDDING'
      //                            ) {
      //                              property.remainingLabel = '入札締切まであと';
      //                              property.deadline = auction.bidEnd;
      //                            } else if (
      //                              property.status === 'WAIT_START_BID'
      //                            ) {
      //                              property.remainingLabel = '入札開始まであと';
      //                              property.deadline = auction.bidStart;
      //                            } else if (
      //                              property.status ===
      //                              'ADDITIONAL_DURING_BIDDING'
      //                            ) {
      //                              property.remainingLabel =
      //                                '追加入札締切まであと';
      //                              property.deadline = auction.bidEnd;
      //                            }

      //                            let carProperty = {
      //                              carProperty: retCarProperty,
      //                            };
      //                            let estateProperty = {
      //                              estateProperty: retEstateProperty,
      //                            };

      //                            let bidHistories = [];
      //                            maxBidderHistories.bidderHistories?.forEach(
      //                              (history) => {
      //                                history.membershipId = auctionUsers.find(
      //                                  (user) =>
      //                                    user.id === history.auctionUserId,
      //                                )?.membershipId;
      //                                bidHistories.push(history);
      //                              },
      //                            );

      //                            let bidderHistories = {
      //                              bidHistory: bidHistories,
      //                            };

      //                            government.governmentName = government.name;

      //                            let tags = [];
      //                            tags.push(
      //                              auction.procedureType === 'ASSET_SALE'
      //                                ? '公有財産売却'
      //                                : 'インターネット公売',
      //                            );
      //                            tags.push(
      //                              property.publicSaleType === 'AUCTION'
      //                                ? 'せり売形式'
      //                                : '入札形式',
      //                            );

      //                            if (invitation) {
      //                              let tag = '';
      //                              if (
      //                                invitation.paymentCreditCard === 'TRUE' ||
      //                                invitation.paymentBankTransfer === 'TRUE'
      //                              ) {
      //                                tag = '保証金あり';
      //                                if (
      //                                  invitation.paymentCreditCard === 'TRUE'
      //                                ) {
      //                                  tag += '(クレカ納付OK)';
      //                                }
      //                              } else {
      //                                tag = '保証金なし';
      //                              }
      //                              tags.push(tag);
      //                            }

      //                            let mergedProperty = {
      //                              ...government,
      //                              ...auction,
      //                              ...invitation,
      //                              ...currentTopUser,
      //                              ...myAuctionUser,
      //                              ...myBidder,
      //                              ...proposal,
      //                              ...property,
      //                              ...carProperty,
      //                              ...estateProperty,
      //                              ...paymentMethod,
      //                              ...biddedUser,
      //                              ...nextWinner,
      //                              ...bidderHistories,
      //                              ...{ tags: tags },
      //                            };

      //                            setProperty(mergedProperty);

      //                          })
      //                        },
      //                      );
      //                    });
      //                  },
      //                );
      //              });

      //              findGuidelines(
      //                type,
      //                government.id,
      //                auction.procedureType,
      //              ).then((guidelines) => {
      //                let guideline = guidelines.filter((guideline) => {
      //                  return (
      //                    guideline.publicSaleType === property.publicSaleType
      //                  );
      //                });
      //                let retGuidelines = [guideline];

      //                setGuidelines(retGuidelines);
      //              });

      //              if (auction.procedureType === 'PUBLIC_SALE') {
      //                findPublicSaleCautionPages(type, government.id).then(
      //                  (publicSaleCautionPages) => {
      //                    let cautionPage = publicSaleCautionPages.find(
      //                      (cautionPage) => {
      //                        return (
      //                          cautionPage.publicSaleType ===
      //                          property.publicSaleType
      //                        );
      //                      },
      //                    );
      //                    let retCautionPages = [];
      //                    if (cautionPage) {
      //                      retCautionPages.push(cautionPage);
      //                    }
      //                    setCautionPages(retCautionPages);
      //                  },
      //                );
      //              } else {
      //                findAssetSaleCautionPages(type, government.id).then(
      //                  (assetSaleCautionPages) => {
      //                    let cautionPage = assetSaleCautionPages.find(
      //                      (cautionPage) => {
      //                        return (
      //                          cautionPage.publicSaleType ===
      //                          property.publicSaleType
      //                        );
      //                      },
      //                    );
      //                    let retCautionPages = [];
      //                    if (cautionPage) {
      //                      retCautionPages.push(cautionPage);
      //                    }
      //                    setCautionPages(retCautionPages);
      //                  },
      //                );
      //              }
      //            },
      //          );
      //        });
      //      });
      //    });
      //  });
      //});
    }, [id, localStorageUserId]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <PropertyDetailContext.Provider
      value={{
        property,
        guidelines,
        cautionPages,
        isBlocklist,
      }}>
      {children}
    </PropertyDetailContext.Provider>
  );
};

export default PropertyDetailProvider;
