import { useEffect, useState } from 'react';
import { dateFormat } from "shared/utils/helper/date_format.js";
// import * as auctionFilters from '../helpers/auctionFilters';

export const useAppliableAuctions = (allAuctions, invitations) => {
  const [auctions, setAuctions] = useState([]);
  useEffect(() => {
    (async () => {
      const requests = [
        allAuctions,
        //await getInvitations(GOVERNMENT_ID),
        invitations,
      ];

      const [auctionsResponse, invitationsResponse] =
        await Promise.all(requests);
      const data = {
        initialAuctions: auctionsResponse,
        invitations: invitationsResponse,
        appliedInvitationsIds: [],
        noAppliedAuctions: [],
        appliableAuctions: [],
        auctions: [],
      };

      // const auctions = await getAuctions();
      // const invitations = await getInvitations();
      // const appliedIds = invitations.map(invitation => {
      //   return invitation.id;
      // });

      // let filtered = auctions;

      // filtered = auctionFilters.filterByProcedure(filtered, PROCEDURE_TYPE);
      // filtered = auctionFilters.filterByApplied(filtered, appliedIds);
      // filtered = auctionFilters.filterByPeriod(filtered);

      // ログインユーザのprocedureTypeでオークションを絞り込み
      if (data.initialAuctions?.length === 0) {
        return;
      }

      // すでに参加している公告のid一覧
      data.invitations.map((invitation) =>
        data.appliedInvitationsIds.push(invitation.id),
      );

      // 非参加のオークション一覧
      data.noAppliedAuctions = data.initialAuctions?.filter((auction) => {
        const auctionId = auction.id.toString();
        return !data.appliedInvitationsIds.includes(auctionId);
      });
      if (data.noAppliedAuctions?.length === 0) {
        return;
      }

      data.appliableAuctions = data.noAppliedAuctions?.filter((auction) => {
        const govAplEnd = auction.govAplEnd;
        const govAplStart = auction.govAplStart;
        const currentTime = new Date().getTime();
        const govAplEndTime = new Date(govAplEnd).getTime();
        const govAplStartTime = new Date(govAplStart).getTime();

        return govAplStartTime <= currentTime && currentTime <= govAplEndTime;
      });
      if (data.appliableAuctions?.length === 0) {
        return;
      }

      const publicSaleTypeTranslateRules = {
        DATA_EMPTY: '',
        BOTH: '（せり売/入札）',
        AUCTION: '（せり売）',
        BID: '（入札）',
      };
      data.appliableAuctions?.map((auction) => {
        data.auctions.push({
          id: auction.id,
          value: auction.id,
          //text: `${auction.name} - ${dateFormat(auction.aplStart, 'YYYY-MM-DD')} ${publicSaleTypeTranslateRules[auction.publicSaleType]}`,
          text: `${auction.name} - ${dateFormat(auction.aplStart, 'YYYY-MM-DD')} ${publicSaleTypeTranslateRules[auction.publicSaleType]}`,
          //aplStart:`${dateFormat(auction.aplStart, 'YYYY-MM-DD')}`,
          aplStart: `${auction.aplStart}`,
          publicSaleType: auction.publicSaleType,
        });
      });

      setAuctions(data.auctions);
    })();
  }, [allAuctions]);

  return auctions;
};
