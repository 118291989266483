import React from 'react';

const InputUserName = React.memo(function InputUserName({ state, setState }) {
  return (
    <div className="form-area">
      <div className="form-area_input-wrap">
        <div className="form-area_input-wrap_icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z" />
          </svg>
        </div>
        <div className="form-area_input-wrap_input">
          <div className="form-area_input-wrap_input_placeholeder">
            ユーザー名
          </div>
          <input
            type="text"
            aria-label="ユーザー名"
            data-testid="input-username"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
});
export default InputUserName;
