import CautionDescription from './CautionDescription.jsx';
import FormCheckbox from './FormCheckbox';
import Label from './Label.jsx';
import ValidationMessage from './ValidationMessage';
import 'interfaces/css/common/property-info-general-form-input-check.css';

const FormCheckboxWithCaution = ({
  caution_description,
  checkbox_label,
  class_name,
  data = {},
  label,
  required,
  handler,
  isHorizon = true,
  invalidMessage = '',
}) => {
  const handleCheckboxChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;

    if (handler) {
      handler({ [name]: checked });
    }
  };

  return (
    <div
      className="property-info-general-form-table-row"
      data-testid={`form-checkbox-with-caution-${label}`}>
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        {isHorizon ? (
          <div
            className={
              class_name
                ? 'property-info-general-form-input-check-' + class_name
                : 'property-info-general-form-input-check'
            }>
            {checkbox_label?.map((label) => {
              return (
                <div
                  className={'flex'}
                  key={label.id}
                  data-testid="form-checkbox">
                  <FormCheckbox
                    id={label.name}
                    name={label.name ?? ''}
                    handleCheckboxChange={handleCheckboxChange}
                    data={data[label.name] ?? false}
                  />
                  <label
                    htmlFor={label?.name}
                    data-testid="form-checkbox-label">
                    {label.text}
                  </label>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {checkbox_label?.map((label) => {
              return (
                <div
                  className={'flex '}
                  key={label.id}
                  data-testid="form-checkbox">
                  <FormCheckbox
                    id={label.name}
                    name={label.name ?? ''}
                    handleCheckboxChange={handleCheckboxChange}
                    data={data[label.name] ?? false}
                  />
                  <label
                    htmlFor={label?.name}
                    data-testid="form-checkbox-label">
                    {label.text}
                  </label>
                </div>
              );
            })}
          </div>
        )}
        <ValidationMessage invalidMessage={invalidMessage} />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormCheckboxWithCaution;
