
export class EstateBidFee {
  #estateBidFee

  constructor(estateBidFee) {
    this.#estateBidFee = estateBidFee;
  }

  get() {
    return this.#estateBidFee
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
