export const propertyStatus = {
  APPLYING: '申し込み受付中',
  WAIT_START_BID: '入札開始待ち',
  DURING_BIDDING: '入札受付中',
  WAIT_OPEN_BID: '開札準備中',
  ADDITIONAL_DURING_BIDDING: '追加入札中',
  DONE_BIDDING: '入札完了',
  END_BIDDING: '入札終了',
  CANCEL: '中止',
  CLOSED: '公開終了',
};
