import { createContext } from 'react';

import { DATA_EMPTY } from '../../../../shared/utils/helper/constants.js';

export const PropertyDetailContext = createContext({
  // Default Value
  responsive: DATA_EMPTY,
  property: DATA_EMPTY,
  guidelines: DATA_EMPTY,
  cautionPages: DATA_EMPTY,
  isBlocklist: false,
});
