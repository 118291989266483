import { ReactComponent as ArrowDropDownIcon } from 'interfaces/assets/svg/arrow_drop_down_icon.svg';
import { ReactComponent as ChevronLeftIcon } from 'interfaces/assets/svg/chevron_left_icon.svg';
import { ReactComponent as ChevronRightIcon } from 'interfaces/assets/svg/chevron_right_icon.svg';
import 'interfaces/css/common/pagination.css';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

const Pagination = ({
  DATA_COUNT = 463,
  currentPage,
  pageCount,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  display_number = [5, 10, 15, 'ALL'],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage === 'ALL' ? DATA_COUNT : newRowsPerPage);
    setCurrentPage(1);
    handleClose();
  };

  const MIN_CURRENT_DISPLAY_NUMBER_RANGE =
    rowsPerPage * currentPage - rowsPerPage + 1;
  const MAX_CURRENT_DISPLAY_NUMBER_RANGE =
    rowsPerPage * currentPage > DATA_COUNT
      ? DATA_COUNT
      : rowsPerPage * currentPage;

  const toPreviousPage = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
    window.scrollTo(0, 0);
  };

  const toNextPage = () => {
    setCurrentPage(Math.min(pageCount, currentPage + 1));
    window.scrollTo(0, 0);
  };

  return (
    <div
      data-testid="kmanager-pagination"
      className="kmanager-pagination d-flex align-center justify-end flex-wrap-wrap">
      <div
        data-testid="kmanager-display-number-switcher"
        className="display-number-switcher d-flex align-center">
        <span style={{ marginRight: '34px' }}>Rows per page:</span>
        <div>
          <button
            className="display-number-select d-flex align-center"
            onClick={handleClick}
            data-testid="rows-per-page-select">
            <span className="mr-2">
              {rowsPerPage === DATA_COUNT ? 'ALL' : rowsPerPage}
            </span>
            <ArrowDropDownIcon title="arrow-drop-down-icon" />
          </button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {display_number.map((number) => (
              <MenuItem
                key={number}
                onClick={() => handleMenuItemClick(number)}>
                {number}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
      <div
        data-testid="kmanager-display-number-counter"
        className="display-number-counter">
        {MIN_CURRENT_DISPLAY_NUMBER_RANGE === MAX_CURRENT_DISPLAY_NUMBER_RANGE
          ? MIN_CURRENT_DISPLAY_NUMBER_RANGE
          : `${MIN_CURRENT_DISPLAY_NUMBER_RANGE}-${MAX_CURRENT_DISPLAY_NUMBER_RANGE}`}{' '}
        of {DATA_COUNT}
      </div>
      <div className="page-control-buttons d-flex align-center">
        <button
          data-testid="kmanager-prev-page-button"
          className={`page-control-button d-flex align-center ${currentPage <= 1 ? '' : 'page-control-button-active'}`}
          onClick={toPreviousPage}
          disabled={currentPage <= 1}>
          {<ChevronLeftIcon title="chevron-left-icon" />}
        </button>
        <button
          data-testid="kmanager-next-page-button"
          className={`page-control-button d-flex align-center ${currentPage >= pageCount ? '' : 'page-control-button-active'}`}
          onClick={toNextPage}
          disabled={currentPage >= pageCount}>
          {<ChevronRightIcon title="chevron-right-icon" />}
        </button>
      </div>
    </div>
  );
};
export default Pagination;
