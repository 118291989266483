import { Link } from 'react-router-dom';
import { useError } from 'shared/hooks/useError';

// todo: api呼び出し部。api完成後作成する
//import { putEmail } from '../../../../api/auctionUsers.js';
import './css/confirm-number-form-links.css';

const ConfirmNumberFormLinks = ({ targetEmail, setModalType }) => {
  const { setError } = useError();

  const reSendConfirmNumber = async () => {
    try {
      //await putEmail(localStorageUserId, { 'email': targetEmail });
      console.log('resend confirm number success');
      console.log(targetEmail);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className="mypage confirm-number-form-links">
      <Link onClick={reSendConfirmNumber} className="re-send-code-link">
        確認コードを再送する
      </Link>
      <Link
        onClick={() => setModalType('EMAIL')}
        className="to-email-input-link">
        メールアドレスを入力し直す
      </Link>
    </div>
  );
};

export default ConfirmNumberFormLinks;
