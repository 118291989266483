import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import FlatButton from 'interfaces/components/common/Button/FlatButton';
import CheckBox from 'interfaces/components/common/CheckBox/CheckBox';
import Memo from 'interfaces/components/Memo/Memo';
import DeleteBlockListModal from './DeleteBlockListModal';
import { useState } from 'react';
import { dateFormat } from 'shared/utils/helper/date_format';
import useModalControl from 'shared/hooks/useModalControl';

import 'interfaces/css/BlockList/block-list-table.css';

const BlockListTable = ({ tableData, setDeleteIds, deleteIds }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [deleteId, setDeleteId] = useState(null);
  const checkBoxInitialState = tableData
    ? tableData.reduce((acc, item) => {
        acc[item.id] = false;
        return acc;
      }, {})
    : {};
  const [checkboxStates, setCheckBoxStates] = useState(checkBoxInitialState);
  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setDeleteIds([...deleteIds, id]);
    } else {
      setDeleteIds(deleteIds.filter((id) => id !== id));
    }
    setCheckBoxStates({
      ...checkboxStates,
      [id]: isChecked,
    });
  };

  const handleDelete = (id) => {
    handleOpen();
    setDeleteId(id);
  };

  const handleDeleteConfirm = () => {
    console.log('削除確認');
    handleClose();
    setDeleteId(null);
  };

  return (
    <span className="block-list-table-wrap">
      <div className="block-list-table" data-testid="block-list-table">
        <div className="block-list-table-inner">
          <table className="block-list-table">
            <thead
              className="block-list-table-header"
              data-testid="block-list-table-header">
              <tr>
                <th className="table-check">選択</th>
                <th>会員識別番号</th>
                <th>登録日時</th>
                <th>メモ</th>
                <th className="table-buttons"></th>
              </tr>
            </thead>
            <tbody
              className="block-list-table-body"
              data-testid="block-list-table-body">
              {tableData &&
                tableData.map((d) => (
                  <tr
                    key={d.auctionUserId}
                    data-testid="block-list-table-body-row">
                    <td>
                      <CheckBox
                        id={d.auctionUserId}
                        onClick={() =>
                          handleCheckboxChange(
                            d.auctionUserId,
                            !checkboxStates[d.auctionUserId],
                          )
                        }
                        isChecked={checkboxStates[d.auctionUserId]}
                      />
                    </td>
                    <td>{d.auctionUserId}</td>
                    <td>{dateFormat(d.createdAt, 'YYYY-MM-DD-hh-mm')}</td>
                    <td>{d.memo}</td>
                    <td className="action-buttons">
                      <Memo isFlatButton={true} text="メモ登録" initialText={d.memo} />
                      <FlatButton
                        text="削除"
                        icon={<DeleteIcon />}
                        buttonStyle={{
                          backgroundColor: '#ff5252',
                          borderColor: '#ff5252',
                          color: '#fff',
                          fill: '#fff',
                        }}
                        onClick={() => handleDelete(d.auctionUserId)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <DeleteBlockListModal
        isOpen={isOpen}
        handleClose={handleClose}
        deleteId={deleteId}
        onDelete={handleDeleteConfirm}
      />
    </span>
  );
};

export default BlockListTable;
