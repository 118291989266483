import { useNewEntryInputs } from 'shared/hooks/useNewEntryInputs';

// validation
import { ProcedureNewEntryForm } from '../ProcedureNewEntryForm.jsx';

export function validateInputsProcedureNewEntryForm(is_edit) {
  const formInputs = useNewEntryInputs();
  const validate = ProcedureNewEntryForm(is_edit);

  function handleChangeName(e) {
    validate.setters.handleNameValid(e);
    formInputs.setters.setName(e.target.value);
  }

  function handleChangeAuctionId(e) {
    validate.setters.handleAuctionIdValid(e);
    formInputs.setters.setAuctionId(e.target.value);
  }

  function handleChangeArea(e) {
    validate.setters.handleAreaValid(e);
    formInputs.setters.setArea(e.target.value);
  }

  function handleChangeContactOffice(e) {
    validate.setters.handleContactOfficeValid(e);
    formInputs.setters.setContactOffice(e.target.value);
  }

  function handleChangeContactEmail(e) {
    validate.setters.handleContactEmailValid(e);
    formInputs.setters.setContactEmail(e.target.value);
  }

  function handleChangeContactTel(tel) {
    validate.setters.handleContactTelValid(tel);
    formInputs.setters.setContactTel({
      ...formInputs.data.contactTel,
    });
  }

  function handleChangeTelNum1(tel) {
    validate.setters.handleTel1Valid(tel);
    formInputs.setters.setTelNum1(tel);
  }

  function handleChangeTelNum2(tel) {
    validate.setters.handleTel2Valid(tel);
    formInputs.setters.setTelNum2(tel);
  }

  function handleChangeTelNum3(tel) {
    validate.setters.handleTel3Valid(tel);
    formInputs.setters.setTelNum3(tel);
  }

  function handleChangeAplCountAuctionDisplay(e) {
    validate.setters.handleAplCountAuctionDisplayValid(e);
    formInputs.setters.setAplCountAuctionDisplay(e.target.value);
  }
  function handleChangeAplCountBidDisplay(e) {
    validate.setters.handleAplCountBidDisplayValid(e);
    formInputs.setters.setAplCountBidDisplay(e.target.value);
  }
  function handleChangeBidCountDisplay(e) {
    validate.setters.handleBidCountDisplayValid(e);
    formInputs.setters.setBidCountDisplay(e.target.value);
  }

  function handleChangeBidEndType(e) {
    validate.setters.handleBidEndTypeValid(e);
    formInputs.setters.setBidEndType(e.target.value);
  }
  function handleChangeMailCertDocument(e) {
    validate.setters.handleMailCertDocumentValid(e);
    formInputs.setters.setMailCertDocument(e.target.value);
  }
  function handleChangeMailReductionDocument(e) {
    validate.setters.handleMailReductionDocumentValid(e);
    formInputs.setters.setMailReductionDocument(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleChangeName,
      handleChangeArea,
      handleChangeContactOffice,
      handleChangeContactEmail,
      handleChangeContactTel,
      handleChangeTelNum1,
      handleChangeTelNum2,
      handleChangeTelNum3,
      handleChangeAuctionId,
      handleChangeAplCountAuctionDisplay,
      handleChangeAplCountBidDisplay,
      handleChangeBidCountDisplay,
      handleChangeBidEndType,
      handleChangeMailCertDocument,
      handleChangeMailReductionDocument,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
