import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeldInfoWrapper = styled.div`
  padding: 12px;
  border: 3px solid #e2e1da;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 8px;
`;

const HeldInfo = ({ auctionStatus }) => {
  return (
    <div data-testid="held-info">
      {auctionStatus ? (
        <>
          <HeldInfoWrapper data-testid="held-info-message">
            <b>参加申し込み受付期間中</b>
          </HeldInfoWrapper>
          <p className="text-right mb-0">
            <Link
              to="/search?searchQuery=&searchStatus=APPLYING"
              data-testid="held-info-link">
              今すぐ参加できます！＞
            </Link>
          </p>
          <hr
            role="separator"
            aria-orientation="horizontal"
            className="my-2 divider"
          />
        </>
      ) : (
        <>
          <div className="mb-2" data-testid="held-info-message">
            現在参加申込可能なオークションはありません
          </div>
          <hr
            role="separator"
            aria-orientation="horizontal"
            className="my-2 divider"
          />
        </>
      )}
    </div>
  );
};

export default HeldInfo;
