import { useRealEstateInputs } from 'interfaces/components/Property/navi/RealEstate/hook/useRealEstateInputs';

// validation
import { UseRealEstateInputsValidation } from './hook/useRealEstateInputsValidation.jsx';

export function RealEstateFormData({ isEdit = false }, invitation) {
  const formInputs = useRealEstateInputs(invitation);
  const validate = UseRealEstateInputsValidation(isEdit);

  // 公開設定
  function handleChangeIsDraft(e) {
    formInputs.setters.setIsDraft(e.target.value);
  }

  // 2. 物件情報
  function handleChangePublicSaleType(e) {
    formInputs.setters.setPublicSaleType(e.target.value);
  }

  function handleChangeIsNextBuyApl(e) {
    formInputs.setters.setIsNextBuyApl(e.target.value);
  }

  function handleChangePrintOrder(e) {
    validate.setters.handlePrintOrderValid(e);
    formInputs.setters.setPrintOrder(e.target.value);
  }

  function handleChangeCategoryId(id) {
    validate.setters.handleCategoryIdValid(id);
    formInputs.setters.setCategoryId(id);
  }

  function handleChangeRecommendProperties(e) {
    formInputs.setters.setRecommendProperties(e);
  }

  function handleChangeDivisionId(e) {
    validate.setters.handleDivisionIdValid(e);
    formInputs.setters.setDivisionId(e.target.value);
  }

  function handleChangeSaleNumber(e) {
    console.log('handle sale number');
    validate.setters.handleSaleNumberValid(e);
    formInputs.setters.setSaleNumber(e.target.value);
  }

  function handleChangeName(e) {
    validate.setters.handleTitleValid(e);
    formInputs.setters.setName(e.target.value);
  }

  function handleChangePaymentMethod(paymentMethod) {
    validate.setters.handlePaymentMethodValid(paymentMethod);
    formInputs.setters.setPaymentMethod(paymentMethod);
  }

  function handleChangePaymentDeadline(date) {
    validate.setters.handlePaymentDeadlineValid(date);
    formInputs.setters.setPaymentDeadline(date);
  }

  function handleChangeResolutionDate(e) {
    validate.setters.handleResolutionDateValid(e);
    formInputs.setters.setResolutionDate(e.target.value);
  }

  function handleChangeContractedLimit(e) {
    formInputs.setters.setContractedLimit(e);
  }

  function handleChangeVideoUrl(e) {
    validate.setters.handleVideoUrlValid(e);
    formInputs.setters.setVideoUrl(e.target.value);
  }

  function handleChangeDescription(text) {
    validate.setters.handleDescriptionValid(text);
    formInputs.setters.setDescription(text);
  }

  // 3. 物件に関するお問い合わせ先
  function handleChangeInquiryName(e) {
    validate.setters.handleInquiryNameValid(e);
    formInputs.setters.setInquiryName(e.target.value);
  }

  function handleChangeInquiryOffice(e) {
    validate.setters.handleInquiryOfficeValid(e);
    formInputs.setters.setInquiryOffice(e.target.value);
  }

  function handleChangeInquiryEmail(e) {
    validate.setters.handleInquiryEmailValid(e);
    formInputs.setters.setInquiryEmail(e.target.value);
  }

  function handleChangeInquiryTel(telData) {
    validate.setters.handleInquiryTelValid(telData);
    formInputs.setters.setInquiryTel(telData);
  }
  function handleChangeInquiryTelNum1(e) {
    validate.setters.handleInquiryTelNum1Valid(e);
    formInputs.setters.setInquiryTelNum1(e);
  }
  function handleChangeInquiryTelNum2(e) {
    validate.setters.handleInquiryTelNum2Valid(e);
    formInputs.setters.setInquiryTelNum2(e);
  }
  function handleChangeInquiryTelNum3(e) {
    validate.setters.handleInquiryTelNum3Valid(e);
    formInputs.setters.setInquiryTelNum3(e);
  }

  function handleChangeInquiryExtension(e) {
    validate.setters.handleInquiryExtensionValid(e);
    formInputs.setters.setInquiryExtension(e.target.value);
  }

  function handleChangeInquiryAccept(e) {
    validate.setters.handleInquiryAcceptValid(e);
    formInputs.setters.setInquiryAccept(e.target.value);
  }

  function handleChangeInquiryNote(e) {
    validate.setters.handleInquiryNoteValid(e);
    formInputs.setters.setInquiryNote(e.target.value);
  }

  // 4. 物件の詳細設定
  function handleChangeDepositPayment(e) {
    formInputs.setters.setDepositPayment(e.target.value);
  }

  function handleChangeIsApprovalRequired(e) {
    formInputs.setters.setIsApprovalRequired(e.target.value);
  }

  function handleChangeEstimatePrice(e) {
    validate.setters.handleEstimatePriceValid(e);
    formInputs.setters.setEstimatePrice(e.target.value);
  }

  function handleChangeDeposit(e, payment) {
    validate.setters.handleDepositValid(e, payment);
    formInputs.setters.setDeposit(e.target.value);
  }

  function handleChangeQualifiedInvoice(e) {
    validate.setters.handleQualifiedInvoiceValid(e);
    formInputs.setters.setQualifiedInvoice(e.target.value);
  }

  // 5. 落札後の連絡先
  function handleChangeContactName(e) {
    validate.setters.handleContactNameValid(e);
    formInputs.setters.setContactName(e.target.value);
  }

  function handleChangeContactOffice(e) {
    console.log('handle contract office');
    validate.setters.handleContactOfficeValid(e);
    formInputs.setters.setContactOffice(e.target.value);
  }

  function handleChangeContactEmail(e) {
    validate.setters.handleContactEmailValid(e);
    formInputs.setters.setContactEmail(e.target.value);
  }

  function handleChangeContactTel(telData) {
    validate.setters.handleContactTelValid(telData);
    formInputs.setters.setContactTel(telData);
  }
  function handleChangeContactTelNum1(e) {
    validate.setters.handleContactTelNum1Valid(e);
    formInputs.setters.setContactTelNum1(e);
  }
  function handleChangeContactTelNum2(e) {
    validate.setters.handleContactTelNum2Valid(e);
    formInputs.setters.setContactTelNum2(e);
  }
  function handleChangeContactTelNum3(e) {
    console.log(e);
    validate.setters.handleContactTelNum3Valid(e);
    formInputs.setters.setContactTelNum3(e);
  }

  function handleChangeContactAccept(e) {
    validate.setters.handleContactAcceptValid(e);
    formInputs.setters.setContactAccept(e.target.value);
  }

  function handleChangeContactExtension(e) {
    validate.setters.handleContactExtensionValid(e);
    formInputs.setters.setContactExtension(e.target.value);
  }

  //function handleChangeAuctionUserId(e) {
  //  validate.setters.handleAuctionUserIdValid(e);
  //  formInputs.setters.setAuctionUserId(e.target.value);
  //}

  //function handleChangePaymentDate(e) {
  //  formInputs.setters.setPaymentDate(e.target.value);
  //}

  //function handleChangePaymentScheduledDate(e) {
  //  validate.setters.handlePaymentScheduledDateValid(e);
  //  formInputs.setters.setPaymentScheduledDate(e.target.value);
  //}

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeIsDraft,

      // 2. 物件情報
      handleChangePublicSaleType, // 出品形式*
      handleChangeIsNextBuyApl, // 次順位買受申込者の決定*
      handleChangePrintOrder, // 掲載順位
      handleChangeCategoryId, // カテゴリ*
      handleChangeRecommendProperties, // イチ押し設定
      handleChangeDivisionId, // 執行機関名称*
      handleChangeSaleNumber, // 売却区分番号*
      handleChangeName, // タイトル*
      handleChangePaymentMethod, // 支払い方法*
      handleChangePaymentDeadline, // 買受代金納付期限*, 売払代金納付期限*
      handleChangeResolutionDate, // 議決日
      handleChangeContractedLimit, // 契約締結期限
      handleChangeVideoUrl, // 動画URL
      handleChangeDescription, // 説明*

      // 3. 物件に関するお問い合わせ先
      handleChangeInquiryName,
      handleChangeInquiryOffice,
      handleChangeInquiryEmail,
      handleChangeInquiryTel,
      handleChangeInquiryTelNum1,
      handleChangeInquiryTelNum2,
      handleChangeInquiryTelNum3,
      handleChangeInquiryExtension,
      handleChangeInquiryAccept,
      handleChangeInquiryNote,

      // 4. 物件の詳細設定
      handleChangeDepositPayment, // 保証金納付手続き*
      handleChangeIsApprovalRequired, // 参加申し込み承認*	(インターネット公売のみ)
      handleChangeEstimatePrice, // 見積価額*
      handleChangeDeposit, // 公売保証金*
      handleChangeQualifiedInvoice, // 適格請求書*

      // 5. 落札後の連絡先
      handleChangeContactName,
      handleChangeContactOffice,
      handleChangeContactEmail,
      handleChangeContactTel,
      handleChangeContactTelNum1,
      handleChangeContactTelNum2,
      handleChangeContactTelNum3,
      handleChangeContactAccept,
      handleChangeContactExtension,

      //handleChangeAuctionUserId,
      //handleChangePaymentDate,
      //handleChangePaymentScheduledDate,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
