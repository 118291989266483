import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import GrandChildCategories from './GrandChildCategories.jsx';
import { queryParams } from '../../../../../../pages/Dashboard/entities/queryParam.js';
import AccordionWithCheckBox from '../AccordionWithCheckBox.jsx';

const ChildCategories = ({
  categories,
  isSeeAll,
  showCheckBox,
  showCount,
  checkBoxState,
}) => {
  const initialArray = new Array(categories.length).fill(false);
  const [childCheckBoxStates, setChildCheckBoxStates] = useState(initialArray);
  const [searchParams] = useSearchParams();
  const setChildCheckBoxState = (index, value) => {
    const newState = [...childCheckBoxStates];
    newState[index] = value;
    setChildCheckBoxStates(newState);
  };

  useEffect(() => {
    setChildCheckBoxStates(
      Array(childCheckBoxStates.length).fill(checkBoxState),
    );
  }, [checkBoxState]);

  useEffect(() => {
    const updateArray = [...childCheckBoxStates];
    if (searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      const searchCategoryIds = searchParams.get(
        queryParams.SEARCH_PROPERTY_CATEGORY_ID,
      );
      if (searchCategoryIds !== '') {
        const searchPropertyCategoryId = searchCategoryIds.split(',');
        categories.forEach((category, index) => {
          searchPropertyCategoryId.includes(category.id.toString())
            ? (updateArray[index] = true)
            : null;
        });
        setChildCheckBoxStates(updateArray);
      }
    }
  }, [categories]);

  return categories?.map((category, index) => {
    if (category.displayService !== 'DISPLAY') {
      return null;
    }
    if (index > 4) {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="child-category"
          categoryData={category}
          marginLeft={40}
          style={isSeeAll ? {} : { display: 'none' }}
          onClick={() => {
            setChildCheckBoxState(index, !childCheckBoxStates[index]);
          }}
          checkBoxState={checkBoxState}
          checkboxActive={childCheckBoxStates[index]}
          showCheckBox={showCheckBox}
          showCount={showCount}
          contents={
            <GrandChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={childCheckBoxStates[index]}
            />
          }
        />
      );
    } else {
      return (
        <AccordionWithCheckBox
          key={category.id}
          id="child-category"
          categoryData={category}
          marginLeft={40}
          style={{}}
          onClick={() => {
            setChildCheckBoxState(index, !childCheckBoxStates[index]);
          }}
          checkBoxState={checkBoxState}
          checkboxActive={childCheckBoxStates[index]}
          showCheckBox={showCheckBox}
          showCount={showCount}
          contents={
            <GrandChildCategories
              categories={category.childCategory}
              isSeeAll={isSeeAll}
              showCheckBox={showCheckBox}
              showCount={showCount}
              checkBoxState={childCheckBoxStates[index]}
            />
          }
        />
      );
    }
  });
};

export default ChildCategories;
