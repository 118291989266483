import {
  condition,
} from 'domain/Property/entities/constants/property';

import { isEmpty } from 'shared/utils/constants/emptyData';

import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';

import { Message, MessageLink } from './WaitOpenBid';

//export const BlockList = ({ data, isContainBlockList, setIsNoMessage }) => {
export const BlockList = ({ data, isContainBlockList }) => {
  if (isContainBlockList) {
    if (data?.status === 'APPLYING') {
      return <Message content={'この物件には参加申し込みできません。'} />;
    }

    if (data?.status === 'WAIT_OPEN_BID') {
      return (
        <Message
          content={
            '現在執行機関による入札結果確認中です。落札者は入札結果の確認完了後に確定されます。しばらくお待ちください。'
          }
        />
      );
    }

    if (data?.status === 'ADDITIONAL_DURING_BIDDING') {
      return (
        <MessageLink
          prevText={'この物件は現在'}
          href={URL_LINKS.ABOUT_ADD_BID_URL}
          content={'追加入札'}
          laterText={'中です。'}
        />
      );
    }

    if (data?.status === 'DURING_BIDDING' && !condition.includes(data.aplStatus)) {
      return <Message content={'この物件には入札できません。'} />;
    }

    if (data?.status === 'END_BIDDING') {
      if (isEmpty(data?.auctionUserId)) {
        return <Message content={'この物件には入札がありませんでした。'} />;
      }
    }
  }
};
