// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage .confirm-number-form-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mypage .re-send-code-link, .mypage .to-email-input-link {
  height: 36px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.mypage .re-send-code-link:hover, .mypage.to-email-input-link:hover {
  text-decoration: none;
  font-size: 0.875rem;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/MyPage/confirm-number-form-links.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":[".mypage .confirm-number-form-links {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n}\n\n.mypage .re-send-code-link, .mypage .to-email-input-link {\n  height: 36px;\n  display: flex;\n  align-items: center;\n  font-size: 0.875rem;\n}\n\n.mypage .re-send-code-link:hover, .mypage.to-email-input-link:hover {\n  text-decoration: none;\n  font-size: 0.875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
