import { useState } from 'react';

const useTextCounter = (maxTextLimit, initialText = '') => {
  const [text, setText] = useState(initialText);
  const [count, setCount] = useState(maxTextLimit - initialText.length);
  const [isOverCount, setIsOverCount] = useState(false);

  const isWithinLimit = (value, maxLimit) => {
    return value.length <= maxLimit;
  };

  const changeHandler = (e) => {
    const inputTextValue = e.target.value;
    const inputTextValueLength = inputTextValue.length;

    if (isWithinLimit(inputTextValue, maxTextLimit)) {
      setIsOverCount(false);
    } else {
      setIsOverCount(true);
    }

    setText(inputTextValue);
    setCount(maxTextLimit - inputTextValueLength);
  };

  return {
    text,
    count,
    isOverCount,
    isWithinLimit,
    changeHandler,
  };
};

export default useTextCounter;
