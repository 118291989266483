import {
  validateRangeLength,
  includesHalftwidthKatakana,
  replaceFullwidthKatakana,
  hasEmoji,
} from '../../../../application/validations.js';

export class NameKana {
  #nameKana;

  constructor(nameKana) {
    this.#nameKana = nameKana;
  }

  get() {
    return this.#nameKana;
  }

  get isValid() {
    return [
      this.validateRangeLength,
      this.includesHalftwidthKatakana,
      this.hasEmoji,
    ].every((result) => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(replaceFullwidthKatakana(this.#nameKana), 1, 50);
  }

  // バリデーションルールの適用
  get includesHalftwidthKatakana() {
    return includesHalftwidthKatakana(replaceFullwidthKatakana(this.#nameKana));
  }

  // バリデーションルールの適用
  get hasEmoji() {
    return hasEmoji(replaceFullwidthKatakana(this.#nameKana));
  }
}
