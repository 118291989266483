
import CustomError from "shared/utils/helper/customError.js";
import { sanitizeInput } from "shared/utils/helper/sanitizer.js";
import { postLogin, postResetPassword } from "infrastructure/api/AuthAPI.js";
import { ServiceTypes, UserKeyByService } from "shared/utils/constants/service.js";

export async function login(email, password, kind) {
  kind = ServiceTypes[kind].string;
  if (!kind) {
    throw new CustomError('サービスが指定されていません', null);
  }

  let body = {
    user_login_id: sanitizeInput(email),
    password: sanitizeInput(password),
    kind: kind
  }
  console.log(body)

  let requiredNewPassword = false;

  try {
    await postLogin(body).then(
      (response) => {
        setLocalStorage(response.data, response.data.service)
        requiredNewPassword = response.data.requiredNewPassword
      }
    );
  } catch (error) {
    throw new CustomError('ログインに失敗しました', error);
  }
  return requiredNewPassword;
}

export async function resetPassword(email, initialPassword, password, kind) {
  kind = ServiceTypes[kind].string;
  if (!kind) {
    throw new CustomError('サービスが指定されていません', null);
  }

  let body = {
    user_login_id: sanitizeInput(email),
    initial_password: sanitizeInput(initialPassword),
    password: sanitizeInput(password),
    kind: kind
  }
  console.log(body)
  try {
    await postResetPassword(body).then(
      (response) => {
        setLocalStorage(response.data, response.data.service)
      }
    );
  } catch (error) {
    throw new CustomError('パスワードの更新に失敗しました', error);
  }
}

const setLocalStorage = (data, service) => {
  Object.values(UserKeyByService).forEach((value) => {
    localStorage.removeItem(value);
  });
  localStorage.setItem(UserKeyByService[service], data.userId)
  localStorage.setItem("procedureType", data.procedureType)
  localStorage.setItem("governmentId", data.governmentId)
  localStorage.setItem("governmentType", data.governmentType)
  localStorage.setItem("requestAccessToken", data.appAccessToken)
  localStorage.setItem("requestRefreshToken", data.appRefreshToken)
}
