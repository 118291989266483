// ロジックを含む処理
// フロントから呼ぶ関数

import { compareData } from '../../../shared/utils/helper/compareData.js';
import { getProposals } from '../repositories/Proposal.js';

export async function get(type) {
  return await getProposals(type);
}

export async function findByPropertyId(type, propertyId) {
  const proposals = await getProposals(type);
  return proposals.filter(
    (proposal) => proposal.propertyId.toString() === propertyId?.toString(),
  );
}

export async function findByPropertyIds(type, propertyIds) {
  const proposals = await getProposals(type);
  return proposals.filter((proposal) =>
    propertyIds.includes(proposal.propertyId.toString()),
  );
}

export async function findByIdAndUserId(type, propertyId, userId) {
  const proposals = await getProposals(type);
  return proposals.find(
    (proposal) =>
      proposal.propertyId.toString() === propertyId?.toString() &&
      proposal.auctionUserId.toString() === userId?.toString(),
  );
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
