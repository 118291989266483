// 日付の計算がし易くするため
import { isAfter, subYears } from 'date-fns';

import { PREFECTURE } from '../shared/utils/helper/constants.js';

// 地域のリスト
const areas = [
  '北海道',
  '東北',
  '関東',
  '信越・北陸',
  '東海',
  '近畿',
  '中国',
  '四国',
  '九州・沖縄',
];
// メールフォーマットの正規表現
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// 拡張子のリスト
const regex = /\.(jpg|jpeg|png|gif)$/i;
// 画像ファイルのサイズ上限: 5MB
const MAX_SIZE = 5 * 1024 * 1024;
// 絵文字・機種依存文字の正規表現
const emojiRegex =
  /[\u{1F600}-\u{1F64F}]|[\u{1F300}-\u{1F5FF}]|[\u{1F680}-\u{1F6FF}]|[\u{2600}-\u{26FF}]|[\u{2700}-\u{27BF}]|[\u{FE0E}-\u{FE0F}]|[\u{1F900}-\u{1F9FF}]|[\u{1FA70}-\u{1FAFF}]/gu;
// カタカナの正規表現
const katakanaRegex = /^[ァ-ヶー ]+$/;
// 半角のカタカナの正規表現
const halkataRegex = /[\uFF66-\uFF9F]+/;
// 支払方法別に値域を変更
const thresholds = {
  BOTH: {
    min: 2,
    max: 1000000,
    minMessage: '１円以下の場合、保証金納付手続きでクレジットカードは選択できません。２円以上を指定する必要があります。',
    maxMessage: '100万円以下の金額を入力してください',
  },
  CREDIT_CARD: {
    min: 2,
    max: 1000000,
    minMessage: '１円以下の場合、保証金納付手続きでクレジットカードは選択できません。２円以上を指定する必要があります。',
    maxMessage: '100万円以下の金額を入力してください',
  },
  BANK_TRANSFER: {
    min: 1,
    max: 99999999999,
    minMessage: '1以上の数値を入力してください',
    maxMessage: '11桁以内で入力してください',
  },
  NONE: {
    min: 0,
    max: 0,
  },
};
// 内線の正規表現
const extensionRegex = /^[,|\-./\d]+$/;

const telRegex = /^\d{1,4}-\d{1,4}-\d{1,4}$/;

export function validateTelFormat(tel) {
  if (!tel) {
    return 'TRUE';
  }
  return telRegex.test(tel)
    ? 'TRUE'
    : '有効な電話番号を入力して下さい(半角数字、ハイフン)';
}

export function validateTelRangeLength(tel_number) {
  return validateRangeLength(tel_number, 10, 11);
}

export function validateTelPartRangeLength(tel_number) {
  return validateRangeLength(tel_number, 0, 4);
}

export function validateExtensionRangeLength(extension) {
  return validateRangeLength(extension, 0, 10);
}


// 必須チェック
export function validateRequired(value) {
  return validateEmptyString(value);
}

// メールフォーマットか検証
export function validateEmailFormatCheck(email) {
  if (!email) {
    return 'TRUE';
  }
  return emailRegex.test(email) === true
    ? 'TRUE'
    : 'メールフォーマットが正しくありません';
}

export function validateUrlFormatCheck(url) {
  return validateInclude(url, ['https://', 'http://']);
}

export function validateEmailLength(email) {
  return validateRangeLength(email, 0, 128);
}


export function validateUrlLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// 指定した文字数の範囲内か検証
export function validateRangeLength(value, min, max, customErrorMessage) {
  if (!value) {
    return 'TRUE';
  }
  let len = value?.length;
  return len >= min && len <= max
    ? 'TRUE'
    : customErrorMessage ?? '指定文字数の範囲内ではありません';
}

// 空白が含まれていないか
export function validateHasNoSpace(input) {
  return notInclude(input, [' ']);
}

// 値があるか(空文字列でないか)検証
export function validateEmptyString(value) {
  return value ? 'TRUE' : '入力されていません（値が空です）';
}

// 空文字か検証
export function isEmpty(text) {
  return text.length === 0 ? 'TRUE' : '入力されています';
}

// 文字列が半角数字か検証
export function validateNumber(value) {
  if (value?.length <= 0) {
    return 'TRUE';
  }
  return /^[0-9]+$/.test(value) ? 'TRUE' : '半角数字ではありません';
}

// 文字列が半角英数字か検証
export function validateAlphanum(value) {
  return /^[a-zA-Z0-9]+$/.test(value) ? 'TRUE' : '半角英数字ではありません';
}

// // ASCIIの数字か検証
// function isAsciiDigit(text) {
//   return /^[0-9]+$/.test(text);
// }

// 指定した桁数か検証
export function validateLength(value, length) {
  return value.length === length ? 'TRUE' : '指定桁数ではありません';
}

// 必要な文字列が含まれているか検証
export function validateInclude(value, texts) {
  return texts.some((text) => value.includes(text))
    ? 'TRUE'
    : '必要な内容が含まれていません';
}

// 指定文字列を含まないか検証
export function notInclude(value, texts) {
  return texts.every((text) => !value.includes(text))
    ? 'TRUE'
    : '禁止している内容が含まれています';
}

// 絵文字・機種依存文字の検証
export function hasEmoji(text) {
  return !text.match(emojiRegex)
    ? 'TRUE'
    : '絵文字もしくは機種依存文字が含まれています';
}

export function validateJapaneseString(text) {
  return text.match(/^[一-龠ぁ-んァ-ンー]+$/)
    ? 'TRUE'
    : '漢字、ひらがな、カタカナ(全角)で入力して下さい';
}

// 都道府県が選択されているか否か検証
export function validatePrefecture(prefecture) {
  let prefectureId = parseInt(prefecture);
  if (isNaN(prefectureId)) {
    return '都道府県が選択されていません';
  }
  prefecture = prefectureId;
  return PREFECTURE.map((prefecture) => prefecture.id).includes(prefecture)
    ? 'TRUE'
    : '都道府県が選択されていません';
}

// 地域が選択されているか否か検証
export function validateArea(area) {
  return areas.includes(area) ? 'TRUE' : '地域が選択されていません';
}

// 1~9999のいずれかが入力されているか否か検証
export function validateNumberRange(value) {
  return /^[1-9][0-9]{0,3}$/.test(value)
    ? 'TRUE'
    : '1から9999の範囲内ではありません';
}

// 特定の項目が選択されているのかを検証
export function validateSelected(value) {
  return value ? 'TRUE' : '項目が選択されていません';
}

// 複数の項目のどちらか1つが選択されているのかを検証
export function validateAtLeastOneSelected(options) {
  return options.some((option) => validateRequired(option))
    ? 'TRUE'
    : '少なくとも1つの項目が選択されていません';
}

// 画面の必須項目がすべて入力されているかを検証
export function validateRequiredFields(data, requiredList) {
  // 必須項目を全てチェック
  const isValid = requiredList.every((field) => {
    return data[field];
  });
  return isValid ? 'TRUE' : '必須項目が入力されていません';
}

// 適格請求書: 「発行可能」もしくは「発行不可」のどちらかが選択されているかを検証
export function validateInvoiceType(type) {
  return ['発行可能', '発行不可'].includes(type)
    ? 'TRUE'
    : '「発行可能」または「発行不可」を選択してください';
}

// 半角全角のカタカナは全角のカタカナに置換する
export function replaceFullwidthKatakana(text) {
  return text.replace(/[\uff66-\uff9f]+/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) - 0xfee0);
  });
}

// 1つ以上選択されているかを検証
export function validateSomeSelected(values) {
  const selected = values.filter(Boolean);
  return selected.length > 0 ? 'TRUE' : '1つ以上選択してください';
}

// 文字列に都道府県が含まれているかを検証
export function includesPrefecture(text) {
  return PREFECTURE.some((pref) => text.includes(pref.name))
    ? 'TRUE'
    : '都道府県が含まれていません';
}

// 文字列が全角のカタカナであるかを検証
export function isFullwidthKatakana(text) {
  if (text === '') return 'TRUE';
  return katakanaRegex.test(text) ? 'TRUE' : '全角カタカナではありません';
}

// 画像ファイルの拡張子を検証
export function isValidImageExtension(file) {
  return regex.test(file.name)
    ? 'TRUE'
    : '画像ファイルの拡張子が「.jpg, .jpeg, .png, .gif」のいずれでもありません';
}

// 画像のサイズが上限以下であるかを検証
export function validateImageSizeCheck(image) {
  // ファイルリーダーで読み込み
  const reader = new FileReader();
  // バイナリデータ取得
  return new Promise((resolve) => {
    reader.onload = () => {
      const bytes = new Uint8Array(reader.result);
      // サイズ比較
      resolve(bytes.length <= MAX_SIZE);
    };
    reader.readAsArrayBuffer(image);
    return image.size <= MAX_SIZE ? 'TRUE' : '画像のサイズが上限を超えています';
  });
}

// 半角数字が＜指定＞文字以内であるかを検証
export function validateNumberLength(value, maxLength) {
  // 数値・文字列チェック
  if (typeof value !== 'string' || isNaN(Number(value))) {
    return `入力内容が、数値もしくは文字ではありません`;
  }
  // 指定長チェック
  return value.length <= maxLength
    ? 'TRUE'
    : `入力内容が${maxLength}文字を超えています`;
}

// 文字列に半角のカタカナが含まれていないかを検証
export function includesHalftwidthKatakana(text) {
  return !halkataRegex.test(text) ? 'TRUE' : '半角のカタカナが含まれています';
}

// 文字列が全角カタカナのみを検証
export function isOnlyFullwidthKatakana(text) {
  return isFullwidthKatakana(text) === 'TRUE' &&
    includesHalftwidthKatakana(text) === 'TRUE'
    ? 'TRUE'
    : '全角カタカナではありません';
}

// 指定した小数以内かを検証
export function validateDecimal(value, decimal) {
  // 小数点位置取得
  const pointIndex = value.indexOf('.');
  const decimalCount = value.length - pointIndex - 1;
  return pointIndex === -1 || decimalCount <= decimal
    ? 'TRUE'
    : `${decimal}桁の小数以下でなければなりません`;
}

// 保証金納付方法の内容を検証
export function validateDeposit(value, type) {
  // 数値チェック
  if (isNaN(Number(value))) {
    return '無効な数値です';
  }
  // 最小、最大check
  const { min, max, minMessage, maxMessage } = thresholds[type];
  if (value < min) {
    return minMessage;
  } else if (value > max) {
    return maxMessage;
  }
  return 'TRUE';
}

// 指定金額の範囲内かを検証
export function isValidAmount(value, min, max) {
  // min <= value <= max
  return value >= min && value <= max
    ? 'TRUE'
    : `金額は${min}から${max}の範囲内でなければなりません`;
}

// 指定した最低金額より高額かを検証
export function isOverAmount(value, min) {
  // min <= value <= max
  return value > min ? 'TRUE' : `金額は${min}円より高額でなければなりません`;
}

// 指定した最低走行距離より大きいかを検証
export function isOverMilage(value, min) {
  return value > min ? 'TRUE' : `走行距離は${min}km以上でなければなりません`;
}

// 入力不可かを検証
export function isInvalidInput(value) {
  return value === 0 ? 'TRUE' : '入力は許可されていません';
}

// 現在日時以降かを検証
export function isAfterNow(dateTime) {
  return dateTime > new Date() ? 'TRUE' : '現在日時以降ではありません';
}

// 設定日時以降かを検証
export function isAfterSet(dateTime, setDateTime) {
  return new Date(dateTime) > new Date(setDateTime)
    ? 'TRUE'
    : `${new Date(setDateTime).toLocaleDateString('sv-SE')}以降ではありません`;
}

// 「-」と「空白」を除外する
export function removeBlankSymbols(value) {
  return value.replace(/[-\s]+/g, '');
}

// 日時が設定されているかを検証
export function isDefinedDateTime(dateTime) {
  return dateTime ? 'TRUE' : '日時が設定されていません';
}

// 画像がアップロード済みを検証
export function isUploadedImageFile(image) {
  return image ? 'TRUE' : '画像がアップロードされていません';
}

// 設定日時から追加検討時間が経過した時間以降に設定されているかを検証
export function isAfterEnd(dateTime, endDateTime, considerationHours) {
  return dateTime >
    new Date(endDateTime.getTime() + considerationHours * 60 * 60 * 1000)
    ? 'TRUE'
    : `${endDateTime}以降ではありません`;
}

// 設定額未満かを検証
export function isLessThanCriteria(amount, criteria) {
  return amount < criteria ? 'TRUE' : `設定額が${criteria}未満です`;
}

// 設定額超過かを検証
export function isExceedCriteria(amount, criteria) {
  return amount > criteria ? 'TRUE' : `設定額が${criteria}を超えています`;
}

// 年齢が指定年齢以上であるか否かを検証
export function validateAgeCheck(birthday, minAge) {
  const minAgeYearsAgo = subYears(new Date(), minAge);

  if (!birthday) {
    return '日付が設定されていません';
  }

  if (isAfter(minAgeYearsAgo, birthday)) {
    return 'TRUE';
  } else {
    return `年齢が${minAge}歳未満です`;
  }
}

// 入札額	せり売形式の現在価格が1円～1,000円未満の場合,入札額が現在価格より10円以上である
// 入札額	せり売形式の現在価格が1,000円～5,000円未満の場合,入札額が現在価格より100円以上である
// 入札額	せり売形式の現在価格が5,000円～1万円未満の場合,入札額が現在価格より250円以上である
// 入札額	せり売形式の現在価格が1万円～5万円未満の場合,入札額が現在価格より500円以上である
// 入札額	せり売形式の現在価格が5万円～の場合,入札額が現在価格より1,000円以上である
export function validateCurrentBidCheck(currentPrice, bidPrice) {
  if (currentPrice < 1000) {
    return bidPrice >= currentPrice + 10;
  } else if (currentPrice >= 1000 && currentPrice < 5000) {
    return bidPrice >= currentPrice + 100;
  } else if (currentPrice >= 5000 && currentPrice < 10000) {
    return bidPrice >= currentPrice + 250;
  } else if (currentPrice >= 10000 && currentPrice < 50000) {
    return bidPrice >= currentPrice + 500;
  } else if (currentPrice >= 50000) {
    return bidPrice >= currentPrice + 1000;
  } else {
    return `入札額が現在価額（${currentPrice}）＋入札単位以上ではありません。`;
  }
}

// 指定した文字列（複数の場合もある）が含まれていないか検証
export function validateNotIncludes(text, bannedWords) {
  if (!text || !bannedWords) {
    return '入力内容が、数値もしくは文字ではありません';
  }

  for (let i = 0; i < bannedWords.length; i++) {
    if (text.includes(bannedWords[i])) {
      return '入力内容に、禁止されている内容が含まれています';
    }
  }
  return 'TRUE';
}

// 半角のアルファベットの小文字と大文字と半角数字を８文字以上か検証
export function validatePasswordFormat(password) {
  const re = /^[a-zA-Z0-9]{8,}$/;
  return re.test(password)
    ? 'TRUE'
    : '半角のアルファベットの小文字と大文字と半角数字を8文字以上で入力してください';
}

// ハイフンと空白を除外する
export function removeDashesAndSpaces(text) {
  return text.replace(/\s+|-/g, '');
}

// 2つのパラメータが一致するかを検証
export function validateParamsMatch(param1, param2) {
  if (!param1 || !param2) {
    return '入力内容が一致しません';
  }
  return param1 === param2 ? 'TRUE' : '入力内容が一致しません';
}

// 内線に「,」「-」「.」「/」と半角数字以外が含まれていないか検証
export function validateExtensionFormat(extension) {
  if (extension?.length <= 0) {
    return 'TRUE';
  }
  return extensionRegex.test(extension)
    ? 'TRUE'
    : '半角数字と「,」「-」「.」「/」のみ使用可能です';
}
