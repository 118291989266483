import { Link } from 'react-router-dom';

const HorizontalLink = ({ link, label, target = '' }) => {
  return (
    <div className="vertical-link-group-item pa-0">
      <div className="font-weight-bold">
        {target === '_blank' ? (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            {label}
          </Link>
        ) : (
          <Link to={link}>{label}</Link>
        )}
      </div>
    </div>
  );
};

export default HorizontalLink;
