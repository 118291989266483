import PropertyFormInputMarkDown from 'interfaces/components/common/PropertyInfoForm/PropertyFormInputMarkDown';

import 'interfaces/css/common/property-info-general-form-input-email.css';
import CautionDescription from './CautionDescription';
import Label from './Label';

const FormMarkDownWithCaution = ({
  invalidMessage,
  caution_description,
  label,
  number_remaining_characters,
  required,
  validationType,
  data = '',
  handler1 = () => {},
  handler2 = () => {},
}) => {
  return (
    <div
      className="property-info-general-form-input-text property-info-general-form-table-row"
      data-testid={`form-markdown-with-caution-${label}`}>
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <PropertyFormInputMarkDown
          number_remaining_characters={number_remaining_characters}
          validationType={validationType}
          data={data}
          handler1={handler1}
          handler2={handler2}
          invalidMessage={invalidMessage}
        />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormMarkDownWithCaution;
