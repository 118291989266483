
export class IsDisplay {
  #isDisplay

  constructor(isDisplay) {
    this.#isDisplay = isDisplay;
  }

  get() {
    return this.#isDisplay
  }

}
