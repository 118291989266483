import Button from 'interfaces/components/common/Button';
import ButtonList from 'interfaces/components/common/ButtonList';
import PageTitle from 'interfaces/components/common/PageTitle';
import KmanagerTableRowHeaderData from 'interfaces/components/common/Table/KmanagerTableRowHeaderData';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackBar } from 'interfaces/components/Auth/kmanager/SnackBarProvider';

import { create } from "domain/User/services/GovernmentUser";
import { ServiceTypes } from "shared/utils/constants/service";
//import { putApiData } from "../../api/request";
//import { bodyToSnake, createBody } from "../../api/convertData";

import 'interfaces/css/common/kmanager-table.css';
import { KmanagerUserTypes } from 'shared/utils/constants/user';

const TOP_PATH = '/kmanager/gov_list';

//const PROCEDURE_TYPE = {
//  PublicSale: "PUBLIC_SALE",
//  AssetSale: "ASSET_SALE",
//}

//const GovernmentUserConfirm = ({ type = 'create', data, cancel, id = 0, userConf }) => {
const GovernmentUserConfirm = ({ type = 'create', data, cancel, userConf }) => {
  const { governmentId } = useParams();
  const navigation = useNavigate();
  const { openSnackBar } = useSnackBar();

  //const inputBody = createBody(data)

  //const formDataAdd = (inputBody) => {
  //  let snakeBody = bodyToSnake(inputBody)

  //  snakeBody.procedure_type = PROCEDURE_TYPE[userConf.procedureType]
  //  snakeBody.access_personal_info = "AUTHORIZED"
  //  snakeBody.want_receive_email = "NO_RECEIVE"

  //  return snakeBody
  //}

  const Create = async () => {
    console.log('create');
    const user = {}
    data.map((datum) => {
      user[datum.column] = datum.state
    })
    user.governmentId = userConf.governmentId
    user.procedureType = userConf.procedureType
    user.userType = KmanagerUserTypes.ADMIN.string
    try {
      await create(ServiceTypes.KMANAGER.string, user)
      navigation(`${TOP_PATH}/${governmentId}`);
      openSnackBar('ユーザーを登録しました。ID: r8y234098dj5y2348jdy23049');
    } catch (error) {
      console.log(error)
    }
  };

  const Put = async () => {
    console.log('put');
    navigation(`${TOP_PATH}/${governmentId}`);
    openSnackBar('ユーザーを登録しました。ID: r8y234098dj5y2348jdy230489');
    //await putApiData(`government-users/${userConf.governmentId}/edit/${id}`, formDataAdd(inputBody))
    //await putApiData(`government-users/${userConf.governmentId}/edit/${id}`, formDataAdd(inputBody))
    //  .then(() => {
    //    navigation(TOP_PATH)
    //  })
    //  .catch((err) => {
    //    // TODO: エラーハンドリング
    //    console.log(err)
    //  })
  };

  const CANCEL_BUTTON_DATA = {
    text: 'キャンセル',
    clickHandler: cancel,
    className: 'edit',
  };

  const CREATE_BUTTON_DATA = {
    text: '作成',
    clickHandler: Create,
    className: 'primary',
  };

  const EDIT_BUTTON_DATA = {
    text: '編集',
    clickHandler: Put,
    className: 'primary',
  };

  const BUTTONS_DATA = {
    cancel: CANCEL_BUTTON_DATA,
    create: CREATE_BUTTON_DATA,
    edit: EDIT_BUTTON_DATA,
  };

  const TitleText = () => {
    if (type === 'create') {
      return '下記内容で作成します。よろしければ作成ボタンをクリックしてください';
    } else if (type === 'edit') {
      return '下記内容で編集します。よろしければ編集ボタンをクリックしてください';
    }
  };

  return (
    <div
      className="kmanager-col-12 pa-5 box-shadow kmanager-confirm"
      style={{ maxWidth: '1000px' }}>
      <PageTitle text={TitleText()} />
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-government-user-confirm-table">
          <tbody>
            {data.map((data, index) => {
              return <KmanagerTableRowHeaderData key={index} data={data} />;
            })}
          </tbody>
        </table>
      </div>
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <Button
            text={BUTTONS_DATA['cancel'].text}
            clickHandler={BUTTONS_DATA['cancel'].clickHandler}
            className={BUTTONS_DATA['cancel'].className}
          />
          <Button
            text={BUTTONS_DATA[type].text}
            clickHandler={BUTTONS_DATA[type].clickHandler}
            className={BUTTONS_DATA[type].className}
          />
        </div>
      </ButtonList>
    </div>
  );
};

export default GovernmentUserConfirm;
