import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormInputMarkDown from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputMarkDown';
import { local_government_management_radio1 } from 'interfaces/components/common/RadioLabel';
import {
  guideline_body,
  guideline_title,
} from 'shared/utils/helper/DummyEditFormData';

import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';
import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const Form = ({ data, handler1 = () => {}, invalidMessage }) => {
  return (
    <div className="property-info-general-form-table">
      <Heading3 text={'設定' + data.itemNo} />
      <FormRadioWithCaution
        label={'設定' + data.itemNo + 'の表示'}
        radio_label={local_government_management_radio1}
        data={data.isDisplay}
        handler1={(e) => {
          handler1.setIsDisplay(e),
            handler1.setTitle(
              { target: { value: data.title } },
              e.target.value == 'DISPLAY',
            );
          handler1.setBody({ text: data.body }, e.target.value == 'DISPLAY');
        }}
      />
      <FormTextWithCaution
        label={'タイトル'}
        required={data.isDisplay == 'DISPLAY'}
        caution_description={guideline_title}
        data={data.title}
        maxCharacters={50}
        handler1={(e) => handler1.setTitle(e, data.isDisplay == 'DISPLAY')}
        invalidMessage={invalidMessage.title}
      />
      <PropertyInfoGeneralFormInputMarkDown
        label={'本文'}
        required={data.isDisplay == 'DISPLAY'}
        caution_description={guideline_body}
        data={data.body}
        maxCharacters={12000}
        handler1={(text) => handler1.setBody(text, data.isDisplay == 'DISPLAY')}
        invalidMessage={invalidMessage.body}
      />
    </div>
  );
};

export default Form;
