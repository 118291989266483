import Button from 'interfaces/components/common/Button/Button.jsx';
import ResetFormInput from 'interfaces/components/MyPage/ResetFormInput.jsx';
import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError.js';
import { useInput } from 'shared/hooks/useInput.js';
import { useValidate } from 'shared/hooks/useValidate.js';
//import { useAuth } from 'shared/hooks/useAuth.js';
// api作成後修正
//import { putNewPassword } from 'interfaces/api/auctionUsers.js';
//import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';

import 'interfaces/css/MyPage/reset-password-form.css';

import { styled } from 'styled-components';

const FormArea = styled.div`
  padding: 16px;
`;

const currentPasswordValidates = ['no_message_required', 'password'];
const newPasswordValidates = ['no_message_required', 'password'];
const confirmPasswordValidates = ['no_message_required', 'confirm_password'];

const ResetPasswordForm = ({ handleClose, setModalType }) => {
  //const { userId } = useAuth();
  //const { localStorageUserId } = useLocalStorage();
  const { setError } = useError();
  const [
    currentPassword,
    handleCurrentPasswordChange,
    validaCurrentPasswordMessage,
  ] = useInput(currentPasswordValidates);
  const [newPassword, handleNewPasswordChange, validaNewPasswordMessage] =
    useInput(newPasswordValidates);
  const [
    confirmPassword,
    handleConfirmPasswordChange,
    validaConfirmPasswordMessage,
  ] = useInput(confirmPasswordValidates, newPassword);
  const [isDisabled, setIsDisabled] = useState(true);
  const { handleValidates } = useValidate();

  const checkAllValidation = () => {
    const currentPasswordValidateMessage = handleValidates(
      currentPassword,
      currentPasswordValidates,
    );
    const newPasswordValidateMessage = handleValidates(
      newPassword,
      newPasswordValidates,
    );
    const confirmPasswordValidateMessage = handleValidates(
      confirmPassword,
      confirmPasswordValidates,
      newPassword,
    );
    setIsDisabled(
      currentPasswordValidateMessage ||
        newPasswordValidateMessage ||
        confirmPasswordValidateMessage,
    );
  };

  useEffect(() => {
    checkAllValidation();
  }, [currentPassword, newPassword, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      //const params = {
      //  current_password: currentPassword,
      //  new_password: newPassword,
      //};
      // await putNewPassword(userId, params);
      setModalType('COMPLETE');
    } catch (e) {
      setError(e);
    }
  };

  const serviceLoginFormInputs = [
    {
      id: 1,
      label: '現在のパスワードを入力してください。',
      type: 'password',
      value: currentPassword,
      handleChange: handleCurrentPasswordChange,
      validateMessage: validaCurrentPasswordMessage,
    },
    {
      id: 2,
      label: '新しいパスワードを入力してください。',
      type: 'password',
      value: newPassword,
      handleChange: handleNewPasswordChange,
      validateMessage: validaNewPasswordMessage,
    },
    {
      id: 3,
      label: '確認のため、もう一度パスワードを入力してください。',
      type: 'password',
      value: confirmPassword,
      handleChange: handleConfirmPasswordChange,
      validateMessage: validaConfirmPasswordMessage,
    },
  ];

  return (
    <div className="reset-password-form">
      <form noValidate onSubmit={handleSubmit}>
        <FormArea>
          {serviceLoginFormInputs.map((input) => (
            <ResetFormInput
              key={input.id}
              label={input.label}
              type={input.type}
              value={input.value}
              handleChange={input.handleChange}
              validateMessage={input.validateMessage}
              caption={input.caption}
            />
          ))}
        </FormArea>
        <div className="flex justify-center">
          <Button
            text="キャンセル"
            height="44px"
            width="100px"
            className="cancel-button"
            type="button"
            onClick={handleClose}
          />
          <Button
            text="次へ"
            height="44px"
            width="100px"
            className="mx-2"
            type="submit"
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
