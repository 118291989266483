import Button from 'interfaces/components/common/Button/Button';
import { new_entry_checkbox } from 'interfaces/components/common/CheckboxLabel';
import ConfirmInputText from 'interfaces/components/common/ConfirmInputText';
import { caution_description_char_30 } from 'interfaces/components/common/DummyEditFormData';
import {
  new_entry_description1,
  new_entry_description2,
  new_entry_description3,
  new_entry_description4,
  new_entry_description5,
  new_entry_description_public_deposit_delivery_status,
  new_entry_description_asset_deposit_delivery_status,
  new_entry_description7,
  new_entry_description8,
  new_entry_description9,
  new_entry_description10,
  new_entry_description11,
  new_entry_description12,
} from 'interfaces/components/common/DummyNewEntry';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import {
  new_entry_radio1,
  new_entry_radio2,
  new_entry_radio3,
  new_entry_radio4,
  new_entry_radio5,
  new_entry_radio6,
} from 'interfaces/components/common/RadioLabel';
import {
  procedure_new_entry_labels_asset,
  procedure_new_entry_labels_pub,
} from 'interfaces/components/common/TableLabels';
import FormCheckboxWithCaution from 'interfaces/components/Form/navi/FormCheckboxWithCaution';
import FormPullDownWithCaution from 'interfaces/components/Form/navi/FormPullDownWithCaution';
import FormRadioWithCaution from 'interfaces/components/Form/navi/FormRadioWithCaution';
import FormTelWithCaution from 'interfaces/components/Form/navi/FormTelWithCaution';
import FormTextWithCaution from 'interfaces/components/Form/navi/FormTextWithCaution';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const NaviNewEntryForm = ({
  auctions = [],
  auction,
  inputs,
  procedureType = '',
  toConfirm,
  isValid,
}) => {
  const labels =
    procedureType === 'PUBLIC_SALE'
      ? procedure_new_entry_labels_pub
      : procedure_new_entry_labels_asset;

  const [displayCountDisplayInputs, setDisplayCountDisplayInputs] =
    useState(false);
  const [selectedAuction, setSelectedAuction] = useState(undefined);
  const handleDepositDeliveryStatus = (value) => {
    inputs.init.setDepositDeliveryStatus({
      ...inputs.data.depositDeliveryStatus,
      ...value,
    });
  };
  const { auction_id } = useParams();

  useEffect(() => {
    let selectedAuction = auctions.find(
      (auction) => auction.id === inputs.data.auctionId,
    );
    if (inputs.data.auctionId === '') {
      return setDisplayCountDisplayInputs(false);
    }
    setDisplayCountDisplayInputs(true);
    setSelectedAuction(selectedAuction);
    inputs.init.setAuctionName(selectedAuction?.text);
  }, [inputs.data.auctionId]);

  return (
    <form
      className="new-style_navi_procedure-new-entry-form"
      data-testid="procedure-new-entry-form">
      <div className="pt-6">
        <Heading3
          text={`1. ${procedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}登録申請`}
        />
        <div className="property-info-general-form-table pt-3">
          {auction_id ? (
            <ConfirmInputText label={labels[0]} state={auction?.aplStart} />
          ) : (
            <FormPullDownWithCaution
              label={labels[0]}
              listItemData={auctions}
              selectedData={inputs.data.auctionName}
              caution_description={new_entry_description1(procedureType)}
              formStateHandler={inputs.setters.handleChangeAuctionId}
            />
          )}
          <FormTextWithCaution
            label={'公告名'}
            data={inputs.data.name}
            number_remaining_characters={
              inputs.data.name !== undefined ? 30 - inputs.data.name.length : 30
            }
            caution_description={new_entry_description2(procedureType)}
            required={true}
            handler1={inputs.setters.handleChangeName}
            invalidMessage={
              inputs.validationMessage.invitationNameRequiredValid
            }
          />
          <FormRadioWithCaution
            data={inputs.data.bidEndType}
            label={'入札自動終了'}
            radio_label={new_entry_radio1}
            caution_description={new_entry_description3}
            handler1={inputs.setters.handleChangeBidEndType}
          />
          <FormRadioWithCaution
            label={'認証書面（表紙）の押印と郵送を希望する'}
            data={inputs.data.mailCertDocument}
            radio_label={new_entry_radio2}
            caution_description={new_entry_description4}
            handler1={inputs.setters.handleChangeMailCertDocument}
          />
          <FormRadioWithCaution
            label={'減額申請書に押印し、KSIへ郵送する'}
            data={inputs.data.mailReductionDocument}
            radio_label={new_entry_radio3}
            caution_description={new_entry_description5}
            handler1={inputs.setters.handleChangeMailReductionDocument}
          />
          <FormCheckboxWithCaution
            label={labels[1]}
            data={inputs.data.depositDeliveryStatus}
            handler={handleDepositDeliveryStatus}
            checkbox_label={new_entry_checkbox}
            class_name={'payment-method'}
            caution_description={
              procedureType === 'PUBLIC_SALE'
                ? new_entry_description_public_deposit_delivery_status
                : new_entry_description_asset_deposit_delivery_status
            }
            required={true}
          />
        </div>
      </div>

      {displayCountDisplayInputs && (
        <div data-testid="count-display-inputs">
          {(selectedAuction?.publicSaleType === 'BOTH' ||
            selectedAuction?.publicSaleType === 'AUCTION') && (
            <div className="pt-9">
              <Heading3 text="【せり売形式】" />
              <div className="property-info-general-form-table">
                <FormRadioWithCaution
                  data={inputs.data.aplCountAuctionDisplay}
                  label={'申し込み総数の表示（せり売）'}
                  radio_label={new_entry_radio4}
                  caution_description={new_entry_description10}
                  handler1={inputs.setters.handleChangeAplCountAuctionDisplay}
                  required={true}
                />
              </div>
            </div>
          )}
          {(selectedAuction?.publicSaleType === 'BOTH' ||
            selectedAuction?.publicSaleType === 'BID') && (
            <div className="pt-9">
              <Heading3 text="【入札形式】" />
              <div className="property-info-general-form-table">
                <FormRadioWithCaution
                  data={inputs.data.aplCountBidDisplay}
                  label={'申し込み総数の表示（入札）'}
                  radio_label={new_entry_radio5}
                  caution_description={new_entry_description11}
                  handler1={inputs.setters.handleChangeAplCountBidDisplay}
                  required={true}
                />
                <FormRadioWithCaution
                  data={inputs.data.bidCountDisplay}
                  label={'入札件数の表示（入札）'}
                  radio_label={new_entry_radio6}
                  caution_description={new_entry_description12}
                  handler1={inputs.setters.handleChangeBidCountDisplay}
                  required={true}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <div className="pt-9">
        <Heading3 text="2. 行政機関登録申請" />
        <p data-testid="procedure-new-entry-form-description">
          行政機関ごとに設定する項目です。
        </p>
        <h4 data-testid="procedure-new-entry-form-headline">【基本情報】</h4>
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            label={'地域'}
            data={inputs.data.area}
            number_remaining_characters={
              inputs.data.area !== undefined ? 30 - inputs.data.area.length : 30
            }
            caution_description={new_entry_description7}
            required={true}
            handler1={inputs.setters.handleChangeArea}
            invalidMessage={inputs.validationMessage.areaRequiredValid}
          />
        </div>
        <h4 className="pt-6" data-testid="procedure-new-entry-form-headline">
          【落札後画面表示情報】
        </h4>
        <p data-testid="procedure-new-entry-form-description">
          落札者の物件詳細ページに掲載される内容です（落札者のみが見られます）。
          <br />
          入力した内容は、官公庁ナビの物件登録画面の落札後の連絡先項目にデフォルト表示されます。
        </p>
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            label={'担当部署'}
            data={inputs.data.contactOffice}
            number_remaining_characters={
              inputs.data.contactOffice !== undefined
                ? 30 - inputs.data.contactOffice.length
                : 30
            }
            caution_description={caution_description_char_30}
            handler1={inputs.setters.handleChangeContactOffice}
            invalidMessage={inputs.validationMessage.departmentValid}
          />
          {/*
                    <FormMailWithCaution
                    */}
          <FormTextWithCaution
            label={'メールアドレス'}
            data={inputs.data.contactEmail}
            caution_description={new_entry_description8}
            handler1={inputs.setters.handleChangeContactEmail}
            invalidMessage={inputs.validationMessage.contactEmailValid}
          />
          <FormTelWithCaution
            label={'電話番号'}
            caution_description={new_entry_description9}
            telData={inputs.data?.contactTel}
            setTelData={(tel) => inputs.setters.handleChangeContactTel(tel)}
            setTel1Data={(tel) => inputs.setters?.handleChangeTelNum1(tel)}
            setTel2Data={(tel) => inputs.setters?.handleChangeTelNum2(tel)}
            setTel3Data={(tel) => inputs.setters?.handleChangeTelNum3(tel)}
            hasExtension={false}
            invalidMessage={inputs.validationMessage.contactTelValid}
            invalid1Message={inputs.validationMessage.tel1Valid}
            invalid2Message={inputs.validationMessage.tel2Valid}
            invalid3Message={inputs.validationMessage.tel3Valid}
          />
        </div>
      </div>
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={'確認画面'}
              isOutline={false}
              isDisabled={!isValid}
              height={'44px'}
              onClick={toConfirm}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default NaviNewEntryForm;

// import configs from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/configs/CAMEL_TABLE_NAME";
// import { allFetch } from "../../../../../work/members_student/project/auction_matching/react/src/navi/api/newEntry";
//
// const create = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.create);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const update = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.update);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
//
// const destroy = async (e) => {
//   e.preventDefault();
//
//   const Configs = configs(GOVERNMENT_ID, validations)
//   await allFetch(Configs.destroy);
//   // API送信後にモーダルを表示する場合は必要
//   // setIsOpen(true)
// }
