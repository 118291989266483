import { apiRequest } from '../../shared/utils/helper/apiRequest.js';

import { API_BASE_URL } from '../../config/envConstants.js';

export const createNaviBankTransferPagesData = async (body) => {
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/navi/bank_transfer_page`,
    body,
  );
};

export const updateNaviBankTransferPagesData = async (body) => {
  return await apiRequest(
    'put',
    `${API_BASE_URL}/api/v1/navi/bank_transfer_page`,
    body,
  );
};

export const deleteNaviBankTransferPagesData = async (id) => {
  return await apiRequest(
    'delete',
    `${API_BASE_URL}/api/v1/navi/bank_transfer_page/${id}`,
  );
};
