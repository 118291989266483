// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { AccountName } from './Divisions/accountName';
import { AccountNameKana } from './Divisions/accountNameKana';
import { AccountNumber } from './Divisions/accountNumber';
import { AccountType } from './Divisions/accountType';
import { Address } from './Divisions/address';
import { BranchName } from './Divisions/branchName';
import { BuildName } from './Divisions/buildName';
import { City } from './Divisions/city';
import { ContactEmail } from './Divisions/contactEmail';
import { ContactExtension } from './Divisions/contactExtension';
import { ContactName } from './Divisions/contactName';
import { ContactTel } from './Divisions/contactTel';
import { DeletedAt } from './Divisions/deletedAt';
import { Destination } from './Divisions/destination';
import { FinancialInstitutionName } from './Divisions/financialInstitutionName';
import { GovernmentId } from './Divisions/governmentId';
import { Name } from './Divisions/name';
import { NameKana } from './Divisions/nameKana';
import { PostalCode } from './Divisions/postalCode';
import { PrefectureId } from './Divisions/prefectureId';
import { ProcedureType } from './Divisions/procedureType';
import { UseCreditCard } from './Divisions/useCreditCard';
import { WrittenDestination } from './Divisions/writtenDestination';

export class DivisionGet {
  constructor(data) {
    this.accountName = data.accountName;
    this.accountNameKana = data.accountNameKana;
    this.accountNumber = data.accountNumber;
    this.accountType = data.accountType;
    this.address = data.address;
    this.branchName = data.branchName;
    this.buildName = data.buildName;
    this.city = data.city;
    this.contactEmail = data.contactEmail;
    this.contactExtension = data.contactExtension;
    this.contactName = data.contactName;
    this.contactTel = data.contactTel;
    this.deletedAt = data.deletedAt;
    this.destination = data.destination;
    this.financialInstitutionName = data.financialInstitutionName;
    this.governmentId = data.governmentId;
    this.id = data.id;
    this.name = data.name;
    this.nameKana = data.nameKana;
    this.postalCode = data.postalCode;
    this.prefectureId = data.prefectureId;
    this.procedureType = data.procedureType;
    this.useCreditCard = data.useCreditCard;
    this.writtenDestination = data.writtenDestination;
  }
}

export class DivisionCreate {
  constructor(data) {
    this.accountName = new AccountName(data.accountName);
    this.accountNameKana = new AccountNameKana(data.accountNameKana);
    this.accountNumber = new AccountNumber(data.accountNumber);
    this.accountType = new AccountType(data.accountType);
    this.address = new Address(data.address);
    this.branchName = new BranchName(data.branchName);
    this.buildName = new BuildName(data.buildName);
    this.city = new City(data.city);
    this.contactEmail = new ContactEmail(data.contactEmail);
    this.contactExtension = new ContactExtension(data.contactExtension);
    this.contactName = new ContactName(data.contactName);
    this.contactTel = new ContactTel(
      data.contactTel.telNum1 &&
      data.contactTel.telNum2 &&
      data.contactTel.telNum3
        ? `${data.contactTel.telNum1}-${data.contactTel.telNum2}-${data.contactTel.telNum3}`
        : '',
    );
    this.deletedAt = new DeletedAt(data.deletedAt);
    this.destination = new Destination(data.destination);
    this.financialInstitutionName = new FinancialInstitutionName(
      data.financialInstitutionName,
    );
    this.governmentId = new GovernmentId(data.governmentId);
    this.name = new Name(data.divisionName);
    this.nameKana = new NameKana(data.divisionNameKana);
    this.postalCode = new PostalCode(
      data.postalCode.postalCode1 && data.postalCode.postalCode2
        ? `${data.postalCode.postalCode1}-${data.postalCode.postalCode2}`
        : '',
    );
    this.prefectureId = new PrefectureId(data.prefectureId);
    this.procedureType = new ProcedureType(data.procedureType);
    this.useCreditCard = new UseCreditCard(data.useCreditCard);
    this.writtenDestination = new WrittenDestination(data.writtenDestination);
  }
}
