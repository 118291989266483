import { styled } from "styled-components";

const HeaderTitle = styled.h5`
    display: block;
    font-size: 0.83em;
    font-weight: bold;
    unicode-bidi: isolate;
`;

const PersonalInformationCaption = () => {
  return (
    <div className="personal-information-caption" data-testid="personal-information-caption">
      <HeaderTitle>ウェブサイト上の個人情報の取扱いについて</HeaderTitle>
      ＜利用目的＞<br/>
      ・この画面でご提出いただく個人情報は、KSI官公庁オークションの利用にあたり必要な会員登録のために利用します。<br/>
      ＜第三者提供＞<br/>
      ・法令に基づく場合を除いて、ご本人様の同意なく当個人情報を第三者に提供することはありません。<br/>
      ＜委託＞<br/>
      ・当個人情報の取扱いを委託することがありますが、委託にあたっては、委託先における個人情報の安全管理が図られるよう、委託先に対する必要かつ適切な監督を行います。<br/>
      ＜開示等のお求め＞<br/>
      ・当個人情報の利用目的の通知、開示、内容の訂正・追加または削除、利用の停止・消去および第三者への提供の停止（「開示等」といいます。）を受け付けております。開示等の求めは、以下の「個人情報苦情及び相談窓口」で受け付けます。<br/>
      ＜個人情報をご入力するにあたっての注意事項＞<br/>
      ・必要事項が記載されていない場合、会員登録ができません。<br/>
      ＜個人情報保護管理者＞<br/>
      紀尾井町戦略研究所株式会社 別所直哉<br/>
      ＜個人情報苦情及び相談窓口＞<br/>
      紀尾井町戦略研究所株式会社 苦情相談窓口<br/>
      メール：<a href="mailto:privacy@ksi-corp.jp">privacy@ksi-corp.jp</a>
    </div>
  );
};

export default PersonalInformationCaption;
