import { useNavigate } from 'react-router-dom';

import Button from 'interfaces/components/common/Button.jsx';
import ButtonList from 'interfaces/components/common/ButtonList.jsx';
import KmanagerTableRowHeaderData from 'interfaces/components/common/Table/KmanagerTableRowHeaderData.jsx';

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

import 'interfaces/css/common/kmanager-table.css';

const TOP_PATH = '/kmanager';

const AuctionConfirm = ({ type = 'create', data, cancel, id }) => {
  const navigation = useNavigate();

  const dateTimeField = (value) => {
    if (value instanceof Date) {
      return value.getTime();
    } else {
      return value;
    }
  };

  const Put = async () => {
    const dataObj = data.reduce((acc, cur) => {
      acc[cur.column] = cur.state;

      return acc;
    }, {});

    let body = {};

    switch (dataObj.publicSaleType) {
      case 'AUCTION':
        body = {
          name: dataObj.name,
          government_type_group: dataObj.governmentTypeGroup,
          procedure_type: dataObj.procedureType,
          public_sale_type: dataObj.publicSaleType,
          gov_apl_start: dateTimeField(dataObj.govAplStart),
          gov_apl_end: dateTimeField(dataObj.govAplEnd),
          apl_start: dateTimeField(dataObj.aplStart),
          apl_end: dateTimeField(dataObj.aplEnd),
          bid_start: dateTimeField(dataObj.bidStart),
          auction_end: dateTimeField(dataObj.auctionEnd),
          bid_decision: dateTimeField(dataObj.bidDecision),
          open_end: dateTimeField(dataObj.openEnd),
          deposit_type: dataObj.depositType,
        };

        break;
      case 'BID':
        body = {
          name: dataObj.name,
          government_type_group: dataObj.governmentTypeGroup,
          procedure_type: dataObj.procedureType,
          public_sale_type: dataObj.publicSaleType,
          gov_apl_start: dateTimeField(dataObj.govAplStart),
          gov_apl_end: dateTimeField(dataObj.govAplEnd),
          apl_start: dateTimeField(dataObj.aplStart),
          apl_end: dateTimeField(dataObj.aplEnd),
          bid_start: dateTimeField(dataObj.bidStart),
          bid_end: dateTimeField(dataObj.bidEnd),
          add_bid_duration: dateTimeField(dataObj.addBidDuration),
          next_payment_limit: dateTimeField(dataObj.nextPaymentLimit),
          estate_next_payment_limit: dateTimeField(
            dataObj.estateNextPaymentLimit,
          ),
          bid_decision: dateTimeField(dataObj.bidDecision),
          open_end: dateTimeField(dataObj.openEnd),
          deposit_type: dataObj.depositType,
        };

        break;
      case 'BOTH':
        body = {
          name: dataObj.name,
          government_type_group: dataObj.governmentTypeGroup,
          procedure_type: dataObj.procedureType,
          public_sale_type: dataObj.publicSaleType,
          gov_apl_start: dateTimeField(dataObj.govAplStart),
          gov_apl_end: dateTimeField(dataObj.govAplEnd),
          apl_start: dateTimeField(dataObj.aplStart),
          apl_end: dateTimeField(dataObj.aplEnd),
          bid_start: dateTimeField(dataObj.bidStart),
          auction_end: dateTimeField(dataObj.auctionEnd),
          bid_end: dateTimeField(dataObj.bidEnd),
          add_bid_duration: dateTimeField(dataObj.addBidDuration),
          next_payment_limit: dateTimeField(dataObj.nextPaymentLimit),
          estate_next_payment_limit: dateTimeField(
            dataObj.estateNextPaymentLimit,
          ),
          bid_decision: dateTimeField(dataObj.bidDecision),
          open_end: dateTimeField(dataObj.openEnd),
          deposit_type: dataObj.depositType,
        };

        break;
    }

    switch (type) {
      case 'create':
        await apiRequest(
          'post',
          `${API_BASE_URL}/api/v1/kmanager/auctions`,
          JSON.stringify(body),
        );

        break;
      case 'edit':
        await apiRequest(
          'put',
          `${API_BASE_URL}/api/v1/kmanager/auctions/${id}`,
          JSON.stringify(body),
        );

        break;
    }

    navigation(TOP_PATH);
  };

  const CANCEL_BUTTON_DATA = {
    text: 'キャンセル',
    clickHandler: cancel,
    className: 'edit',
  };

  const CREATE_BUTTON_DATA = {
    text: '作成',
    clickHandler: Put,
    className: 'primary',
  };

  const EDIT_BUTTON_DATA = {
    text: '編集',
    clickHandler: Put,
    className: 'primary',
  };

  const BUTTONS_DATA = {
    cancel: CANCEL_BUTTON_DATA,
    create: CREATE_BUTTON_DATA,
    edit: EDIT_BUTTON_DATA,
  };

  const TitleText = () => {
    if (type === 'create') {
      return '下記内容で作成します。よろしければ作成ボタンをクリックしてください';
    } else if (type === 'edit') {
      return '下記内容で編集します。よろしければ編集ボタンをクリックしてください';
    }
  };

  return (
    <div
      className="kmanager-col-12 pa-5 box-shadow kmanager-confirm kmanager-auction-operation"
      style={{ maxWidth: '1000px' }}>
      <h2 className="font-color-red">{TitleText()}</h2>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-auction-confirm-table">
          <tbody>
            {data
              .filter((data) => data)
              .map((data, index) => {
                return <KmanagerTableRowHeaderData key={index} data={data} />;
              })}
          </tbody>
        </table>
      </div>
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <Button
            text={BUTTONS_DATA['cancel'].text}
            clickHandler={BUTTONS_DATA['cancel'].clickHandler}
            className={BUTTONS_DATA['cancel'].className}
          />
          <Button
            text={BUTTONS_DATA[type].text}
            clickHandler={BUTTONS_DATA[type].clickHandler}
            className={BUTTONS_DATA[type].className}
          />
        </div>
      </ButtonList>
    </div>
  );
};
export default AuctionConfirm;
