/** @format */

import Button from 'interfaces/components/common/Button/Button';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
/*
  TODO
    * 入札額のバリデーション
      * 半角数字のみであること
      * 入札金額が最低入札金額以下であること
      * 入札金額が300億円以下であること
    * validate関数からエラメッセージがあれば表示されること
    * 入力時に全角から半角数字に置換されること
    * テストファイル: react/src/service/__tests__/components/PropertyDetail/BiddingDialog.test.jsx
    * 現行コード: work/members_student/original/gl-auc/front/components/items/ItemsBidDialog.m.vue
*/

// バリデーション関数をコンポーネントの外に移動
const validateBid = (value, bidMin) => {
  const numValue = value.replace(/[０-９]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0),
  );
  if (!/^[0-9]+$/.test(numValue)) {
    return '半角数字で入力してください。';
  }
  if (parseInt(numValue, 10) < bidMin) {
    return '最低入札額より小さい金額です。';
  }
  if (parseInt(numValue, 10) > 30000000000) {
    return '入札できるのは300億円までです。';
  }
  return '';
};

const Dialog = styled.div`
  .dialog {
    height: 90%;

    & > .v-card {
      height: 100%;
      display: flex;
      flex-direction: column;

      .v-card__text {
        overflow: hidden;
        height: 100%;
      }
    }
  }
`;

const E_BiddingDialog = ({ bidMin, isOpen, handleClose }) => {
  const [bidPrice, setBidPrice] = useState('');
  const [error, setError] = useState('');
  const [isBidden, setIsBidden] = useState(false);
  const navigate = useNavigate();

  // useCallbackを使用して、関数が不要に再生成されるのを防ぐ
  const handleBidChange = useCallback(
    (event) => {
      const { value } = event.target;
      setBidPrice(value);
      setError(validateBid(value, bidMin));
    },
    [bidMin],
  );

  const bid = useCallback(() => {
    if (error) {
      console.error(error);
      return;
    }

    setIsBidden(true);

    // TODO: 入札価格をサーバーに送信する処理を実装
    navigate(`/confirmation-bids`, { state: { price: bidPrice } });
  }, [error, history]);

  return (
    <Modal2 isOpen={isOpen} handleClose={handleClose} modalSize="S">
      <Dialog
        data-testid="bidding-dialog"
        style={{ maxWidth: '400px' }}>
        {/* ...省略 */}
        
        <span className="headline">入札する</span>
        <p>{bidMin}円から入札できます。</p>
        <form data-testid="bid-amount-form">
          {/* ...省略 */}
          <input
            data-testid="bid-amount-input"
            type="text"
            aria-label="入札額" // アクセシビリティのためのARIAラベル
            placeholder="入札額"
            value={bidPrice}
            onChange={handleBidChange}
            // スタイルはCSSクラスに移動
          />
          {error && <div data-testid="error-message">{error}</div>}
          {/* ...省略 */}
          <Button
            text="キャンセルする"
            height="46px"
            disabled={!!error || isBidden}
            onClick={handleClose}
          />
          <Button
            text="確認する"
            height="46px"
            disabled={!!error || isBidden}
            onClick={bid}
          />
        </form>
      </Dialog>
    </Modal2>
  );
};

E_BiddingDialog.propTypes = {
  bidMin: PropTypes.number.isRequired,
};

export default React.memo(E_BiddingDialog);
