import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import {API_BASE_URL} from "../../config/envConstants.js";

export const fetchGovernmentUsersData = async () => {
  return await axios.get(
    `${API_BASE_URL}/api/v1/management/government-users`,
  );
};
export const createGovernmentUsersData = async (body) => {
  console.log(body)
  return await axios.post(`${API_BASE_URL}/api/v1/kmanager/government_users`,
    body, {headers: apiHeaders()}
  );

};
export const updateGovernmentUsersData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(`${API_BASE_URL}/api/v1/management/government-users/${id}`,
    body, {headers: headers}
  );
};
export const deleteGovernmentUsersData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/government-users/${id}`, {
      headers: headers,
    },);
};