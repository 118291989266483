import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Guideline from './Guideline';

import { styled } from 'styled-components';

const ModalBase = styled.div`
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
`;

const GuidelineModal = ({
  guideline,
  governmentName,
  procedureType,
  label,
}) => {
  const location = useLocation();
  const currentQuery = location.search;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    document.documentElement.style.overflowY = 'hidden';
  };

  const handleClose = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'scroll';
  };

  return (
    <span data-testid="guideline-modal">
      <Link
        className="noshrink"
        to={{ pathname: location.pathname, search: currentQuery }}
        onClick={() => handleOpen()}
        data-testid="guideline-label">
        {label}
      </Link>
      <Modal2
        isOpen={isOpen}
        closeClickOutside={true}
        handleClose={() => handleClose()}
        isCloseButton={true}
        className=""
        isScrollModal={true}
        isNotDefaultModal={true}>
        <ModalBase>
          <Guideline
            governmentName={governmentName}
            procedureType={procedureType}
            data={guideline}
            closeModal={() => handleClose()}
          />
        </ModalBase>
      </Modal2>
    </span>
  );
};

export default GuidelineModal;
