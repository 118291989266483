import { useState } from 'react';

export function useEditUserInputs() {
  const [corpName, setCorpName] = useState('');
  const [corpNameKana, setCorpNameKana] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [familyNameKana, setFamilyNameKana] = useState('');
  const [firstName, setFirstName] = useState('');
  const [firstNameKana, setFirstNameKana] = useState('');
  const [sex, setSex] = useState('');
  const [birthDate, setBirthDate] = useState('');
  //const [postalCode, setPostalCode] = useState('');
  const [postalCode1, setPostalCode1] = useState('');
  const [postalCode2, setPostalCode2] = useState('');
  const [locationGovernmentSubCategoryId, setLocationGovernmentSubCategoryId] =
    useState('');
  const [address, setAddress] = useState('');
  const [houseTel, setHouseTel] = useState('');
  const [mobileTel, setMobileTel] = useState('');

  return {
    data: {
      corpName,
      corpNameKana,
      familyName,
      familyNameKana,
      firstName,
      firstNameKana,
      sex,
      birthDate,
      //postalCode,
      postalCode1,
      postalCode2,
      locationGovernmentSubCategoryId,
      address,
      houseTel,
      mobileTel,
    },
    setters: {
      setCorpName,
      setCorpNameKana,
      setFamilyName,
      setFamilyNameKana,
      setFirstName,
      setFirstNameKana,
      setSex,
      setBirthDate,
      //setPostalCode,
      setPostalCode1,
      setPostalCode2,
      setLocationGovernmentSubCategoryId,
      setAddress,
      setHouseTel,
      setMobileTel,
    },
  };
}
