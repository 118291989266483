// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items-hero-action-area {
  min-width: 300px;
  max-width: 340px;
  width: 25%;
}

.items-hero-action-area .items-action-area-remaining {
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e6e6;
}

.items-hero-action-area .items-action-area-btn--appli,
.items-hero-action-area .items-action-area-btn--mylist {
  font-size: 1.25rem;
  width: 100%;
  height: 70px;
}

.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
}

.v-btn--outlined {
  background: #fff;
  border: thin solid;
}

.btn--shadow-outlined {
  border-width: 2px;
}

.hero-area-bid-deadline {
  text-align: right;
  color: #f44336;
  caret-color: #f44336;
  font-size: 1.25rem;
  width: 100%;
}

.hero-area-mylist-button {
  font-size: 1.25rem;
  width: 100%;
  height: 70px;
  min-width: 92px;
  background: #fff;
  color: var(--primary-base2);
  caret-color: var(--primary-base2);
  padding: 0 23px;
  /*margin-top: 12px;\`*/
  border: 2px solid;
}
`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/hero-area.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;;EAEE,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EAGE,mBAAmB;EACnB,cAAc;EAGd,aAAa;EAGb,cAAc;EAGd,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,2BAA2B;EAC3B,iCAAiC;EACjC,eAAe;EACf,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".items-hero-action-area {\n  min-width: 300px;\n  max-width: 340px;\n  width: 25%;\n}\n\n.items-hero-action-area .items-action-area-remaining {\n  padding-bottom: 8px;\n  border-bottom: 1px solid #e8e6e6;\n}\n\n.items-hero-action-area .items-action-area-btn--appli,\n.items-hero-action-area .items-action-area-btn--mylist {\n  font-size: 1.25rem;\n  width: 100%;\n  height: 70px;\n}\n\n.v-btn__content {\n  -webkit-box-align: center;\n  -ms-flex-align: center;\n  align-items: center;\n  color: inherit;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-flex: 1;\n  -ms-flex: 1 0 auto;\n  flex: 1 0 auto;\n  -webkit-box-pack: inherit;\n  -ms-flex-pack: inherit;\n  justify-content: inherit;\n  line-height: normal;\n  position: relative;\n}\n\n.v-btn--outlined {\n  background: #fff;\n  border: thin solid;\n}\n\n.btn--shadow-outlined {\n  border-width: 2px;\n}\n\n.hero-area-bid-deadline {\n  text-align: right;\n  color: #f44336;\n  caret-color: #f44336;\n  font-size: 1.25rem;\n  width: 100%;\n}\n\n.hero-area-mylist-button {\n  font-size: 1.25rem;\n  width: 100%;\n  height: 70px;\n  min-width: 92px;\n  background: #fff;\n  color: var(--primary-base2);\n  caret-color: var(--primary-base2);\n  padding: 0 23px;\n  /*margin-top: 12px;`*/\n  border: 2px solid;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
