import { useEffect } from 'react';
import { useState } from 'react';
//import { logInput } from "../../utils/log/logInputs";
import * as Validation from 'shared/utils/helper/naviValidators';

const CardProvisionalApplyCompletionForm = (isEdit) => {
  const [documentSubmissionUrlRequiredValid, setDocumentSubmissionUrlRequiredValid] = useState(isEdit ? "TRUE" : null);
  const [jointBiddingUrlValid, setJointBiddingUrlValid] = useState(isEdit ? "TRUE" : null);
  useEffect(() => {
    setDocumentSubmissionUrlRequiredValid(isEdit ? "TRUE" : null);
    setJointBiddingUrlValid(isEdit ? "TRUE" : null);
  }, [isEdit]);

  function handleDocumentSubmissionUrlValid(e, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }

    if (e.target.value.length > 0) {
      invalid = Validation.validateDocumentSubmissionUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateDocumentSubmissionUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setDocumentSubmissionUrlRequiredValid(invalid);
        return;
      }
    }
    setDocumentSubmissionUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleJointBiddingUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateJointBiddingUrlValidUrl1(e.target.value);
      if (invalid !== 'TRUE') {
        setJointBiddingUrlValid(invalid);
        return;
      }
      invalid = Validation.validateJointBiddingUrlLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setJointBiddingUrlValid(invalid);
        return;
      }
    }
    setJointBiddingUrlValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleDocumentSubmissionUrlValid,
      handleJointBiddingUrlValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      documentSubmissionUrlRequiredValid,
      jointBiddingUrlValid,
    },
  };

  return validations;
};

export default CardProvisionalApplyCompletionForm;
