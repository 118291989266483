import 'interfaces/css/common/listbox.css';

const PropertyCategoriesListBox = ({
  data,
  setShowListBox,
  setSelectedId,
  setSelectedName,
  formStateHandler,
  width,
}) => {
  const closeShowListBox = () => setShowListBox(false);
  const clickHandler = (e, d) => {
    setSelectedId(d.id);
    setSelectedName(d.name);
    closeShowListBox();
    if (formStateHandler) {
      formStateHandler(e);
    }
  };
  return (
    <div className="list-box-wrap" style={{ width: width }}>
      <div className="list-box">
        {Array.isArray(data) && data.length === 0 && (
          <div className="list-item" onClick={closeShowListBox}>
            <div className="list-item-content">
              <div className="list-item-title">No data available</div>
            </div>
          </div>
        )}
        {data &&
          data.map((d) => (
            <div className="list-item" key={d.id}>
              <input
                type="button"
                id={d.id}
                value={d.value ?? d.name}
                style={{
                  opacity: 0,
                  flex: 0,
                }}
                onClick={(e) => clickHandler(e, d)}
                data-testid="list-item"
              />
              <label htmlFor={d.id} style={{ width: '100%' }}>
                <div className="list-item-content">
                  <div className="list-item-title">{d?.name ?? ''}</div>
                </div>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PropertyCategoriesListBox;
