import ConfirmNumberForm from './ConfirmNumberForm.jsx';
import { styled } from 'styled-components';

const Title = styled.p`
  font-size: 1.25rem;
  text-align: center;
  padding: 16px;
`;

const Message = styled.p`
  padding: 10px;
  font-size: 0.875rem;
`;

const ConfirmNumberModalContents = ({ handleClose, setModalType, signUpEmail }) => {
  return (
    <>
      <Title data-testid="confirm-number-modal-contents-title">
        確認コード入力
      </Title>
      <Message data-testid="confirm-number-modal-contents-caption">
        入力されたメールアドレスに確認コードを送信しました。<br/>
        メールに記載された確認コードを入力してください。
      </Message>
      <ConfirmNumberForm handleClose={handleClose} setModalType={setModalType} targetEmail={signUpEmail}/>
    </>
  );
};

export default ConfirmNumberModalContents;
