import axios from 'axios';
import { API_BASE_URL } from 'config/envConstants.js';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';

const refreshAccessToken = async () => {
  console.log('Attempting to refresh access token.');
  try {
    const refreshToken = localStorage.getItem('requestRefreshToken');
    console.log(`Using refresh token: ${refreshToken}`);
    const response = await axios.post(
      `${API_BASE_URL}/api/v1/refresh_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    if (response.status === 200) {
      const { appAccessToken, appRefreshToken } = response.data;
      console.log('Access token refreshed successfully.');
      localStorage.setItem('requestAccessToken', appAccessToken);
      localStorage.setItem('requestRefreshToken', appRefreshToken);
      return { accessToken: appAccessToken, refreshToken: appRefreshToken };
    } else {
      console.error(
        `Failed to refresh access token, status code: ${response.status}`,
      );
      throw new Error('Failed to refresh access token');
    }
  } catch (error) {
    console.error('Error during token refresh:', error);
    throw new Error('Failed to refresh access token');
  }
};

export const apiRequest = async (
  method,
  url,
  body = null,
  customHeaders = {},
) => {
  console.log(`Making API request to ${url} with method ${method}`);
  let headers = { ...apiHeaders(), ...customHeaders };
  console.log('Headers:', headers);

  try {
    const response = await axios({
      method: method,
      url: url,
      data: body,
      headers: headers,
    });

    console.log(`Response status: ${response.status}`);
    if (response.status === 200) {
      console.log('API request successful.');
      return response.data;
    } else {
      console.error(`Unexpected status code: ${response.status}`);
      throw new Error(`Unexpected status code: ${response.status}`);
    }
  } catch (error) {
    console.error('API request failed:');
    console.error(error);
    if (error.response && error.response.status === 401) {
      console.log('Access token expired, attempting to refresh.');
      try {
        const { accessToken, refreshToken } = await refreshAccessToken();
        if (accessToken) {
          console.log('accessToken:', accessToken);
          console.log('refreshToken:', refreshToken);
          console.log(
            'accessToken is same as localStorage:',
            accessToken === localStorage.getItem('requestAccessToken'),
          );
          console.log(
            'refreshToken is same as localStorage:',
            refreshToken === localStorage.getItem('requestRefreshToken'),
          );
          headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Refresh-Token': `Bearer ${refreshToken}`,
          };
          console.log('Retrying API request with new access token.');
          const retryResponse = await axios({
            method: method,
            url: url,
            data: body,
            headers: headers,
          });
          return retryResponse.data;
        }
      } catch (error) {
        console.error(`Error occurred: ${error.message}`);
        if (error.response && error.response.status === 401) {
          console.error('Refresh token invalid, redirecting to login.');
          console.log(url);
          if (url.includes('kmanager')) {
            window.location.href = '/kmanager/login';
          } else if (url.includes('service')) {
            window.location.href = '/service/login';
          } else if (url.includes('navi')) {
            window.location.href = '/navi/login';
          } else {
            window.location.href = '/';
          }
        }
      }
    } else {
      console.error(`Error occurred: ${error.message}`);
    }
    throw error;
  }
};
