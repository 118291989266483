import { useState, useEffect } from "react";

export const useBankProvisionalApplyCompletionInputs = (data) =>  {
  const [bankTransferUrl,setBankTransferUrl] = useState(data?.bankTransferUrl ?? '');
  const [jointBiddingUrl,setJointBiddingUrl] = useState(data?.jointBiddingUrl ?? '');
  useEffect(() => {
    setBankTransferUrl(data?.bankTransferUrl ?? '');
    setJointBiddingUrl(data?.jointBiddingUrl ?? '');
  }, [data]);

  const inputs = {
    data: {
      bankTransferUrl,
      jointBiddingUrl,
    },
    setters:{
      setBankTransferUrl,
      setJointBiddingUrl,
    }
  }
  return inputs;
}