import CardProvisionalApplyCompletionForm from 'interfaces/components/common/CardProvisionalApplyCompletionForm';
import { useCardProvisionalInputs } from 'shared/utils/helper/useCardProvisionalInputs';

export const validateInputsCardProvisionalApplyCompletion = (data) => {
  const formInputs = useCardProvisionalInputs(data);
  const validate = CardProvisionalApplyCompletionForm(data !== null);

  function handleDocumentSubmissionUrl(e, required) {
    validate.setters.handleDocumentSubmissionUrlValid(e, required);
    formInputs.setters.setDocumentSubmissionUrl(e.target.value);
  }

  function handleJointBiddingUrl(e) {
    validate.setters.handleJointBiddingUrlValid(e);
    formInputs.setters.setJointBiddingUrl(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleDocumentSubmissionUrl,
      handleJointBiddingUrl,
    },
    init: {
      setDocumentSubmissionUrl: formInputs.setters.setDocumentSubmissionUrl,
      setJointBiddingUrl: formInputs.setters.setJointBiddingUrl,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
};
