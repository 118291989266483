import AfterWinningBidLink from 'interfaces/components/common/AfterWinningBid/AfterWinningBidLink';
import GuidelineLink from 'interfaces/components/common/Guideline/GuidelineLink';
import { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { propertyStatus } from 'domain/Property/entities/constants/propertyStatus';
import { PropertyDetailContext } from 'interfaces/pages/PropertyDetail/entities/PropertyDetailContext';
import './css/property-detail-head.css';

const PropertyDetailHead = ({ data }) => {
  const { cautionPages, guidelines } = useContext(PropertyDetailContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section data-testid="property-detail-head">
      <div className="no-bg v-sheet theme--light">
        <div className="government-info-group no-bg flex mb-4 wrap v-sheet theme--light">
          <div className="government-name-wrap">
            <div className="flex align-center flex-grow-0 my-2 mr-4">
              <div className="mr-2">
                <div data-testid="government-emblem" className="v-avatar">
                  <img
                    alt=""
                    src={`/image_storage/${data?.governmentId}/emblem.jpg`}
                    style={{ width: '32px' }}
                  />
                </div>
              </div>
              <div className="text-h6">{data?.governmentName}</div>
            </div>
          </div>
          <div className="property-detail-head-link">
            <Link
              to={`/gov/${data?.governmentId}?p=${data?.procedureType === 'PUBLIC_SALE' ? 'au' : 'as'}`}
              className="mr-7 noshrink">
              物件一覧
            </Link>
            <div className="guideline-link-wrap mr-7">
              <GuidelineLink
                governmentId={data?.governmentId}
                governmentName={data?.governmentName}
                procedureType={data?.procedureType}
                guidelines={guidelines}
              />
            </div>
            <AfterWinningBidLink
              governmentId={data?.governmentId}
              governmentName={data?.governmentName}
              procedureType={data?.procedureType}
              publicSaleType={data?.publicSaleType}
              afterWinningBids={cautionPages}
            />
          </div>
        </div>
      </div>

      <div>
        <h1 className={'property-title-h1'} data-testid="property-name">
          {data?.name}
        </h1>
      </div>
      <div className="flex mt-4 align-center">
        <div className="property-status-tag mr-4" data-testid="property-status">
          {propertyStatus[data?.status]}
        </div>
        <div className="d-inline-flex flex-wrap">
          {data?.tags?.map((tag, index) => (
            <div key={index} className="items-tags">
              <div className="property-tag mr-4">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fal"
                  data-icon="tag"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="mx-1 svg-inline--fa fa-tag fa-w-16 fa-fw">
                  <path
                    fill="currentColor"
                    d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.745 18.745 49.137 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zm-22.627 45.255L271.196 475.314c-6.243 6.243-16.375 6.253-22.627 0L36.686 263.431A15.895 15.895 0 0 1 32 252.117V48c0-8.822 7.178-16 16-16h204.118c4.274 0 8.292 1.664 11.314 4.686l211.882 211.882c6.238 6.239 6.238 16.39 0 22.628zM144 124c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20m0-28c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48z"></path>
                </svg>
                {tag}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PropertyDetailHead;
