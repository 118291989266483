import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import AddressForm from 'interfaces/components/common/Form/AddressForm';
import PostalCodeForm from 'interfaces/components/common/Form/PostalCodeForm';
import PrefectureForm from 'interfaces/components/common/Form/PrefectureForm';
import CommonTextInput from 'interfaces/components/common/Input/CommonTextInput';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault';
import handleAddressSearch from 'shared/hooks/handleAddressSearch';
import { PREFECTURE } from 'shared/utils/constants/prefecture';

import BirthDateInput from './BirthDateInput';
import BothTelInput from './BothTelInput';
import FullNameInput from './FullNameInput';
import FullNameKanaInput from './FullNameKanaInput';
import GenderInput from './GenderInput';
import CorporateIndividualInput from './CorporateIndividualInput';

import 'interfaces/css/common/edit-user-info-body.css';

const MembershipRow = ({ membershipId }) => {
  return (
    <tr>
      <th>
        会員識別番号
        <RequiredLabel />
      </th>
      <td>{membershipId}</td>
    </tr>
  );
};

const EditUserInfoBody = ({
  prefix,
  membershipId,
  userInfo,
  handleUserInfoChange,
  validationMessages,
  children,
  suffix,
  //isDelegator = false,
}) => {
  return (
    <div className="edit-user-info-body">
      <ArticleDefaultBody>
        {prefix}
        <CommonTableDefault>
          {membershipId ? <MembershipRow membershipId={membershipId} /> : null}

          <CorporateIndividualInput
            userCorporateIndividual={userInfo?.corporateIndividual}
            handleUserInfoChange={handleUserInfoChange}
            validationMessage={validationMessages?.corporateIndividual}
          />

          {userInfo?.corporateIndividual === 'CORPORATE' ? (
            <CommonTextInput
              label={
                <p>
                  法人名
                  <RequiredLabel />
                  <br />
                  <small>法人の場合のみ</small>
                </p>
              }
              name="corp-name"
              value={userInfo?.corpName}
              placeholder="（例）サンプル株式会社"
              handleChange={(e) =>
                handleUserInfoChange({
                  ...{ corpName: e.target.value },
                })
              }
              validationMessage={validationMessages?.corpName}
            />
          ) : null}

          {userInfo?.corporateIndividual === 'CORPORATE' ? (
            <CommonTextInput
              label={
                <p>
                  法人名 カナ
                  <RequiredLabel />
                  <br />
                  <small>法人の場合のみ</small>
                </p>
              }
              name="corp-name-kana"
              value={userInfo?.corpNameKana}
              placeholder="（例）サンプルカブシキガイシャ"
              handleChange={(e) =>
                handleUserInfoChange({ corpNameKana: e.target.value })
              }
              validationMessage={validationMessages?.corpNameKana}
            />
          ) : null}
          <FullNameInput
            familyName={userInfo?.familyName}
            firstName={userInfo?.firstName}
            handleFamilyNameChange={(e) =>
              handleUserInfoChange({ familyName: e.target.value })
            }
            handleFirstNameChange={(e) =>
              handleUserInfoChange({ firstName: e.target.value })
            }
            familyNameValidationMessage={validationMessages?.familyName}
            firstNameValidationMessage={validationMessages?.firstName}
          />
          <FullNameKanaInput
            familyNameKana={userInfo?.familyNameKana}
            firstNameKana={userInfo?.firstNameKana}
            handleFamilyNameKanaChange={(e) =>
              handleUserInfoChange({ familyNameKana: e.target.value })
            }
            handleFirstNameKanaChange={(e) =>
              handleUserInfoChange({ firstNameKana: e.target.value })
            }
            familyNameKanaValidationMessage={validationMessages?.familyNameKana}
            firstNameKanaValidationMessage={validationMessages?.firstNameKana}
          />
          <GenderInput
            userGender={userInfo?.sex}
            handleUserInfoChange={handleUserInfoChange}
            validationMessage={validationMessages?.sex}
          />
          <BirthDateInput
            birthDate={userInfo?.birthDate}
            handleChange={(selectDate) =>
              handleUserInfoChange({ birthDate: selectDate })
            }
            validationMessage={validationMessages?.birthDate}
          />
          <PostalCodeForm
            postalCode1={userInfo?.postalCode1}
            postalCode2={userInfo?.postalCode2}
            postalCode1ValidationMessage={validationMessages?.postalCode1}
            postalCode2ValidationMessage={validationMessages?.postalCode2}
            handleChangePostalCode1={(e) =>
              handleUserInfoChange({ postalCode1: e.target.value })
            }
            handleChangePostalCode2={(e) =>
              handleUserInfoChange({ postalCode2: e.target.value })
            }
            handleAddressSearch={() =>
              handleAddressSearch(
                userInfo?.postalCode1,
                userInfo?.postalCode2,
                handleUserInfoChange,
              )
            }
          />
          <PrefectureForm
            prefectureId={userInfo?.prefectureId}
            handleSelect={(item) =>
              handleUserInfoChange({
                prefectureId: PREFECTURE.find(
                  (prefecture) => prefecture.name === item,
                ).id,
              })
            }
            validationMessage={validationMessages?.prefectureId}
          />
          <AddressForm
            receiverAddress={userInfo?.address}
            handleChange={(e) =>
              handleUserInfoChange({ address: e.target.value })
            }
            validationMessage={validationMessages?.address}
          />
          <BothTelInput
            houseTel={userInfo?.houseTel}
            mobileTel={userInfo?.mobileTel}
            houseTelValidationMessage={validationMessages?.houseTel}
            mobileTelValidationMessage={validationMessages?.mobileTel}
            handleHoseTelChange={(e) =>
              handleUserInfoChange({ houseTel: e.target.value })
            }
            handleMobileTelChange={(e) =>
              handleUserInfoChange({ mobileTel: e.target.value })
            }
          />
          {children}
        </CommonTableDefault>
        {suffix}
      </ArticleDefaultBody>
    </div>
  );
};

export default EditUserInfoBody;
