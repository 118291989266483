import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle';

import 'interfaces/css/common/basic-info.css';
import { useEffect, useState } from 'react';
import { dateFormat } from 'shared/utils/helper/date_format';
import { structureType } from 'shared/utils/constants/property';

import BasicInfoTable from './BasicInfoTable';

import { CAR, ESTATE } from 'domain/Property/entities/constants/property';
import {
  estateTypeToJp,
  carPurposeToJp,
  privateOrBusinessToJp,
  carManufactureToJp,
  carTransmissionTypeToJp,
  handleTypeToJp,
  existsCarMaintenanceSheetToJp,
} from 'shared/utils/constants/property';

const CarOptionInfo = ({ carOptionInfo }) => {
  return (
    <BasicInfoTable title={'登録事項等証明書の記載内容'} data={carOptionInfo} />
  );
};
const CarInfo = ({ carInfo }) => {
  return <BasicInfoTable title={'自動車の基本情報'} data={carInfo} />;
};

const EstateInfo = ({
  estateInfo,
  estateBasicInfo,
  estateLandInfo,
  estateBuildInfo,
}) => {
  return (
    <>
      <BasicInfoTable title={'物件情報'} data={estateInfo} />
      <BasicInfoTable title={'不動産の基本情報'} data={estateBasicInfo} />
      <BasicInfoTable title={'土地の情報'} data={estateLandInfo} />
      <BasicInfoTable title={'建物の情報'} data={estateBuildInfo} />
    </>
  );
};

const BasicInfo = ({ data }) => {
  const [scheduleData, setScheduleData] = useState([]);
  const [paymentMethodData, setPaymentMethodData] = useState([]);
  const [basicData, setBasicData] = useState([]);
  const [carOptionInfo, setCarOptionInfo] = useState([]);
  const [carInfo, setCarInfo] = useState([]);
  const [estateInfo, setEstateInfo] = useState([]);
  const [estateBasicInfo, setEstateBasicInfo] = useState([]);
  const [estateLandInfo, setEstateLandInfo] = useState([]);
  const [estateBuildInfo, setEstateBuildInfo] = useState([]);

  const depositPaymentMethod = (bank, credit) => {
    if (bank === 'TRUE' && credit === 'TRUE') {
      return '銀行振込など';
    }
    if (bank === 'TRUE') {
      return '銀行振込';
    }
    if (credit === 'TRUE') {
      return 'クレジットカード';
    }
    return '-';
  };

  const paymentMethodToJP = {
    BANK_TRANSFER: '銀行振込',
    REGISTERED_MAIL: '現金書留',
    CHECK: '小切手',
    POSTAL_MONEY_ORDER: '郵便為替',
    CARRY: '直接持参',
  };

  useEffect(() => {
    if (data?.contractedLimit !== '' && data?.status === 'CANCEL') {
      setScheduleData([
        {
          id: 1,
          title: '参加申し込み受付期間',
          value: '中止',
        },
        {
          id: 2,
          title: '入札期間',
          value: '中止',
        },
        {
          id: 3,
          title: `${data?.procedureType === 'PUBLIC_SALE' ? '買受' : '売払'}代金納付期限`,
          value: '中止',
        },
        {
          id: 4,
          title: '契約締結期限',
          value: '中止',
        },
      ]);
    }

    if (data?.contractedLimit !== '' && data?.status !== 'CANCEL') {
      setScheduleData([
        {
          id: 1,
          title: '参加申し込み受付期間',
          value: `開始：${dateFormat(data?.aplStart)} 〜 終了：${dateFormat(data?.aplEnd)}`,
        },
        {
          id: 2,
          title: '入札期間',
          value: `開始：${dateFormat(data?.bidStart)} 〜 終了：${data?.publicSaleType === 'せり売り形式' ? dateFormat(data?.auctionEnd) : dateFormat(data?.bidEnd)}`,
        },
        {
          id: 3,
          title: `${data?.procedureType === 'PUBLIC_SALE' ? '買受' : '売払'}代金納付期限`,
          value: dateFormat(data?.paymentDeadline),
        },
        {
          id: 4,
          title: `契約締結期限`,
          value: dateFormat(data?.contractedLimit),
        },
      ]);
    }

    if (data?.contractedLimit === '' && data?.status === '中止') {
      setScheduleData([
        {
          id: 1,
          title: '参加申し込み受付期間',
          value: '中止',
        },
        {
          id: 2,
          title: '入札期間',
          value: '中止',
        },
        {
          id: 3,
          title: `${data?.procedureType === 'PUBLIC_SALE' ? '買受' : '売払'}代金納付期限`,
          value: '中止',
        },
      ]);
    }

    if (data?.contractedLimit === '' && data?.status !== '中止') {
      setScheduleData([
        {
          id: 1,
          title: '参加申し込み受付期間',
          value: `開始：${dateFormat(data?.aplStart)} 〜 終了：${dateFormat(data?.aplEnd)}`,
        },
        {
          id: 2,
          title: '入札期間',
          value: `開始：${dateFormat(data?.bidStart)} 〜 終了：${data?.publicSaleType === 'せり売り形式' ? dateFormat(data?.auctionEnd) : dateFormat(data?.bidEnd)}`,
        },
        {
          id: 3,
          title: `${data?.procedureType === 'PUBLIC_SALE' ? '買受' : '売払'}代金納付期限`,
          value: dateFormat(data?.paymentDeadline),
        },
      ]);
    }

    setPaymentMethodData([
      {
        id: 1,
        title: `${data?.procedureType === 'PUBLIC_SALE' ? '公売' : '入札'}保証金納付方法`,
        value: `${depositPaymentMethod(data?.paymentBankTransfer, data?.paymentCreditCard)}`,
      },
      {
        id: 2,
        title: `${data?.procedureType === 'PUBLIC_SALE' ? '買受' : '売払'}代金納付方法`,
        value:
          data?.payments !== undefined
            ? data?.payments.length === 0
              ? '-'
              : data?.payments
                  .map((payment) => paymentMethodToJP[payment.payment])
                  .join(' ')
            : '-',
      },
    ]);

    setBasicData([
      {
        id: 1,
        title: 'オークション種別',
        value:
          data?.procedureType === 'PUBLIC_SALE'
            ? 'インターネット公売'
            : '公有財産売却',
      },
      {
        id: 2,
        title: '実施方法',
        value: data?.publicSaleType === 'AUCTION' ? 'せり売形式' : '入札形式',
      },
      {
        id: 3,
        title: '執行機関名称',
        value: data?.governmentName,
      },
      {
        id: 4,
        title: '売却区分番号',
        value: data?.saleNumber,
      },
      {
        id: 5,
        title: '支払い方法',
        value:
          data?.payments !== undefined
            ? data?.payments.length === 0
              ? '-'
              : data?.payments
                  .map((payment) => paymentMethodToJP[payment.payment])
                  .join(' ')
            : '-',
      },
      {
        id: 6,
        title: '契約締結期限',
        value: `${dateFormat(data?.contractedLimit)}`,
      },
      {
        id: 7,
        title: '動画URL',
        value: data?.videoUrl,
        type: 'link',
      },
    ]);
    setCarOptionInfo([
      {
        id: 1,
        title: '証明書発行日',
        value: dateFormat(data?.carProperty?.effectiveDate, 'wareki(YYYY)-MM-DD'),
      },
      {
        id: 2,
        title: '自動車登録番号',
        value: data?.carProperty?.registerNumber,
      },
      {
        id: 3,
        title: '登録年月日',
        value: dateFormat(data?.carProperty?.registerDate, 'wareki(YYYY)-MM-DD'),
      },
      {
        id: 4,
        title: '初度登録年月',
        value: dateFormat(data?.carProperty?.firstRegisterDate, 'wareki(YYYY)-MM-DD'),
      },
      {
        id: 5,
        title: '自動車の種別',
        value: data?.carProperty?.type,
      },
      {
        id: 6,
        title: '用途',
        value: carPurposeToJp[data?.carProperty?.purpose],
      },
      {
        id: 7,
        title: '自家用、事業用の別',
        value: privateOrBusinessToJp[data?.carProperty?.privateOrBusiness],
      },
      {
        id: 8,
        title: '車体の形状',
        value: data?.carProperty?.shape,
      },
      {
        id: 9,
        title: '車名（メーカー）',
        value: carManufactureToJp[data?.carProperty?.manufacture],
      },
      {
        id: 10,
        title: '型式',
        value: data?.carProperty?.form,
      },
      {
        id: 11,
        title: '乗車定員(人)',
        value: data?.carProperty?.seatingCapacity
          ? `${data?.carProperty?.seatingCapacity} 人`
          : '',
      },
      {
        id: 12,
        title: '車両重量(kg)',
        value: data?.carProperty?.weight
          ? `${data?.carProperty?.weight} kg`
          : '',
      },
      {
        id: 13,
        title: '車両総重量(kg)',
        value: data?.carProperty?.totalWeight
          ? `${data?.carProperty?.totalWeight} kg`
          : '',
      },
      {
        id: 14,
        title: '車台番号',
        value: data?.carProperty?.productNumber,
      },
      {
        id: 15,
        title: '原動機の型式',
        value: data?.carProperty?.engineModel,
      },
      {
        id: 16,
        title: '長さ(cm)',
        value: data?.carProperty?.length
          ? `${data?.carProperty?.length} cm`
          : '',
      },
      {
        id: 17,
        title: '幅(cm)',
        value: data?.carProperty?.width ? `${data?.carProperty?.width} cm` : '',
      },
      {
        id: 18,
        title: '高さ(cm)',
        value: data?.carProperty?.height
          ? `${data?.carProperty?.height} cm`
          : '',
      },
      {
        id: 19,
        title: '総排気量(リットル)',
        value: data?.carProperty?.displacement
          ? `${data?.carProperty?.displacement} リットル`
          : '',
      },
      {
        id: 20,
        title: '燃料の種類',
        value: data?.carProperty?.fuelType,
      },
      {
        id: 21,
        title: '前前軸重(kg)',
        value: data?.carProperty?.frontAxelWeight
          ? `${data?.carProperty?.frontAxelWeight} kg`
          : '',
      },
      {
        id: 22,
        title: '後後軸重(kg)',
        value: data?.carProperty?.rearAxelWeight
          ? `${data?.carProperty?.rearAxelWeight} kg`
          : '',
      },
      {
        id: 23,
        title: '自動車検査証有効期限',
        value: data?.carProperty?.safetyTerm
          ? `${dateFormat(data?.carProperty?.safetyTerm, 'wareki(YYYY)-MM-DD')}`
          : '',
      },
      {
        id: 24,
        title: '型式指定番号',
        value: data?.carProperty?.formalNumber,
      },
      {
        id: 25,
        title: '類別区分番号',
        value: data?.carProperty?.classificationNumber,
      },
    ]);
    setCarInfo([
      {
        id: 1,
        title: 'ミッション',
        value: carTransmissionTypeToJp[data?.carProperty?.transmissionType],
      },
      {
        id: 2,
        title: 'ハンドル',
        value: handleTypeToJp[data?.carProperty?.handleType],
      },
      {
        id: 3,
        title: '定期点検記録簿',
        value:
          existsCarMaintenanceSheetToJp[
            data?.carProperty?.existsCarMaintenanceSheet
          ],
      },
      {
        id: 4,
        title: '車体の色',
        value: data?.carProperty?.carBodyColor,
      },
      {
        id: 5,
        title: '走行距離(km)',
        value: data?.carProperty?.carMileage
          ? `${data?.carProperty?.carMileage} km`
          : '',
      },
      {
        id: 6,
        title: '引渡時保管場所',
        value: data?.carProperty?.inventoryLocation,
      },
      {
        id: 7,
        title: '地図',
        value: data?.carProperty?.mapUrl,
        type: 'map',
      },
      {
        id: 8,
        title: '車両等の状況',
        value: data?.carProperty?.carStatus,
      },
      {
        id: 9,
        title: '引き渡し条件',
        value: data?.carProperty?.deliveryTerms,
      },
      {
        id: 10,
        title: 'その他',
        value: data?.carProperty?.other,
      },
      {
        id: 11,
        title: '説明',
        value: data?.carProperty?.description,
      },
    ]);
    setEstateInfo([
      {
        id: 1,
        title: '物件の種類',
        value: `${estateTypeToJp[data?.estateProperty?.type]}`,
      },
      {
        id: 2,
        title: '物件の所在',
        value: `${data?.estateProperty?.locationText}`,
      },
    ]);
    setEstateBasicInfo([
      {
        id: 1,
        title: '登記簿の表示',
        value: data?.estateProperty?.propertyPrint,
      },
      {
        id: 2,
        title: '所在地',
        value: data?.estateProperty?.locationText,
      },
      {
        id: 3,
        title: '住居表示',
        value: data?.estateProperty?.residenceLocation,
      },
      {
        id: 4,
        title: '交通、最寄り駅など',
        value: data?.estateProperty?.nearestStation,
      },
      {
        id: 5,
        title: '所在図',
        value: data?.estateProperty?.locationUrl,
        type: 'map',
      },
      {
        id: 6,
        title: '現況',
        value: data?.estateProperty?.estateStatus,
      },
      {
        id: 7,
        title: 'その他事項',
        value: data?.estateProperty?.other,
      },
      {
        id: 8,
        title: '見取り図など',
        value: '資料をみる',
        url: data?.estateProperty?.sketchUrl,
      },
      {
        id: 9,
        title: '説明',
        value: data?.estateProperty?.description,
      },
    ]);
    setEstateLandInfo([
      {
        id: 1,
        title: '土地面積',
        value:
          data?.estateProperty?.landSpace !== 'NULL'
            ? `${data?.estateProperty?.landSpace} ㎡`
            : 'NULL',
      },
      {
        id: 2,
        title: '土地権利',
        value: data?.estateProperty?.landRights,
      },
      {
        id: 3,
        title: '土地持分',
        value: data?.estateProperty?.landShare,
      },
      {
        id: 4,
        title: '地目',
        value: data?.estateProperty?.landCategory,
      },
      {
        id: 5,
        title: '都市計画地域',
        value: data?.estateProperty?.cityPlanningArea,
      },
      {
        id: 6,
        title: '用途地域',
        value: data?.estateProperty?.restrictedArea,
      },
      {
        id: 7,
        title: '建ぺい率(%)',
        value: data?.estateProperty?.buildRatio,
      },
      {
        id: 8,
        title: '容積率(%)',
        value: data?.estateProperty?.floorAreaRatio,
      },
      {
        id: 9,
        title: '高度地区(種)',
        value: data?.estateProperty?.highDistrict,
      },
      {
        id: 10,
        title: '防火地域',
        value: data?.estateProperty?.firebreakArea,
      },
      {
        id: 11,
        title: '日影規制',
        value: data?.estateProperty?.shadeRegulation,
      },
      {
        id: 12,
        title: 'その他法令などの規制',
        value: data?.estateProperty?.lawRegulation,
      },
      {
        id: 13,
        title: '地勢など',
        value: data?.estateProperty?.geographical,
      },
      {
        id: 14,
        title: '幅員、接道状況',
        value: data?.estateProperty?.neighboringRoad,
      },
      {
        id: 15,
        title: '私道の負担など',
        value: data?.estateProperty?.provideEquipment,
      },
      {
        id: 16,
        title: '供給処理施設',
        value: data?.estateProperty?.provideEquipment,
      },
      {
        id: 17,
        title: 'その他土地に関する物件情報',
        value: data?.estateProperty?.landOther,
      },
    ]);
    setEstateBuildInfo([
      {
        id: 1,
        title: '建物の名称',
        value: data?.estateProperty?.buildName,
      },
      {
        id: 2,
        title: '建物面積',
        value:
          data?.estateProperty?.buildSpace !== 'NULL'
            ? `${data?.estateProperty?.buildSpace} ㎡`
            : 'NULL',
      },
      {
        id: 3,
        title: '専有面積',
        value:
          data?.estateProperty?.exclusiveSpace !== 'NULL'
            ? `${data?.estateProperty?.exclusiveSpace} ㎡`
            : 'NULL',
      },
      {
        id: 4,
        title: '延床面積',
        value:
          data?.estateProperty?.floorSpace !== 'NULL'
            ? `${data?.estateProperty?.floorSpace} ㎡`
            : 'NULL',
      },
      {
        id: 5,
        title: '構造',
        value:
          structureType[data?.estateProperty?.structureType]
           ? `${structureType[data?.estateProperty?.structureType]?.jp}` 
           : '',
      },
      {
        id: 6,
        title: '建築年月',
        value: dateFormat(data?.estateProperty?.buildDate, 'wareki(YYYY)-MM-DD'),
      },
      {
        id: 7,
        title: '建築年月 その他',
        value: data?.estateProperty?.buildDateOther,
      },
      {
        id: 8,
        title: '階建/階',
        value: data?.estateProperty?.floor,
      },
      {
        id: 9,
        title: '総戸数',
        value:
          data?.estateProperty?.totalHouses !== 'NULL'
            ? `${data?.estateProperty?.totalHouses} 戸`
            : 'NULL',
      },
      {
        id: 10,
        title: '間取り',
        value: data?.estateProperty?.layout,
      },
      {
        id: 11,
        title: 'その他建物に関する物件情報',
        value: data?.estateProperty?.buildOther,
      },
    ]);
  }, [data]);

  return (
    <section data-testid="basic-info">
      <section className="mb-10">
        <ArticleDefaultTitle title={'基本情報'} />
        <div className="common-article-default-body rounded px-6 py-8 mb-8">
          <BasicInfoTable
            title={'オークションスケジュール'}
            data={scheduleData}
          />
          <BasicInfoTable title={'代金納付方法'} data={paymentMethodData} />
          <BasicInfoTable title={'基本情報'} data={basicData} />
          {data?.propertyType === CAR ? (
            <CarOptionInfo carOptionInfo={carOptionInfo} />
          ) : null}
          {data?.propertyType === CAR ? <CarInfo carInfo={carInfo} /> : null}
          {data?.propertyType === ESTATE ? (
            <EstateInfo
              estateInfo={estateInfo}
              estateBasicInfo={estateBasicInfo}
              estateLandInfo={estateLandInfo}
              estateBuildInfo={estateBuildInfo}
            />
          ) : null}
        </div>
      </section>
    </section>
  );
};
export default BasicInfo;
