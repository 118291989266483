// ロジックを含む処理
// フロントから呼ぶ関数

import {
  createNaviBankTransferPagesData,
  updateNaviBankTransferPagesData,
  deleteNaviBankTransferPagesData
} from "../../../infrastructure/api/NaviBankTransferPages.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { SettingBankPageCreate } from "../entities/Setting.js";
import { getBankTransferPages } from "../repositories/BankTransferPages.js";
import { bodyToSnake, getValues } from 'shared/utils/helper/convertData.js';

export async function get(type) {
  return await getBankTransferPages(type)
}

export async function findById(type, id) {
  const BankTransferPages = await getBankTransferPages(type);
  return BankTransferPages.filter(data => data.governmentId.toString() === id.toString())
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (type === NAVI) {
    const bankTransferPages = new SettingBankPageCreate(data)
    response = await createNaviBankTransferPagesData(bodyToSnake(getValues(bankTransferPages)));
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create BankTransferPages', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (type === NAVI) {
    const bankTransferPages = new SettingBankPageCreate(data)
    response = await updateNaviBankTransferPagesData(bodyToSnake(getValues(bankTransferPages)));
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update BankTransferPages', response);
  }
  return response
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (type === NAVI) {
    response = await deleteNaviBankTransferPagesData(data.id);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (BankTransferPages)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete BankTransferPages', response);
  }
  return response
}

// function createBankTransferPagesArray(data) {
//   let settings = []
//   data.forEach((setting) => {
//     settings.push(new BankTransferPages(setting))
//   })
//   return settings
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
