import { ReactComponent as RadioBlank } from 'interfaces/assets/svg/radio-button-blank.svg';
import { ReactComponent as RadioMarked } from 'interfaces/assets/svg/radio-button-marked.svg';
import PropertyInfoFormCautionDescription from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription';
import PropertyInfoFormLabel from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormLabel';
import FormTelWithCaution from 'interfaces/components/Form/navi/FormTelWithCaution';
import FormTextWithCaution from 'interfaces/components/Form/navi/FormTextWithCaution';
import {
  caution_description_char_30_and_kana,
  caution_description_char_30,
  caution_description_half_number,
  navi_user_register_description_email_example,
  navi_user_register_description_access_personal_info,
  navi_user_register_description_email_receive,
} from 'shared/utils/helper/DummyEditFormData';

import Button from '../../common/Button/Button.jsx';
import 'interfaces/css/common/property-info-general-form.css';
import 'interfaces/css/common/text.css';
import 'interfaces/css/User/user-register-form.css';
import ClearButton from '../../common/Button/ClearButton.jsx';

const NaviUserRegisterForm = ({
  inputs,
  toConfirm,
  toIndex,
  loginUserType,
  enableToDelete,
  isSameUser,
  isValid,
  isEnableToAccessPersonalInfo,
  isDetail,
  destroy,
}) => {
  console.log(loginUserType);
  const radioIcon = (selected, disabled) => {
    if (selected) {
      if (disabled) {
        return (
          <i className="radio-button-marked radio-icon-padding radio-button-estate">
            <RadioMarked />
          </i>
        );
      } else {
        return (
          <i className="radio-button-marked radio-icon-padding">
            <RadioMarked />
          </i>
        );
      }
    } else {
      if (disabled) {
        return (
          <i className="radio-button-estate radio-icon-padding">
            <RadioBlank />
          </i>
        );
      }
      return (
        <i className="radio-button-blank radio-icon-padding">
          <RadioBlank />
        </i>
      );
    }
  };

  const isDisableWantReceiveEmailRECEIVE = () => {
    if (isSameUser) {
      return true;
    }

    if (loginUserType === 'MANAGER') {
      if (inputs.data.userType === 'PARENT') {
        return false;
      }
    }

    if (inputs.data.userType === 'CHILD') {
      if (inputs.data?.email) {
        if (inputs.validationMessage.contactEmailValid == 'TRUE') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const isDisableWantReceiveEmailNORECEIVE = () => {
    if (isSameUser) {
      return true;
    }

    return loginUserType == 'MANAGER' && inputs.data.userType == 'PARENT';
  };

  const isDisableUserTypeMANAGER = () => {
    return isSameUser;
  };

  const isDisableUserTypePARENT = () => {
    return isSameUser;
  };

  const isDisableUserTypeCHILD = () => {
    return isSameUser;
  };

  const isDisableAccessPersonalInfoAUTHORIZED = () => {
    if (isSameUser) {
      return true;
    }

    return loginUserType === 'PARENT' && !isEnableToAccessPersonalInfo;
  };

  function isDisableAccessPersonalInfoNOAUTHORIZED() {
    return isSameUser;
  }

  return (
    <form
      className="new-style_navi_navi-user-register-form"
      data-testid="navi-user-register-form">
      <div className="pt-9 property-info-general-form-table">
        <FormTextWithCaution
          label={'担当者名（姓）'}
          maxCharacters={30}
          caution_description={caution_description_char_30}
          required={true}
          handler1={(e) => inputs.setters?.handleChangeFamilyName(e)}
          data={inputs.data?.familyName}
          disabled={false}
          invalidMessage={inputs.validationMessage.familyNameRequiredValid}
        />
        <FormTextWithCaution
          label={'担当者名（名）'}
          maxCharacters={30}
          caution_description={caution_description_char_30}
          required={true}
          handler1={(e) => inputs.setters?.handleChangeFirstName(e)}
          data={inputs.data?.firstName}
          disabled={false}
          invalidMessage={inputs.validationMessage.firstNameRequiredValid}
        />
        <FormTextWithCaution
          label={'担当者名（姓カナ）'}
          maxCharacters={30}
          caution_description={caution_description_char_30_and_kana}
          required={true}
          handler1={(e) => inputs.setters?.handleChangeFamilyNameKana(e)}
          data={inputs.data?.familyNameKana}
          disabled={false}
          invalidMessage={inputs.validationMessage.familyNameKanaRequiredValid}
        />
        <FormTextWithCaution
          label={'担当者名（名カナ）'}
          maxCharacters={30}
          caution_description={caution_description_char_30_and_kana}
          required={true}
          handler1={(e) => inputs.setters?.handleChangeFirstNameKana(e)}
          data={inputs.data?.firstNameKana}
          disabled={false}
          invalidMessage={inputs.validationMessage.firstNameKanaRequiredValid}
        />
        <FormTextWithCaution
          label={'部署名'}
          maxCharacters={30}
          caution_description={caution_description_char_30}
          required={true}
          handler1={(e) => inputs.setters?.handleChangeDepartment(e)}
          data={inputs.data?.department}
          disabled={false}
          invalidMessage={inputs.validationMessage.departmentValid}
        />
        <FormTextWithCaution
          label={'役職名'}
          maxCharacters={30}
          caution_description={caution_description_char_30}
          required={false}
          handler1={(e) => inputs.setters?.handleChangeOfficePosition(e)}
          data={inputs.data?.officePosition}
          disabled={false}
          invalidMessage={inputs.validationMessage.positionValid}
        />
        <FormTelWithCaution
          label={'電話番号'}
          telData={inputs.data?.tel}
          setTelData={(tel) => inputs.setters?.handleChangeTel(tel)}
          setTel1Data={(tel) => inputs.setters?.handleChangeTelNum1(tel)}
          setTel2Data={(tel) => inputs.setters?.handleChangeTelNum2(tel)}
          setTel3Data={(tel) => inputs.setters?.handleChangeTelNum3(tel)}
          hasExtension={true}
          extensionData={inputs.data?.extension}
          setExtensionData={(e) => inputs.setters?.handleChangeExtension(e)}
          required={true}
          caution_description={caution_description_half_number}
          disabled={false}
          invalidMessage={inputs.validationMessage.telValid}
          invalid1Message={inputs.validationMessage.tel1Valid}
          invalid2Message={inputs.validationMessage.tel2Valid}
          invalid3Message={inputs.validationMessage.tel3Valid}
          extensionMessage={inputs.validationMessage.extensionValid}
        />
        <div
          data-testid="organization-register-form-radio"
          className="property-info-general-form-table-row">
          <PropertyInfoFormLabel required={true} label={'権限'} />
          <div className="property-info-general-form-radio-table-button">
            <div className={'flex'}>
              {loginUserType == 'MANAGER' && isDetail && (
                <div data-testid="radio-button" key={'credit_true'}>
                  <div className={'mr-2 d-inline-flex'}>
                    <label className="radio-flex">
                      {radioIcon(
                        inputs.data.userType === 'MANAGER',
                        isDisableUserTypeMANAGER(),
                      )}
                      <input
                        type={'radio'}
                        name={'user_type'}
                        value={'MANAGER'}
                        className="radio-hidden"
                        checked={inputs.data.userType === 'MANAGER'}
                        onClick={(e) => inputs.setters.handleChangeUserType(e)}
                        disabled={isDisableUserTypeMANAGER()}
                      />
                      <p
                        className={
                          isDisableUserTypeMANAGER() ? 'text-disabled' : ''
                        }>
                        管理者
                      </p>
                    </label>
                  </div>
                </div>
              )}
              {(loginUserType == 'MANAGER' || isSameUser) && (
                <div data-testid="radio-button" key={'credit_true'}>
                  <div className={'mr-2 d-inline-flex'}>
                    <label className="radio-flex">
                      {radioIcon(
                        inputs.data.userType === 'PARENT',
                        isDisableUserTypePARENT(),
                      )}
                      <input
                        type={'radio'}
                        name={'user_type'}
                        value={'PARENT'}
                        className="radio-hidden"
                        checked={inputs.data.userType === 'PARENT'}
                        onClick={(e) => {
                          inputs.setters.handleChangeUserType(e);
                          inputs.setters.handleChangeEmail(
                            { target: { value: inputs.data.email } },
                            true,
                          );
                          if (loginUserType == 'MANAGER') {
                            inputs.setters.handleChangeWantReceiveEmail({
                              target: { value: 'RECEIVE' },
                            });
                          }
                        }}
                        disabled={isDisableUserTypePARENT()}
                      />
                      <p
                        className={
                          isDisableUserTypePARENT() ? 'text-disabled' : ''
                        }>
                        親
                      </p>
                    </label>
                  </div>
                </div>
              )}
              <div data-testid="radio-button" key={'credit_false'}>
                <div className={'mr-2 d-inline-flex'}>
                  <label className="radio-flex">
                    {radioIcon(
                      inputs.data.userType === 'CHILD',
                      isDisableUserTypeCHILD(),
                    )}
                    <input
                      type={'radio'}
                      name={'user_type'}
                      value={'CHILD'}
                      className="radio-hidden"
                      checked={inputs.data.userType === 'CHILD'}
                      onClick={(e) => {
                        inputs.setters.handleChangeUserType(e);
                        inputs.setters.handleChangeEmail(
                          { target: { value: inputs.data.email } },
                          false,
                        );
                        if (loginUserType == 'MANAGER') {
                          inputs.setters.handleChangeWantReceiveEmail({
                            target: { value: 'NO_RECEIVE' },
                          });
                        }
                      }}
                      disabled={isDisableUserTypeCHILD()}
                    />
                    <p
                      className={
                        isDisableUserTypeCHILD() ? 'text-disabled' : ''
                      }>
                      子
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormTextWithCaution
          label={'メールアドレス'}
          caution_description={navi_user_register_description_email_example}
          required={inputs.data.userType === 'PARENT'}
          handler1={(e) => {
            inputs.setters?.handleChangeEmail(
              e,
              inputs.data.userType === 'PARENT',
            );
            if (inputs.data.userType === 'CHILD' && e.target.value == '') {
              inputs.setters.handleChangeWantReceiveEmail({
                target: { value: 'NO_RECEIVE' },
              });
            }
          }}
          data={inputs.data?.email}
          disabled={false}
          invalidMessage={inputs.validationMessage.contactEmailValid}
        />
        <div
          data-testid="organization-register-form-radio"
          className="property-info-general-form-table-row">
          <PropertyInfoFormLabel required={true} label={'個人情報閲覧権限'} />
          <div className="property-info-general-form-radio-table-button">
            <div className={'flex'}>
              <div data-testid="radio-button" key={'credit_true'}>
                <div className={'mr-2 d-inline-flex'}>
                  <label className="radio-flex">
                    {radioIcon(
                      inputs.data.accessPersonalInfo === 'AUTHORIZED',
                      isDisableAccessPersonalInfoAUTHORIZED(),
                    )}
                    <input
                      type={'radio'}
                      name={'access_personal_info'}
                      value={'AUTHORIZED'}
                      className="radio-hidden"
                      checked={inputs.data.accessPersonalInfo === 'AUTHORIZED'}
                      onClick={(e) =>
                        inputs.setters.handleChangeAccessPersonalInfo(e)
                      }
                      disabled={isDisableAccessPersonalInfoAUTHORIZED()}
                    />
                    <p
                      className={
                        isDisableAccessPersonalInfoAUTHORIZED()
                          ? 'text-disabled radio-label'
                          : 'radio-label'
                      }>
                      あり
                    </p>
                  </label>
                </div>
              </div>
              <div data-testid="radio-button" key={'credit_false'}>
                <div className={'mr-2 d-inline-flex'}>
                  <label className="radio-flex">
                    {radioIcon(
                      inputs.data.accessPersonalInfo === 'NO_AUTHORIZED',
                      isDisableAccessPersonalInfoNOAUTHORIZED(),
                    )}
                    <input
                      type={'radio'}
                      name={'access_personal_info'}
                      value={'NO_AUTHORIZED'}
                      className="radio-hidden"
                      checked={
                        inputs.data.accessPersonalInfo === 'NO_AUTHORIZED'
                      }
                      onClick={(e) =>
                        inputs.setters.handleChangeAccessPersonalInfo(e)
                      }
                      disabled={isDisableAccessPersonalInfoNOAUTHORIZED()}
                    />
                    <p
                      className={
                        isDisableAccessPersonalInfoNOAUTHORIZED()
                          ? 'text-disabled radio-label'
                          : 'radio-label'
                      }>
                      なし
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <PropertyInfoFormCautionDescription
              data={navi_user_register_description_access_personal_info}
            />
          </div>
        </div>
        <div
          data-testid="organization-register-form-radio"
          className="property-info-general-form-table-row">
          <PropertyInfoFormLabel
            required={true}
            label={'運営からのメールの受信'}
          />
          <div className="property-info-general-form-radio-table-button">
            <div className={'flex'}>
              <div data-testid="radio-button" key={'credit_true'}>
                <div className={'mr-2 d-inline-flex'}>
                  <label className="radio-flex">
                    {radioIcon(
                      inputs.data.wantReceiveEmail === 'RECEIVE',
                      isDisableWantReceiveEmailRECEIVE(),
                    )}
                    <input
                      type={'radio'}
                      name={'want_receive_email'}
                      value={'RECEIVE'}
                      className="radio-hidden"
                      onClick={(e) =>
                        inputs.setters.handleChangeWantReceiveEmail(e)
                      }
                      checked={inputs.data.wantReceiveEmail === 'RECEIVE'}
                      disabled={isDisableWantReceiveEmailRECEIVE()}
                    />
                    <p
                      className={
                        isDisableWantReceiveEmailRECEIVE()
                          ? 'text-disabled'
                          : ''
                      }>
                      受信する
                    </p>
                  </label>
                </div>
              </div>
              <div data-testid="radio-button" key={'credit_false'}>
                <div className={'mr-2 d-inline-flex'}>
                  <label className="radio-flex">
                    {radioIcon(
                      inputs.data.wantReceiveEmail === 'NO_RECEIVE',
                      isDisableWantReceiveEmailNORECEIVE(),
                    )}
                    <input
                      type={'radio'}
                      name={'want_receive_email'}
                      value={'NO_RECEIVE'}
                      className="radio-hidden"
                      onClick={(e) =>
                        inputs.setters.handleChangeWantReceiveEmail(e)
                      }
                      checked={inputs.data.wantReceiveEmail !== 'RECEIVE'}
                      disabled={isDisableWantReceiveEmailNORECEIVE()}
                    />
                    <p
                      className={
                        isDisableWantReceiveEmailNORECEIVE()
                          ? 'text-disabled'
                          : ''
                      }>
                      受信しない
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <PropertyInfoFormCautionDescription
              data={navi_user_register_description_email_receive}
            />
          </div>
        </div>
      </div>

      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'確認する'}
              isOutline={false}
              height={'44px'}
              className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
              onClick={toConfirm}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              onClick={toIndex}
            />
          </div>
          {enableToDelete && (
            <div className="pr-1 pl-1">
              <Button
                text={'削除'}
                isOutline={false}
                height={'44px'}
                isDelete={true}
                onClick={destroy}
              />
            </div>
          )}
          {!isDetail && (
            <div className="pr-1 pl-1">
              <ClearButton
                inputs={inputs}
                className={
                  'property-info-general-form-button new-style_navi_normal-button'
                }
              />
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
export default NaviUserRegisterForm;
