import axios from 'axios';
import { API_BASE_URL } from '../../config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

export const fetchGovernmentsData = async () => {
  return await axios.get(`${API_BASE_URL}/api/v1/management/governments`);
};

export const createGovernmentsData = async (body) => {
  console.log(body);
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/kmanager/governments`,
    body,
  );
};
export const updateGovernmentsData = async (body, id) => {
  console.log(body);
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(
    `${API_BASE_URL}/api/v1/kmanager/governments/${id}`,
    body,
    { headers: headers },
  );
};
export const deleteGovernmentsData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/kmanager/governments/${id}`,
    {
      headers: headers,
    },
  );
};
