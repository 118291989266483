
export class CharacterName {
  #characterName

  constructor(characterName) {
    this.#characterName = characterName;
  }

  get() {
    return this.#characterName
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
