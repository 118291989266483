import Button from 'interfaces/components/common/Button';
import {
  checkbox_label,
  checkbox_label_recommend,
} from 'interfaces/components/common/CheckboxLabel';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormImage from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormImage';
import PropertyInfoGeneralFormInputDate from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputDate';
import {
  car_radio_label1,
  car_radio_label3,
  car_radio_label4,
  car_radio_label5,
  procedure_asset,
  procedure_pub,
  radio_label,
  radio_label2,
  radio_label3,
  radio_label4,
} from 'interfaces/components/common/RadioLabel';
import {
  property_info_form_asset,
  property_info_form_pub,
} from 'interfaces/components/common/TableLabels';
import FormNumberWithCheckBox from 'interfaces/components/Property/navi/Car/FormNumberWithCheckBox';
import FormStorageLocation from 'interfaces/components/Property/navi/Car/FormStorageLocation';
import { useContext } from 'react';
import {
  address_caution_description,
  caution_description1,
  caution_description10,
  caution_description11,
  caution_description12,
  caution_description13,
  caution_description_contact_accept,
  caution_description_char_50,
  caution_description15,
  //caution_description16,
  caution_description17,
  caution_description18,
  caution_description19,
  caution_description23,
  caution_description2,
  caution_description20,
  car_caution_description1,
  caution_description3,
  caution_description4,
  caution_description5,
  caution_description6,
  caution_description7,
  caution_description8,
  caution_description9,
  url_caution_description,
  caution_description_char_30,
  car_caution_description3,
  car_caution_description4,
  caution_description_half_number,
  caution_description_half_char,
  car_caution_description7,
  car_caution_description8,
  caution_description_char_100,
  caution_description_char_500,
  resolution_date,
  caution_category,
} from 'shared/utils/helper/DummyEditFormData';

import {
  car_manufactures,
  car_purpose,
} from '../../../../../shared/utils/helper/Pulldown';
import { PropertyInfoCarContext } from '../../../../pages/PropertyInfoCar/entities/PropertyInfoCarContext';
import FormCategory from '../../../Form/navi/FormCategory';
import FormCheckboxWithCaution from '../../../Form/navi/FormCheckboxWithCaution';
import FormMarkDownWithCaution from '../../../Form/navi/FormMarkDownWithCaution';
import FormNumberWithCaution from '../../../Form/navi/FormNumberWithCaution';
import FormPullDownWithCaution from '../../../Form/navi/FormPullDownWithCaution';
import FormRadioWithCaution from '../../../Form/navi/FormRadioWithCaution';
import FormTelWithCaution from '../../../Form/navi/FormTelWithCaution';
import FormTextAreaWithCaution from '../../../Form/navi/FormTextAreaWithCaution';
import FormTextWithCaution from '../../../Form/navi/FormTextWithCaution';
import GeneralStateInputCheck from '../../../NewEntry/GeneralStateInputCheck';
import FormDatePullDown from '../../navi/Car/FormDatePullDown';
import FormPullDownAndOtherTextWithCaution from '../../navi/Car/FormPullDownAndOtherTextWithCaution';
import FormRegNumber from '../../navi/Car/FormRegNumber';
import FormSafetyTerm from '../../navi/Car/FormSafetyTerm';
import FormVehicleIdentificationNumber from '../../navi/Car/FormVehicleIdentificationNumber';
import FormDateTimeWithCaution from '../../../Form/navi/FormDateTimeWithCaution';
import { useNavigate } from 'react-router-dom';
import { ProcedureTypes } from 'shared/utils/constants/government';

const PropertyInfoCarForm = ({
  formData,
  procedureType,
  handleClick,
  isValid,
}) => {
  const navigate = useNavigate();
  const labels =
    procedureType === ProcedureTypes.PUBLIC_SALE.string
      ? property_info_form_pub
      : property_info_form_asset;
  const radio_labels =
    procedureType === ProcedureTypes.PUBLIC_SALE.string
      ? procedure_pub
      : procedure_asset;

  const { divisions, selectedCategory } = useContext(PropertyInfoCarContext);

  const handlePaymentMethod = (value) => {
    formData.setters.handleChangePaymentMethod({
      ...formData.data.paymentMethod,
      ...value,
    });
  };

  const handleClear = (e) => {
    e.preventDefault();
    Object.values(formData.setters).forEach((setter) => {
      if (setter.name === 'handleChangeDeposit') {
        setter({ target: { value: 0 } }, 'BOTH');
      } else if (setter.name === 'handleChangeDescription') {
        setter('');
      } else {
        setter({ target: { value: '' } });
      }
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/navi/');
  };

  return (
    <form>
      <div className="property-info-general-form-table">
        <FormRadioWithCaution
          caution_description={caution_description1}
          label={'公開設定'}
          radio_label={radio_label}
          data={formData.data.isDraft}
        />
      </div>

      <div className="pt-9">
        <Heading3 text="1. 画像の選択" />
        <PropertyInfoGeneralFormImage label={'画像データ'} />
      </div>

      <div className="pt-9">
        <Heading3 text="2. 物件情報" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            label={'出品形式'}
            radio_label={radio_label2}
            procedureType={procedureType}
            data={formData.data.publicSaleType}
            exhibitData={formData.data.isNextBuyApl}
            handler1={formData.setters.handleChangePublicSaleType}
            handler2={formData.setters.handleChangeIsNextBuyApl}
          />
          <FormNumberWithCaution
            caution_description={caution_description2}
            handler1={formData.setters.handleChangePrintOrder}
            invalidMessage={formData.validationMessage.printOrderValid}
            label={'掲載順位'}
            min={1}
            state={formData.data.printOrder}
            width={'6em'}
          />
          <FormCategory
            data={selectedCategory}
            label={'カテゴリ'}
            handler={formData.setters.handleChangeCategoryId}
            propertyType={'CAR'}
            caution_description={caution_category}
            invalidMessage={formData.validationMessage.categoryIdValid}
          />
          <GeneralStateInputCheck
            checkbox_label={checkbox_label_recommend}
            caution_description={caution_description3}
            label={'イチ押し設定'}
            data={formData.data.recommendProperties}
            stateHandler={formData.setters.handleChangeRecommendProperties}
          />
          <FormPullDownWithCaution
            caution_description={caution_description4}
            formStateHandler={formData.setters.handleChangeDivisionId}
            label={'執行機関名称'}
            listItemData={divisions}
            selectedData={
              formData.data.divisionId === '' ||
              formData.data.divisionId === undefined
                ? divisions?.length === 0
                  ? ''
                  : divisions[0]
                : divisions.find(
                    (data) =>
                      formData.data.divisionId.toString() ===
                      data.id.toString(),
                  )
            }
          />
          <FormTextWithCaution
            caution_description={caution_description5}
            data={formData.data.saleNumber}
            handler1={formData.setters.handleChangeSaleNumber}
            invalidMessage={formData.validationMessage.saleNumberRequiredValid}
            label={'売却区分番号'}
            maxCharacters={30}
            required={true}
            width={'80'}
          />
          <FormTextWithCaution
            caution_description={caution_description6}
            data={formData.data.name}
            handler1={formData.setters.handleChangeName}
            invalidMessage={formData.validationMessage.titleRequiredValid}
            label={'タイトル'}
            maxCharacters={50}
            required={true}
            //validationType={['ZenKatakanaOnly']}
          />
          <FormCheckboxWithCaution
            caution_description={caution_description7}
            checkbox_label={checkbox_label}
            class_name={'payment-method'}
            data={formData.data.paymentMethod}
            label={'支払い方法'}
            required={true}
            handler={handlePaymentMethod}
          />
          {procedureType === ProcedureTypes.PUBLIC_SALE.string ? (
            <FormDateTimeWithCaution
              caution_description={caution_description19}
              date={formData.data.paymentLimit}
              handleChange={formData.setters.handleChangePaymentLimit}
              label={'買受代金納付期限'}
              required={true}
              invalidMessage={formData.validationMessage.paymentLimitValid}
            />
          ) : (
            <>
              <FormTextWithCaution
                caution_description={resolution_date}
                data={formData.data.resolutionDate}
                handler1={formData.setters.handleChangeResolutionDate}
                invalidMessage={formData.validationMessage.resolutionDateValid}
                label={'議決日'}
                maxCharacters={30}
              />
              <PropertyInfoGeneralFormInputDate
                label={'売払代金納付期限'}
                handleChange={formData.setters.handleChangePaymentLimit}
                caution_description={caution_description23}
                date={formData?.data?.paymentLimit}
                required={true}
              />
              <PropertyInfoGeneralFormInputDate
                label={'契約締結期限'}
                handleChange={formData.setters.handleChangeContractedLimit}
                date={formData?.data?.contractedLimit}
              />
            </>
          )}
          <FormTextWithCaution
            caution_description={caution_description8}
            data={formData.data.videoUrl}
            handler1={formData.setters.handleChangeVideoUrl}
            invalidMessage={formData.validationMessage.videoUrlValid}
            label={'動画URL'}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="3. 登録事項など証明書の記載内容" />
        <div>
          不明な項目については、右の「不明」のチェックボックスにチェックを入れてください。
        </div>
        <div>
          入力をした場合でもチェックを入れた場合はそちらが優先されます。
        </div>
        <div className="property-info-general-form-table">
          <FormDatePullDown
            caution_description={car_caution_description1}
            data={formData.data.effectiveDate}
            label={'証明書発行日'}
            invalidMessage={formData.validationMessage.effectiveDateValid}
            handler={formData.setters.handleChangeEffectiveDate}
            required={true}
          />
          <FormRegNumber
            displayRegNumberData={formData.data.displayRegisterNumber}
            invalidMessage={formData.validationMessage.registerNumberValid}
            label={'自動車登録番号'}
            regNumberData={formData.data.registerNumber}
            handlerDisplayRegNumberData={
              formData.setters.handleChangeDisplayRegisterNumber
            }
            handlerRegNumberData={formData.setters.handleChangeRegisterNumber}
            handlerRegNumberFirst={
              formData.setters.handleChangeRegisterNumberFirst
            }
            handlerRegNumberLatter={
              formData.setters.handleChangeRegisterNumberLatter
            }
          />
          <FormDatePullDown
            data={formData.data.registerDate}
            label={'登録年月日'}
            invalidMessage={formData.validationMessage.registerDateValid}
            handler={formData.setters.handleChangeRegisterDate}
          />
          <FormDatePullDown
            data={formData.data.firstRegisterDate}
            handler={formData.setters.handleChangeFirstRegisterDate}
            invalidMessage={formData.validationMessage.firstRegisterDateValid}
            label={'初度登録年月'}
            noDay={true}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_char_30}
            data={formData.data.type}
            handler1={formData.setters.handleChangeType}
            handler2={formData.setters.handleChangeTypeReset}
            invalidMessage={formData.validationMessage.carTypeValid}
            label={'自動車の種別'}
            maxCharacters={30}
            width={70}
          />
          <FormPullDownAndOtherTextWithCaution
            label={'用途'}
            handler1={formData.setters.handleChangePurpose}
            invalidMessage={formData.validationMessage.purposeValid}
            listItemData={car_purpose}
            otherData={formData.data.purposeOther}
            otherHandler={formData.setters.handleChangePurposeOther}
            otherInvalidMessage={formData.validationMessage.purposeOtherValid}
            otherWidth={70}
            selectedData={formData.data.purpose}
            width={'20%'}
          />
          <FormRadioWithCaution
            label={'自家用、事業用の別'}
            data={formData.data.privateOrBusiness}
            handler1={formData.setters.handleChangePrivateOrBusiness}
            radio_label={car_radio_label1}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_char_30}
            data={formData.data.shape}
            handler1={formData.setters.handleChangeShape}
            handler2={formData.setters.handleChangeShapeReset}
            invalidMessage={formData.validationMessage.shapeValid}
            label={'車体の形状'}
            maxCharacters={30}
            width={70}
          />

          <FormPullDownAndOtherTextWithCaution
            label={'車名（メーカー）'}
            caution_description={car_caution_description3}
            handler1={formData.setters.handleChangeManufacture}
            invalidMessage={formData.validationMessage.manufactureValid}
            listItemData={car_manufactures}
            otherData={formData.data.manufactureOther}
            otherHandler={formData.setters.handleChangeManufactureOther}
            otherInvalidMessage={
              formData.validationMessage.manufactureOtherValid
            }
            otherWidth={100}
            selectedData={formData.data.manufacture}
            width={'240px'}
          />

          <FormNumberWithCheckBox
            caution_description={car_caution_description4}
            data={formData.data.model}
            handler1={formData.setters.handleChangeModel}
            handler2={formData.setters.handleChangeModelReset}
            invalidMessage={formData.validationMessage.modelValid}
            label={'型式'}
            maxCharacters={30}
            width={70}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.seatingCapacity}
            handler1={formData.setters.handleChangeSeatingCapacity}
            handler2={formData.setters.handleChangeSeatingCapacityReset}
            invalidMessage={formData.validationMessage.seatingCapacityValid}
            label={'乗車定員'}
            unit={'人'}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.weight}
            handler1={formData.setters.handleChangeWeight}
            handler2={formData.setters.handleChangeWeightReset}
            invalidMessage={formData.validationMessage.weightValid}
            label={'車両重量'}
            unit={'kg'}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.totalWeight}
            handler1={formData.setters.handleChangeTotalWeight}
            handler2={formData.setters.handleChangeTotalWeightReset}
            invalidMessage={formData.validationMessage.totalWeightValid}
            label={'車両総重量'}
            unit={'kg'}
          />
          <FormVehicleIdentificationNumber
            caution_description={caution_description_half_char}
            displayProductNumber={formData.data.displayProductNumber}
            invalidMessage={formData.validationMessage.productNumberValid}
            label={'車台番号'}
            productNumber={formData.data.productNumber}
            handlerDisplayProductNumber={
              formData.setters.handleChangeDisplayProductNumber
            }
            handlerProductNumberFirst={
              formData.setters.handleChangeProductNumberFirst
            }
            handlerProductNumberLatter={
              formData.setters.handleChangeProductNumberLatter
            }
            handlerProductNumber={formData.setters.handleChangeProductNumber}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_char}
            data={formData.data.engineModel}
            handler1={formData.setters.handleChangeEngineModel}
            handler2={formData.setters.handleChangeEngineModelReset}
            invalidMessage={formData.validationMessage.engineModelValid}
            label={'原動機の型式'}
            maxCharacters={30}
            width={70}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.length}
            handler1={formData.setters.handleChangeLength}
            handler2={formData.setters.handleChangeLengthReset}
            invalidMessage={formData.validationMessage.lengthValid}
            label={'長さ'}
            unit={'cm'}
            width={20}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.width}
            handler1={formData.setters.handleChangeWidth}
            handler2={formData.setters.handleChangeWidthReset}
            invalidMessage={formData.validationMessage.widthValid}
            label={'幅'}
            unit={'cm'}
            width={20}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.height}
            handler1={formData.setters.handleChangeHeight}
            handler2={formData.setters.handleChangeHeightReset}
            invalidMessage={formData.validationMessage.heightValid}
            label={'高さ'}
            unit={'cm'}
            width={20}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.displacement}
            handler1={formData.setters.handleChangeDisplacement}
            handler2={formData.setters.handleChangeDisplacementReset}
            invalidMessage={formData.validationMessage.displacementValid}
            label={'総排気量'}
            unit={'リットル'}
            width={20}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_char_30}
            data={formData.data.fuelType}
            handler1={formData.setters.handleChangeFuelType}
            handler2={formData.setters.handleChangeFuelTypeReset}
            invalidMessage={formData.validationMessage.fuelTypeValid}
            label={'燃料の種類'}
            maxCharacters={30}
            width={70}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.frontAxelWeight}
            handler1={formData.setters.handleChangeFrontAxelWeight}
            handler2={formData.setters.handleChangeFrontAxelWeightReset}
            invalidMessage={formData.validationMessage.frontAxelWeightValid}
            label={'前前軸重'}
            unit={'kg'}
            width={20}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_half_number}
            data={formData.data.rearAxelWeight}
            handler1={formData.setters.handleChangeRearAxelWeight}
            handler2={formData.setters.handleChangeRearAxelWeightReset}
            invalidMessage={formData.validationMessage.rearAxelWeightValid}
            label={'後後軸重'}
            unit={'kg'}
            width={20}
          />
          <FormSafetyTerm
            label={'自動車検査証有効期限'}
            safetyTerm={formData.data.safetyTerm}
            setSafetyTerm={formData.setters.handleChangeSafetyTerm}
            setStatusSafetyTerm={formData.setters.handleChangeStatusSafetyTerm}
            statusSafetyData={formData.data.statusSafetyTerm}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_char_30}
            data={formData.data.formalNumber}
            handler1={formData.setters.handleChangeFormalNumber}
            handler2={formData.setters.handleChangeFormalNumberReset}
            invalidMessage={formData.validationMessage.formalNumberValid}
            label={'型式指定番号'}
            maxCharacters={30}
            width={70}
          />
          <FormNumberWithCheckBox
            caution_description={caution_description_char_30}
            data={formData.data.classificationNumber}
            label={'類型区分番号'}
            handler1={formData.setters.handleChangeClassificationNumber}
            handler2={formData.setters.handleChangeClassificationNumberReset}
            invalidMessage={
              formData.validationMessage.classificationNumberValid
            }
            maxCharacters={30}
            width={70}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="4. 基本情報" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            data={formData.data.transmissionType}
            handler1={formData.setters.handleChangeTransmissionType}
            label={'ミッション'}
            radio_label={car_radio_label3}
          />
          <FormRadioWithCaution
            data={formData.data.handleType}
            handler1={formData.setters.handleChangeHandleType}
            label={'ハンドル'}
            radio_label={car_radio_label4}
          />
          <FormRadioWithCaution
            data={formData.data.existsCarMaintenanceSheet}
            handler1={formData.setters.handleChangeExistsCarMaintenanceSheet}
            label={'定期点検記録簿'}
            radio_label={car_radio_label5}
          />
          <FormTextWithCaution
            caution_description={car_caution_description7}
            label={'車体の色'}
            data={formData.data.carBodyColor}
            handler1={formData.setters.handleChangeCarBodyColor}
            invalidMessage={formData.validationMessage.carBodyColorValid}
            maxCharacters={20}
            required={true}
            width={'80'}
          />
          <FormNumberWithCheckBox
            caution_description={car_caution_description8}
            data={formData.data.carMileage}
            label={'走行距離'}
            handler1={formData.setters.handleChangeCarMileage}
            handler2={formData.setters.handleChangeStatusCarMileage}
            invalidMessage={formData.validationMessage.carMileageValid}
            unit={'km'}
          />
          <FormStorageLocation
            address_caution_description={address_caution_description}
            formData={formData}
            label={'引渡時保管場所'}
            url_caution_description={url_caution_description}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_100}
            data={formData.data.carStatus}
            handler1={formData.setters.handleChangeCarStatus}
            invalidMessage={formData.validationMessage.carStatusValid}
            label={'車両等の状況'}
            maxCharacters={100}
            required={true}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_100}
            data={formData.data.deliveryTerms}
            handler1={formData.setters.handleChangeDeliveryTerms}
            invalidMessage={formData.validationMessage.deliveryTermsValid}
            label={'引き渡し条件'}
            maxCharacters={100}
            required={true}
          />
          <FormTextAreaWithCaution
            caution_description={caution_description_char_500}
            data={formData.data.other}
            handler1={formData.setters.handleChangeOther}
            invalidMessage={formData.validationMessage.otherValid}
            label={'その他'}
            maxCharacters={500}
          />
          <FormMarkDownWithCaution
            caution_description={caution_description20}
            data={formData.data.description}
            handler1={formData.setters.handleChangeDescription}
            invalidMessage={formData.validationMessage.descriptionValid}
            label={'説明'}
            number_remaining_characters={
              formData.data.description !== undefined
                ? 15000 - formData.data.description.length
                : 15000
            }
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="5. 物件に関するお問い合わせ先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            caution_description={caution_description9}
            data={formData.data.inquiryName}
            handler1={formData.setters.handleChangeInquiryName}
            invalidMessage={formData.validationMessage.inquiryNameValid}
            label={'出品行政機関名'}
            maxCharacters={30}
            width={'80'}
          />
          <FormTextWithCaution
            caution_description={caution_description10}
            data={formData.data.inquiryOffice}
            handler1={formData.setters.handleChangeInquiryOffice}
            invalidMessage={formData.validationMessage.inquiryOfficeValid}
            label={'担当部署名'}
            maxCharacters={50}
          />
          <FormTextWithCaution
            caution_description={caution_description11}
            data={formData.data.inquiryEmail}
            handler1={formData.setters.handleChangeInquiryEmail}
            invalidMessage={formData.validationMessage.inquiryEmailValid}
            label={'メールアドレス'}
            maxCharacters={50}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.inquiryExtension}
            extensionMessage={formData.validationMessage.inquiryExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.inquiryTelValid}
            invalid1Message={
              formData.validationMessage.auctionInquiryTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionInquiryTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionInquiryTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeInquiryExtension}
            setTelData={formData.setters.handleChangeInquiryTel}
            setTel1Data={formData.setters.handleChangeInquiryTelNum1}
            setTel2Data={formData.setters.handleChangeInquiryTelNum2}
            setTel3Data={formData.setters.handleChangeInquiryTelNum3}
            telData={formData.data.inquiryTel}
          />
          <FormTextWithCaution
            caution_description={caution_description13}
            data={formData.data.inquiryAccept}
            handler1={formData.setters.handleChangeInquiryAccept}
            invalidMessage={formData.validationMessage.inquiryAcceptValid}
            label={'電話受付時間'}
            maxCharacters={100}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.inquiryNote}
            handler1={formData.setters.handleChangeInquiryNote}
            invalidMessage={formData.validationMessage.inquiryNoteValid}
            label={'備考'}
            maxCharacters={50}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="6. 物件の詳細設定" />
        <div className="property-info-general-form-table">
          <FormRadioWithCaution
            class_name={'procedure'}
            data={formData.data.depositPayment}
            handler1={(e) => {
              formData.setters.handleChangeDepositPayment(e);
              if (e.target.value === 'NONE') {
                formData.setters.handleChangeDeposit(
                  { target: { value: 0 } },
                  'NONE',
                );
              } else {
                formData.setters.handleChangeDeposit(
                  { target: { value: 0 } },
                  e.target.value,
                );
              }
            }}
            label={'保証金納付手続き'}
            radio_label={radio_labels}
          />
          {procedureType === ProcedureTypes.PUBLIC_SALE.string &&
          (formData.data.depositPayment === 'BOTH' ||
            formData.data.depositPayment === 'CREDIT_CARD') ? (
            <FormRadioWithCaution
              caution_description={caution_description15}
              data={formData.data.isApprovalRequired}
              handler1={formData.setters.handleChangeIsApprovalRequired}
              label={'参加申し込み承認'}
              radio_label={radio_label3}
            />
          ) : (
            <></>
          )}
          <FormNumberWithCaution
            afterFormWord={'円'}
            caution_description={caution_description12}
            handler1={formData.setters.handleChangeEstimatePrice}
            invalidMessage={
              formData.validationMessage.estimatePriceRequiredValid
            }
            label={labels[0]}
            required={true}
            state={formData.data.estimatePrice}
            width={'16em'}
          />
          <FormNumberWithCaution
            afterFormWord={'円'}
            caution_description={caution_description17}
            handler1={(e) =>
              formData.setters.handleChangeDeposit(
                e,
                formData.data.depositPayment,
              )
            }
            invalidMessage={formData.validationMessage.depositRequiredValid}
            isDisabled={formData.data.depositPayment === 'NONE' ? true : false}
            label={labels[1]}
            required={true}
            state={formData.data.deposit}
            width={'16em'}
          />
          <FormRadioWithCaution
            caution_description={caution_description18}
            data={formData.data.qualifiedInvoice}
            handler1={formData.setters.handleChangeQualifiedInvoice}
            label={'適格請求書'}
            radio_label={radio_label4}
          />
        </div>
      </div>

      <div className="pt-9">
        <Heading3 text="7. 落札後の連絡先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.contactName}
            handler1={formData.setters.handleChangeContactName}
            invalidMessage={formData.validationMessage.contactNameValid}
            label={'連絡先機関名'}
            maxCharacters={50}
            required={true}
          />
          <FormTextWithCaution
            caution_description={caution_description_char_50}
            data={formData.data.contactOffice}
            handler1={formData.setters.handleChangeContactOffice}
            invalidMessage={formData.validationMessage.contactOfficeValid}
            label={'担当部署名'}
            maxCharacters={50}
          />
          <FormTextWithCaution
            caution_description={caution_description11}
            data={formData.data.contactEmail}
            handler1={formData.setters.handleChangeContactEmail}
            invalidMessage={formData.validationMessage.contactEmailValid}
            label={'メールアドレス'}
            maxCharacters={50}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.contactExtension}
            extensionMessage={formData.validationMessage.contactExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.contactTelValid}
            invalid1Message={
              formData.validationMessage.auctionContactTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionContactTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionContactTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeContactExtension}
            setTelData={formData.setters.handleChangeContactTel}
            setTel1Data={formData.setters.handleChangeContactTelNum1}
            setTel2Data={formData.setters.handleChangeContactTelNum2}
            setTel3Data={formData.setters.handleChangeContactTelNum3}
            telData={formData.data.contactTel}
          />
          <FormTextWithCaution
            caution_description={caution_description_contact_accept}
            data={formData.data.contactAccept}
            handler1={formData.setters.handleChangeContactAccept}
            invalidMessage={formData.validationMessage.contactAcceptValid}
            label={'電話受付時間'}
            maxCharacters={50}
          />
        </div>
      </div>

      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'確認する'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleClick}
              className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleCancel}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'クリア'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleClear}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default PropertyInfoCarForm;
