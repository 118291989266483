import { LocalGovernmentManagementTopContext } from 'interfaces/pages/LocalGovernmentManagementTop/entities/LocalGovernmentManagementTopContext';
import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsLocalGovernmentManagementTopForm } from 'interfaces/components/common/validateInputs/ValidationLocalGovernmentManagementTop';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import BothForm from 'interfaces/components/LocalGovernmentManagement/top/BothForm';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/top/IndividualForm';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementTop/entities/breadcrumbs';
import { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { update } from 'domain/Setting/services/GovernmentTopPage';
import { ServiceTypes } from 'shared/utils/constants/service.js';
import 'interfaces/css/LocalGovernmentManagement/local-government-management-top.css';

const TopForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state } = useLocation();
  const { governmentType, procedureType } = state || {};
  const { localStorageProcedureType } = useLocalStorage();
  const navigate = useNavigate();
  const [indivisualSetting, setIndivisualSetting] = useState(false);
  const { auctionData, bidData } = useContext(
    LocalGovernmentManagementTopContext,
  );
  const auction = validateInputsLocalGovernmentManagementTopForm(auctionData);
  const bid = validateInputsLocalGovernmentManagementTopForm(bidData);
  
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      return Object.entries(validationMessage).every(
        ([key, validationMessage]) => {
          if (
            [
              'contactEmailValid',
              'telValid',
              'extensionValid',
              'referenceAcceptValid',
              'inquiryNameHiddenValid',
              'homePageUrlHiddenValid',
              data?.displayPreview === 'DISPLAY' ? '' : 'previewUrlValid',
              data?.displayInquiry === 'DISPLAY' ? '' : 'inquiryNameValid',
              data?.displayHomePage === 'DISPLAY' ? '' : 'hpNameValid',
              data?.displayHomePage === 'DISPLAY' ? '' : 'homePageUrlValid',
            ].includes(key)
          ) {
            return validationMessage === null || validationMessage === 'TRUE';
          }
          return validationMessage === 'TRUE';
        },
      );
    };

    if (indivisualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, indivisualSetting]);
 
  const onRegisterClick = async (e) => {
    e.preventDefault();
    auction.data.publicSaleType = 'AUCTION';
    bid.data.publicSaleType = 'BID';
    auction.data.procedureType = procedureType;
    bid.data.procedureType = procedureType;
    try {
      await update(ServiceTypes.NAVI.string, auction.data);
      if (indivisualSetting) {
        await update(ServiceTypes.NAVI.string, bid.data);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  return (
    <span>
      {isOpen ? (
        <LocalGovernmentManagementModal
          handleClose={() => setIsOpen(false)}
          procedureType={procedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：行政機関トップページ'}
        content={
          '行政機関トップページに関する項目を作成します。入力が終わったら「登録」ボタンをクリックしてください。'
        }
        displayRequired={true}
      />
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />
      <div
        className="new-style_navi_local-government-management-top-form"
        data-testid="local-government-management-top-form">
        {governmentType !== 'NATIONAL_TAX_AGENCY' && (
          <>
            <Toggle
              message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
              handler={() => setIndivisualSetting(!indivisualSetting)}
            />
            <div className="sub-message">
              ※
              KSI官公庁オークションでは、行政機関トップページは「せり売形式」と「入札形式」の物件をまとめたページに統合されています。
              <br />
              閲覧者が表示する形式を切り替えた際の表示内容を個別に設定したい場合にご利用ください。
              <br />
            </div>
          </>
        )}
        {!indivisualSetting && <BothForm auctionData={auction} />}
        {indivisualSetting && (
          <IndividualForm auctionData={auction} bidData={bid} />
        )}
        <div className="pt-9">
          <div className="property-info-general-form-button-box ">
            <div className="pr-1 pl-1">
              <Button
                text={'登録'}
                isOutline={false}
                height={'44px'}
                className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
                onClick={onRegisterClick}
                isDisabled={!isValid}
              />
            </div>
            <div className="pr-1 pl-1">
              <Button
                text={'キャンセル'}
                isOutline={false}
                height={'44px'}
                className={
                  'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
                }
                onClick={backToLocalGovernmentManagement}
              />
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
export default TopForm;
