import '../../../interfaces/css/common/listbox.css';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

const ListBox = ({
  data,
  setShowListBox,
  setSelectedValue,
  formStateHandler,
  width,
}) => {
  const { ref } = useCloseClickOutside(() => setShowListBox(false));
  return (
    <div className="list-box-wrap" style={{ width: width }} ref={ref}>
      <div className="list-box" data-testid="list-box">
        {Array.isArray(data) && data.length === 0 && (
          <div
            className="list-item"
            onClick={() => {
              setShowListBox(false);
            }}
            data-testid="list-item">
            <div className="list-item-content">
              <div className="list-item-title">No data available</div>
            </div>
          </div>
        )}
        {data &&
          data.map((d) => (
            <div className="list-item" key={d.id}>
              <input
                type="button"
                id={d.id}
                value={d.value ?? d.text}
                style={{ opacity: 0, flex: 0, width: 0 }}
                onClick={(e) => {
                  setSelectedValue(d.text);
                  setShowListBox(false);
                  if (formStateHandler) {
                    formStateHandler(e);
                  }
                }}
                data-testid="list-item"
              />
              <label htmlFor={d.id} style={{ width: '100%' }}>
                <div className="list-item-content">
                  <div className="list-item-title">{d?.text ?? ''}</div>
                </div>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListBox;
