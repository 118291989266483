import HorizontalLink from './HorizontalLink.jsx';

const HorizontalLinkGroup = ({ groupTitle, items }) => {
  return (
    <div data-testid="vertical-link-group" className="vertical-link-group">
      <div data-testid="vertical-link-group-headline">
        <h4>{groupTitle}</h4>
      </div>
      <div
        data-testid="vertical-link-group-items"
        className="vertical-link-group-items">
        {items.map((item) => (
          <HorizontalLink
            key={item.id}
            link={item.link}
            label={item.label}
            target={item.target}
          />
        ))}
      </div>
    </div>
  );
};

export default HorizontalLinkGroup;
