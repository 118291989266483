import Button from 'interfaces/components/common/Button/Button.jsx';
import Toggle from 'interfaces/components/common/Toggle/Toggle.jsx';
import BothForm from 'interfaces/components/LocalGovernmentManagement/bank_transfer/BothForm.jsx';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/bank_transfer/IndividualForm.jsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { update } from 'domain/Setting/services/BankTransferPages';
import { ServiceTypes } from 'shared/utils/constants/service.js';

const BankTransferForm = ({
  auction,
  bid,
  toggle: individualSetting,
  setToggle,
  setIsOpen,
  governmentType,
  procedureType,
}) => {
  const navigate = useNavigate();

  const performUpdate = async (e) => {
    e.preventDefault();
    auction.data.publicSaleType = 'AUCTION';
    auction.data.procedureType = procedureType;
    bid.data.publicSaleType = 'BID';
    bid.data.procedureType = procedureType;
    try {
      await update(ServiceTypes.NAVI.string, auction.data);
      if (individualSetting) {
        await update(ServiceTypes.NAVI.string, bid.data);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (auction, bid, individualSetting) => {
      const auctionValidationMessages = auction.validationMessage;
      const bidValidationMessages = bid.validationMessage;

      if (individualSetting) {
        const auctionValid =
          auctionValidationMessages.bankTransferUrlRequiredValid === 'TRUE';
        const bidValid =
          bidValidationMessages.bankTransferUrlRequiredValid === 'TRUE';
        const jointBiddingValid =
          bidValidationMessages.jointBiddingUrlValid === 'TRUE' ||
          bidValidationMessages.jointBiddingUrlValid === null;

        return auctionValid && bidValid && jointBiddingValid;
      } else {
        const auctionOrJointBiddingValid =
          auctionValidationMessages.bankTransferUrlRequiredValid === 'TRUE' &&
          (auctionValidationMessages.jointBiddingUrlValid === 'TRUE' ||
            auctionValidationMessages.jointBiddingUrlValid === null);
        return auctionOrJointBiddingValid;
      }
    };

    setIsValid(validateMessages(auction, bid, individualSetting));
  }, [auction, bid, individualSetting]);

  return (
    <div
      className="new-style_navi_local-government-management-top-form"
      data-testid="bank-provisional-apply-completion-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <>
          <Toggle
            message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
            handler={() => setToggle(!individualSetting)}
          />
          <div className="sub-message">
            ※
            参加者が仮申し込みを完了した際の表示を「せり売形式」と「入札形式」の物件で個別に設定したい場合にご利用ください。
            <br />
          </div>
        </>
      )}
      {!individualSetting && (
        <BothForm input={auction} governmentType={governmentType} />
      )}
      {individualSetting && (
        <IndividualForm
          auction={auction}
          bid={bid}
          governmentType={governmentType}
        />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={performUpdate}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankTransferForm;
