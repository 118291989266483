import {
  card_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2,
} from 'shared/utils/helper/DummyEditFormData';

import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const BidForm = ({ bid, procedureType, governmentType }) => {
  return (
    <div className="pt-9">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={'必要書類提出の案内ページURL'}
          required={
            procedureType == 'ASSET_SALE' ||
            governmentType == 'NATIONAL_TAX_AGENCY'
          }
          caution_description={card_provisional_apply_completion_description1(
            procedureType,
          )}
          data={bid.data.documentSubmissionUrl}
          handler1={(e) =>
            bid.setters.handleDocumentSubmissionUrl(
              e,
              procedureType == 'ASSET_SALE' ||
                governmentType == 'NATIONAL_TAX_AGENCY',
            )
          }
          invalidMessage={
            bid.validationMessage.documentSubmissionUrlRequiredValid
          }
        />
        <FormTextWithCaution
          label={'共同入札案内ページURL'}
          caution_description={card_provisional_apply_completion_description2(
            governmentType,
          )}
          data={bid.data.jointBiddingUrl}
          handler1={bid.setters.handleJointBiddingUrl}
          invalidMessage={bid.validationMessage.jointBiddingUrlValid}
        />
      </div>
    </div>
  );
};

export default BidForm;
