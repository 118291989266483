import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import CheckBox from './CheckBox.jsx';
import { queryParams } from '../../../../../pages/Dashboard/entities/queryParam.js';

const AccordionPanel = styled.div`
  min-height: 30px;
  padding: 0;

  display: flex;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const AccordionLabel = styled.div`
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;

  & a {
    color: var(--text-base);
  }

  & .disabled {
    opacity: 0.5;
  }
`;

const ToggleButton = styled.button`
  height: 24px;
  width: 24px;
  outline: none;
  position: relative;
  display: inline-block;
  color: var(--line-darken4);
  text-decoration: none;

  display: inline-flex;
  flex-shrink: 0;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 0 4px 5px;
    border-color: transparent transparent transparent var(--line-darken4);
    position: absolute;
    left: 10px;
    margin-top: -3px;
    transition: transform 0.25s ease;
  }

  & .toggle-open:before {
    transform: rotate(90deg);
  }
`;

const AccordionWithCheckBox = ({
  id,
  categoryData,
  marginLeft,
  style,
  onClick,
  checkBoxState,
  showCount,
  showCheckBox,
  checkboxActive,
  contents,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const contentRef = useRef(null);

  // const label = showCheckBox ? categoryData?.name : <Link to={`${useSearchLink(userId, categoryData.id)}`}>{categoryData?.name}</Link>;
  const label = categoryData?.name;
  const count = categoryData?.propertyIds?.length;
  const depth = categoryData?.depth;
  const childCategoryVal = categoryData?.childCategory?.length;

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    contentRef.current.style.display = isOpen ? 'none' : 'inline-block';
  };
  const appendQueryParam = (queryParamName, queryParamValue) => {
    if (!searchParams.has(queryParamName)) {
      searchParams.append(queryParamName, queryParamValue);
    }
  };

  const getCategoryIds = (categoryData) => {
    let categoryIds = [];

    // すでにクエリパラメータが設定されているかを確認
    if (searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      const currentQueryParam = searchParams
        .get(queryParams.SEARCH_PROPERTY_CATEGORY_ID)
        .split(',');
      if (currentQueryParam[0] === '') {
        currentQueryParam.shift();
      }
      categoryIds.push(...currentQueryParam);
    }
    categoryIds.push(categoryData?.id.toString());

    // 子・孫要素のカテゴリIDをすべて取得
    categoryData?.childCategory?.forEach((childCategory) => {
      childCategory?.childCategory?.forEach((grandChildCategory) => {
        categoryIds.push(grandChildCategory.id.toString());
      });
      categoryIds.push(childCategory.id.toString());
    });

    // カテゴリID一覧の重複を排除
    categoryIds = Array.from(new Set(categoryIds));
    // 昇順にソート
    categoryIds.sort((a, b) => a - b);
    return categoryIds;
  };
  const getAfterExclusionCategoryIds = (categoryData) => {
    // すでにクエリパラメータが設定されているかを確認
    if (!searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      return;
    }

    let removeTarget = [];
    removeTarget.push(categoryData?.id.toString());
    categoryData?.childCategory?.forEach((childCategory) => {
      childCategory?.childCategory?.forEach((grandChildCategory) => {
        removeTarget.push(grandChildCategory.id.toString());
      });
      removeTarget.push(childCategory.id.toString());
    });

    return searchParams
      .get(queryParams.SEARCH_PROPERTY_CATEGORY_ID)
      .split(',')
      .filter((el) => !removeTarget.includes(el));
  };
  const toggleCheckBox = () => {
    setIsChecked(!isChecked);
    onClick && onClick();

    // チェックを外すときは外したカテゴリのID一覧を除外したものをセットする
    if (isChecked) {
      searchParams.set(
        queryParams.SEARCH_PROPERTY_CATEGORY_ID,
        getAfterExclusionCategoryIds(categoryData),
      );
      setSearchParams(searchParams);
      return;
    }

    // クリックしたチェックボックスが持つカテゴリIDとその子・孫カテゴリのID一覧を取得
    const categoryIds = getCategoryIds(categoryData);
    // カンマ区切りの文字列に変換
    let searchPropertyCategoryId = categoryIds.join(',');

    // すでにクエリパラメータがセットされていたら新しいデータをセットする
    if (searchParams.has(queryParams.SEARCH_PROPERTY_CATEGORY_ID)) {
      searchParams.set(
        queryParams.SEARCH_PROPERTY_CATEGORY_ID,
        searchPropertyCategoryId,
      );
    } else {
      searchParams.append(
        queryParams.SEARCH_PROPERTY_CATEGORY_ID,
        searchPropertyCategoryId,
      );
    }
    appendQueryParam(queryParams.SEARCH_GOVERNMENT_SUB_CATEGORY_ID, '');
    appendQueryParam(queryParams.DIRECTION, 'ASC');
    appendQueryParam(queryParams.FIELD, 'PRICE');
    appendQueryParam(queryParams.PAGE, 1);
    appendQueryParam(queryParams.PAGE_SIZE, 50);
    setSearchParams(searchParams);
  };

  const toggleAccordionButton =
    childCategoryVal > 0 ? (
      <ToggleButton onClick={toggleAccordion} data-testid={`${id}-toggle`} />
    ) : (
      ''
    );

  const checkBox = showCheckBox ? (
    <CheckBox id={id} isChecked={isChecked} onClick={toggleCheckBox} />
  ) : (
    ''
  );

  const styleWithoutButton =
    childCategoryVal > 0 || depth === '3' ? {} : { marginLeft: '24px' };

  useEffect(() => {
    checkboxActive ? setIsChecked(true) : null;
  }, [checkboxActive]);

  useEffect(() => {
    setIsChecked(checkBoxState);
  }, [checkBoxState]);

  return (
    <div id={id} style={style} data-testid={`${id}-accordion`}>
      <AccordionPanel style={styleWithoutButton}>
        {toggleAccordionButton}
        {checkBox}
        <AccordionLabel className={showCheckBox ? 'text-decoration-none' : ''}>
          <span className={count === 0 ? 'disabled' : ''}>
            {count === 0 ? (
              label
            ) : (
              <Link
                to={`/search?searchPropertyCategoryId=${categoryData.relationShipCategoryIds.join(',')}&searchGovernmentSubCategoryId=&direction=ASC&field=PRICE&page=1&pageSize=50`}>
                {label}
              </Link>
            )}
            {showCount ? `(${count})` : ''}
          </span>
        </AccordionLabel>
      </AccordionPanel>
      <div
        ref={contentRef}
        style={{ display: 'none', marginLeft: `${marginLeft ?? 0}px` }}
        data-testid="margin-left">
        {contents}
      </div>
    </div>
  );
};

export default AccordionWithCheckBox;
