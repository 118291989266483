//import modal from 'interfaces/css/common/modal2.module.css'
import { useEffect } from 'react';
import styled from 'styled-components';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

const ModalOverlay = styled.div`
  background-color: rgba(33, 33, 33, 0.46);
  bottom: 0;
  height: 100%;
  left: 0;
  min-height: 100%;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 100;
`;

const ModalContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 50%;
  max-height: ${(props) => props.maxheight};
  padding: ${(props) => (props.$padding ? '24px' : '0px')};
  position: fixed;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: 105;
`;

const ModalScrollContents = styled.div`
  display: flex;
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: inherit;
  box-shadow:
    0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
  max-height: 90%;
`;

const ModalContentsInner = styled.div`
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props) => props.$padding};
  text-align: left;
`;

const Modal2 = ({
  isOpen = true,
  handleClose,
  children,
  modalSize,
  padding = true,
  className,
  closeClickOutside = false,
  maxHeight = '90vh',
  isScrollModal = false,
  isNotDefaultModal = false,
}) => {
  const { ref } = useCloseClickOutside(() => handleClose());

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const modalStyle = () => {
    if (modalSize === 'S') {
      return { maxWidth: '338px' };
    } else if (modalSize === 'M') {
      return { maxWidth: '1048px' };
    } else if (modalSize) {
      return { maxWidth: modalSize };
    } else {
      return { width: '95%' };
    }
  };

  return (
    <ModalOverlay data-testid="modal-overlay">
      {isScrollModal ? (
        <ModalScrollContents ref={closeClickOutside ? ref : null}>
          {children}
        </ModalScrollContents>
      ) : (
        <ModalContents
          ref={closeClickOutside ? ref : null}
          $padding={padding}
          data-testid="modal"
          className="new-style_modal"
          style={modalStyle()}
          onClick={handleClose}
          maxheight={maxHeight}>
          {isNotDefaultModal === true ? (
            <>{children}</>
          ) : (
            // モーダルに初期スタイルを持たせ過ぎている。共通コンポーネントとして使用したいので書くモーダルにこの設定は持たせるようにしたい。
            <ModalContentsInner
              className={className}
              $padding={padding}
              onClick={(e) => e.stopPropagation()}>
              {children}
            </ModalContentsInner>
          )}
        </ModalContents>
      )}
    </ModalOverlay>
  );
};

export default Modal2;
