import { validatePrefecture } from '../../../../application/validations.js';

export class PrefectureId {
  #prefectureId;

  constructor(prefectureId) {
    this.#prefectureId = prefectureId;
  }

  get() {
    return parseInt(this.#prefectureId);
  }

  get isValid() {
    if (!this.#prefectureId) return true;
    return [this.validatePrefecture].every((result) => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validatePrefecture() {
    return validatePrefecture(this.#prefectureId);
  }
}
