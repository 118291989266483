import Button from 'interfaces/components/common/Button/Button';

import GuidelineBody from './GuidelineBody';
import 'interfaces/css/common/guideline.css';

import { styled } from 'styled-components';

const GuidelineArea = styled.div`
  padding: 16px;
  backface-visibility: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
`;

const GuidelineButtonArea = styled.div`
margin-left: auto;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
`;

const Guideline = ({
  governmentName,
  procedureType,
  data,
  closeModal = () => {},
}) => {
  return (
    <>
      <GuidelineArea data-testid="guideline">
        <GuidelineBody
          governmentName={governmentName}
          procedureType={procedureType}
          data={data}
          closeModal={closeModal}
        />
      </GuidelineArea>
      <GuidelineButtonArea>
        <Button
          text="閉じる"
          className="common-button"
          onClick={closeModal}
        />
      </GuidelineButtonArea>
    </>
  );
};

export default Guideline;
