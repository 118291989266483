import LightButton from 'interfaces/components/common/Button/LightButton.jsx';
import PrimaryButton from 'interfaces/components/common/Button/PrimaryButton.jsx';
import CopyForm from 'interfaces/components/Property/List/CopyForm';
import PropertyTable from 'interfaces/components/Property/List/PropertyTable.jsx';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import styled from 'styled-components';
import 'interfaces/css/PropertyList/copy-dialog.css';

const ModalLabel = styled.div`
  line-height: 2rem;
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  padding: 16px 24px 10px;
  background-color: #e0e0e0;
`;

const Padding = styled.div`
  padding-bottom: ${(props) => props.padding};
  padding-right: ${(props) => props.padding};
  padding-left: ${(props) => props.padding};
`;

const CopyDialog = ({
  isOpen,
  cancelButtonOnClick,
  invitations,
  invitation,
}) => {
  return (
    <Modal2
      isOpen={isOpen}
      handleClose={cancelButtonOnClick}
      closeClickOutside={true}>
      <div className="copy-dialog-wrap">
        <div className="copy-dialog" data-testid="copy-dialog">
          <div className="copy-dialog-inner">
            <ModalLabel>物件データのコピー | {invitation.name}</ModalLabel>
            <Padding padding={'20px'}>
              <div data-testid="copy-dialog-description">
                物件データをコピーした場合は、以下の項目の選択内容はそれぞれ同じ内容が設定されます。
                <br />
                以下の項目以外の内容は、元の物件データがそのままコピーされます。
                <br />
                *は必須項目です。必ず選択してください。
              </div>
              <CopyForm invitations={invitations} invitation={invitation} />
              <PropertyTable />
            </Padding>
            <hr className="divider" />
            <div className="actions">
              <div className="spacer"></div>
              <LightButton onClick={cancelButtonOnClick}>
                キャンセル
              </LightButton>
              <PrimaryButton disabled={true}>登録</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal2>
  );
};

export default CopyDialog;
