import { useState, useEffect } from 'react';

export function useCardProvisionalInputs(data) {
  const [documentSubmissionUrl, setDocumentSubmissionUrl] = useState(
    data?.documentSubmissionUrl ?? '',
  );
  const [jointBiddingUrl, setJointBiddingUrl] = useState(
    data?.jointBiddingUrl ?? '',
  );
  useEffect(() => {
    setDocumentSubmissionUrl(data?.documentSubmissionUrl ?? '');
    setJointBiddingUrl(data?.jointBiddingUrl ?? '');
  }, [data]);

  const cardProvisionalInputs = {
    data: {
      documentSubmissionUrl,
      jointBiddingUrl,
    },
    setters: {
      setDocumentSubmissionUrl,
      setJointBiddingUrl,
    },
  };
  return cardProvisionalInputs;
}
