import Heading2 from 'interfaces/components/common/Heading/Heading2';
import RequiredLabel from 'interfaces/components/common/RequiredLabel/RequiredLabel';
import { validateInputsProcedureNewEntryForm } from 'interfaces/components/common/validateInputs/ValidationNewEntryForm';
import { breadcrumbs } from 'interfaces/pages/NewEntry/entities/breadcrumbs';
import NaviNewEntryConfirm from 'interfaces/pages/NewEntry/services/NaviNewEntryConfirm';
import NaviNewEntryForm from 'interfaces/pages/NewEntry/services/NaviNewEntryForm';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppliableAuctions } from 'shared/hooks/useAppliableAuctions';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { DataEmptyConvert } from 'shared/utils/helper/CreateConfirmData';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import { createInvitation } from 'domain/Auction/services/Invitation';
import { NAVI } from 'shared/utils/helper/constants.js';

import NaviNewEntryComplete from './NaviNewEntryComplete';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { NewEntryContext } from '../entities/NewEntryContext';

const NaviNewEntry = () => {
  const { localStorageProcedureType, localStorageGovernmentId } =
    useLocalStorage();
  const [onConfirm, setOnConfirm] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const { auctions, government, invitations, invitation, auction } =
    useContext(NewEntryContext);
  const iauctions = useAppliableAuctions(auctions, invitations);
  const { auction_id } = useParams();
  const inputs = validateInputsProcedureNewEntryForm(auction_id !== undefined);
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    const notRequiredKeys = [
      'departmentValid',
      'contactEmailValid',
      'contactTelValid',
      'tel1Valid',
      'tel2Valid',
      'tel3Valid',
      'mailCertDocumentValid',
      'mailReductionDocumentValid',
      'aplCountAuctionDisplayValid',
      'aplCountBidDisplayValid',
      'bidCountDisplayValid',
      'bidEndTypeValid',
    ];
    const validationResults = Object.entries(inputs.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );
    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [inputs.validationMessage]);

  useEffect(() => {
    inputs.init.setArea(government?.location);
  }, [government]);

  useEffect(() => {
    if (!invitation) return;

    inputs.init.setAuctionName(
      iauctions?.find((object) => object.id === invitation?.auctionId)?.text,
    );
    inputs.init.setName(DataEmptyConvert(invitation?.name));
    inputs.init.setAplCountAuctionDisplay(invitation?.aplCountAuctionDisplay);
    inputs.init.setAplCountBidDisplay(invitation?.aplCountBidDisplay);
    inputs.init.setBidCountDisplay(invitation?.bidCountDisplay);
    inputs.init.setAuctionId(invitation?.auctionId);
    inputs.init.setBidEndType(invitation?.bidEndType);
    inputs.init.setMailCertDocument(invitation?.mailCertDocument);
    inputs.init.setMailReductionDocument(invitation?.mailReductionDocument);
    inputs.init.setDepositDeliveryStatus({
      paymentCreditCard: invitation?.paymentCreditCard,
      paymentBankTransfer: invitation?.paymentBankTransfer,
      noneDeposit: invitation?.noneDeposit,
    });
    inputs.init.setArea(DataEmptyConvert(invitation?.area));
    inputs.init.setContactOffice(DataEmptyConvert(invitation?.contactOffice));
    inputs.init.setContactEmail(invitation?.contactEmail);
    inputs.init.setContactTel({
      telNum1: invitation?.contactTel.split('-')[0],
      telNum2: invitation?.contactTel.split('-')[1],
      telNum3: invitation?.contactTel.split('-')[2],
    });
  }, [invitation]);

  const handleConfirm = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setOnConfirm(true);
  };

  const cancel = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const handleComplete = (e) => {
    e.preventDefault();
    createInvitation(NAVI, inputs, localStorageGovernmentId);
    setOnComplete(true);
  };

  const getMethod = () => {
    if (auction_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() !== 'update') {
      return `${localStorageProcedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込フォーム`;
    }
    return `${localStorageProcedureType === 'PUBLIC_SALE' ? 'インターネット公売' : '公有財産売却'}参加申込情報修正`;
  };

  return (
    <div className="new-style_navi_form" data-testid="new-entry-create">
      {onComplete ? (
        <NaviNewEntryComplete method={getMethod()} />
      ) : onConfirm ? (
        invitation ? (
          <NaviNewEntryConfirm
            inputs={inputs}
            procedureType={localStorageProcedureType}
            cancel={cancel}
            toRegister={handleComplete}
            auction={auction}
          />
        ) : (
          <NaviNewEntryConfirm
            inputs={inputs}
            procedureType={localStorageProcedureType}
            cancel={cancel}
            toRegister={handleComplete}
          />
        )
      ) : (
        <>
          <Breadcrumb
            breadcrumbs={breadcrumbs(
              getMethod(),
              'form',
              localStorageProcedureType,
              auction_id,
            )}
          />
          <Heading2 title={getTitle()} />
          <p className="pt-3 pb-3">
            <RequiredLabel />
            は入力、選択必須項目です。
            <br />
            ※半角カタカナと機種依存文字は使用できません。
          </p>
          {auction_id !== undefined && (
            <PageCautionDescription
              content={`※${textExtractionByProcedureType(localStorageProcedureType)}参加申込開始日以降は修正できません。`}
            />
          )}
          <NaviNewEntryForm
            auctions={iauctions}
            auction={auction}
            inputs={inputs}
            procedureType={localStorageProcedureType}
            toConfirm={handleConfirm}
            isValid={isValid}
          />
        </>
      )}
    </div>
  );
};

export default NaviNewEntry;
