//import {useDeleteSubmit} from "shared/hooks/useSubmit";
import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as ExpandMoreIcon } from 'interfaces/assets/svg/expand_more_icon.svg';
import { ReactComponent as ExpandLessIcon } from 'interfaces/assets/svg/expand_less_icon.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'interfaces/css/Dashboard/kmanager-auction-table-record.css';
import { dateFormat } from 'shared/utils/helper/date_format';
import {
  PROCEDURE_TYPE_JP,
  PUBLIC_SALE_TYPE_JP,
  GOVERNMENT_TYPE_GROUP_JP,
} from 'shared/utils/helper/constants';
const EDIT_PATH = `/kmanager/auction/edit/`;

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

const KmanagerAuctionTableRecord = ({ data, onClick, isVisibleRow }) => {
  const isBoth = data.publicSaleType === 'BOTH';
  const navigation = useNavigate();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const closeModalHandler = () => {
    setIsDeleteOpen(false);
  };
  const deleteHandler = async (id) => {
    await apiRequest(
      'delete',
      `${API_BASE_URL}/api/v1/kmanager/auctions/${id}`,
      null,
    );

    setIsDeleteOpen(false);
  };

  return (
    <tr
      className="kmanager-auction-table-record"
      data-testid="kmanager-auction-table-record">
      <Confirm
        type="delete"
        isOpen={isDeleteOpen}
        closeModal={closeModalHandler}
        Submit={() => deleteHandler(data.id)}
      />
      <td
        className="kmanager-auction-table-control"
        data-testid="kmanager-auction-table-td">
        {isVisibleRow ? (
          <ExpandLessIcon title="expand-less" onClick={onClick} />
        ) : (
          <ExpandMoreIcon title="expand-more" onClick={onClick} />
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{data.name}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{PROCEDURE_TYPE_JP[data.procedureType]}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        {isBoth ? (
          <div>
            せり売り<br></br>入札
          </div>
        ) : (
          <div>{PUBLIC_SALE_TYPE_JP[data.publicSaleType]}形式</div>
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{GOVERNMENT_TYPE_GROUP_JP[data.governmentTypeGroup]}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{dateFormat(data.aplStart, 'YYYY-MM-DD-hh-mm')}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{dateFormat(data.aplEnd, 'YYYY-MM-DD-hh-mm')}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{dateFormat(data.bidStart, 'YYYY-MM-DD-hh-mm')}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        {(isBoth || data.publicSaleType === 'AUCTION') && (
          <div>{dateFormat(data.auctionEnd, 'YYYY-MM-DD-hh-mm')}</div>
        )}
        {(isBoth || data.publicSaleType === 'BID') && (
          <div>{dateFormat(data.bidEnd, 'YYYY-MM-DD-hh-mm')}</div>
        )}
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>{dateFormat(data.openEnd, 'YYYY-MM-DD-hh-mm')}</div>
      </td>
      <td data-testid="kmanager-auction-table-td">
        <div>
          <Button
            className="kmanager-common-button text-no-wrap edit"
            text={<PencilIcon />}
            clickHandler={() => navigation(EDIT_PATH + data.id)}
          />
          <Button
            className="kmanager-common-button text-no-wrap warning"
            text={<DeleteIcon />}
            clickHandler={() => setIsDeleteOpen(true)}
          />
        </div>
      </td>
    </tr>
  );
};

export default KmanagerAuctionTableRecord;
