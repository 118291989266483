export class InquiryTel {
  #inquiryTel;

  constructor(inquiryTel) {
    this.#inquiryTel = inquiryTel;
  }

  get() {
    return this.#inquiryTel === '' ? null : this.#inquiryTel;
  }
}
