import PageTitle from 'interfaces/components/common/PageTitle';
import { validateInputsGovernmentCreate } from 'interfaces/components/common/validateInputs/validateInputsGovernmentCreate';
import GovernmentConfirm from 'interfaces/components/Government/Create/GovernmentConfirm';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GovernmentForm from './GovernmentForm';

export const dummyFormHeader = [
  { id: 1, column: 'name', text: '行政機関名' },
  { id: 2, column: 'nameKana', text: '行政機関名（カナ）' },
  { id: 3, column: 'governmentType', text: '行政機関種別' },
  { id: 4, column: 'procedureType', text: '手続き種別' },
  { id: 5, column: 'region', text: '地域' },
  { id: 6, column: 'location', text: '都道府県' },
];

const GovernmentCreate = () => {
  const [onConfirm, setOnConfirm] = useState(false);
  const location = useLocation();
  const data = location.state?.data;
  const createInputs = validateInputsGovernmentCreate(data);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const hasErrors = createInputs.some(
      (input) =>
        input.validationMessage !== 'TRUE' &&
        input.column !== 'governmentType' &&
        input.column !== 'procedureType',
    );
    setIsValid(!hasErrors);
  }, [createInputs]);

  return (
    <div
      className="kmanager-container kmanager-government-create"
      data-testid="kmanager-government-create">
      <div className="kmanager-row">
        <div className="kmanager-col-12">
          <PageTitle text="行政機関新規作成" />
        </div>
        {onConfirm ? (
          <GovernmentConfirm
            cancel={() => setOnConfirm(false)}
            type="create"
            headers={dummyFormHeader}
            data={createInputs}
            // governmentId={data.id}
          />
        ) : (
          <GovernmentForm
            inputs={createInputs}
            toConfirm={() => setOnConfirm(true)}
            isValid={isValid}
          />
        )}
      </div>
    </div>
  );
};

export default GovernmentCreate;
