import { useState, useEffect } from 'react';

export function useRealEstateInputs(invitation) {
  const [isDraft, setIsDraft] = useState('TRUE');

  // 2. 物件情報
  const [publicSaleType, setPublicSaleType] = useState(undefined); // 出品形式
  const [isNextBuyApl, setIsNextBuyApl] = useState('FALSE'); // 出品形式
  const [printOrder, setPrintOrder] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [governmentSubCategoryId, setGovernmentSubCategoryId] = useState('');
  const [recommendProperties, setRecommendProperties] = useState({
    recommend: false,
  });
  const [divisionId, setDivisionId] = useState('');
  const [saleNumber, setSaleNumber] = useState('');
  const [name, setName] = useState('');
  const [paymentMethod, setPaymentMethod] = useState({
    bankTransfer: false,
    registeredMail: false,
    check: false,
    postalMoneyOrder: false,
    carry: false,
  });
  const [paymentDeadline, setPaymentDeadline] = useState(''); // 買受代金納付期限・売払代金納付期限
  const [resolutionDate, setResolutionDate] = useState(''); // 議決日
  const [contractedLimit, setContractedLimit] = useState(''); // 契約締結期限
  const [videoUrl, setVideoUrl] = useState('');
  const [description, setDescription] = useState('');

  // 3. 物件に関するお問い合わせ先
  const [inquiryName, setInquiryName] = useState('');
  const [inquiryOffice, setInquiryOffice] = useState('');
  const [inquiryEmail, setInquiryEmail] = useState('');
  const [inquiryTel, setInquiryTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [inquiryTelNum1, setInquiryTelNum1] = useState('');
  const [inquiryTelNum2, setInquiryTelNum2] = useState('');
  const [inquiryTelNum3, setInquiryTelNum3] = useState('');
  const [inquiryExtension, setInquiryExtension] = useState('');
  const [inquiryAccept, setInquiryAccept] = useState('');
  const [inquiryNote, setInquiryNote] = useState('');

  // 4. 物件の詳細設定
  const [depositPayment, setDepositPayment] = useState('BOTH');
  const [isApprovalRequired, setIsApprovalRequired] = useState('FALSE');
  const [estimatePrice, setEstimatePrice] = useState('');
  const [deposit, setDeposit] = useState('');
  const [qualifiedInvoice, setQualifiedInvoice] = useState('');

  // 5. 落札後の連絡先
  const [contactName, setContactName] = useState('');
  const [contactOffice, setContactOffice] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactTel, setContactTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  useEffect(() => {
    setContactOffice(invitation?.contactOffice);
    setContactEmail(invitation?.contactEmail);
    setContactTel({
      telNum1: invitation?.contactTel.split('-')[0],
      telNum2: invitation?.contactTel.split('-')[1],
      telNum3: invitation?.contactTel.split('-')[2],
    });
  }, [invitation]);
  const [contactTelNum1, setContactTelNum1] = useState('');
  const [contactTelNum2, setContactTelNum2] = useState('');
  const [contactTelNum3, setContactTelNum3] = useState('');
  const [contactExtension, setContactExtension] = useState('');
  const [contactAccept, setContactAccept] = useState('');

  // その他カラム
  const [id, setId] = useState('');
  const [auctionId, setAuctionId] = useState('');
  const [governmentId, setGovernmentId] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [status, setStatus] = useState('');
  const [paymentLimit, setPaymentLimit] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [isCopied, setIsCopied] = useState('');

  const propertyInfoGeneralInputs = {
    data: {
      isDraft,

      // 2. 物件情報
      publicSaleType,
      isNextBuyApl,
      printOrder,
      categoryId,
      governmentSubCategoryId,
      recommendProperties,
      divisionId,
      saleNumber,
      name,
      paymentMethod,
      paymentDeadline,
      paymentLimit,
      resolutionDate,
      contractedLimit,
      videoUrl,
      description,

      // 3. 物件に関するお問い合わせ先
      inquiryName,
      inquiryOffice,
      inquiryEmail,
      inquiryTel,
      inquiryTelNum1,
      inquiryTelNum2,
      inquiryTelNum3,
      inquiryExtension,
      inquiryAccept,
      inquiryNote,

      // 4. 物件の詳細設定
      depositPayment,
      isApprovalRequired,
      estimatePrice,
      deposit,
      qualifiedInvoice,

      // 5. 落札後の連絡先
      contactName,
      contactOffice,
      contactEmail,
      contactTel,
      contactTelNum1,
      contactTelNum2,
      contactTelNum3,
      contactExtension,
      contactAccept,

      // その他
      isCopied,
      paymentDate,
      propertyType,
      status,
      auctionId,
      governmentId,
      id,
    },
    setters: {
      setIsDraft,

      // 2
      setPublicSaleType,
      setIsNextBuyApl,
      setPrintOrder,
      setCategoryId,
      setGovernmentSubCategoryId,
      setRecommendProperties,
      setDivisionId,
      setSaleNumber,
      setName,
      setPaymentMethod,
      setPaymentDeadline,
      setPaymentLimit,
      setResolutionDate,
      setContractedLimit,
      setVideoUrl,
      setDescription,

      // 3
      setInquiryName,
      setInquiryOffice,
      setInquiryEmail,
      setInquiryTel,
      setInquiryTelNum1,
      setInquiryTelNum2,
      setInquiryTelNum3,
      setInquiryExtension,
      setInquiryAccept,
      setInquiryNote,

      // 4
      setDepositPayment,
      setIsApprovalRequired,
      setEstimatePrice,
      setDeposit,
      setQualifiedInvoice,

      // 5
      setContactName,
      setContactOffice,
      setContactEmail,
      setContactTel,
      setContactTelNum1,
      setContactTelNum2,
      setContactTelNum3,
      setContactExtension,
      setContactAccept,

      // その他
      setIsCopied,
      setPaymentDate,
      setPropertyType,
      setStatus,
      setAuctionId,
      setGovernmentId,
      setId,
    },
  };
  return propertyInfoGeneralInputs;
}
