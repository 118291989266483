import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import Button from 'interfaces/components/common/Button';
import OrganizationForm from 'interfaces/components/Division/navi/OrganizationForm/OrganizationForm';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/OrganizationRegister/entities/new/breadcrumbs';
import 'interfaces/css/Organization/organization.css';
import 'interfaces/css/common/property-info-general-form.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import OrganizationNewConfirm from './OrganizationNewConfirm';
import { useLocalStorage } from '../../../../../shared/hooks/useLocalStorage';
import { organizationFormData } from '../OrganizationForm/OrganizationFormData';

import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';
import { create } from 'domain/Division/services/Division';
import { ServiceTypes } from 'shared/utils/constants/service.js';

const OrganizationNew = () => {
  const {
    localStorageProcedureType,
    localStorageGovernmentType,
    localStorageGovernmentID,
  } = useLocalStorage();
  const formData = organizationFormData();
  const [onConfirm, setOnConfirm] = useState(false);

  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const baseNotRequiredKeys = [
      'contactExtension',
      'contactEmail',
      'useCreditCard',
      'buildName',
      'area',
      'accountType',
    ];

    const additionalNotRequiredKeys =
      formData?.data.useCreditCard === 'NO'
        ? [
            'financialInstitutionName',
            'branchName',
            'accountNumber',
            'accountName',
            'accountNameKana',
          ]
        : [];

    const addressRelatedKeys = [
      'postalCode',
      'postalCode1',
      'postalCode2',
      'city',
      'address',
      'destination',
      'writtenDestination',
    ];

    const isPublicSaleAndNotNationalTax =
      localStorageProcedureType === 'PUBLIC_SALE' &&
      localStorageGovernmentType !== 'NATIONAL_TAX_AGENCY';
    const includeAddressKeys =
      formData?.data.useCreditCard === 'YES'
        ? !isPublicSaleAndNotNationalTax
        : true;

    const notRequiredKeys = [
      ...baseNotRequiredKeys,
      ...additionalNotRequiredKeys,
      ...(includeAddressKeys ? addressRelatedKeys : []),
    ];
    const validationResults = Object.entries(formData.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';

        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [formData, localStorageProcedureType, localStorageGovernmentType]);

  const handleConfirm = (e) => {
    e.preventDefault();
    setOnConfirm(true);
  };

  const handleBackForm = (e) => {
    e.preventDefault();
    setOnConfirm(false);
  };

  const navigation = useNavigate();

  const handleCancel = (e) => {
    e.preventDefault();
    navigation('/navi/organization');
  };

  const handleClear = (e) => {
    e.preventDefault();
    Object.values(formData.setters).forEach((setter) => setter({ target: { value: '' } }));
    console.log(formData);
  };

  const handleComplete = async (e) => {
    e.preventDefault();
    formData.data.governmentId = localStorageGovernmentID;
    formData.data.procedureType = localStorageProcedureType;
    try {
      await create(ServiceTypes.NAVI.string, formData.data);
      navigation(`/navi/organization/new/complete`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {onConfirm ? (
        <OrganizationNewConfirm
          formData={formData}
          governmentType={localStorageGovernmentType}
          handleComplete={handleComplete}
          handleBackForm={handleBackForm}
        />
      ) : (
        <>
          <div className="organization" data-testid="organization">
            <Breadcrumb breadcrumbs={breadcrumbs} />
            <PropertyInfoGeneralPageDescription
              title={'執行機関管理：新規登録'}
              content={
                '執行機関を新規登録します。入力が終わりましたら「確認する」ボタンをクリックしてください。'
              }
              displayRequired={true}
            />
            <PageCautionDescription
              content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
            />
            <OrganizationForm
              formData={formData}
              governmentType={localStorageGovernmentType}
              procedureType={localStorageProcedureType}
            />
            <div className="pt-9">
              <div className="property-info-general-form-button-box">
                <div className="pr-1 pl-1">
                  <Button
                    text={'確認する'}
                    isOutline={false}
                    height={'44px'}
                    className={`property-info-general-form-button ${isValid ? 'new-style_navi_primary-button' : 'new-style_navi_disabled-button'}`}
                    //clickHandler={() => navigate('/navi/organization/confirm', {state: formData})}
                    clickHandler={handleConfirm}
                    isDisabled={!isValid}
                  />
                </div>
                <div className="pr-1 pl-1">
                  <Button
                    text={'キャンセル'}
                    isOutline={false}
                    height={'44px'}
                    clickHandler={handleCancel}
                    className={
                      'property-info-general-form-button new-style_navi_normal-button'
                    }
                  />
                </div>
                <div className="pl-1 pr-1">
                  <Button
                    text={'クリア'}
                    isOutline={false}
                    height={'44px'}
                    clickHandler={handleClear}
                    className={
                      'property-info-general-form-button new-style_navi_normal-button'
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default OrganizationNew;
