// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { Body } from './Setting/body';
import { ItemNo } from './Setting/itemNo';
import { Title } from './Setting/title';
import { IsDisplay } from './Setting/isDisplay';
import { CostContractDescription1 } from './SettingAssetCautionPageCreate/costContractDescription1';
import { CostContractDescription2 } from './SettingAssetCautionPageCreate/costContractDescription2';
import { CostContractDescription3 } from './SettingAssetCautionPageCreate/costContractDescription3';
import { CostContractTitle1 } from './SettingAssetCautionPageCreate/costContractTitle1';
import { CostContractTitle2 } from './SettingAssetCautionPageCreate/costContractTitle2';
import { CostContractTitle3 } from './SettingAssetCautionPageCreate/costContractTitle3';
import { CostPaymentDescription1 } from './SettingAssetCautionPageCreate/costPaymentDescription1';
import { CostPaymentDescription2 } from './SettingAssetCautionPageCreate/costPaymentDescription2';
import { CostPaymentDescription3 } from './SettingAssetCautionPageCreate/costPaymentDescription3';
import { CostPaymentDescription4 } from './SettingAssetCautionPageCreate/costPaymentDescription4';
import { CostPaymentDescription5 } from './SettingAssetCautionPageCreate/costPaymentDescription5';
import { CostPaymentTitle1 } from './SettingAssetCautionPageCreate/costPaymentTitle1';
import { CostPaymentTitle2 } from './SettingAssetCautionPageCreate/costPaymentTitle2';
import { CostPaymentTitle3 } from './SettingAssetCautionPageCreate/costPaymentTitle3';
import { CostPaymentTitle4 } from './SettingAssetCautionPageCreate/costPaymentTitle4';
import { CostPaymentTitle5 } from './SettingAssetCautionPageCreate/costPaymentTitle5';
import { DocumentDescription } from './SettingAssetCautionPageCreate/documentDescription';
import { ImportantDescription1 } from './SettingAssetCautionPageCreate/importantDescription1';
import { ImportantDescription2 } from './SettingAssetCautionPageCreate/importantDescription2';
import { ImportantDescription3 } from './SettingAssetCautionPageCreate/importantDescription3';
import { ImportantHandlingBidDeposit } from './SettingAssetCautionPageCreate/importantHandlingBidDeposit';
import { ImportantHandlingContractDeposit } from './SettingAssetCautionPageCreate/importantHandlingContractDeposit';
import { ImportantOther1 } from './SettingAssetCautionPageCreate/importantOther1';
import { ImportantOther2 } from './SettingAssetCautionPageCreate/importantOther2';
import { ImportantOther3 } from './SettingAssetCautionPageCreate/importantOther3';
import { ImportantRestrictionUse } from './SettingAssetCautionPageCreate/importantRestrictionUse';
import { ImportantRestrictionUseBefore } from './SettingAssetCautionPageCreate/importantRestrictionUseBefore';
import { TransRightCompletedTime } from './SettingAssetCautionPageCreate/transRightCompletedTime';
import { TransRightDate } from './SettingAssetCautionPageCreate/transRightDate';
import { BankTransferUrl } from './SettingBankPageCreate/bankTransferUrl';
import { DocumentName } from './SettingBankPageCreate/documentName';
import { DocumentSubmissionUrl } from './SettingCreditPageCreate/documentSubmissionUrl';
import { JointBiddingUrl } from './SettingCreditPageCreate/jointBiddingUrl';
import { PublicSaleType } from './SettingCreditPageCreate/publicSaleType';
import { CertDocumentAddress } from './SettingDocumentSendCreate/certDocumentAddress';
import { CertDocumentArea } from './SettingDocumentSendCreate/certDocumentArea';
import { CertDocumentBuildName } from './SettingDocumentSendCreate/certDocumentBuildName';
import { CertDocumentCity } from './SettingDocumentSendCreate/certDocumentCity';
import { CertDocumentDestination } from './SettingDocumentSendCreate/certDocumentDestination';
import { CertDocumentPostalCode } from './SettingDocumentSendCreate/certDocumentPostalCode';
import { CertDocumentWrittenDestination } from './SettingDocumentSendCreate/certDocumentWrittenDestination';
import { GovernmentId } from './SettingDocumentSendCreate/governmentId';
import { InvoiceAddress } from './SettingDocumentSendCreate/invoiceAddress';
import { InvoiceArea } from './SettingDocumentSendCreate/invoiceArea';
import { InvoiceBuildName } from './SettingDocumentSendCreate/invoiceBuildName';
import { InvoiceCity } from './SettingDocumentSendCreate/invoiceCity';
import { InvoiceDepartment } from './SettingDocumentSendCreate/invoiceDepartment';
import { InvoiceExtension } from './SettingDocumentSendCreate/invoiceExtension';
import { InvoicePayerName } from './SettingDocumentSendCreate/invoicePayerName';
import { InvoicePostalCode } from './SettingDocumentSendCreate/invoicePostalCode';
import { InvoiceTel } from './SettingDocumentSendCreate/invoiceTel';
import { InvoiceUrl } from './SettingDocumentSendCreate/invoiceUrl';
import { InvoiceWrittenDestination } from './SettingDocumentSendCreate/invoiceWrittenDestination';
import { ProcedureType } from './SettingDocumentSendCreate/procedureType';
import { DisplayHomePage } from './SettingGovernmentTopPageCreate/displayHomePage';
import { DisplayInquiry } from './SettingGovernmentTopPageCreate/displayInquiry';
import { DisplayPreview } from './SettingGovernmentTopPageCreate/displayPreview';
import { HomePageName } from './SettingGovernmentTopPageCreate/homePageName';
import { HomePageUrl } from './SettingGovernmentTopPageCreate/homePageUrl';
import { PreviewUrl } from './SettingGovernmentTopPageCreate/previewUrl';
import { CostAttention } from './SettingPublicCautionPageCreate/costAttention';
import { CostCarDescription } from './SettingPublicCautionPageCreate/costCarDescription';
import { CostCarDisplay } from './SettingPublicCautionPageCreate/costCarDisplay';
import { CostEstateDescription } from './SettingPublicCautionPageCreate/costEstateDescription';
import { CostEstateDisplay } from './SettingPublicCautionPageCreate/costEstateDisplay';
import { CostPropertyDescription } from './SettingPublicCautionPageCreate/costPropertyDescription';
import { CostPropertyDisplay } from './SettingPublicCautionPageCreate/costPropertyDisplay';
import { DocumentAttention } from './SettingPublicCautionPageCreate/documentAttention';
import { DocumentCarDescription } from './SettingPublicCautionPageCreate/documentCarDescription';
import { DocumentCarDisplay } from './SettingPublicCautionPageCreate/documentCarDisplay';
import { DocumentEstateDescription } from './SettingPublicCautionPageCreate/documentEstateDescription';
import { DocumentEstateDisplay } from './SettingPublicCautionPageCreate/documentEstateDisplay';
import { DocumentPageDisplay } from './SettingPublicCautionPageCreate/documentPageDisplay';
import { DocumentPageName } from './SettingPublicCautionPageCreate/documentPageName';
import { DocumentPageUrl } from './SettingPublicCautionPageCreate/documentPageUrl';
import { DocumentPropertyDescription } from './SettingPublicCautionPageCreate/documentPropertyDescription';
import { DocumentPropertyDisplay } from './SettingPublicCautionPageCreate/documentPropertyDisplay';
import { ImportantAttention } from './SettingPublicCautionPageCreate/importantAttention';
import { ImportantNonConformingContractsLiability } from './SettingPublicCautionPageCreate/importantNonConformingContractsLiability';
import { ImportantObligationDelivery } from './SettingPublicCautionPageCreate/importantObligationDelivery';
import { ImportantOther } from './SettingPublicCautionPageCreate/importantOther';
import { ImportantReturnDeposit } from './SettingPublicCautionPageCreate/importantReturnDeposit';
import { ImportantReturnItems } from './SettingPublicCautionPageCreate/importantReturnItems';
import { ImportantRiskBearing } from './SettingPublicCautionPageCreate/importantRiskBearing';
import { ImportantStorageCost } from './SettingPublicCautionPageCreate/importantStorageCost';
import { ImportantTermsDelivery } from './SettingPublicCautionPageCreate/importantTermsDelivery';
import { InquiryAccept } from './SettingPublicCautionPageCreate/inquiryAccept';
import { InquiryDisplay } from './SettingPublicCautionPageCreate/inquiryDisplay';
import { InquiryEmail } from './SettingPublicCautionPageCreate/inquiryEmail';
import { InquiryExtension } from './SettingPublicCautionPageCreate/inquiryExtension';
import { InquiryName } from './SettingPublicCautionPageCreate/inquiryName';
import { InquiryTel } from './SettingPublicCautionPageCreate/inquiryTel';
import { OtherTransRightAttention } from './SettingPublicCautionPageCreate/otherTransRightAttention';
import { OtherTransRightDescription } from './SettingPublicCautionPageCreate/otherTransRightDescription';
import { TransRightAttention } from './SettingPublicCautionPageCreate/transRightAttention';
import { TransRightCarDescription } from './SettingPublicCautionPageCreate/transRightCarDescription';
import { TransRightCarDisplay } from './SettingPublicCautionPageCreate/transRightCarDisplay';
import { TransRightDateDescription } from './SettingPublicCautionPageCreate/transRightDateDescription';
import { TransRightDescription } from './SettingPublicCautionPageCreate/transRightDescription';
import { TransRightEstateDescription } from './SettingPublicCautionPageCreate/transRightEstateDescription';
import { TransRightEstateDisplay } from './SettingPublicCautionPageCreate/transRightEstateDisplay';
import { TransRightPropertyDescription } from './SettingPublicCautionPageCreate/transRightPropertyDescription';
import { TransRightPropertyDisplay } from './SettingPublicCautionPageCreate/transRightPropertyDisplay';

export class SettingDocumentSendGet {
  // 必要なデータ

  constructor(data) {
    this.certDocumentAddress = data.certDocumentAddress;
    this.certDocumentArea = data.certDocumentArea;
    this.certDocumentBuildName = data.certDocumentBuildName;
    this.certDocumentCity = data.certDocumentCity;
    this.certDocumentDestination = data.certDocumentDestination;
    this.certDocumentPostalCode = data.certDocumentPostalCode;
    this.certDocumentWrittenDestination = data.certDocumentWrittenDestination;
    this.governmentId = data.governmentId;
    this.invoiceAddress = data.invoiceAddress;
    this.invoiceArea = data.invoiceArea;
    this.invoiceBuildName = data.invoiceBuildName;
    this.invoiceCity = data.invoiceCity;
    this.invoiceDepartment = data.invoiceDepartment;
    this.invoiceExtension = data.invoiceExtension;
    this.invoicePayerName = data.invoicePayerName;
    this.invoicePostalCode = data.invoicePostalCode;
    this.invoiceTel = data.invoiceTel;
    this.invoiceUrl = data.invoiceUrl;
    this.invoiceWrittenDestination = data.invoiceWrittenDestination;
    this.procedureType = data.procedureType;
  }
}

// (creditCardPage)
export class SettingCreditPageGet {
  constructor(data) {
    this.documentSubmissionUrl = data.documentSubmissionUrl;
    this.governmentId = data.governmentId;
    this.jointBiddingUrl = data.jointBiddingUrl;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
    this.createdUser = data.createdUser;
    this.createdAt = data.createdAt;
    this.updatedUser = data.updatedUser;
    this.updatedAt = data.updatedAt;
  }
}

export class SettingBankPageGet {
  // 必要なデータ

  constructor(data) {
    this.bankTransferUrl = data.bankTransferUrl;
    this.documentName = data.documentName;
    this.governmentId = data.governmentId;
    this.jointBiddingUrl = data.jointBiddingUrl;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
  }
}

export class SettingGuidelineGet {
  constructor(data) {
    this.governmentId = data.governmentId;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
    this.isDisplay = data.isDisplay;
    this.data = new GuidelineDataGet(data);
  }
}

class GuidelineDataGet {
  constructor(data) {
    this.itemNo = data.itemNo;
    this.title = data.title;
    this.body = data.body;
  }
}

export class SettingPublicCautionPageGet {
  constructor(data) {
    this.costAttention = data.costAttention;
    this.costCarDescription = data.costCarDescription;
    this.costCarDisplay = data.costCarDisplay;
    this.costEstateDescription = data.costEstateDescription;
    this.costEstateDisplay = data.costEstateDisplay;
    this.costPropertyDescription = data.costPropertyDescription;
    this.costPropertyDisplay = data.costPropertyDisplay;
    this.documentAttention = data.documentAttention;
    this.documentCarDescription = data.documentCarDescription;
    this.documentCarDisplay = data.documentCarDisplay;
    this.documentEstateDescription = data.documentEstateDescription;
    this.documentEstateDisplay = data.documentEstateDisplay;
    this.documentPageDisplay = data.documentPageDisplay;
    this.documentPageName = data.documentPageName;
    this.documentPageUrl = data.documentPageUrl;
    this.documentPropertyDescription = data.documentPropertyDescription;
    this.documentPropertyDisplay = data.documentPropertyDisplay;
    this.governmentId = data.governmentId;
    this.importantAttention = data.importantAttention;
    this.importantNonConformingContractsLiability =
      data.importantNonConformingContractsLiability;
    this.importantObligationDelivery = data.importantObligationDelivery;
    this.importantOther = data.importantOther;
    this.importantReturnDeposit = data.importantReturnDeposit;
    this.importantReturnItems = data.importantReturnItems;
    this.importantRiskBearing = data.importantRiskBearing;
    this.importantStorageCost = data.importantStorageCost;
    this.importantTermsDelivery = data.importantTermsDelivery;
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryDisplay = data.inquiryDisplay;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryTel = data.inquiryTel;
    this.otherTransRightAttention = data.otherTransRightAttention;
    this.otherTransRightDescription = data.otherTransRightDescription;
    this.publicSaleType = data.publicSaleType;
    this.transRightAttention = data.transRightAttention;
    this.transRightCarDescription = data.transRightCarDescription;
    this.transRightCarDisplay = data.transRightCarDisplay;
    this.transRightDateDescription = data.transRightDateDescription;
    this.transRightDescription = data.transRightDescription;
    this.transRightEstateDescription = data.transRightEstateDescription;
    this.transRightEstateDisplay = data.transRightEstateDisplay;
    this.transRightPropertyDescription = data.transRightPropertyDescription;
    this.transRightPropertyDisplay = data.transRightPropertyDisplay;
  }
}

export class SettingAssetCautionPageGet {
  constructor(data) {
    this.costAttention = data.costAttention;
    this.costContractDescription1 = data.costContractDescription1;
    this.costContractDescription2 = data.costContractDescription2;
    this.costContractDescription3 = data.costContractDescription3;
    this.costContractTitle1 = data.costContractTitle1;
    this.costContractTitle2 = data.costContractTitle2;
    this.costContractTitle3 = data.costContractTitle3;
    this.costPaymentDescription1 = data.costPaymentDescription1;
    this.costPaymentDescription2 = data.costPaymentDescription2;
    this.costPaymentDescription3 = data.costPaymentDescription3;
    this.costPaymentDescription4 = data.costPaymentDescription4;
    this.costPaymentDescription5 = data.costPaymentDescription5;
    this.costPaymentTitle1 = data.costPaymentTitle1;
    this.costPaymentTitle2 = data.costPaymentTitle2;
    this.costPaymentTitle3 = data.costPaymentTitle3;
    this.costPaymentTitle4 = data.costPaymentTitle4;
    this.costPaymentTitle5 = data.costPaymentTitle5;
    this.documentDescription = data.documentDescription;
    this.documentPageDisplay = data.documentPageDisplay;
    this.documentPageName = data.documentPageName;
    this.documentPageUrl = data.documentPageUrl;
    this.governmentId = data.governmentId;
    this.importantAttention = data.importantAttention;
    this.importantDescription1 = data.importantDescription1;
    this.importantDescription2 = data.importantDescription2;
    this.importantDescription3 = data.importantDescription3;
    this.importantHandlingBidDeposit = data.importantHandlingBidDeposit;
    this.importantHandlingContractDeposit =
      data.importantHandlingContractDeposit;
    this.importantOther1 = data.importantOther1;
    this.importantOther2 = data.importantOther2;
    this.importantOther3 = data.importantOther3;
    this.importantRestrictionUse = data.importantRestrictionUse;
    this.importantRestrictionUseBefore = data.importantRestrictionUseBefore;
    this.importantRiskBearing = data.importantRiskBearing;
    this.importantTermsDelivery = data.importantTermsDelivery;
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryDisplay = data.inquiryDisplay;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryTel = data.inquiryTel;
    this.otherTransRightAttention = data.otherTransRightAttention;
    this.otherTransRightDescription = data.otherTransRightDescription;
    this.publicSaleType = data.publicSaleType;
    this.transRightCompletedTime = data.transRightCompletedTime;
    this.transRightDate = data.transRightDate;
    this.transRightDescription = data.transRightDescription;
  }
}

export class SettingGovernmentTopPageGet {
  constructor(data) {
    this.displayHomePage = data.displayHomePage;
    this.displayInquiry = data.displayInquiry;
    this.displayPreview = data.displayPreview;
    this.governmentId = data.governmentId;
    this.homePageName = data.homePageName;
    this.homePageUrl = data.homePageUrl;
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryTel = data.inquiryTel;
    this.previewUrl = data.previewUrl;
    this.procedureType = data.procedureType;
    this.publicSaleType = data.publicSaleType;
  }
}

export class SettingStatusGet {
  constructor(data) {
    this.location = data.location;
    this.prefecture = data.prefecture;
    this.governmentName = data.governmentName;
    this.count = data.count;
    this.governmentTopPages = data.governmentTopPages;
    this.creditCardPages = data.creditCardPages;
    this.bankTransferPages = data.bankTransferPages;
    this.guidelines = data.guidelines;
    this.cautionPages = data.cautionPages;
    this.systemFee = data.systemFee;
    this.certDocument = data.certDocument;
    this.emblem = data.emblem;
    this.depositPaymentMismatch = data.depositPaymentMismatch;
    this.financialInstitution = data.financialInstitution;
    this.divisionName = data.divisionName;
  }
}

export class SettingDocumentSendCreate {
  // 必要なデータ

  constructor(data) {
    this.certDocumentAddress = new CertDocumentAddress(
      data.certDocumentAddress,
    );
    this.certDocumentArea = new CertDocumentArea(data.certDocumentArea);
    this.certDocumentBuildName = new CertDocumentBuildName(
      data.certDocumentBuildName,
    );
    this.certDocumentCity = new CertDocumentCity(data.certDocumentCity);
    this.certDocumentDestination = new CertDocumentDestination(
      data.certDocumentDestination,
    );
    this.certDocumentPostalCode = new CertDocumentPostalCode(
      data.certDocumentPostalCode,
    );
    this.certDocumentWrittenDestination = new CertDocumentWrittenDestination(
      data.certDocumentWrittenDestination,
    );
    this.governmentId = new GovernmentId(data.governmentId);
    this.invoiceAddress = new InvoiceAddress(data.invoiceAddress);
    this.invoiceArea = new InvoiceArea(data.invoiceArea);
    this.invoiceBuildName = new InvoiceBuildName(data.invoiceBuildName);
    this.invoiceCity = new InvoiceCity(data.invoiceCity);
    this.invoiceDepartment = new InvoiceDepartment(data.invoiceDepartment);
    this.invoiceExtension = new InvoiceExtension(data.invoiceExtension);
    this.invoicePayerName = new InvoicePayerName(data.invoicePayerName);
    this.invoicePostalCode = new InvoicePostalCode(data.invoicePostalCode);
    this.invoiceTel = new InvoiceTel(data.invoiceTel);
    this.invoiceUrl = new InvoiceUrl(data.invoiceUrl);
    this.invoiceWrittenDestination = new InvoiceWrittenDestination(
      data.invoiceWrittenDestination,
    );
    this.procedureType = new ProcedureType(data.procedureType);
  }
}

// (creditCardPage)
export class SettingCreditPageCreate {
  // 必要なデータ

  constructor(data) {
    this.documentSubmissionUrl = new DocumentSubmissionUrl(
      data.documentSubmissionUrl,
    );
    this.governmentId = new GovernmentId(data.governmentId);
    this.jointBiddingUrl = new JointBiddingUrl(data.jointBiddingUrl);
    this.procedureType = new ProcedureType(data.procedureType);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
  }
}

export class SettingBankPageCreate {
  // 必要なデータ

  constructor(data) {
    this.bankTransferUrl = new BankTransferUrl(data.bankTransferUrl);
    this.documentName = new DocumentName(data.documentName);
    this.governmentId = new GovernmentId(data.governmentId);
    this.jointBiddingUrl = new JointBiddingUrl(data.jointBiddingUrl);
    this.procedureType = new ProcedureType(data.procedureType);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
  }
}

export class SettingGuidelineCreate {
  // 必要なデータ
  constructor(data) {
    console.log(data);
    this.governmentId = new GovernmentId(data.governmentId);
    this.procedureType = new ProcedureType(data.procedureType);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
    this.isDisplay = new IsDisplay(data.isDisplay);
    this.itemNo = new ItemNo(data.itemNo);
    this.title = new Title(data.title);
    this.body = new Body(data.body);
  }
}

export class SettingPublicCautionPageCreate {
  // 必要なデータ

  constructor(data) {
    this.cost_attention = new CostAttention(data.cost_attention);
    this.cost_car_description = new CostCarDescription(
      data.cost_car_description,
    );
    this.cost_car_display = new CostCarDisplay(data.cost_car_display);
    this.cost_estate_description = new CostEstateDescription(
      data.cost_estate_description,
    );
    this.cost_estate_display = new CostEstateDisplay(data.cost_estate_display);
    this.cost_property_description = new CostPropertyDescription(
      data.cost_property_description,
    );
    this.cost_property_display = new CostPropertyDisplay(
      data.cost_property_display,
    );
    this.document_attention = new DocumentAttention(data.document_attention);
    this.document_car_description = new DocumentCarDescription(
      data.document_car_description,
    );
    this.document_car_display = new DocumentCarDisplay(
      data.document_car_display,
    );
    this.document_estate_description = new DocumentEstateDescription(
      data.document_estate_description,
    );
    this.document_estate_display = new DocumentEstateDisplay(
      data.document_estate_display,
    );
    this.document_page_display = new DocumentPageDisplay(
      data.document_page_display,
    );
    this.document_page_name = new DocumentPageName(data.document_page_name);
    this.document_page_url = new DocumentPageUrl(data.document_page_url);
    this.document_property_description = new DocumentPropertyDescription(
      data.document_property_description,
    );
    this.document_property_display = new DocumentPropertyDisplay(
      data.document_property_display,
    );
    this.government_id = new GovernmentId(data.government_id);
    this.important_attention = new ImportantAttention(data.important_attention);
    this.important_non_conforming_contracts_liability =
      new ImportantNonConformingContractsLiability(
        data.important_non_conforming_contracts_liability,
      );
    this.important_obligation_delivery = new ImportantObligationDelivery(
      data.important_obligation_delivery,
    );
    this.important_other = new ImportantOther(data.important_other);
    this.important_return_deposit = new ImportantReturnDeposit(
      data.important_return_deposit,
    );
    this.important_return_items = new ImportantReturnItems(
      data.important_return_items,
    );
    this.important_risk_bearing = new ImportantRiskBearing(
      data.important_risk_bearing,
    );
    this.important_storage_cost = new ImportantStorageCost(
      data.important_storage_cost,
    );
    this.important_terms_delivery = new ImportantTermsDelivery(
      data.important_terms_delivery,
    );
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_display = new InquiryDisplay(data.inquiry_display);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.other_trans_right_attention = new OtherTransRightAttention(
      data.other_trans_right_attention,
    );
    this.other_trans_right_description = new OtherTransRightDescription(
      data.other_trans_right_description,
    );
    this.public_sale_type = new PublicSaleType(data.public_sale_type);
    this.trans_right_attention = new TransRightAttention(
      data.trans_right_attention,
    );
    this.trans_right_car_description = new TransRightCarDescription(
      data.trans_right_car_description,
    );
    this.trans_right_car_display = new TransRightCarDisplay(
      data.trans_right_car_display,
    );
    this.trans_right_date_description = new TransRightDateDescription(
      data.trans_right_date_description,
    );
    this.trans_right_description = new TransRightDescription(
      data.trans_right_description,
    );
    this.trans_right_estate_description = new TransRightEstateDescription(
      data.trans_right_estate_description,
    );
    this.trans_right_estate_display = new TransRightEstateDisplay(
      data.trans_right_estate_display,
    );
    this.trans_right_property_description = new TransRightPropertyDescription(
      data.trans_right_property_description,
    );
    this.trans_right_property_display = new TransRightPropertyDisplay(
      data.trans_right_property_display,
    );
  }
}

export class SettingAssetCautionPageCreate {
  // 必要なデータ

  constructor(data) {
    this.cost_attention = new CostAttention(data.cost_attention);
    this.cost_contract_description_1 = new CostContractDescription1(
      data.cost_contract_description_1,
    );
    this.cost_contract_description_2 = new CostContractDescription2(
      data.cost_contract_description_2,
    );
    this.cost_contract_description_3 = new CostContractDescription3(
      data.cost_contract_description_3,
    );
    this.cost_contract_title_1 = new CostContractTitle1(
      data.cost_contract_title_1,
    );
    this.cost_contract_title_2 = new CostContractTitle2(
      data.cost_contract_title_2,
    );
    this.cost_contract_title_3 = new CostContractTitle3(
      data.cost_contract_title_3,
    );
    this.cost_payment_description_1 = new CostPaymentDescription1(
      data.cost_payment_description_1,
    );
    this.cost_payment_description_2 = new CostPaymentDescription2(
      data.cost_payment_description_2,
    );
    this.cost_payment_description_3 = new CostPaymentDescription3(
      data.cost_payment_description_3,
    );
    this.cost_payment_description_4 = new CostPaymentDescription4(
      data.cost_payment_description_4,
    );
    this.cost_payment_description_5 = new CostPaymentDescription5(
      data.cost_payment_description_5,
    );
    this.cost_payment_title_1 = new CostPaymentTitle1(
      data.cost_payment_title_1,
    );
    this.cost_payment_title_2 = new CostPaymentTitle2(
      data.cost_payment_title_2,
    );
    this.cost_payment_title_3 = new CostPaymentTitle3(
      data.cost_payment_title_3,
    );
    this.cost_payment_title_4 = new CostPaymentTitle4(
      data.cost_payment_title_4,
    );
    this.cost_payment_title_5 = new CostPaymentTitle5(
      data.cost_payment_title_5,
    );
    this.document_description = new DocumentDescription(
      data.document_description,
    );
    this.document_page_display = new DocumentPageDisplay(
      data.document_page_display,
    );
    this.document_page_name = new DocumentPageName(data.document_page_name);
    this.document_page_url = new DocumentPageUrl(data.document_page_url);
    this.government_id = new GovernmentId(data.government_id);
    this.important_attention = new ImportantAttention(data.important_attention);
    this.important_description_1 = new ImportantDescription1(
      data.important_description_1,
    );
    this.important_description_2 = new ImportantDescription2(
      data.important_description_2,
    );
    this.important_description_3 = new ImportantDescription3(
      data.important_description_3,
    );
    this.important_handling_bid_deposit = new ImportantHandlingBidDeposit(
      data.important_handling_bid_deposit,
    );
    this.important_handling_contract_deposit =
      new ImportantHandlingContractDeposit(
        data.important_handling_contract_deposit,
      );
    this.important_other_1 = new ImportantOther1(data.important_other_1);
    this.important_other_2 = new ImportantOther2(data.important_other_2);
    this.important_other_3 = new ImportantOther3(data.important_other_3);
    this.important_restriction_use = new ImportantRestrictionUse(
      data.important_restriction_use,
    );
    this.important_restriction_use_before = new ImportantRestrictionUseBefore(
      data.important_restriction_use_before,
    );
    this.important_risk_bearing = new ImportantRiskBearing(
      data.important_risk_bearing,
    );
    this.important_terms_delivery = new ImportantTermsDelivery(
      data.important_terms_delivery,
    );
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_display = new InquiryDisplay(data.inquiry_display);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.other_trans_right_attention = new OtherTransRightAttention(
      data.other_trans_right_attention,
    );
    this.other_trans_right_description = new OtherTransRightDescription(
      data.other_trans_right_description,
    );
    this.public_sale_type = new PublicSaleType(data.public_sale_type);
    this.trans_right_completed_time = new TransRightCompletedTime(
      data.trans_right_completed_time,
    );
    this.trans_right_date = new TransRightDate(data.trans_right_date);
    this.trans_right_description = new TransRightDescription(
      data.trans_right_description,
    );
  }
}

export class SettingGovernmentTopPageCreate {
  constructor(data) {
    this.displayHomePage = new DisplayHomePage(data.displayHomePage);
    this.displayInquiry = new DisplayInquiry(data.displayInquiry);
    this.displayPreview = new DisplayPreview(data.displayPreview);
    this.governmentId = new GovernmentId(data.governmentId);
    this.homePageName = new HomePageName(data.homePageName);
    this.homePageUrl = new HomePageUrl(data.homePageUrl);
    this.inquiryAccept = new InquiryAccept(data.inquiryAccept);
    this.inquiryEmail = new InquiryEmail(data.inquiryEmail);
    this.inquiryExtension = new InquiryExtension(data.inquiryExtension);
    this.inquiryName = new InquiryName(data.inquiryName);
    this.inquiryTel = new InquiryTel(
      data.inquiryTel.telNum1 &&
      data.inquiryTel.telNum2 &&
      data.inquiryTel.telNum3
        ? data.inquiryTel.telNum1 +
          '-' +
          data.inquiryTel.telNum2 +
          '-' +
          data.inquiryTel.telNum3
        : null,
    );
    this.previewUrl = new PreviewUrl(data.previewUrl);
    this.procedureType = new ProcedureType(data.procedureType);
    this.publicSaleType = new PublicSaleType(data.publicSaleType);
  }
}
