import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import Pagination from 'interfaces/components/common/Pagination.jsx';
import { useState } from 'react';
import usePagination from 'shared/hooks/usePagination';
import { useSortableData } from 'shared/hooks/useSort.js';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';

import { dateFormat } from 'shared/utils/helper/date_format';
import MessageFormModal from './MessageFormModal';

import KmanagerTableEmptyRecord from '../../common/Table/KmanagerTableEmptyRecord';

import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

export const headers = [
  { id: 1, column: 'id', text: 'No' },
  { id: 2, column: 'title', text: 'タイトル' },
  { id: 3, column: 'body', text: '本文' },
  { id: 4, column: 'displayServiceType', text: '公開対象システム' },
  { id: 5, column: 'openAt', text: '公開開始日時' },
  { id: 6, column: 'closeAt', text: '公開終了日時' },
  { id: 7, column: 'severityType', text: '重要度' },
  { id: 8, text: '編集/削除' },
];

const MessageTable = ({ managementMessages }) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openModalId, setOpenModalId] = useState(null);

  const {
    items: sortedUsageFees,
    requestSort,
    sortConfig,
  } = useSortableData(managementMessages);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedUsageFees, rowsPerPage);

  const deleteHandler = async (id) => {
    await apiRequest(
      'delete',
      `${API_BASE_URL}/api/v1/kmanager/messages/${id}`,
      null,
    );

    setIsDeleteOpen(false);
  };

  return (
    <div>
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {headers.map((header) => (
                <SortableTh
                  key={header.id}
                  header={header}
                  onClick={() => requestSort(header.column)}
                  sortConfig={sortConfig}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((message) => {
                return (
                  <tr
                    key={message.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td data-testid="kmanager-table-td">
                      <div>{message.id}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>{message.title}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>{message.body}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      {message.displayServiceType === 'BOTH' ? (
                        <>
                          <div>サービス画面</div>
                          <div>官公庁ナビ</div>
                        </>
                      ) : message.displayServiceType === 'SERVICE' ? (
                        <div>サービス画面</div>
                      ) : (
                        <div>官公庁ナビ</div>
                      )}
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>
                        {dateFormat(message.openAt, 'YYYY/MM/DD HH:mm')}
                      </div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>
                        {dateFormat(message.closeAt, 'YYYY/MM/DD HH:mm')}
                      </div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>
                        {message.severityType === 'NORMAL' ? '' : '重要'}
                      </div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      {isDeleteOpen && openModalId === message.id ? (
                        <Confirm
                          type="delete"
                          isOpen={isDeleteOpen}
                          closeModal={() => setIsDeleteOpen(false)}
                          Submit={() => deleteHandler(message.id)}
                        />
                      ) : (
                        ''
                      )}
                      {isEditOpen && openModalId === message.id ? (
                        <MessageFormModal
                          closeModal={() => setIsEditOpen(false)}
                          message={message}
                        />
                      ) : (
                        ''
                      )}
                      <div className="d-flex justify-center">
                        <Button
                          className="kmanager-common-button small text-no-wrap edit"
                          text={<PencilIcon />}
                          clickHandler={() => {
                            setOpenModalId(message.id);
                            setIsEditOpen(true);
                          }}
                        />
                        <Button
                          className="kmanager-common-button small text-no-wrap warning"
                          text={<DeleteIcon />}
                          clickHandler={() => {
                            setOpenModalId(message.id);
                            setIsDeleteOpen(true);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={managementMessages.length}
      />
    </div>
  );
};

export default MessageTable;
