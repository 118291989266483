import Button from 'interfaces/components/common/Button/Button';
import { useState } from 'react';
import useModalControl from 'shared/hooks/useModalControl';

import ResetPasswordModal from './ResetPasswordModal.jsx';

const ResetPasswordButton = () => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [modalType, setModalType] = useState();

  return (
    <div>
      <Button text="変更する" height="44px" onClick={handleOpen} />
      <ResetPasswordModal
        isOpen={isOpen}
        handleClose={handleClose}
        modalType={modalType}
        setModalType={setModalType}
      />
    </div>
  );
};
export default ResetPasswordButton;
