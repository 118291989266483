import Deadline from './Deadline.jsx';
import { depositToYen } from '../../helper/deposit';

import {
  PropertyInfo,
  PropertyInfoLabel,
  PropertyInfoValue,
} from 'interfaces/components/common/PropertyInfo/index';

const DepositBody = ({ data }) => {
  return (
    <div data-testid="deposit-body">
      <hr role="separator" aria-orientation="horizontal" className="divider" />
      <PropertyInfo className="flex justify-space-between align-center py-1">
        <PropertyInfoLabel className="ma-0">保証金</PropertyInfoLabel>
        <PropertyInfoValue
          className="ma-0 text-right text-caption"
          data-testid="property-deposit">
          {depositToYen(data.deposit)}
        </PropertyInfoValue>
      </PropertyInfo>
      <Deadline data={data} />
    </div>
  );
};

export default DepositBody;
