import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import { API_BASE_URL } from '../../config/envConstants.js';

export const createNaviInvitationData = async (body) => {
  return await axios.post(`${API_BASE_URL}/api/v1/navi/invitations`, body, {
    headers: apiHeaders(),
  });
};
export const updateNaviInvitationData = async (body, id) => {
  return await axios.put(
    `${API_BASE_URL}/api/v1/navi/invitations/${id}`,
    body,
    { headers: apiHeaders() },
  );
};
export const deleteNaviInvitationData = async (id) => {
  return await axios.delete(`${API_BASE_URL}/api/v1/navi/invitations/${id}`, {
    headers: apiHeaders(),
  });
};
