import { findById as getGovernment } from 'domain/Government/services/Government';
import { findByGovernmentId as getGovernmentUsers } from 'domain/User/services/GovernmentUser';
import { findById } from 'domain/User/services/GovernmentUser';
import { get as getManagementUser } from 'domain/User/services/ManagementUser';

import { useEffect, useState } from 'react';
import { useAuth } from 'shared/hooks/useAuth.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from 'shared/utils/helper/authState.js';
import { NAVI, KMANAGER } from 'shared/utils/helper/constants.js';

import { breadcrumbs } from '../entities/breadcrumbs.js';
import { UserContext } from '../entities/UserContext.js';

const UserProvider = ({ type, children }) => {
  // const { governmentId, governmentType, procedureType } = useAuth();
  const { procedureType } = useAuth();
  const { localStorageUserId } = useLocalStorage();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  // const [managementMessages, setManagementMessages] = useState([]);
  const [managementUsers, setManagementUsers] = useState([]);
  const [governmentUsers, setGovernmentUsers] = useState([]);
  const { localStorageGovernmentId } = useLocalStorage();
  const [governmentType, setGovernmentType] = useState('');
  const [loginUser, setLoginUser] = useState({});

  if (type === NAVI) {
    useEffect(() => {
      if (localStorageGovernmentId != '') {
        getGovernment(type, localStorageGovernmentId).then((result) => {
          setGovernmentType(result.governmentType);
        });
        getGovernmentUsers(type, localStorageGovernmentId).then(
          async (users) => {
            const data = await Promise.all(
              users.map(async (user) => {
                if (user.userType === 'CHILD' && !user.email) {
                  const createdUser = await findById(type, user.createdUser);
                  user.email = createdUser.email;
                }
                return user;
              }),
            );

            setGovernmentUsers(data);
          },
        );
      }
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getManagementUser(type).then((result) => {
        setManagementUsers(result);
        let loginUser = result.find((data) => data.id === +localStorageUserId);
        setLoginUser(loginUser);
      });
    }, [localStorageUserId]);
  }

  return (
    <UserContext.Provider
      value={{
        authState,
        breadcrumbs,
        //managementMessages,
        //governmentId,
        governmentType,
        procedureType,
        managementUsers,
        governmentUsers,
        loginUser,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
