import { useState, useEffect } from 'react';
//import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const BankProvisionalApplyCompletionForm = (isEdit) => {
  const [bankTransferUrlRequiredValid, setBankTransferUrlRequiredValid] = useState(isEdit ? "TRUE" : null);
  const [jointBiddingUrlValid, setJointBiddingUrlValid] = useState(isEdit ? "TRUE" : null);

  useEffect(() => {
    setBankTransferUrlRequiredValid(isEdit ? "TRUE" : null);
    setJointBiddingUrlValid(isEdit ? "TRUE" : null);
  }, [isEdit]);

  function handleBankTransferUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    invalid = Validation.validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setBankTransferUrlRequiredValid(invalid);
      return;
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateBankTransferUrlRequiredValidUrl(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBankTransferUrlRequiredValid(invalid);
        return;
      }
      invalid = Validation.validateBankTransferUrlRequiredLength(
        e.target.value,
      );
      if (invalid !== 'TRUE') {
        setBankTransferUrlRequiredValid(invalid);
        return;
      }
    }
    setBankTransferUrlRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleJointBiddingUrlValid(e) {
    // logInput(e.target.name, e.target.value);;
    let invalid = null;
    if (e.target.value.length > 0) {
      invalid = Validation.validateJointBiddingUrlValidUrl1(e.target.value);
      if (invalid !== 'TRUE') {
        setJointBiddingUrlValid(invalid);
        return;
      }
      invalid = Validation.validateJointBiddingUrlLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setJointBiddingUrlValid(invalid);
        return;
      }
    }
    setJointBiddingUrlValid(invalid); // エラーメッセージのリセット
  }


  const validations = {
    // validationの処理
    setters: {
      handleBankTransferUrlValid,
      handleJointBiddingUrlValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      bankTransferUrlRequiredValid,
      jointBiddingUrlValid,
    },
  };

  return validations;
};
