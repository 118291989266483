import { ReactComponent as ArrowRightIcon } from 'interfaces/assets/svg/arrow_back_icon.svg';
import BackButton from 'interfaces/components/common/BackButton';
import PageTitle from 'interfaces/components/common/PageTitle';
import { validateInputsGovernmentUserForm } from 'interfaces/components/common/validateInputs/validateInputsGovernmentUserForm';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import GovernmentUserConfirm from './GovernmentUserConfirm';
import GovernmentUserForm from './GovernmentUserForm';
import { GovernmentUserEntryContext } from '../../../pages/GovernmentUserEntry/entities/GovernmentUserEntryContext';
import { PROCEDURE_TYPE } from 'shared/utils/helper/constants';
import { KmanagerProcedureTypes } from 'shared/utils/constants/government';

const BACK_PATH = '/kmanager/gov_list';

const GovernmentUserCreate = () => {
  const { government, governmentId, procedureType } = useContext(
    GovernmentUserEntryContext,
  );
  const navigation = useNavigate();
  const [onConfirm, setOnConfirm] = useState(false);
  const createInputs = validateInputsGovernmentUserForm();
  const userConf = {
    governmentId: governmentId,
    procedureType: Object.values(KmanagerProcedureTypes).find(
      (procedure) => procedure.en === procedureType,
    ).string,
  };

  const backToList = () => {
    navigation(BACK_PATH);
  };
  return (
    <div
      className="kmanager-container"
      data-testid="kmanager-government-user-create">
      <div className="kmanager-row">
        <div className="kmanager-col kmanager-col-12">
          <BackButton
            text="行政機関管理"
            svg={<ArrowRightIcon title="back-to-page" />}
            handleClick={backToList}
          />
        </div>
        <div className="kmanager-col-6">
          <PageTitle
            text={`${government?.name}:${
              PROCEDURE_TYPE.find(
                (procedure) => procedure.value === government?.procedureType,
              )?.label
            }管理者ユーザー新規登録`}
          />
        </div>
        {onConfirm ? (
          <GovernmentUserConfirm
            type="create"
            data={createInputs}
            cancel={() => setOnConfirm(false)}
            userConf={userConf}
          />
        ) : (
          <GovernmentUserForm
            inputs={createInputs}
            toConfirm={() => setOnConfirm(true)}
            backToList={backToList}
          />
        )}
      </div>
    </div>
  );
};
export default GovernmentUserCreate;
