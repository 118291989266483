
export class CarBidFeeType {
  #carBidFeeType

  constructor(carBidFeeType) {
    this.#carBidFeeType = carBidFeeType;
  }

  get() {
    return this.#carBidFeeType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
