import Button from 'interfaces/components/common/Button/Button';
import { useEffect, useState } from 'react';
import { useError } from 'shared/hooks/useError';

import { putEmailConfirm } from 'domain/User/services/ServiceUser.js';
import handleValidationMessage from 'shared/utils/helper/handleValidationMessage';
import {
  validateConfirmNumber,
  validateConfirmNumLength,
} from 'shared/utils/helper/serviceValidators';

import ConfirmNumberFormLinks from './ConfirmNumberFormLinks';
import ResetFormInput from '../ResetFormInput';



//import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import './css/confirm-number-form.css';

const ConfirmNumberForm = ({ handleClose, setModalType, targetEmail }) => {
  const { setError } = useError();
  const [confirmNum, setConfirmNum] = useState('');
  const [validateMessage, setValidateMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (validateMessage === 'TRUE') {
      setIsDisabled(false);
      return;
    }
    setIsDisabled(true);
  }, [validateMessage]);

  const handleValidate = (value) => {
    let result;
    result = validateConfirmNumber(value);
    if (result !== 'TRUE') return result;
    return validateConfirmNumLength(value);
  };

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, '');
    setConfirmNum(value);
    setValidateMessage(handleValidate(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isDisabled) {
      return;
    }

    try {
      await putEmailConfirm(targetEmail, confirmNum);
      setModalType('COMPLETE');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <div className="confirm-number-form" data-testid="confirm-number-form">
      <form noValidate onSubmit={handleSubmit}>
        <ResetFormInput
          label="確認コード"
          type="text"
          value={confirmNum}
          handleChange={handleChange}
          validateMessage={handleValidationMessage(validateMessage)}
        />
        <ConfirmNumberFormLinks
          targetEmail={targetEmail}
          setModalType={setModalType}
        />
        <div className="flex justify-center mt-10">
          <Button
            text="キャンセル"
            height="44px"
            width="100px"
            className="cancel-button"
            type="button"
            onClick={handleClose}
          />
          <Button
            text="次へ"
            height="44px"
            width="100px"
            className="mx-2"
            type="submit"
            isDisabled={isDisabled}
          />
        </div>
      </form>
    </div>
  );
};

export default ConfirmNumberForm;
