import { useEffect, useState } from 'react';

import { get as getGovernmentTopPages } from '../../../../domain/Setting/services/GovernmentTopPage.js';
import { useAuth } from '../../../../shared/hooks/useAuth.js';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementTopContext } from '../entities/LocalGovernmentManagementTopContext.js';
const LocalGovernmentManagementTopProvider = ({ type, children }) => {
  const { governmentId, governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [governmentTopPages, setGovernmentTopPages] = useState([]);
  const [auctionData, setAuctionData] = useState(null);
  const [bidData, setBidData] = useState(null);
  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getGovernmentTopPages(type).then((result) => {
        setGovernmentTopPages(result);
      });
    }, []);
  }

  if (type === NAVI) {
    useEffect(() => {
      getGovernmentTopPages(type).then((result) => {
        setGovernmentTopPages(result);
        setAuctionData(
          result?.find((object) => object?.publicSaleType === 'AUCTION'),
        );
        setBidData(result?.find((object) => object?.publicSaleType === 'BID'));
      });
    }, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getGovernmentTopPages(type).then((result) => {
        setGovernmentTopPages(result);
      });
    }, []);
  }

  return (
    <LocalGovernmentManagementTopContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        governmentTopPages,
        auctionData,
        bidData,
      }}>
      {children}
    </LocalGovernmentManagementTopContext.Provider>
  );
};

export default LocalGovernmentManagementTopProvider;
