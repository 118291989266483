import Button from 'interfaces/components/common/Button';
import {
  checkbox_label,
  checkbox_label_recommend,
} from 'interfaces/components/common/CheckboxLabel';
import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormImage from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormImage';
import {
  procedure_asset,
  procedure_pub,
  radio_label,
  radio_label2,
  radio_label3,
  radio_label4,
} from 'interfaces/components/common/RadioLabel';
import {
  property_info_form_asset,
  property_info_form_pub,
} from 'interfaces/components/common/TableLabels';
import GeneralStateInputCheck from 'interfaces/components/NewEntry/GeneralStateInputCheck';
import { useContext } from 'react';
import {
  caution_description1,
  caution_description10,
  caution_description11,
  caution_description12,
  caution_description13,
  caution_description_char_50,
  caution_description15,
  caution_description17,
  caution_description18,
  caution_description19,
  caution_description23,
  caution_description2,
  caution_description20,
  caution_description3,
  caution_description4,
  caution_description5,
  caution_description6,
  caution_description7,
  caution_description8,
  caution_description9,
  resolution_date,
  //caution_category,
} from 'shared/utils/helper/DummyEditFormData';

import 'interfaces/css/common/property-info-general-form.css';
import { PropertyInfoRealEstateContext } from '../../../../pages/PropertyInfoRealEstate/entities/PropertyInfoRealEstateContext';
import FormCategory from '../../../Form/navi/FormCategory';
import FormCheckboxWithCaution from '../../../Form/navi/FormCheckboxWithCaution';
import FormDateTimeWithCaution from '../../../Form/navi/FormDateTimeWithCaution';
import FormMarkDownWithCaution from '../../../Form/navi/FormMarkDownWithCaution';
import FormNumberWithCaution from '../../../Form/navi/FormNumberWithCaution';
import FormPullDownWithCaution from '../../../Form/navi/FormPullDownWithCaution';
import FormRadioWithCaution from '../../../Form/navi/FormRadioWithCaution';
import FormTelWithCaution from '../../../Form/navi/FormTelWithCaution';
import FormTextWithCaution from '../../../Form/navi/FormTextWithCaution';

import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { ProcedureTypes } from 'shared/utils/constants/government';

const PropertyInfoGeneralForm = ({
  formData,
  procedureType,
  handleClick,
  isValid,
}) => { 
  console.log(formData)
  const navigate = useNavigate();
  const labels =
    procedureType === ProcedureTypes.PUBLIC_SALE.string
      ? property_info_form_pub
      : property_info_form_asset;
  const radio_labels =
    procedureType === ProcedureTypes.PUBLIC_SALE.string ? procedure_pub : procedure_asset;

  const { divisions, selectedCategory } = useContext(
    PropertyInfoRealEstateContext,
  );

  const { localStorageGovernmentType } = useLocalStorage();

  const handleClear = (e) => {
    e.preventDefault();
    Object.values(formData.setters).forEach((setter) => {
      if (setter.name === "handleChangeDeposit") {
        setter({ target: { value: 0 } }, "BOTH")
      } else if (setter.name === "handleChangePaymentMethod") {
        setter({bankTransfer: false, carry: false, check: false, postalMoneyOrder: false, registeredMail: false})
      } else if (setter.name === "handleChangeDescription") {
        setter('')
      } else {
        setter({ target: { value: '' } })
      }
    });
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/navi/');
  };

  return (
    <form>
      <div className="property-info-general-form-table">
        <FormRadioWithCaution
          caution_description={caution_description1}
          label={'公開設定'}
          radio_label={radio_label}
          data={formData.data.isDraft}
          handler1={formData.setters.handleChangeIsDraft}
        />
      </div>
      <div className="pt-9">
        <Heading3 text="1. 画像の選択" />
        <PropertyInfoGeneralFormImage label={'画像データ'} />
      </div>
      <div className="pt-9">
        <Heading3 text="2. 物件情報" />
        <div className="property-info-general-form-table">
          {localStorageGovernmentType === 'NATIONAL_TAX_AGENCY' ? (
            <></>
          ) : (
            <FormRadioWithCaution
              label={'出品形式'}
              radio_label={radio_label2}
              procedureType={procedureType}
              data={formData.data.publicSaleType}
              exhibitData={formData.data.isNextBuyApl}
              handler1={formData.setters.handleChangePublicSaleType}
              handler2={formData.setters.handleChangeIsNextBuyApl}
            />
          )}
          <FormNumberWithCaution
            caution_description={caution_description2}
            invalidMessage={formData.validationMessage.printOrderValid}
            handler1={formData.setters.handleChangePrintOrder}
            min={1}
            label={'掲載順位'}
            state={formData.data.printOrder}
            width={'6em'}
          />
          <FormCategory
            data={selectedCategory}
            handler={formData.setters.handleChangeCategoryId}
            invalidMessage={formData.validationMessage.categoryIdValid}
            label={'カテゴリ'}
            propertyType={'REAL_ESTATE'}
          />
          <GeneralStateInputCheck
            checkbox_label={checkbox_label_recommend}
            caution_description={caution_description3}
            label={'イチ押し設定'}
            data={formData.data.recommendProperties}
            stateHandler={formData.setters.handleChangeRecommendProperties}
          />
          <FormPullDownWithCaution
            caution_description={caution_description4}
            formStateHandler={formData.setters.handleChangeDivisionId}
            label={'執行機関名称'}
            listItemData={divisions}
            selectedData={
              formData.data.divisionId === '' ||
              formData.data.divisionId === undefined
                ? divisions[0]
                : divisions.find(
                  (data) =>
                    formData.data.divisionId.toString() ===
                    data.id.toString(),
                )
            }
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.manageIdRequiredValid}
            label={'売却区分番号'}
            caution_description={caution_description5}
            maxCharacters={30}
            required={true}
            data={formData.data.saleNumber}
            handler1={formData.setters.handleChangeSaleNumber}
            width={'80'}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.titleRequiredValid}
            label={'タイトル'}
            caution_description={caution_description6}
            maxCharacters={50}
            required={true}
            //validationType={['ZenKatakanaOnly']}
            data={formData.data.name}
            handler1={formData.setters.handleChangeName}
            // onBlurの機能が必要
          />
          <FormCheckboxWithCaution
            checkbox_label={checkbox_label}
            caution_description={caution_description7}
            label={'支払い方法'}
            className={'payment-method'}
            required={true}
            data={formData.data.paymentMethod}
            handler={(value) => {
              formData.setters.handleChangePaymentMethod({
                ...formData.data.paymentMethod,
                ...value,
              })
            }}
            isHorizon={false}
          />
          {procedureType === ProcedureTypes.PUBLIC_SALE.string ? (
            <FormDateTimeWithCaution
              caution_description={caution_description19}
              date={formData.data.paymentDeadline}
              handleChange={formData.setters.handleChangePaymentDeadline}
              label={'買受代金納付期限'}
              required={true}
              invalidMessage={formData.validationMessage.paymentDeadlineRequiredValid}
            />
          ) : (
            <>
              <FormTextWithCaution
                invalidMessage={formData.validationMessage.resolutionDateValid}
                label={'議決日'}
                caution_description={resolution_date}
                maxCharacters={30}
                data={formData.data.resolutionDate}
                handler1={formData.setters.handleChangeResolutionDate}
                width={'80'}
              />
              <FormDateTimeWithCaution
                label={'売払代金納付期限'}
                caution_description={caution_description23}
                required={true}
                handleChange={formData.setters.handleChangePaymentDeadline}
                date={formData.data.paymentDeadline}
              />
              <FormDateTimeWithCaution
                label={'契約締結期限'}
                date={formData.data.contractedLimit}
                handleChange={formData.setters.handleChangeContractedLimit}
              />
            </>
          )}
          <FormTextWithCaution
            label={'動画URL'}
            caution_description={caution_description8}
            data={formData.data.videoUrl}
            handler1={formData.setters.handleChangeVideoUrl}
            invalidMessage={formData.validationMessage.videoUrlValid}
          />
          <FormMarkDownWithCaution
            label={'説明'}
            required={true}
            number_remaining_characters={
              formData.data.description !== undefined
                ? 15000 - formData.data.description.length
                : 15000
            }
            caution_description={caution_description20}
            data={formData.data.description}
            handler1={formData.setters.handleChangeDescription}
            invalidMessage={formData.validationMessage.descriptionValid}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="3. 物件に関するお問い合わせ先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.inquiryNameValid}
            label={'出品行政機関名'}
            caution_description={caution_description9}
            maxCharacters={30}
            data={formData.data.inquiryName}
            handler1={formData.setters.handleChangeInquiryName}
            width={'80'}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.inquiryDepartmentValid}
            label={'担当部署名'}
            caution_description={caution_description10}
            maxCharacters={50}
            data={formData.data.inquiryOffice}
            handler1={formData.setters.handleChangeInquiryOffice}
          />
          <FormTextWithCaution
            label={'メールアドレス'}
            maxCharacters={50}
            caution_description={caution_description11}
            data={formData.data.inquiryEmail}
            handler1={formData.setters.handleChangeInquiryEmail}
            invalidMessage={formData.validationMessage.inquiryEmailValid}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.inquiryExtension}
            extensionMessage={formData.validationMessage.inquiryExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.inquiryTelValid}
            invalid1Message={
              formData.validationMessage.auctionInquiryTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionInquiryTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionInquiryTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeInquiryExtension}
            setTelData={formData.setters.handleChangeInquiryTel}
            setTel1Data={formData.setters.handleChangeInquiryTelNum1}
            setTel2Data={formData.setters.handleChangeInquiryTelNum2}
            setTel3Data={formData.setters.handleChangeInquiryTelNum3}
            telData={formData.data.inquiryTel}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.inquiryAcceptValid}
            label={'電話受付時間'}
            maxCharacters={100}
            caution_description={caution_description13}
            data={formData.data.inquiryAccept}
            handler1={formData.setters.handleChangeInquiryAccept}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.noteValid}
            label={'備考'}
            caution_description={caution_description_char_50}
            maxCharacters={50}
            data={formData.data.inquiryNote}
            handler1={formData.setters.handleChangeInquiryNote}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="4. 物件の詳細設定" />
        <div className="property-info-general-form-table">
          {/*
          <FormRadioWithCaution
            label={'保証金納付手続き'}
            radio_label={radio_labels}
            class_name={'procedure'}
            data={formData.data.depositPayment}
            handler1={formData.setters.handleChangeDepositPayment}
          />
          */}
          <FormRadioWithCaution
            class_name={'procedure'}
            data={formData.data.depositPayment}
            handler1={(e) => {
              formData.setters.handleChangeDepositPayment(e);
              if (e.target.value === 'NONE') {
                formData.setters.handleChangeDeposit({target: {value: 0}}, 'NONE');
              } else {
                formData.setters.handleChangeDeposit({target: {value: ''}}, e.target.value);
              }
            }}
            label={'保証金納付手続き'}
            radio_label={radio_labels}
          />
          {procedureType === ProcedureTypes.PUBLIC_SALE.string && (formData.data.depositPayment === "BOTH" || formData.data.depositPayment === "CREDIT_CARD") ? (
            <FormRadioWithCaution
              caution_description={caution_description15}
              label={'参加申し込み承認'}
              radio_label={radio_label3}
              data={formData.data.isApprovalRequired}
              handler1={formData.setters.handleChangeIsApprovalRequired}
            />
          ) : (
            <></>
          )}
          <FormNumberWithCaution
            invalidMessage={
              formData.validationMessage.estimatePriceRequiredValid
            }
            label={labels[0]}
            caution_description={caution_description12}
            required={true}
            width={'16em'}
            state={formData.data.estimatePrice}
            handler1={formData.setters.handleChangeEstimatePrice}
            afterFormWord={'円'}
            min={1}
          />
          <FormNumberWithCaution
            afterFormWord={'円'}
            caution_description={caution_description17}
            handler1={(e) =>
              formData.setters.handleChangeDeposit(
                e,
                formData.data.depositPayment,
              )
            }
            invalidMessage={formData.validationMessage.depositRequiredValid}
            isDisabled={formData.data.depositPayment === 'NONE' ? true : false}
            label={labels[1]}
            required={true}
            state={formData.data.deposit}
            width={'16em'}
          />
          <FormRadioWithCaution
            caution_description={caution_description18}
            label={'適格請求書'}
            radio_label={radio_label4}
            data={formData.data.qualifiedInvoice}
            handler1={formData.setters.handleChangeQualifiedInvoice}
          />
        </div>
      </div>
      <div className="pt-9">
        <Heading3 text="5. 落札後の連絡先" />
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.contactNameValid}
            label={'連絡先機関名'}
            caution_description={caution_description_char_50}
            maxCharacters={50}
            required={true}
            data={formData.data.contactName}
            handler1={formData.setters.handleChangeContactName}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.contactDepartmentValid}
            label={'担当部署名'}
            caution_description={caution_description_char_50}
            maxCharacters={50}
            data={formData.data.contactOffice}
            handler1={formData.setters.handleChangeContactOffice}
          />
          <FormTextWithCaution
            label={'メールアドレス'}
            maxCharacters={50}
            caution_description={caution_description11}
            data={formData.data.contactEmail}
            handler1={formData.setters.handleChangeContactEmail}
            invalidMessage={formData.validationMessage.contactEmailValid}
          />
          <FormTelWithCaution
            caution_description={caution_description12}
            extensionData={formData.data.contactExtension}
            extensionMessage={formData.validationMessage.contactExtensionValid}
            hasExtension={true}
            invalidMessage={formData.validationMessage.contactTelValid}
            invalid1Message={
              formData.validationMessage.auctionContactTelNum1Valid
            }
            invalid2Message={
              formData.validationMessage.auctionContactTelNum2Valid
            }
            invalid3Message={
              formData.validationMessage.auctionContactTelNum3Valid
            }
            label={'電話番号'}
            setExtensionData={formData.setters.handleChangeContactExtension}
            setTelData={formData.setters.handleChangeContactTel}
            setTel1Data={formData.setters.handleChangeContactTelNum1}
            setTel2Data={formData.setters.handleChangeContactTelNum2}
            setTel3Data={formData.setters.handleChangeContactTelNum3}
            telData={formData.data.contactTel}
          />
          <FormTextWithCaution
            invalidMessage={formData.validationMessage.contactAcceptValid}
            label={'電話受付時間'}
            maxCharacters={50}
            caution_description={caution_description_char_50}
            data={formData.data.contactAccept}
            handler1={formData.setters.handleChangeContactAccept}
          />
        </div>
      </div>
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'確認する'}
              isOutline={false}
              height={'44px'}
              clickHandler={handleClick}
              className={`property-info-general-form-button ${
                isValid
                  ? 'new-style_navi_primary-button'
                  : 'new-style_navi_disabled-button'
              }`}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleCancel}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'クリア'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              clickHandler={handleClear}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default PropertyInfoGeneralForm;
