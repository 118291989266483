import {API_BASE_URL} from "../../config/envConstants.js";
import { apiRequest } from '../../shared/utils/helper/apiRequest.js';

export const createNaviCreditCardPagesData = async (body) => {
  console.log(body);
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/navi/credit-card-pages`,
    body,
  );
};

export const updateNaviCreditCardPagesData = async (body) => {
  console.log(body);
  return await apiRequest(
    'put',
    `${API_BASE_URL}/api/v1/navi/credit_card_page`,
    body,
  );
};

export const deleteNaviCreditCardPagesData = async (id) => {
  return await apiRequest(
    'delete',
    `${API_BASE_URL}/api/v1/navi/credit-card-pages/${id}`,
  );
};