import {
  useBankProvisionalApplyCompletionInputs
} from "shared/utils/helper/useBankProvisionalApplyCompletionInputs.jsx";

import { BankProvisionalApplyCompletionForm } from "../BankProvisionalApplyCompletionForm.jsx";

export const validateInputsBankProvisionalApplyCompletion = (data) => {
  const formInputs = useBankProvisionalApplyCompletionInputs(data);
  const validate = BankProvisionalApplyCompletionForm(data !== null);

  function handleBankTransferUrl(e) {
    validate.setters.handleBankTransferUrlValid(e)
    formInputs.setters.setBankTransferUrl(e.target.value)
  }

  function handleJointBiddingUrl(e) {
    validate.setters.handleJointBiddingUrlValid(e)
    formInputs.setters.setJointBiddingUrl(e.target.value)
  }

  const validateInputs = {
    data: formInputs.data,
    setters: {
      handleBankTransferUrl,
      handleJointBiddingUrl,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  }

  return validateInputs
}