import { createContext } from 'react';
import { DATA_EMPTY } from 'shared/utils/constants/emptyData.js';

export const GovernmentContext = createContext({
  // Default Value
  auction: DATA_EMPTY,
  allowProperties: DATA_EMPTY,
  cautionPages: [],
  government: DATA_EMPTY,
  governmentTopPages: DATA_EMPTY,
  governments: {},
  guidelines: [],
  isLoggedIn: DATA_EMPTY,
  invitations: DATA_EMPTY,
  managementMessages: DATA_EMPTY,
  procedureType: DATA_EMPTY,
  publicSaleBidGovernments: DATA_EMPTY,
  publicSaleAuctionGovernments: DATA_EMPTY,
  queryParams: DATA_EMPTY,
  responsive: DATA_EMPTY,
  userId: DATA_EMPTY,
  proposalCount: DATA_EMPTY,
});
