
export class CharacterInsteadEmblem {
  #characterInsteadEmblem

  constructor(characterInsteadEmblem) {
    this.#characterInsteadEmblem = characterInsteadEmblem;
  }

  get() {
    return this.#characterInsteadEmblem
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
