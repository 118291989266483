import {API_BASE_URL} from "../../config/envConstants.js";
import { apiRequest } from '../../shared/utils/helper/apiRequest.js';

export const createNaviGovernmentTopPagesData = async (body) => {
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/navi/government_top_page`,
    body,
  );
};

export const updateNaviGovernmentTopPagesData = async (body) => {
  console.log(body);
  return await apiRequest(
    'put',
    `${API_BASE_URL}/api/v1/navi/government_top_page`,
    body,
  );
};
export const deleteNaviGovernmentTopPagesData = async (id) => {
  return await apiRequest(
    'delete',
    `${API_BASE_URL}/api/v1/navi/government_top_page/${id}`,
  );
};