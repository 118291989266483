import { useContext } from 'react';
import { GovernmentContext } from 'interfaces/pages/Government/entities/GovernmentContext.js';
import { styled } from 'styled-components';

const TotalApplication = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
  margin-bottom: 16px;
  display: flex;
`;

const TotalApplicationLabel = styled.span`
  margin-right: 20px;
`;

const Partition = styled.span`
  width: 1px;
  background-color: var(--line-base);
  margin: 2px 15px;
`;

const TotalApplications = () => {
  const { proposalCount } = useContext(GovernmentContext);

  let aplCountAuctionDisplay =
    proposalCount.auctionCount !== 0 ? 'DISPLAY' : 'HIDDEN';
  let aplCountBidDisplay = proposalCount.bidCount !== 0 ? 'DISPLAY' : 'HIDDEN';

  return aplCountAuctionDisplay !== 'DISPLAY' &&
    aplCountBidDisplay !== 'DISPLAY' ? null : (
    <TotalApplication>
      <TotalApplicationLabel>参加申し込み総数：</TotalApplicationLabel>
      {aplCountAuctionDisplay !== 'DISPLAY' ? null : (
        <span>せり売形式{proposalCount.auctionCount}件</span>
      )}
      {aplCountAuctionDisplay === 'DISPLAY' &&
        aplCountBidDisplay === 'DISPLAY' && <Partition />}
      {aplCountBidDisplay !== 'DISPLAY' ? null : (
        <span>入札形式{proposalCount.bidCount}件</span>
      )}
    </TotalApplication>
  );
};

export default TotalApplications;
