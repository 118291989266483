import { useContext, useEffect, useState } from 'react';
import { DashboardContext } from '../../../../../../pages/Dashboard/entities/DashboardContext.js';

import TopicCard from '../../../../../common/TopicCard.jsx';
import HeldInfo from './HeldInfo.jsx';
import DeadlineInfo from './DeadlineInfo.jsx';

import { TextCaption } from 'interfaces/components/common/TextCaption/index';

const ScheduleTopic = () => {
  const { progressSchedules } = useContext(DashboardContext);

  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;

  let beforeLimit = new Date(now.getTime());
  beforeLimit.setDate(beforeLimit.getDate() - 50);

  let beforeLimitYear = beforeLimit.getFullYear();
  let beforeLimitMonth = (beforeLimit.getMonth() + 1)
    .toString()
    .padStart(2, '0');
  let beforeLimitDay = beforeLimit.getDate().toString().padStart(2, '0');
  let beforeLimitHours = beforeLimit.getHours().toString().padStart(2, '0');
  let beforeLimitMinutes = beforeLimit.getMinutes().toString().padStart(2, '0');
  let beforeLimitDayString = `${beforeLimitYear}-${beforeLimitMonth}-${beforeLimitDay} ${beforeLimitHours}:${beforeLimitMinutes}`;

  const [isApplying, setIsApplying] = useState(false);

  useEffect(() => {
    progressSchedules?.some((schedule) => {
      if (
        beforeLimitDayString <= schedule.aplStart &&
        schedule.aplStart <= nowString &&
        nowString <= schedule.aplEnd
      ) {
        setIsApplying(true);
        return true;
      }
    });
  }, [progressSchedules]);

  return (
    <div data-testid="schedule-topic">
      <TopicCard
        title="スケジュール"
        body={
          <TextCaption>
            <HeldInfo auctionStatus={isApplying} />
            {progressSchedules?.length && <DeadlineInfo />}
          </TextCaption>
        }
      />
    </div>
  );
};

export default ScheduleTopic;
