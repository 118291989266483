import { useEffect, useState } from 'react';

import { AuctionFooterContext } from '../entities/AuctionFooterContext.js';
import { get } from 'domain/Category/services/PropertyCategory.js';

const AuctionFooterProvider = ({ children }) => {
  const [realEstateLinkItems, setRealEstateLinkItems] = useState([]);
  const [carLinkItems, setCarLinkItems] = useState([]);
  const [generalLinkItems, setGeneralLinkItems] = useState([]);

  // フッターリンク生成用のカテゴリー作成
  const createGategoryData = (categories) => {
    let categoryData = [];

    const parentCategories = categories.filter(
      (data) => data.depth === '1' && data.displayService === 'DISPLAY',
    );

    parentCategories.forEach((category) => {
      let parentCategory = {
        childCategory: [],
        propertyIds: [],
        relationShipCategoryIds: [],
      };
      parentCategory.depth = category.depth;
      parentCategory.displayCar = category.displayCar;
      parentCategory.displayEstate = category.displayEstate;
      parentCategory.displayProperty = category.displayProperty;
      parentCategory.displayService = category.displayService;
      parentCategory.id = category.id;
      parentCategory.name = category.name;
      parentCategory.parentId = '0';
      parentCategory.relationShipCategoryIds.push(parentCategory.id);

      let childCategories = categories.filter(
        (data) =>
          data.parentId === parentCategory.id &&
          data.depth === '2' &&
          data.displayService === 'DISPLAY',
      );

      childCategories.forEach((cCategory) => {
        let childCategory = {
          childCategory: [],
          propertyIds: [],
          relationShipCategoryIds: [],
        };
        childCategory.depth = cCategory.depth;
        childCategory.displayService = cCategory.displayService;
        childCategory.id = cCategory.id;
        childCategory.name = cCategory.name;
        childCategory.parentId = cCategory.parentId;
        parentCategory.childCategory.push(childCategory);
        parentCategory.relationShipCategoryIds.push(childCategory.id);

        let grandchildCategories = categories.filter(
          (data) =>
            data.parentId === childCategory.id &&
            data.depth === '3' &&
            data.displayService === 'DISPLAY',
        );

        grandchildCategories.forEach((gCategory) => {
          let grandchildCategory = {
            childCategory: [],
            propertyIds: [],
            relationShipCategoryIds: [],
          };
          grandchildCategory.depth = gCategory.depth;
          grandchildCategory.displayService = gCategory.displayService;
          grandchildCategory.id = gCategory.id;
          grandchildCategory.name = gCategory.name;
          grandchildCategory.parentId = gCategory.parentId;
          grandchildCategory.relationShipCategoryIds.push(
            grandchildCategory.id,
          );
          childCategory.childCategory.push(grandchildCategory);
          childCategory.relationShipCategoryIds.push(grandchildCategory.id);
          parentCategory.relationShipCategoryIds.push(grandchildCategory.id);
        });
      });
      categoryData.push(parentCategory);
    });

    return categoryData;
  };

  useEffect(() => {
    get('SERVICE').then((propertyCategories) => {
      // 不動産
      let categoryData = createGategoryData(propertyCategories);

      let realEstateCategory = categoryData.find(
        (propertyCategory) => propertyCategory.name === '不 動 産',
      );

      let realEstateLinkItems = {};
      ['マンション', '土地', '一戸建て', 'その他'].forEach((category) => {
        let propertyCategory = realEstateCategory?.childCategory?.find(
          (cat) => cat.name === category,
        );
        realEstateLinkItems[category] =
          `/search?category=ESTATE&searchPropertyCategoryId=${propertyCategory?.relationShipCategoryIds?.join(',')}`;
      });
      setRealEstateLinkItems(realEstateLinkItems);

      // 自動車
      let carCategory = categoryData.find(
        (propertyCategory) => propertyCategory.name === '自動車、オートバイ',
      );

      let carLinkItems = {};
      ['救急車', '消防車', 'バス', 'その他'].forEach((category) => {
        if (category === 'その他') {
          let propertyCategories = carCategory?.childCategory?.filter((cat) =>
            ['キャンピングカー', '部品取り車'].includes(cat.name),
          );
          let ids = propertyCategories?.map((cat) =>
            cat.relationshipCategoryIds?.join(','),
          );
          carLinkItems[category] =
            `/search?category=CAR&searchPropertyCategoryId=${ids?.join(',')}`;
        } else {
          let propertyCategory = carCategory?.childCategory?.find(
            (cat) => cat.name === category,
          );
          carLinkItems[category] =
            `/search?category=CAR&searchPropertyCategoryId=${propertyCategory?.relationShipCategoryIds?.join(',')}`;
        }
      });
      setCarLinkItems(carLinkItems);

      // 人気のカテゴリ
      let generalLinkItems = {};
      [
        'コンピュータ',
        '家電、AV、カメラ',
        '音楽',
        '本、雑誌',
        '映画、ビデオ',
      ].forEach((category) => {
        const propertyCategory = categoryData?.find(
          (propertyCategory) => propertyCategory.name === category,
        );
        generalLinkItems[category] =
          `/search?searchPropertyCategoryId=${propertyCategory?.relationShipCategoryIds?.join(',')}`;
      });
      setGeneralLinkItems(generalLinkItems);
    });
  }, []);

  return (
    <AuctionFooterContext.Provider
      value={{ realEstateLinkItems, carLinkItems, generalLinkItems }}>
      {children}
    </AuctionFooterContext.Provider>
  );
};

export default AuctionFooterProvider;
