// import FormTextWithCaution from 'interfaces/components/Form/navi/FormTextWithCaution';
import FormPullDownWithCaution from 'interfaces/components/Form/navi/FormPullDownWithCaution';
import FormDateTimeWithCaution from 'interfaces/components/Form/navi/FormDateTimeWithCaution';
import 'interfaces/css/PropertyList/input-infomation-table.css';

const CopyForm = ({ invitations }) => {
  console.log(invitations);
  const options = invitations.map((invitation) => ({
    id: invitation.id,
    text: invitation.name,
    value: invitation.id,
  }));

  return (
    <div
      className="input-information-table"
      data-testid="copy-dialog-info-table">
      <FormPullDownWithCaution
        label={'登録先'}
        caution_description={[
          {
            id: 1,
            text: '以下の物件データを登録する公告を選択してください。',
            marked: false,
          },
          {
            id: 2,
            text: '選択した公告にコピーしたデータを登録します。',
            marked: false,
          },
        ]}
        listItemData={options}
        selectedData={options[0]}
        formStateHandler={() => {}}
        width={'100%'}
      />
      <FormDateTimeWithCaution
        caution_description={[
          {
            id: 1,
            text: '以下の物件データを登録する公告を選択してください。',
            marked: false,
          },
        ]}
        date={''}
        handleChange={() => {}}
        label={'買受代金納付期限'}
      />
    </div>
  );
};

export default CopyForm;
