import { ReactComponent as DeleteIcon } from 'interfaces/assets/svg/delete.svg';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import Button from 'interfaces/components/common/Button';
import Confirm from 'interfaces/components/common/Confirm';
import Pagination from 'interfaces/components/common/Pagination.jsx';
import { useContext, useState, useEffect } from 'react';
import { useSortableData } from 'shared/hooks/useSort.js';
import usePagination from 'shared/hooks/usePagination';
import { SortableTh } from 'interfaces/components/common/Table/Th/SortableTh';
import AdvanceNoticeFormModal from './AdvanceNoticeFormModal';
import NoticeFormModal from './NoticeFormModal';
import { NewsContext } from '../../../pages/News/entities/NewsContext';
import KmanagerTableEmptyRecord from '../../common/Table/KmanagerTableEmptyRecord';

import 'interfaces/css/common/kmanager-table.css';
import 'interfaces/css/common/kmanager-table-th.css';
import { dateFormat } from 'shared/utils/helper/date_format';

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

export const headers = [
  { id: 1, column: 'id', text: 'No' },
  { id: 2, column: 'title', text: 'タイトル' },
  { id: 3, column: 'openAt', text: '公開日時' },
  { id: 4, column: 'displaySystem', text: '表示先システム' },
  { id: 5, column: 'isImportant', text: '重要度' },
  { id: 6, text: '編集' },
];

const KmanagerTable = ({ searchInput }) => {
  const { notices } = useContext(NewsContext);
  const [filteredNotices, setFilteredNotices] = useState(notices);
  const {
    items: sortedNotices,
    requestSort,
    sortConfig,
  } = useSortableData(filteredNotices);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { currentPage, currentItems, pageNumbers, setCurrentPage } =
    usePagination(sortedNotices, rowsPerPage);

  useEffect(() => {
    setFilteredNotices(
      notices.filter((notice) => notice.title.includes(searchInput)),
    );
  }, [searchInput, notices]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [openModalId, setOpenModalId] = useState(null);

  const deleteHandler = async (id) => {
    await apiRequest(
      'delete',
      `${API_BASE_URL}/api/v1/kmanager/notices/${id}`,
      null,
    );

    setIsDeleteOpen(false);
  };

  return (
    <div className="kmanager-auction-operation">
      <div className="kmanager-table-wrap">
        <table
          className="kmanager-table kmanager-table-hover"
          data-testid="kmanager-table">
          <thead data-testid="kmanager-table-column">
            <tr data-testid="kmanager-tr">
              {headers.map((header) => {
                return (
                  <SortableTh
                    key={header.id}
                    header={header}
                    onClick={() => requestSort(header.column)}
                    sortConfig={sortConfig}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortedNotices.length > 0 ? (
              currentItems.map((notice) => {
                return (
                  <tr
                    key={notice.id}
                    className="kmanager-table-record"
                    data-testid="kmanager-table-record">
                    <td data-testid="kmanager-table-td">
                      <div>{notice.id}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>{notice.title}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>{dateFormat(new Date(notice.openAt))}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      {notice.isDisplayService === 'TRUE' &&
                      notice.isFarmlandInfo !== 'TRUE' ? (
                        <div>サービス画面</div>
                      ) : (
                        ''
                      )}
                      {notice.isDisplayNavi === 'TRUE' &&
                      notice.isFarmlandInfo !== 'TRUE' ? (
                        <div>ナビ</div>
                      ) : (
                        ''
                      )}
                    </td>
                    <td data-testid="kmanager-table-td">
                      <div>{notice.isImportant === 'FALSE' ? '' : '重要'}</div>
                    </td>
                    <td data-testid="kmanager-table-td">
                      <Confirm
                        type="delete"
                        isOpen={isDeleteOpen}
                        closeModal={() => setIsDeleteOpen(false)}
                        Submit={() => deleteHandler(notice.id)}
                      />
                      {isEditOpen &&
                      notice.isFarmlandInfo === 'FALSE' &&
                      openModalId === notice.id ? (
                        <NoticeFormModal
                          closeModal={() => setIsEditOpen(false)}
                          notice={notice}
                        />
                      ) : (
                        ''
                      )}
                      {isEditOpen &&
                      notice.isFarmlandInfo === 'TRUE' &&
                      openModalId === notice.id ? (
                        <AdvanceNoticeFormModal
                          closeModal={() => setIsEditOpen(false)}
                          data={notice}
                        />
                      ) : (
                        ''
                      )}
                      <div className="d-flex justify-center">
                        <Button
                          className="kmanager-common-button small text-no-wrap edit"
                          text={<PencilIcon />}
                          clickHandler={() => {
                            setOpenModalId(notice.id);
                            setIsEditOpen(true);
                          }}
                        />
                        <Button
                          className="kmanager-common-button small text-no-wrap warning"
                          text={<DeleteIcon />}
                          clickHandler={() => setIsDeleteOpen(true)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <KmanagerTableEmptyRecord />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageNumbers.length}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        DATA_COUNT={notices.length}
      />
    </div>
  );
};

export default KmanagerTable;

{
  /* ここからモバイルのテーブル
      <div className="kmanager-mobile">
        <div className="kmanager-table-wrap">
          <div className="kmanager-form">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="new-style_input-pulldown" style={{ position: 'relative' }}>
                      <div style={{ position: 'relative' }}>
                        <div className="property-list-selector text-field-outlined">
                          <div className="input-control">
                            <div className="select-slot">
                              <div className="select-selections">
                                <div className="select-selection comma">
                                  Sort by
                                </div>
                              </div>
                              <div className="input-append-inner">
                                <div className="input-icon">
                                  <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 -960 960 960" fill="rgba(0, 0, 0, 0.54)"  width="16" preserveAspectRatio="none"><path d="M480-360 280-560h400L480-360Z"/></svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      No
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      タイトル
                    </div>
                    <div className="kmanager-mobile-table-content">
                      システムメンテナンスおよび官公庁ナビURL変更のお知らせ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      公開日時
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2023年11月30日 12時00分
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      表示先システム
                    </div>
                    <div className="kmanager-mobile-table-content">
                      ナビ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      重要度
                    </div>
                    <div className="kmanager-mobile-table-content">
                      重要
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      編集
                    </div>
                    <div className="kmanager-mobile-table-content">
                      <div className="d-flex justify-center">
                        <button className="kmanager-common-button small text-no-wrap edit kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                        </button>
                        <button className="kmanager-common-button small text-no-wrap warning kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      No
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      タイトル
                    </div>
                    <div className="kmanager-mobile-table-content">
                      システムメンテナンスおよび官公庁ナビURL変更のお知らせ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      公開日時
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2023年11月30日 12時00分
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      表示先システム
                    </div>
                    <div className="kmanager-mobile-table-content">
                      ナビ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      重要度
                    </div>
                    <div className="kmanager-mobile-table-content">
                      重要
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      編集
                    </div>
                    <div className="kmanager-mobile-table-content">
                      <div className="d-flex justify-center">
                        <button className="kmanager-common-button small text-no-wrap edit kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                        </button>
                        <button className="kmanager-common-button small text-no-wrap warning kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      No
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      タイトル
                    </div>
                    <div className="kmanager-mobile-table-content">
                      システムメンテナンスおよび官公庁ナビURL変更のお知らせ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      公開日時
                    </div>
                    <div className="kmanager-mobile-table-content">
                      2023年11月30日 12時00分
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      表示先システム
                    </div>
                    <div className="kmanager-mobile-table-content">
                      ナビ
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      重要度
                    </div>
                    <div className="kmanager-mobile-table-content">
                      重要
                    </div>
                  </td>
                  <td>
                    <div className="kmanager-mobile-table-label">
                      編集
                    </div>
                    <div className="kmanager-mobile-table-content">
                      <div className="d-flex justify-center">
                        <button className="kmanager-common-button small text-no-wrap edit kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>
                        </button>
                        <button className="kmanager-common-button small text-no-wrap warning kmanager-button  d-inline-flex align-center justify-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" /></svg>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>    
          </div>
        </div>
        <Pagination DATA_COUNT={notices.length}/>
      </div>
      ここまでモバイルのテーブル */
}
