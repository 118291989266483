import axios from 'axios';

import { API_BASE_URL } from '../../config/envConstants.js';
import apiHeaders from '../../shared/utils/helper/apiHeaders.js';

export const createNaviPropertiesData = async (body) => {
  return await axios.post(`${API_BASE_URL}/api/v1/navi/property`, body, {
    headers: apiHeaders(),
  });
};
