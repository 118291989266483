import {
  validateRangeLength,
  removeBlankSymbols,
} from '../../../../application/validations.js';

export class Tel {
  #tel;

  constructor(tel) {
    this.#tel = tel;
  }

  get() {
    return this.#tel.toString();
  }

  get isValid() {
    return [this.validateRangeLength].every((result) => result === 'TRUE');
  }

  // バリデーションルールの適用
  get validateRangeLength() {
    return validateRangeLength(this.removeBlankSymbols, 10, 11);
  }

  // バリデーションルールの適用
  get removeBlankSymbols() {
    return removeBlankSymbols(this.#tel);
  }
}
