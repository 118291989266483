import { useState, useEffect } from 'react';
import * as Validation from 'shared/utils/helper/naviValidators';

export const LocalGovernmentManagementTopForm = (isEdit) => {
  const [previewUrlValid, setPreviewUrlValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [hpNameValid, setHpNameValid] = useState(isEdit ? 'TRUE' : null);
  const [referenceAcceptValid, setReferenceAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [extensionValid, setExtensionValid] = useState(isEdit ? 'TRUE' : null);
  const [telValid, setTelValid] = useState(isEdit ? 'TRUE' : null);
  const [contactEmailValid, setContactEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [homePageUrlValid, setHomePageUrlValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryNameValid, setInquiryNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setPreviewUrlValid(isEdit ? 'TRUE' : null);
    setHpNameValid(isEdit ? 'TRUE' : null);
    setReferenceAcceptValid(isEdit ? 'TRUE' : null);
    setExtensionValid(isEdit ? 'TRUE' : null);
    setTelValid(isEdit ? 'TRUE' : null);
    setContactEmailValid(isEdit ? 'TRUE' : null);
    setHomePageUrlValid(isEdit ? 'TRUE' : null);
    setInquiryNameValid(isEdit ? 'TRUE' : null);
  }, [isEdit]);

  function handlePreviewUrlValid(e, required) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setPreviewUrlValid(invalid);
        return;
      }
    }
    if (e.target.value && e.target.value.length > 0) {
      invalid = Validation.validateUrlValidUrl(e.target.value);
      if (invalid !== 'TRUE') {
        setPreviewUrlValid(invalid);
        return;
      }
    }
    setPreviewUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryEmailValid(e) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateEmailFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = Validation.validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelValid(e, telData) {
    //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    //invalid = Validation.validateTelLength(tel);
    //if (invalid !== "TRUE") {
    //  setTelValid(invalid);
    //  return
    //}
    invalid = Validation.validateTelSpaceMessage(tel);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    invalid = Validation.validateTelNotInclude(tel);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    invalid = Validation.validateTelRangeLength(tel);
    if (invalid !== 'TRUE') {
      setTelValid(invalid);
      return;
    }
    setTelValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryExtensionValid(e) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateExtensionLength(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionSpaceMessage(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    invalid = Validation.validateExtensionNotInclude(e.target.value);
    if (invalid !== 'TRUE') {
      setExtensionValid(invalid);
      return;
    }
    setExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryAcceptValid(e) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = Validation.validateReferenceAcceptLength(e.target.value);
    if (invalid !== 'TRUE') {
      setReferenceAcceptValid(invalid);
      return;
    }
    invalid = Validation.validateReferenceAcceptHasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setReferenceAcceptValid(invalid);
      return;
    }
    setReferenceAcceptValid(invalid); // エラーメッセージのリセット
  }

  function handleHomePageNameValid(e, isRequired) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    if (isRequired) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setHpNameValid(invalid);
        return;
      }
    }
    if (e.target.value && e.target.value.length > 0) {
      invalid = Validation.validateHpNameDisplayRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setHpNameValid(invalid);
        return;
      }
      invalid = Validation.validateHpNameDisplayHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setHpNameValid(invalid);
        return;
      }
    }
    setHpNameValid(invalid); // エラーメッセージのリセット
  }

  function handleHomePageUrlValid(e, isRequired) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    if (isRequired) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setHomePageUrlValid(invalid);
        return;
      }
    }

    if (e.target.value && e.target.value.length > 0) {
      invalid = Validation.validateUrlValidUrl(e.target.value);
      if (invalid !== 'TRUE') {
        setHomePageUrlValid(invalid);
        return;
      }
      invalid = Validation.validateUrlRequiredLength(e.target.value);
      if (invalid !== 'TRUE') {
        setHomePageUrlValid(invalid);
        return;
      }
    }
    setHomePageUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryNameValid(e, isRequired) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    if (isRequired) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setInquiryNameValid(invalid);
        return;
      }
    }
    if (e.target.value && e.target.value.length > 0) {
      invalid = Validation.validateInquiryDisplayRangeLength1(e.target.value);
      if (invalid !== 'TRUE') {
        setInquiryNameValid(invalid);
        return;
      }
      invalid = Validation.validateInquiryDisplayHasEmoji1(e.target.value);
      if (invalid !== 'TRUE') {
        setInquiryNameValid(invalid);
        return;
      }
    }
    setInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleHomePageUrlValid,
      handleHomePageNameValid,
      handleInquiryAcceptValid,
      handleInquiryExtensionValid,
      handleInquiryNameValid,
      handleInquiryTelValid,
      handleInquiryEmailValid,
      handlePreviewUrlValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      previewUrlValid,
      hpNameValid,
      inquiryNameValid,
      referenceAcceptValid,
      extensionValid,
      telValid,
      contactEmailValid,
      homePageUrlValid,
    },
  };

  return validations;
};
