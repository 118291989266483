import Button from 'interfaces/components/common/Button/Button';
import Toggle from 'interfaces/components/common/Toggle/Toggle';
import { validateInputsGuidelinePublicSaleForm } from 'interfaces/components/common/ValidationGuidelinesPublicSaleForm.jsx';
import Both from 'interfaces/components/LocalGovernmentManagement/guideline/Both';
import IndividualForm from 'interfaces/components/LocalGovernmentManagement/guideline/IndividualForm';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { update } from 'domain/Setting/services/Guidelines';
import { ServiceTypes } from 'shared/utils/constants/service.js';

import { LocalGovernmentManagementGuidelinesContext } from '../../../pages/LocalGovernmentManagementGuidelines/entities/LocalGovernmentManagementGuidelinesContext';

const GuidelinesForm = ({ procedureType, governmentType, setIsOpen }) => {
  const navigate = useNavigate();
  const [individualSetting, setIndividualSetting] = useState(false);
  const { auctionGuidelines, bidGuidelines } = useContext(
    LocalGovernmentManagementGuidelinesContext,
  );
  const auction = validateInputsGuidelinePublicSaleForm(auctionGuidelines);
  const bid = validateInputsGuidelinePublicSaleForm(bidGuidelines);
  console.log(bidGuidelines);
  console.log(bid);

  const performUpdate = async (e) => {
    e.preventDefault();
    const auctionItems = {};
    for (let i = 0; i < 9; i++) {
      auctionItems[i] = {
        publicSaleType: 'AUCTION',
        procedureType: procedureType,
        ...auction.data[i],
      };
    }
    try {
      await Promise.all(
        Object.values(auctionItems).map(async (item) => {
          await update(ServiceTypes.NAVI.string, item);
        }),
      );
      if (individualSetting) {
        const bidItems = {};
        for (let i = 0; i < 9; i++) {
          bidItems[i] = {
            publicSaleType: 'BID',
            procedureType: procedureType,
            ...bid.data[i],
          };
        }
        await Promise.all(
          Object.values(bidItems).map(async (item) => {
            await update(ServiceTypes.NAVI.string, item);
          }),
        );
      }
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    const validateMessages = (form) => {
      const { validationMessage, data } = form;
      const notRequiredKeys = Array.from({ length: 9 }, (_, i) => {
        return i === 0
          ? procedureType !== 'ASSET_SALE' && data[i]?.isDisplay !== 'DISPLAY'
            ? ['body', 'title']
            : ['title']
          : data[i]?.isDisplay !== 'DISPLAY' && ['body', 'title'];
      });
      const validationResults = Object.entries(validationMessage).reduce(
        (acc, [key, messages]) => {
          acc[key] = Object.entries(messages).reduce(
            (colAcc, [column, message]) => {
              const isValid = !notRequiredKeys[key]
                ? message === 'TRUE'
                : notRequiredKeys[key].includes(column)
                  ? message === null || message === 'TRUE'
                  : message === 'TRUE';
              colAcc[column] = { message, isValid };
              return colAcc;
            },
            {},
          );
          return acc;
        },
        {},
      );
      console.log(validationResults);
      return Object.values(validationResults).every((validationResult) => {
        return Object.values(validationResult).every(
          (result) => result.isValid,
        );
      });
    };

    if (individualSetting) {
      setIsValid(validateMessages(auction) && validateMessages(bid));
    } else {
      setIsValid(validateMessages(auction));
    }
  }, [auction, bid, individualSetting, procedureType]);

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  return (
    <div className="new-style_navi_local-government-management-top-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <Toggle
          message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
          handler={() => setIndividualSetting(!individualSetting)}
        />
      )}
      {!individualSetting && (
        <Both data={auction} procedureType={procedureType} />
      )}
      {individualSetting && (
        <IndividualForm
          auctionData={auction}
          bidData={bid}
          procedureType={procedureType}
        />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={performUpdate}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuidelinesForm;
