import Button from 'interfaces/components/common/Button.jsx';
import ConfirmSection from 'interfaces/components/common/Form/ConfirmSection';
import { breadcrumbs } from 'interfaces/pages/NewEntry/entities/breadcrumbs';
import { useParams } from 'react-router-dom';
import { createConfirmSectionData } from 'shared/utils/helper/CreateConfirmSectionData';
import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';

import Breadcrumb from '../../../components/common/Breadcrumb';
import Heading2 from '../../../components/common/Heading/Heading2';

const NaviNewEntryConfirm = ({
  inputs,
  procedureType,
  cancel,
  toRegister,
  auction,
}) => {
  console.log(inputs, procedureType);
  const confirmSectionData = createConfirmSectionData(
    inputs,
    procedureType,
    auction,
  );
  const { auction_id } = useParams();

  const getMethod = () => {
    if (auction_id !== undefined) {
      return 'update';
    }
    return 'create';
  };

  const getTitle = () => {
    if (getMethod() !== 'update') {
      return `登録内容確認：${textExtractionByProcedureType(procedureType)}参加申込フォーム`;
    }
    return `修正内容確認：${textExtractionByProcedureType(procedureType)}参加申込情報修正`;
  };

  console.log(confirmSectionData.section5);
  console.log(confirmSectionData.section1);
  return (
    <div
      className="new-style_navi_procedure-new-entry-confirm"
      data-testid="procedure-new-entry-confirm">
      <Breadcrumb
        breadcrumbs={breadcrumbs(
          getMethod(),
          'confirm',
          procedureType,
          auction_id,
        )}
      />
      <Heading2 title={getTitle()} />
      <ConfirmSection
        headings={[
          {
            type: 'h3',
            title: `1. ${textExtractionByProcedureType(procedureType)}登録申請`,
          },
        ]}
        inputsData={confirmSectionData.section1}
      />
      <ConfirmSection
        headings={[{ type: 'h4', title: '【せり売形式】' }]}
        inputsData={confirmSectionData.section2}
      />
      <ConfirmSection
        headings={[{ type: 'h4', title: '【入札形式】' }]}
        inputsData={confirmSectionData.section3}
      />
      <ConfirmSection
        headings={[
          { type: 'h3', title: '2. 行政機関登録申請' },
          { type: 'h4', title: '【基本情報】' },
        ]}
        inputsData={confirmSectionData.section4}
      />
      <ConfirmSection
        headings={[{ type: 'h4', title: '【落札後画面表示情報】' }]}
        inputsData={confirmSectionData.section5}
        description={
          '落札者の物件詳細ページに掲載される内容です（落札者のみが見られます）。\n入力した内容は、官公庁ナビの物件登録画面の落札後の連絡先項目にデフォルト表示されます。'
        }
      />
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={'登録'}
              isOutline={false}
              height={'44px'}
              clickHandler={toRegister}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              type="button"
              text={'編集'}
              isOutline={false}
              height={'44px'}
              clickHandler={cancel}
              className={
                'property-info-general-form-button common-button-bg-whitesmoke new-style_navi_normal-button'
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NaviNewEntryConfirm;
