import HandlePreview from 'interfaces/components/common/MarkDown/Plugins/HandlePreview';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';

const mdParser = new MarkdownIt();

MdEditor.addLocale('ja-JP', {
  clearTip: '本当にすべての内容をクリアしますか？',
  btnHeader: '見出し',
  btnClear: 'クリア',
  btnBold: '太字',
  btnItalic: '斜字',
  btnUnderline: '下線',
  btnStrikethrough: '取り消し線',
  btnUnordered: '番号なしリスト',
  btnOrdered: '順序付きリスト',
  btnQuote: '引用',
  btnLineBreak: '改行',
  btnInlineCode: 'インラインコード',
  btnCode: 'コード',
  btnTable: 'テーブル',
  btnImage: '画像',
  btnLink: 'リンク',
  btnUndo: '元に戻す',
  btnRedo: 'やり直す',
  btnFullScreen: '全画面表示',
  btnExitFullScreen: '全画面表示を終了',
  btnModeEditor: '編集',
  btnModePreview: 'プレビュー',
  btnModeAll: 'エディタとプレビューを両方表示',
  selectTabMap: 'Tabキーを押したときの実際の入力',
  tab: 'タブ',
  spaces: 'スペース',
});
MdEditor.useLocale('ja-JP');
MdEditor.use(HandlePreview);
const plugins = [
  'toggle_preview',
  'header',
  'font-bold',
  'font-italic',
  'font-strikethrough',
  'block-quote',
  'list-unordered',
  'list-ordered',
  'table',
  'link',
];

export const CustomMdEditor = ({ onChange, value, isValid }) => {
  return (
    <div className="property-form-input-mark-down">
      <MdEditor
        style={{
          height: '500px',
          wordBreak: 'break-all',
          border: isValid ? '' : '2px solid #ff5252',
        }}
        renderHTML={(text) => mdParser.render(text)}
        onChange={onChange}
        value={value}
        canView={{
          both: false,
          hideMenu: false,
        }}
        view={{ html: false }}
        plugins={plugins}
      />
    </div>
  );
};
