// 構造体の定義
// サービスタイプ毎に異なる場合はその数だけ作成

import { AplEnd } from './Governments/aplEnd';
import { AplStart } from './Governments/aplStart';
import { AssetSaleAuctionData } from './Governments/assetSaleAuctionData';
import { AssetSaleBidData } from './Governments/assetSaleBidData';
import { BidEnd } from './Governments/bidEnd';
import { BidStart } from './Governments/bidStart';
import { CarBidFee } from './Governments/carBidFee';
import { CarBidFeeType } from './Governments/carBidFeeType';
import { CharacterInsteadEmblem } from './Governments/characterInsteadEmblem';
import { CharacterName } from './Governments/characterName';
import { CostAttention } from './Governments/costAttention';
import { CostContractDescription1 } from './Governments/costContractDescription1';
import { CostContractDescription2 } from './Governments/costContractDescription2';
import { CostContractDescription3 } from './Governments/costContractDescription3';
import { CostContractTitle1 } from './Governments/costContractTitle1';
import { CostContractTitle2 } from './Governments/costContractTitle2';
import { CostContractTitle3 } from './Governments/costContractTitle3';
import { CostPaymentDescription1 } from './Governments/costPaymentDescription1';
import { CostPaymentDescription2 } from './Governments/costPaymentDescription2';
import { CostPaymentDescription3 } from './Governments/costPaymentDescription3';
import { CostPaymentDescription4 } from './Governments/costPaymentDescription4';
import { CostPaymentDescription5 } from './Governments/costPaymentDescription5';
import { CostPaymentTitle1 } from './Governments/costPaymentTitle1';
import { CostPaymentTitle2 } from './Governments/costPaymentTitle2';
import { CostPaymentTitle3 } from './Governments/costPaymentTitle3';
import { CostPaymentTitle4 } from './Governments/costPaymentTitle4';
import { CostPaymentTitle5 } from './Governments/costPaymentTitle5';
import { DocumentDescription } from './Governments/documentDescription';
import { DocumentPageDisplay } from './Governments/documentPageDisplay';
import { DocumentPageName } from './Governments/documentPageName';
import { DocumentPageUrl } from './Governments/documentPageUrl';
import { EstateBidFee } from './Governments/estateBidFee';
import { EstateBidFeeType } from './Governments/estateBidFeeType';
import { GovernmentSubCategoryId } from './Governments/governmentSubCategoryId';
import { GovernmentType } from './Governments/governmentType';
import { GovHpName } from './Governments/govHpName';
import { GovHpUrl } from './Governments/govHpUrl';
import { Id } from './Governments/id';
import { ImportantAttention } from './Governments/importantAttention';
import { ImportantDescription1 } from './Governments/importantDescription1';
import { ImportantDescription2 } from './Governments/importantDescription2';
import { ImportantDescription3 } from './Governments/importantDescription3';
import { ImportantHandlingBidDeposit } from './Governments/importantHandlingBidDeposit';
import { ImportantHandlingContractDeposit } from './Governments/importantHandlingContractDeposit';
import { ImportantOther1 } from './Governments/importantOther1';
import { ImportantOther2 } from './Governments/importantOther2';
import { ImportantOther3 } from './Governments/importantOther3';
import { ImportantRestrictionUse } from './Governments/importantRestrictionUse';
import { ImportantRestrictionUseBefore } from './Governments/importantRestrictionUseBefore';
import { ImportantRiskBearing } from './Governments/importantRiskBearing';
import { ImportantTermsDelivery } from './Governments/importantTermsDelivery';
import { InquiryAccept } from './Governments/inquiryAccept';
import { InquiryDisplay } from './Governments/inquiryDisplay';
import { InquiryEmail } from './Governments/inquiryEmail';
import { InquiryExtension } from './Governments/inquiryExtension';
import { InquiryName } from './Governments/inquiryName';
import { InquiryTel } from './Governments/inquiryTel';
import { Location } from './Governments/location';
import { MovableBidFee } from './Governments/movableBidFee';
import { MovableBidFeeType } from './Governments/movableBidFeeType';
import { Name } from './Governments/name';
import { NameKana } from './Governments/nameKana';
import { NumOfProperties } from './Governments/numOfProperties';
import { OtherTransRightAttention } from './Governments/otherTransRightAttention';
import { OtherTransRightDescription } from './Governments/otherTransRightDescription';
import { PreviewHpName } from './Governments/previewHpName';
import { PreviewUrl } from './Governments/previewUrl';
import { ProcedureType } from './Governments/procedureType';
import { PropertyIds } from './Governments/propertyIds';
import { PublicSaleAuctionData } from './Governments/publicSaleAuctionData';
import { PublicSaleBidData } from './Governments/publicSaleBidData';
import { Region } from './Governments/region';
import { Status } from './Governments/status';
import { TaxType } from './Governments/taxType';
import { TransRightCompletedTime } from './Governments/transRightCompletedTime';
import { TransRightDate } from './Governments/transRightDate';
import { TransRightDescription } from './Governments/transRightDescription';
import { UseEmblem } from './Governments/useEmblem';
import CustomError from '../../../shared/utils/helper/customError.js';
import { NotUndefined } from '../../../shared/utils/helper/NotUndefined.js';

export class GovernmentGet {
  constructor(data, governmentSubCategoryResponse, governmentCategoryResponse) {
    this.id = data.id;
    this.governmentSubCategoryId = data.governmentSubCategoryId;
    this.governmentSubCategory = this.getSubCategory(
      this.governmentSubCategoryId,
      governmentSubCategoryResponse,
    );
    this.governmentCategory = this.getCategory(
      this.governmentSubCategory.governmentCategoryId,
      governmentCategoryResponse,
    );
    this.governmentType = data.governmentType;
    this.location = this.governmentSubCategory.subCategoryName;
    this.attribute = this.governmentSubCategory.attribute;
    this.procedureType = data.procedureType;
    this.name = data.name;
    this.nameKana = data.nameKana;
    this.movableBidFee = data.movableBidFee;
    this.movableBidFeeType = data.movableBidFeeType;
    this.carBidFee = data.carBidFee;
    this.carBidFeeType = data.carBidFeeType;
    this.estateBidFee = data.estateBidFee;
    this.estateBidFeeType = data.estateBidFeeType;
    this.taxType = data.taxType;
    this.useEmblem = data.useEmblem;
    this.characterName = data.characterName;
    this.characterInsteadEmblem = data.characterInsteadEmblem;
    this.numOfProperties = data.numOfProperties;
    this.region = this.governmentCategory.categoryName;
    this.isEmblemExist = data.isEmblemExist;
    this.isCharaExist = data.isCharaExist;
    //if (!this.isValid) throw new CustomError("Message の値が不正です")
  }

  getSubCategory(governmentSubCategoryId, governmentSubCategoryResponse) {
    const result = governmentSubCategoryResponse.find(
      (governmentSubCategory) => {
        return (
          governmentSubCategory.id.toString() ===
          governmentSubCategoryId.toString()
        );
      },
    );
    return result;
  }

  getCategory(governmentCategoryId, governmentCategoryResponse) {
    const result = governmentCategoryResponse.find((governmentCategory) => {
      return (
        governmentCategory.id.toString() === governmentCategoryId.toString()
      );
    });
    return result;
  }

  get isPublicSale() {
    return this.procedureType === 'PUBLIC_SALE';
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GovernmentFormatDataGet {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.num_of_properties = data.num_of_properties;
    this.region = data.region;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GovernmentDetailsGet {
  constructor(data) {
    this.aplEnd = data.aplEnd;
    this.aplStart = data.aplStart;
    this.bidEnd = data.bidEnd;
    this.bidStart = data.bidStart;
    this.govHpName = data.govHpName;
    this.govHpUrl = data.govHpUrl;
    this.inquiryAccept = data.inquiryAccept;
    this.inquiryEmail = data.inquiryEmail;
    this.inquiryExtension = data.inquiryExtension;
    this.inquiryName = data.inquiryName;
    this.inquiryTel = data.inquiryTel;
    this.name = data.name;
    this.previewHpName = data.previewHpName;
    this.previewUrl = data.previewUrl;
    this.propertyIds = data.propertyIds;
    this.status = data.status;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

// 運営管理の行政機関詳細 参考：/auction_matching/rust/src/management_controller/governments/show/government_details.rs
export class OPEMGRGovernmentDetailsGet {
  constructor(props) {
    this.id = props.id;
    this.governmentType = props.governmentType;
    this.location = props.location;
    this.prefecture = props.prefecture;
    this.name = props.name;
    this.nameKana = props.nameKana;
    this.emblem = props.emblem;
    this.character = props.character;
    this.systemFeeInfo = new SystemFeeInfo(props.systemFeeInfo);
    this.procedureType = props.procedureType;
    this.certDocumentInfo = new CertDocumentInfo(props.certDocumentInfo);
    this.divisions = new DivisionsData(props.divisions);
    this.publicSaleUsers = new GovernmentUsersData(props.publicSaleUsers);
    this.assetSaleUsers = new GovernmentUsersData(props.assetSaleUsers);
    if (!this.isValid)
      throw new CustomError('GovernmentDetails の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

class SystemFeeInfo {
  constructor(props) {
    // 現状はid（publicSale:1, assetSale:2）で判別している
    this.publicSaleInfo = new SystemFeeData(props.publicSaleInfo);
    this.assetSaleInfo = new SystemFeeData(props.assetSaleInfo);
  }
}

class SystemFeeData {
  constructor(props) {
    this.id = props.id;
    this.invoicePostalCode = props.invoicePostalCode;
    this.invoiceArea = props.invoiceArea;
    this.invoiceCity = props.invoiceCity;
    this.invoiceAddress = props.invoiceAddress;
    this.invoiceBuildName = props.invoiceBuildName;
    this.invoiceWrittenDestination = props.invoiceWrittenDestination;
    this.invoiceDepartment = props.invoiceDepartment;
    this.invoicePayerName = props.invoicePayerName;
    this.invoiceTel = props.invoiceTel;
    this.invoiceExtension = props.invoiceExtension;
    this.invoiceUrl = props.invoiceUrl;
    if (!this.isValid)
      throw new CustomError('GovernmentDetails の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

class CertDocumentInfo {
  constructor(props) {
    // 現状はid（publicSale:1, assetSale:2）で判別している
    this.publicSaleInfo = new CertDocumentData(props.publicSaleInfo);
    this.assetSaleInfo = new CertDocumentData(props.assetSaleInfo);
  }
}

class CertDocumentData {
  constructor(props) {
    this.id = props.id;
    this.certDocumentPostalCode = props.certDocumentPostalCode;
    this.certDocumentArea = props.certDocumentArea;
    this.certDocumentCity = props.certDocumentCity;
    this.certDocumentAddress = props.certDocumentAddress;
    this.certDocumentBuildName = props.certDocumentBuildName;
    this.certDocumentWrittenDestination = props.certDocumentWrittenDestination;
    if (!this.isValid)
      throw new CustomError('GovernmentDetails の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

class DivisionsData {
  constructor(props) {
    this.id = props.id;
    this.procedureType = props.procedureType;
    this.name = props.name;
    this.nameKana = props.nameKana;
    this.contactName = props.contactName;
    this.contactTel = props.contactTel;
    this.contactEmail = props.contactEmail;
    this.useCreditCard = props.useCreditCard;
    this.financialInstitutionName = props.financialInstitutionName;
    this.branchName = props.branchName;
    this.accountType = props.accountType;
    this.accountNumber = props.accountNumber;
    this.accountName = props.accountName;
    this.accountNameKana = props.accountNameKana;
    this.postalCode = props.postalCode;
    this.prefectureId = props.prefectureId;
    this.city = props.city;
    this.address = props.address;
    this.buildName = props.buildName;
    this.destination = props.destination;
    this.writtenDestination = props.writtenDestination;
    if (!this.isValid)
      throw new CustomError('GovernmentDetails の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

class GovernmentUsersData {
  constructor(props) {
    this.id = props.id;
    this.userType = props.userType;
    this.name = props.name;
    this.department = props.department;
    this.officePosition = props.officePosition;
    this.tel = props.tel;
    this.extension = props.extension;
    this.email = props.email;
    if (!this.isValid)
      throw new CustomError('GovernmentDetails の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class AssetSaleAuctionGet {
  constructor(data) {
    this.id = data.id;
    this.data = new AssetSaleAuctionDataGet(data.data);
  }
}

export class AssetSaleAuctionDataGet {
  constructor(data) {
    this.apl_end = data.apl_end;
    this.apl_start = data.apl_start;
    this.bid_end = data.bid_end;
    this.bid_start = data.bid_start;
    this.gov_hp_name = data.gov_hp_name;
    this.gov_hp_url = data.gov_hp_url;
    this.inquiry_accept = data.inquiry_accept;
    this.inquiry_email = data.inquiry_email;
    this.inquiry_extension = data.inquiry_extension;
    this.inquiry_name = data.inquiry_name;
    this.inquiry_tel = data.inquiry_tel;
    this.name = data.name;
    this.preview_hp_name = data.preview_hp_name;
    this.preview_url = data.preview_url;
    this.property_ids = data.property_ids;
    this.status = data.status;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class AssetSaleBidGet {
  constructor(data) {
    this.id = data.id;
    this.data = new AssetSaleBidDataGet(data.data);
  }
}

export class AssetSaleBidDataGet {
  constructor(data) {
    this.apl_end = data.apl_end;
    this.apl_start = data.apl_start;
    this.bid_end = data.bid_end;
    this.bid_start = data.bid_start;
    this.gov_hp_name = data.gov_hp_name;
    this.gov_hp_url = data.gov_hp_url;
    this.inquiry_accept = data.inquiry_accept;
    this.inquiry_email = data.inquiry_email;
    this.inquiry_extension = data.inquiry_extension;
    this.inquiry_name = data.inquiry_name;
    this.inquiry_tel = data.inquiry_tel;
    this.name = data.name;
    this.preview_hp_name = data.preview_hp_name;
    this.preview_url = data.preview_url;
    this.property_ids = data.property_ids;
    this.status = data.status;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PublicSaleAuctionGet {
  constructor(data) {
    this.id = data.id;
    this.data = new PublicSaleAuctionDataGet(data.data);
  }
}

export class PublicSaleAuctionDataGet {
  constructor(data) {
    this.apl_end = data.apl_end;
    this.apl_start = data.apl_start;
    this.bid_end = data.bid_end;
    this.bid_start = data.bid_start;
    this.gov_hp_name = data.gov_hp_name;
    this.gov_hp_url = data.gov_hp_url;
    this.inquiry_accept = data.inquiry_accept;
    this.inquiry_email = data.inquiry_email;
    this.inquiry_extension = data.inquiry_extension;
    this.inquiry_name = data.inquiry_name;
    this.inquiry_tel = data.inquiry_tel;
    this.name = data.name;
    this.preview_hp_name = data.preview_hp_name;
    this.preview_url = data.preview_url;
    this.property_ids = data.property_ids;
    this.status = data.status;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class PublicSaleBidGet {
  constructor(data) {
    this.id = data.id;
    this.data = new PublicSaleBidDataGet(data.data);
  }
}

export class PublicSaleBidDataGet {
  constructor(data) {
    this.apl_end = data.apl_end;
    this.apl_start = data.apl_start;
    this.bid_end = data.bid_end;
    this.bid_start = data.bid_start;
    this.gov_hp_name = data.gov_hp_name;
    this.gov_hp_url = data.gov_hp_url;
    this.inquiry_accept = data.inquiry_accept;
    this.inquiry_email = data.inquiry_email;
    this.inquiry_extension = data.inquiry_extension;
    this.inquiry_name = data.inquiry_name;
    this.inquiry_tel = data.inquiry_tel;
    this.name = data.name;
    this.preview_hp_name = data.preview_hp_name;
    this.preview_url = data.preview_url;
    this.property_ids = data.property_ids;
    this.status = data.status;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class CautionGet {
  constructor(data) {
    this.government_id = data.government_id;
    this.procedure_type = data.procedure_type;
    this.public_sale_type = data.public_sale_type;
    this.data = new CautionDataGet(data.data);
  }
}

export class CautionDataGet {
  constructor(data) {
    this.cost_attention = data.cost_attention;
    this.cost_contract_description_1 = data.cost_contract_description_1;
    this.cost_contract_description_2 = data.cost_contract_description_2;
    this.cost_contract_description_3 = data.cost_contract_description_3;
    this.cost_contract_title_1 = data.cost_contract_title_1;
    this.cost_contract_title_2 = data.cost_contract_title_2;
    this.cost_contract_title_3 = data.cost_contract_title_3;
    this.cost_payment_description_1 = data.cost_payment_description_1;
    this.cost_payment_description_2 = data.cost_payment_description_2;
    this.cost_payment_description_3 = data.cost_payment_description_3;
    this.cost_payment_description_4 = data.cost_payment_description_4;
    this.cost_payment_description_5 = data.cost_payment_description_5;
    this.cost_payment_title_1 = data.cost_payment_title_1;
    this.cost_payment_title_2 = data.cost_payment_title_2;
    this.cost_payment_title_3 = data.cost_payment_title_3;
    this.cost_payment_title_4 = data.cost_payment_title_4;
    this.cost_payment_title_5 = data.cost_payment_title_5;
    this.document_description = data.document_description;
    this.document_page_display = data.document_page_display;
    this.document_page_name = data.document_page_name;
    this.document_page_url = data.document_page_url;
    this.important_attention = data.important_attention;
    this.important_description_1 = data.important_description_1;
    this.important_description_2 = data.important_description_2;
    this.important_description_3 = data.important_description_3;
    this.important_handling_bid_deposit = data.important_handling_bid_deposit;
    this.important_handling_contract_deposit =
      data.important_handling_contract_deposit;
    this.important_other_1 = data.important_other_1;
    this.important_other_2 = data.important_other_2;
    this.important_other_3 = data.important_other_3;
    this.important_restriction_use = data.important_restriction_use;
    this.important_restriction_use_before =
      data.important_restriction_use_before;
    this.important_risk_bearing = data.important_risk_bearing;
    this.important_terms_delivery = data.important_terms_delivery;
    this.inquiry_accept = data.inquiry_accept;
    this.inquiry_display = data.inquiry_display;
    this.inquiry_email = data.inquiry_email;
    this.inquiry_extension = data.inquiry_extension;
    this.inquiry_name = data.inquiry_name;
    this.inquiry_tel = data.inquiry_tel;
    this.other_trans_right_attention = data.other_trans_right_attention;
    this.other_trans_right_description = data.other.trans_right_description;
    this.trans_right_completed_time = data.trans_right_completed_time;
    this.trans_right_date = data.trans_right_date;
    this.trans_right_description = data.trans_right_description;
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return NotUndefined(this);
  }
}

export class GovernmentCreate {
  constructor(data) {
    this.governmentSubCategoryId = new GovernmentSubCategoryId(data.region);
    this.governmentType = new GovernmentType(data.governmentType);
    this.procedureType = new ProcedureType(data.procedureType);
    this.name = new Name(data.name);
    this.nameKana = new NameKana(data.nameKana);
    this.location = new Location('DATA_EMPTY');
    this.movableBidFee = new MovableBidFee(0);
    this.movableBidFeeType = new MovableBidFeeType('CALCULATION');
    this.carBidFee = new CarBidFee(0);
    this.carBidFeeType = new CarBidFeeType('CALCULATION');
    this.estateBidFee = new EstateBidFee(0);
    this.estateBidFeeType = new EstateBidFeeType('CALCULATION');
    this.taxType = new TaxType('TOTAL_TAXATION');
    this.useEmblem = new UseEmblem(0);
    this.characterName = new CharacterName('DATA_EMPTY');
    this.characterInsteadEmblem = new CharacterInsteadEmblem('NO');
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isPublicSale() {
    return this.procedureType === 'PUBLIC_SALE';
  }

  get isValid() {
    return (
      this.governmentSubCategoryId.isValid &&
      this.governmentType.isValid &&
      this.procedureType.isValid &&
      this.name.isValid &&
      this.nameKana.isValid
    );
  }
}

export class GovernmentFormatDataCreate {
  constructor(data) {
    this.id = new Id(data.id);
    this.name = new Name(data.name);
    this.num_of_properties = new NumOfProperties(data.num_of_properties);
    this.region = new Region(data.region);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.id.isValid &&
      this.name.isValid &&
      this.num_of_properties.isValid &&
      this.region.isValid
    );
  }
}

export class GovernmentDetailsCreate {
  constructor(data) {
    this.aplEnd = new AplEnd(data.aplEnd);
    this.aplStart = new AplStart(data.aplStart);
    this.bidEnd = new BidEnd(data.bidEnd);
    this.bidStart = new BidStart(data.bidStart);
    this.govHpName = new GovHpName(data.govHpName);
    this.govHpUrl = new GovHpUrl(data.govHpUrl);
    this.inquiryAccept = new InquiryAccept(data.inquiryAccept);
    this.inquiryEmail = new InquiryEmail(data.inquiryEmail);
    this.inquiryExtension = new InquiryExtension(data.inquiryExtension);
    this.inquiryName = new InquiryName(data.inquiryName);
    this.inquiryTel = new InquiryTel(data.inquiryTel);
    this.name = new Name(data.name);
    this.previewHpName = new PreviewHpName(data.previewHpName);
    this.previewUrl = new PreviewUrl(data.previewUrl);
    this.propertyIds = new PropertyIds(data.propertyIds);
    this.status = new Status(data.status);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.aplEnd.isValid &&
      this.aplStart.isValid &&
      this.bidEnd.isValid &&
      this.bidStart.isValid &&
      this.govHpName.isValid &&
      this.govHpUrl.isValid &&
      this.inquiryAccept.isValid &&
      this.inquiryEmail.isValid &&
      this.inquiryExtension.isValid &&
      this.inquiryName.isValid &&
      this.inquiryTel.isValid &&
      this.name.isValid &&
      this.previewHpName.isValid &&
      this.previewUrl.isValid &&
      this.propertyIds.isValid &&
      this.status.isValid
    );
  }
}

export class AssetSaleAuctionCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new AssetSaleAuctionData(data.data);
  }
}

export class AssetSaleAuctionDataCreate {
  constructor(data) {
    this.apl_end = new AplEnd(data.apl_end);
    this.apl_start = new AplStart(data.apl_start);
    this.bid_end = new BidEnd(data.bid_end);
    this.bid_start = new BidStart(data.bid_start);
    this.gov_hp_name = new GovHpName(data.gov_hp_name);
    this.gov_hp_url = new GovHpUrl(data.gov_hp_url);
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.name = new Name(data.name);
    this.preview_hp_name = new PreviewHpName(data.preview_hp_name);
    this.preview_url = new PreviewUrl(data.preview_url);
    this.property_ids = new PropertyIds(data.property_ids);
    this.status = new Status(data.status);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.apl_end.isValid &&
      this.apl_start.isValid &&
      this.bid_end.isValid &&
      this.bid_start.isValid &&
      this.gov_hp_name.isValid &&
      this.gov_hp_url.isValid &&
      this.inquiry_accept.isValid &&
      this.inquiry_email.isValid &&
      this.inquiry_extension.isValid &&
      this.inquiry_name.isValid &&
      this.inquiry_tel.isValid &&
      this.name.isValid &&
      this.preview_hp_name.isValid &&
      this.preview_url.isValid &&
      this.property_ids.isValid &&
      this.status.isValid
    );
  }
}

export class AssetSaleBidCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new AssetSaleBidData(data.data);
  }
}

export class AssetSaleBidDataCreate {
  constructor(data) {
    this.apl_end = new AplEnd(data.apl_end);
    this.apl_start = new AplStart(data.apl_start);
    this.bid_end = new BidEnd(data.bid_end);
    this.bid_start = new BidStart(data.bid_start);
    this.gov_hp_name = new GovHpName(data.gov_hp_name);
    this.gov_hp_url = new GovHpUrl(data.gov_hp_url);
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.name = new Name(data.name);
    this.preview_hp_name = new PreviewHpName(data.preview_hp_name);
    this.preview_url = new PreviewUrl(data.preview_url);
    this.property_ids = new PropertyIds(data.property_ids);
    this.status = new Status(data.status);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.apl_end.isValid &&
      this.apl_start.isValid &&
      this.bid_end.isValid &&
      this.bid_start.isValid &&
      this.gov_hp_name.isValid &&
      this.gov_hp_url.isValid &&
      this.inquiry_accept.isValid &&
      this.inquiry_email.isValid &&
      this.inquiry_extension.isValid &&
      this.inquiry_name.isValid &&
      this.inquiry_tel.isValid &&
      this.name.isValid &&
      this.preview_hp_name.isValid &&
      this.preview_url.isValid &&
      this.property_ids.isValid &&
      this.status.isValid
    );
  }
}

export class PublicSaleAuctionCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new PublicSaleAuctionData(data.data);
  }
}

export class PublicSaleAuctionDataCreate {
  constructor(data) {
    this.apl_end = new AplEnd(data.apl_end);
    this.apl_start = new AplStart(data.apl_start);
    this.bid_end = new BidEnd(data.bid_end);
    this.bid_start = new BidStart(data.bid_start);
    this.gov_hp_name = new GovHpName(data.gov_hp_name);
    this.gov_hp_url = new GovHpUrl(data.gov_hp_url);
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.name = new Name(data.name);
    this.preview_hp_name = new PreviewHpName(data.preview_hp_name);
    this.preview_url = new PreviewUrl(data.preview_url);
    this.property_ids = new PropertyIds(data.property_ids);
    this.status = new Status(data.status);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.apl_end.isValid &&
      this.apl_start.isValid &&
      this.bid_end.isValid &&
      this.bid_start.isValid &&
      this.gov_hp_name.isValid &&
      this.gov_hp_url.isValid &&
      this.inquiry_accept.isValid &&
      this.inquiry_email.isValid &&
      this.inquiry_extension.isValid &&
      this.inquiry_name.isValid &&
      this.inquiry_tel.isValid &&
      this.name.isValid &&
      this.preview_hp_name.isValid &&
      this.preview_url.isValid &&
      this.property_ids.isValid &&
      this.status.isValid
    );
  }
}

export class PublicSaleBidCreate {
  constructor(data) {
    this.id = data.id;
    this.data = new PublicSaleBidData(data.data);
  }
}

export class PublicSaleBidDataCreate {
  constructor(data) {
    this.apl_end = new AplEnd(data.apl_end);
    this.apl_start = new AplStart(data.apl_start);
    this.bid_end = new BidEnd(data.bid_end);
    this.bid_start = new BidStart(data.bid_start);
    this.gov_hp_name = new GovHpName(data.gov_hp_name);
    this.gov_hp_url = new GovHpUrl(data.gov_hp_url);
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.name = new Name(data.name);
    this.preview_hp_name = new PreviewHpName(data.preview_hp_name);
    this.preview_url = new PreviewUrl(data.preview_url);
    this.property_ids = new PropertyIds(data.property_ids);
    this.status = new Status(data.status);
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.apl_end.isValid &&
      this.apl_start.isValid &&
      this.bid_end.isValid &&
      this.bid_start.isValid &&
      this.gov_hp_name.isValid &&
      this.gov_hp_url.isValid &&
      this.inquiry_accept.isValid &&
      this.inquiry_email.isValid &&
      this.inquiry_extension.isValid &&
      this.inquiry_name.isValid &&
      this.inquiry_tel.isValid &&
      this.name.isValid &&
      this.preview_hp_name.isValid &&
      this.preview_url.isValid &&
      this.property_ids.isValid &&
      this.status.isValid
    );
  }
}

export class CautionCreate {
  constructor(data) {
    this.government_id = data.government_id;
    this.procedure_type = data.procedure_type;
    this.public_sale_type = data.public_sale_type;
    this.data = new CautionDataCreate(data.data);
  }
}

export class CautionDataCreate {
  constructor(data) {
    this.cost_attention = new CostAttention(data.cost_attention);
    this.cost_contract_description_1 = new CostContractDescription1(
      data.cost_contract_description_1,
    );
    this.cost_contract_description_2 = new CostContractDescription2(
      data.cost_contract_description_2,
    );
    this.cost_contract_description_3 = new CostContractDescription3(
      data.cost_contract_description_3,
    );
    this.cost_contract_title_1 = new CostContractTitle1(
      data.cost_contract_title_1,
    );
    this.cost_contract_title_2 = new CostContractTitle2(
      data.cost_contract_title_2,
    );
    this.cost_contract_title_3 = new CostContractTitle3(
      data.cost_contract_title_3,
    );
    this.cost_payment_description_1 = new CostPaymentDescription1(
      data.cost_payment_description_1,
    );
    this.cost_payment_description_2 = new CostPaymentDescription2(
      data.cost_payment_description_2,
    );
    this.cost_payment_description_3 = new CostPaymentDescription3(
      data.cost_payment_description_3,
    );
    this.cost_payment_description_4 = new CostPaymentDescription4(
      data.cost_payment_description_4,
    );
    this.cost_payment_description_5 = new CostPaymentDescription5(
      data.cost_payment_description_5,
    );
    this.cost_payment_title_1 = new CostPaymentTitle1(
      data.cost_payment_title_1,
    );
    this.cost_payment_title_2 = new CostPaymentTitle2(
      data.cost_payment_title_2,
    );
    this.cost_payment_title_3 = new CostPaymentTitle3(
      data.cost_payment_title_3,
    );
    this.cost_payment_title_4 = new CostPaymentTitle4(
      data.cost_payment_title_4,
    );
    this.cost_payment_title_5 = new CostPaymentTitle5(
      data.cost_payment_title_5,
    );
    this.document_description = new DocumentDescription(
      data.document_description,
    );
    this.document_page_display = new DocumentPageDisplay(
      data.document_page_display,
    );
    this.document_page_name = new DocumentPageName(data.document_page_name);
    this.document_page_url = new DocumentPageUrl(data.document_page_url);
    this.important_attention = new ImportantAttention(data.important_attention);
    this.important_description_1 = new ImportantDescription1(
      data.important_description_1,
    );
    this.important_description_2 = new ImportantDescription2(
      data.important_description_2,
    );
    this.important_description_3 = new ImportantDescription3(
      data.important_description_3,
    );
    this.important_handling_bid_deposit = new ImportantHandlingBidDeposit(
      data.important_handling_bid_deposit,
    );
    this.important_handling_contract_deposit =
      new ImportantHandlingContractDeposit(
        data.important_handling_contract_deposit,
      );
    this.important_other_1 = new ImportantOther1(data.important_other_1);
    this.important_other_2 = new ImportantOther2(data.important_other_2);
    this.important_other_3 = new ImportantOther3(data.important_other_3);
    this.important_restriction_use = new ImportantRestrictionUse(
      data.important_restriction_use,
    );
    this.important_restriction_use_before = new ImportantRestrictionUseBefore(
      data.important_restriction_use_before,
    );
    this.important_risk_bearing = new ImportantRiskBearing(
      data.important_risk_bearing,
    );
    this.important_terms_delivery = new ImportantTermsDelivery(
      data.important_terms_delivery,
    );
    this.inquiry_accept = new InquiryAccept(data.inquiry_accept);
    this.inquiry_display = new InquiryDisplay(data.inquiry_display);
    this.inquiry_email = new InquiryEmail(data.inquiry_email);
    this.inquiry_extension = new InquiryExtension(data.inquiry_extension);
    this.inquiry_name = new InquiryName(data.inquiry_name);
    this.inquiry_tel = new InquiryTel(data.inquiry_tel);
    this.other_trans_right_attention = new OtherTransRightAttention(
      data.other_trans_right_attention,
    );
    this.other_trans_right_description = new OtherTransRightDescription(
      data.other.trans_right_description,
    );
    this.trans_right_completed_time = new TransRightCompletedTime(
      data.trans_right_completed_time,
    );
    this.trans_right_date = new TransRightDate(data.trans_right_date);
    this.trans_right_description = new TransRightDescription(
      data.trans_right_description,
    );
    if (!this.isValid) throw new CustomError('Message の値が不正です');
  }

  get isValid() {
    return (
      this.cost_attention.isValid &&
      this.cost_contract_description_1.isValid &&
      this.cost_contract_description_2.isValid &&
      this.cost_contract_description_3.isValid &&
      this.cost_contract_title_1.isValid &&
      this.cost_contract_title_2.isValid &&
      this.cost_contract_title_3.isValid &&
      this.cost_payment_description_1.isValid &&
      this.cost_payment_description_2.isValid &&
      this.cost_payment_description_3.isValid &&
      this.cost_payment_description_4.isValid &&
      this.cost_payment_description_5.isValid &&
      this.cost_payment_title_1.isValid &&
      this.cost_payment_title_2.isValid &&
      this.cost_payment_title_3.isValid &&
      this.cost_payment_title_4.isValid &&
      this.cost_payment_title_5.isValid &&
      this.document_description.isValid &&
      this.document_page_display.isValid &&
      this.document_page_name.isValid &&
      this.document_page_url.isValid &&
      this.important_attention.isValid &&
      this.important_description_1.isValid &&
      this.important_description_2.isValid &&
      this.important_description_3.isValid &&
      this.important_handling_bid_deposit.isValid &&
      this.important_handling_contract_deposit.isValid &&
      this.important_other_1.isValid &&
      this.important_other_2.isValid &&
      this.important_other_3.isValid &&
      this.important_restriction_use.isValid &&
      this.important_restriction_use_before.isValid &&
      this.important_risk_bearing.isValid &&
      this.important_terms_delivery.isValid &&
      this.inquiry_accept.isValid &&
      this.inquiry_display.isValid &&
      this.inquiry_email.isValid &&
      this.inquiry_extension.isValid &&
      this.inquiry_name.isValid &&
      this.inquiry_tel.isValid &&
      this.other_trans_right_attention.isValid &&
      this.other_trans_right_description.isValid &&
      this.trans_right_completed_time.isValid &&
      this.trans_right_date.isValid &&
      this.trans_right_description.isValid
    );
  }
}

// original/gl-auc/back/lambda/internal/domain/model/dto/csvBusinessInspectionReportDto.go
export class CsvBusinessInspectionReport {
  constructor(data) {
    this.Id = data.Id;
    this.GovernmentName = data.GovernmentName;
    this.DivisionName = data.DivisionName;
    this.Category = data.Category;
    this.ManageID = data.ManageID;
    this.Title = data.Title;
    this.MyListCount = data.MyListCount;
    this.ProposalCount = data.ProposalCount;
    this.BidCount = data.BidCount;
    this.BidderCount = data.BidderCount;
    this.CancelDate = data.CancelDate;
    this.EstimateFee = data.EstimateFee;
    this.Deposit = data.Deposit;
    this.Price = data.Price;
    this.SystemFee = data.SystemFee;
    this.TaxType = data.TaxType;
    this.ConsumptionTaxRate = data.ConsumptionTaxRate;
    this.IsApplyReduction = data.IsApplyReduction;
    this.IsReductionApproved = data.IsReductionApproved;
    this.MovableBidFee = data.MovableBidFee;
    this.MovableBidFeeRate = data.MovableBidFeeRate;
    this.CarBidFee = data.CarBidFee;
    this.CarBidFeeRate = data.CarBidFeeRate;
    this.EstateBidFee = data.EstateBidFee;
    this.EstateBidFeeRate = data.EstateBidFeeRate;
  }
}
