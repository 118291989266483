import AfterWinningBidModal from './AfterWinningBidModal.jsx';
// providerに持っていく
//import useAfterWinningBid from './hooks/useAfterWinningBid.js';

const AfterWinningBidLink = ({
  governmentId,
  governmentName,
  procedureType = 'PUBLIC_SALE',
  afterWinningBids,
}) => {
  if (!afterWinningBids || afterWinningBids?.length === 0) {
    return;
  }
  if (afterWinningBids?.length === 1) {
    return (
      <div className="after-winning-bid-link">
        <AfterWinningBidModal
          afterWinningBid={afterWinningBids[0]}
          governmentId={governmentId}
          governmentName={governmentName}
          procedureType={procedureType}
          label="落札後の注意事項"
        />
      </div>
    );
  }
  return (
    <div className="after-winning-bid-link">
      <div className="flex">
        <span>落札後の注意事項&emsp;[&emsp;</span>
        <span>
          <AfterWinningBidModal
            afterWinningBid={afterWinningBids[0]}
            governmentId={governmentId}
            governmentName={governmentName}
            procedureType={procedureType}
            label="せり売形式"
          />
        </span>
        <span>&emsp;/&emsp;</span>
        <span>
          <AfterWinningBidModal
            afterWinningBid={afterWinningBids[1]}
            governmentId={governmentId}
            governmentName={governmentName}
            procedureType={procedureType}
            label="入札形式"
          />
        </span>
        <span>&emsp;]</span>
      </div>
    </div>
  );
};
export default AfterWinningBidLink;
