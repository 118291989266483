import PropertyInfoFormCautionDescription from 'interfaces/components/common/PropertyInfoForm/PropertyInfoFormCautionDescription';
import { MyDateTimePicker } from 'interfaces/components/Form/MyDatetimePicker.jsx';
import { useState } from 'react';
import ValidationMessage from './ValidationMessage';

import Label from './Label';

const FormDateTimeWithCaution = ({
  caution_description,
  date,
  handleChange,
  label,
  required,
  invalidMessage,
}) => {

  const [open, setOpen] = useState(false);

  return (
    <div
      className="property-info-general-form-table-row"
      data-testid={`form-date-time-with-caution-${label}`}>
      <Label label={label} required={required} />
      <div className="property-info-general-form-table-cell">
        <MyDateTimePicker
          state={date}
          setState={handleChange}
          open={open}
          setOpen={setOpen}
          outline={true}
          isInvalid={invalidMessage !== 'TRUE' && invalidMessage !== null}
        />
        <ValidationMessage invalidMessage={invalidMessage} />
        <PropertyInfoFormCautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormDateTimeWithCaution;
