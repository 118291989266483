import { useGuidelineInputs } from 'shared/utils/helper/useGuidelineInputs';

// validation
import { GuidelinePublicSaleForm } from './GuidelinesPublicSaleForm.jsx';

export function validateInputsGuidelinePublicSaleForm(data) {
  const formInputs = useGuidelineInputs(data);
  const validate = GuidelinePublicSaleForm((data?.length ?? 0) > 0);

  function handleChangeIsDisplay0(e) {
    formInputs.setters[0].setIsDisplay(e.target.value);
  }

  function handleChangeIsDisplay1(e) {
    formInputs.setters[1].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay2(e) {
    formInputs.setters[2].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay3(e) {
    formInputs.setters[3].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay4(e) {
    formInputs.setters[4].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay5(e) {
    formInputs.setters[5].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay6(e) {
    formInputs.setters[6].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay7(e) {
    formInputs.setters[7].setIsDisplay(e.target.value);
  }
  function handleChangeIsDisplay8(e) {
    formInputs.setters[8].setIsDisplay(e.target.value);
  }

  function handleChangeTitle1(e, required) {
    validate.setters[1].setTitle(e, required);
    formInputs.setters[1].setTitle(e.target.value);
  }

  function handleChangeTitle2(e, required) {
    validate.setters[2].setTitle(e, required);
    formInputs.setters[2].setTitle(e.target.value);
  }

  function handleChangeTitle3(e, required) {
    validate.setters[3].setTitle(e, required);
    formInputs.setters[3].setTitle(e.target.value);
  }

  function handleChangeTitle4(e, required) {
    validate.setters[4].setTitle(e, required);
    formInputs.setters[4].setTitle(e.target.value);
  }

  function handleChangeTitle5(e, required) {
    validate.setters[5].setTitle(e, required);
    formInputs.setters[5].setTitle(e.target.value);
  }

  function handleChangeTitle6(e, required) {
    validate.setters[6].setTitle(e, required);
    formInputs.setters[6].setTitle(e.target.value);
  }

  function handleChangeTitle7(e, required) {
    validate.setters[7].setTitle(e, required);
    formInputs.setters[7].setTitle(e.target.value);
  }

  function handleChangeTitle8(e, required) {
    validate.setters[8].setTitle(e, required);
    formInputs.setters[8].setTitle(e.target.value);
  }

  function handleChangeBody0(e, required) {
    validate.setters[0].setBody(e.text, required);
    formInputs.setters[0].setBody(e.text);
  }

  function handleChangeBody1({ text }, required) {
    validate.setters[1].setBody(text, required);
    formInputs.setters[1].setBody(text);
  }

  function handleChangeBody2({ text }, required) {
    validate.setters[2].setBody(text, required);
    formInputs.setters[2].setBody(text);
  }

  function handleChangeBody3({ text }, required) {
    validate.setters[3].setBody(text, required);
    formInputs.setters[3].setBody(text);
  }

  function handleChangeBody4({ text }, required) {
    validate.setters[4].setBody(text, required);
    formInputs.setters[4].setBody(text);
  }

  function handleChangeBody5({ text }, required) {
    validate.setters[5].setBody(text, required);
    formInputs.setters[5].setBody(text);
  }

  function handleChangeBody6({ text }, required) {
    validate.setters[6].setBody(text, required);
    formInputs.setters[6].setBody(text);
  }

  function handleChangeBody7({ text }, required) {
    validate.setters[7].setBody(text, required);
    formInputs.setters[7].setBody(text);
  }

  function handleChangeBody8({ text }, required) {
    validate.setters[8].setBody(text, required);
    formInputs.setters[8].setBody(text);
  }

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      0: {
        setIsDisplay: handleChangeIsDisplay0,
        setTitle: () => {},
        setBody: handleChangeBody0,
      },
      1: {
        setIsDisplay: handleChangeIsDisplay1,
        setTitle: handleChangeTitle1,
        setBody: handleChangeBody1,
      },
      2: {
        setIsDisplay: handleChangeIsDisplay2,
        setTitle: handleChangeTitle2,
        setBody: handleChangeBody2,
      },
      3: {
        setIsDisplay: handleChangeIsDisplay3,
        setTitle: handleChangeTitle3,
        setBody: handleChangeBody3,
      },
      4: {
        setIsDisplay: handleChangeIsDisplay4,
        setTitle: handleChangeTitle4,
        setBody: handleChangeBody4,
      },
      5: {
        setIsDisplay: handleChangeIsDisplay5,
        setTitle: handleChangeTitle5,
        setBody: handleChangeBody5,
      },
      6: {
        setIsDisplay: handleChangeIsDisplay6,
        setTitle: handleChangeTitle6,
        setBody: handleChangeBody6,
      },
      7: {
        setIsDisplay: handleChangeIsDisplay7,
        setTitle: handleChangeTitle7,
        setBody: handleChangeBody7,
      },
      8: {
        setIsDisplay: handleChangeIsDisplay8,
        setTitle: handleChangeTitle8,
        setBody: handleChangeBody8,
      },
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
