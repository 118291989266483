import Heading2 from './Heading/Heading2.jsx';

const PropertyInfoGeneralPageDescription = ({
  title,
  content,
  displayRequired,
  requiredLabel = "必須項目"
}) => {
  return (
    <>
      <Heading2 title={title} />
      <p className="pt-3 pb-3">
        <>
          {content.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </>
        {displayRequired && (
          <>
            <span className="property-info-general-page-description-content-required">
              *
            </span>
            は{requiredLabel}です。
          </>
        )}
      </p>
    </>
  );
};
export default PropertyInfoGeneralPageDescription;
