import Breadcrumb from 'interfaces/components/common/Breadcrumb';
import PageCautionDescription from 'interfaces/components/common/PageCautionDescription';
import { validateInputsBankProvisionalApplyCompletion } from 'interfaces/components/common/validateInputs/validateInputsBankProvisionalApplyCompletion.jsx';
import LocalGovernmentManagementModal from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementModal';
import PropertyInfoGeneralPageDescription from 'interfaces/components/common/PropertyInfoGeneralPageDescription';
import { breadcrumbs } from 'interfaces/pages/LocalGovernmentManagementBankTransfer/entities/breadcrumbs';
import { useContext, useState } from 'react';

import BankTransferForm from './BankTransferForm';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import { LocalGovernmentManagementBankTransferContext } from '../../../pages/LocalGovernmentManagementBankTransfer/entities/LocalGovernmentManagementBankTransferContext';

import { textExtractionByProcedureType } from 'shared/utils/helper/TextExtraction';

const BankTransfer = () => {
  const [toggle, setToggle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { auctionBankTransferPage, bidBankTransferPage } = useContext(
    LocalGovernmentManagementBankTransferContext,
  );

  const auction = validateInputsBankProvisionalApplyCompletion(
    auctionBankTransferPage,
  );
  const bid = validateInputsBankProvisionalApplyCompletion(bidBankTransferPage);

  const { localStorageProcedureType, localStorageGovernmentType } =
    useLocalStorage();

  return (
    <>
      {isOpen ? (
        <LocalGovernmentManagementModal
          handleClose={() => setIsOpen(false)}
          procedureType={localStorageProcedureType}
        />
      ) : (
        <></>
      )}
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <PropertyInfoGeneralPageDescription
        title={'行政機関ページ管理：銀行振込など仮申し込み完了画面'}
        content={`銀行振込などで${localStorageProcedureType == 'ASSET_SALE' ? '入札' : '公売'}保証金を納付する参加者への、その後の手続きの案内を設定します。`}
        displayRequired={true}
      />
      <div className="card-provisional-apply-completion-page-description">
        下記の場合は設定の必要はありません。
        <br />
        <ul>
          <li>
            {localStorageProcedureType === 'ASSET_SALE' ? '入札' : '公売'}
            保証金の納付に銀行振込などによる納付方法を利用しない場合
          </li>
          <li>
            すべての物件に
            {localStorageProcedureType === 'ASSET_SALE' ? '入札' : '公売'}
            保証金を設定しない場合
          </li>
        </ul>
      </div>
      <PageCautionDescription
        content={`※${textExtractionByProcedureType(localStorageProcedureType)}の参加申込開始日以降は掲載終了まで修正ができません。`}
      />
      <BankTransferForm
        auction={auction}
        bid={bid}
        toggle={toggle}
        setToggle={setToggle}
        setIsOpen={setIsOpen}
        governmentType={localStorageGovernmentType}
        procedureType={localStorageProcedureType}
      />
    </>
  );
};
export default BankTransfer;
