import ResetPasswordForm from './ResetPasswordForm.jsx';

import { styled } from 'styled-components';

const ResetPasswordModalContent = styled.div`
  width: 100%;
  padding: 8px 8px 16px 8px;
  border-top: solid 8px var(--primary-base);
  border-radius: 5px;
  pointer-events: auto;
`;

const ResetPasswordModalContentsTitle = styled.p`
  font-size: 1.25rem;
  text-align: center;
  padding: 16px;
`;

const ResetPasswordModalContents = ({ handleClose, setModalType }) => {
  return (
    <ResetPasswordModalContent data-testid="reset-password-modal-contents">
      <ResetPasswordModalContentsTitle data-testid="reset-password-modal-contents-title">
        パスワード変更
      </ResetPasswordModalContentsTitle>
      <ResetPasswordForm
        handleClose={handleClose}
        setModalType={setModalType}
      />
    </ResetPasswordModalContent>
  );
};

export default ResetPasswordModalContents;
