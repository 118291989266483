import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';
import { Link } from 'react-router-dom';
import { calc_dead_line } from 'shared/utils/helper/calc_dead_line.js';

import { MessageLink } from './WaitOpenBid';

export const NotAddBid = ({ data, setIsNoMessage }) => {
  if (data.status === 'ADDITIONAL_DURING_BIDDING') {
    let deadline = calc_dead_line(data.deadline);

    return (
      <>
      <MessageLink
        prevText={'あなたの他に最高価額の入札者がいます。'}
        href={URL_LINKS.ABOUT_ADD_BID_URL}
        content={'追加入札'}
        laterText={'を行ってください。'}
      />
      <p>{`追加入札締切まであと${deadline}`}</p>
      </>
    );
  }

  if (data.status === 'END_BIDDING') {
    if (data.bidderUser === data.membershipId) {
      return (
        <p className="ma-0" data-testid="announce-message">
          <span>おめでとうございます！ あなたが落札者です。</span>
          <br />
          今後のお手続きについては、お客様から電話で下記連絡先に、問い合わせください。
          <br />
          ※買受代金納付期限までに買受代金の納付を執行機関が確認できない場合、公売保証金は没収となります。
        </p>
      );
    }
    if (data.nextWinner === data.membershipId) {
      return (
        <p className="ma-0" data-testid="announce-message">
          あなたは次順位買受申し込み者となりました。
          <br />
          最高価申し込み者が買受代金を納付しなかった場合などに、あなたは落札者となります。
        </p>
      );
    }
    return (
      <p className="ma-0" data-testid="announce-message">
        残念ながら落札できませんでした。またのご利用をお待ちしております。
        <br />
        今後の開催予定は<Link to={'/schedules'}>こちら</Link>
        からご確認ください。
      </p>
    );
  }

  setIsNoMessage(true);
};
