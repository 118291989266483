import Confirm from 'interfaces/components/common/Confirm';
import Form from 'interfaces/components/common/Form/Form';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import { validateInputsNotices } from 'interfaces/components/News/kmanager/validateInputsNotices';
import { useState } from 'react';

//import { useNoticeRegister } from '../../hooks/useNotices';

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

const dateTimeField = (value) => {
  if (value instanceof Date) {
    return value.getTime();
  } else {
    return value;
  }
};

const NoticeFormModal = ({ closeModal, notice }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  let inputs = validateInputsNotices(notice);

  const isDisplayServiceTypeNavi = inputs.find(
    (object) => object.column === 'displayServiceType',
  ).state.navi;

  // お知らせ表示先システムで官公庁ナビがチェックされていない場合は、お知らせ表示先（契約種別）とお知らせ表示先(行政機関種別)を非表示にする
  if (isDisplayServiceTypeNavi !== 'TRUE') {
    inputs = inputs.filter(
      (object) =>
        object.column !== 'displayProcedureType' &&
        object.column !== 'displayGovernmentType',
    );
  }

  inputs = inputs.filter((object) => object.column !== 'closeAt');

  //const formDataAdd = (inputBody) => {
  //  let snakeBody = bodyToSnake(createBody(inputBody));
  //  // お知らせ表示先システム
  //  const displayServiceTypeService = snakeBody['display_service_type'].service;
  //  const displayServiceTypeNavi = snakeBody['display_service_type'].navi;
  //  // お知らせ表示先システムでサービス画面、官公庁ナビの両方を選択していた場合
  //  if (displayServiceTypeService === "TRUE" && displayServiceTypeNavi === "TRUE") {
  //    snakeBody['display_service_type'] = "BOTH";
  //  }
  //  // お知らせ表示先システムでサービス画面のみ選択していた場合
  //  if (displayServiceTypeService === "TRUE" && displayServiceTypeNavi === "") {
  //    snakeBody['display_service_type'] = "SERVICE";
  //  }
  //  // お知らせ表示先システムで官公庁ナビのみ選択していた場合
  //  if (displayServiceTypeService === "" && displayServiceTypeNavi === "TRUE") {
  //    snakeBody['display_service_type'] = "NAVI";
  //  }

  //  // お知らせ表示先システムでサービス画面のみ選択していた場合は、display_procedure_type, display_government_typeはNoneになる
  //  if (snakeBody['display_service_type'] === "SERVICE") {
  //    snakeBody['display_procedure_type'] = "NONE";
  //    snakeBody['display_government_type'] = "NONE";
  //  } else {
  //    // お知らせ表示先（契約種別）
  //    const displayProcedureTypePublicSale = snakeBody['display_procedure_type']?.publicSale;
  //    const displayProcedureTypeAssetSale = snakeBody['display_procedure_type']?.assetSale;
  //    // お知らせ表示先システムでサービス画面、官公庁ナビの両方を選択していた場合
  //    if (displayProcedureTypePublicSale === "TRUE" && displayProcedureTypeAssetSale === "TRUE") {
  //      snakeBody['display_procedure_type'] = "BOTH";
  //    }
  //    // お知らせ表示先システムでサービス画面のみ選択していた場合
  //    if (displayProcedureTypePublicSale === "TRUE" && displayProcedureTypeAssetSale === "") {
  //      snakeBody['display_procedure_type'] = "PUBIC_SALE";
  //    }
  //    // お知らせ表示先システムで官公庁ナビのみ選択していた場合
  //    if (displayProcedureTypePublicSale === "" && displayProcedureTypeAssetSale === "TRUE") {
  //      snakeBody['display_procedure_type'] = "ASSET_SALE";
  //    }

  //    // お知らせ表示先(行政機関種別)
  //    const displayGovernmentTypeNationalTaxAgency = snakeBody['display_government_type']?.nationalTaxAgency;
  //    const displayGovernmentTypeLocalGovernment = snakeBody['display_government_type']?.localGovernment;
  //    // お知らせ表示先システムでサービス画面、官公庁ナビの両方を選択していた場合
  //    if (displayGovernmentTypeNationalTaxAgency === "TRUE" && displayGovernmentTypeLocalGovernment === "TRUE") {
  //      snakeBody['display_government_type'] = "BOTH";
  //    }
  //    // お知らせ表示先システムでサービス画面のみ選択していた場合
  //    if (displayGovernmentTypeNationalTaxAgency === "TRUE" && displayGovernmentTypeLocalGovernment === "") {
  //      snakeBody['display_government_type'] = "NATIONAL_TAX_AGENCY";
  //    }
  //    // お知らせ表示先システムで官公庁ナビのみ選択していた場合
  //    if (displayGovernmentTypeNationalTaxAgency === "" && displayGovernmentTypeLocalGovernment === "TRUE") {
  //      snakeBody['display_government_type'] = "LOCAL_GOVERNMENT";
  //    }
  //  }

  //  // 重要度
  //  const severityType = snakeBody['severity_type']?.severityType;
  //  if (severityType === "TRUE") {
  //    snakeBody['severity_type'] = "EMERGENCY";
  //  } else {
  //    snakeBody['severity_type'] = "NORMAL";
  //  }

  //  snakeBody['open_at'] = dateFormat(snakeBody['open_at'], 'Y-M-D hh:mm');
  //  snakeBody['close_at'] = dateFormat(snakeBody['close_at'], 'Y-M-D hh:mm');

  //  return snakeBody
  //}

  const Submit = async () => {
    const dataObj = inputs.reduce((acc, cur) => {
      acc[cur.column] = cur.state;

      return acc;
    }, {});

    console.log(dataObj);

    const body = {
      title: dataObj.title,
      body: dataObj.body,
      is_display_service: dataObj.displayServiceType.service || 'FALSE',
      is_display_navi: dataObj.displayServiceType.navi || 'FALSE',
      is_display_public_sale:
        dataObj.displayProcedureType?.publicSale || 'FALSE',
      is_display_asset_sale: dataObj.displayProcedureType?.assetSale || 'FALSE',
      is_display_local_gov:
        dataObj.displayGovernmentType?.localGovernment || 'FALSE',
      is_display_tax_agency:
        dataObj.displayGovernmentType?.nationalTaxAgency || 'FALSE',
      is_farmland_info: 'FALSE',
      is_important: dataObj.severityType.severityType || 'FALSE',
      open_at: dateTimeField(dataObj.openAt),
      //close_at: null,
    };

    if (notice?.id) {
      await apiRequest(
        'put',
        `${API_BASE_URL}/api/v1/kmanager/notices/${notice.id}`,
        JSON.stringify(body),
      );
    } else {
      await apiRequest(
        'post',
        `${API_BASE_URL}/api/v1/kmanager/notices`,
        JSON.stringify(body),
      );
    }

    closeModal();
  };

  const Create = () => {
    setIsConfirmOpen(true);
  };

  const FORM_BUTTONS_DATA = [
    {
      text: '確認',
      clickHandler: Create,
      className: 'primary',
    },
    {
      text: 'キャンセル',
      clickHandler: () => closeModal(),
      className: 'warning',
    },
  ];

  return (
    <Modal2 handleClose={closeModal} modalSize={'M'}>
      <Confirm
        type="submit"
        isOpen={isConfirmOpen}
        closeModal={() => setIsConfirmOpen(false)}
        Submit={Submit}
      />
      <div
        className="kmanager-container kmanager-notice-create-modal kmanager-create-modal"
        data-testid="kmanager-notice-create-modal">
        <h2 className="my-5 pl-5">
          {notice?.id ? 'お知らせ編集' : 'お知らせ新規作成'}
        </h2>
        <Form inputs={inputs} buttonData={FORM_BUTTONS_DATA} />
      </div>
    </Modal2>
  );
};

export default NoticeFormModal;
