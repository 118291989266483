import { procedureType } from 'shared/utils/constants/property';

export const procedureTypeParam = (type) => {
  if (type === procedureType.PUBLIC_SALE) {
    return 'au';
  }
  if (type === procedureType.ASSET_SALE) {
    return 'as';
  }
  return '';
};
