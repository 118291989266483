import Heading3 from 'interfaces/components/common/Heading/Heading3';
import PropertyInfoGeneralFormInputMarkDown from 'interfaces/components/common/PropertyInfoForm/PropertyInfoGeneralFormInputMarkDown';
import { local_government_management_radio1 } from 'interfaces/components/common/RadioLabel';
import Form from 'interfaces/components/LocalGovernmentManagement/guideline/Form';
import { guideline_pledge_body } from 'shared/utils/helper/DummyEditFormData';

import FormRadioWithCaution from '../../Form/navi/FormRadioWithCaution';

const Both = ({ data, procedureType }) => {
  return (
    <form data-testid="both-form">
      <div className="property-info-general-form-table">
        {procedureType === 'ASSET_SALE' && (
          <div className="property-info-general-form-table">
            <Heading3 text={'誓約書'} />
            <FormRadioWithCaution
              label={'誓約書の表示'}
              radio_label={local_government_management_radio1}
              data={data.data[0].isDisplay}
              handler1={(e) => {
                data.setters[0].setIsDisplay(e);
                data.setters[0].setBody(
                  { text: data.data[0].body },
                  e.target.value == 'DISPLAY',
                );
              }}
            />
            <PropertyInfoGeneralFormInputMarkDown
              label={'誓約書本文'}
              required={data.data[0].isDisplay == 'DISPLAY'}
              caution_description={guideline_pledge_body}
              data={data.data[0].body}
              maxCharacters={2000}
              handler1={(e) =>
                data.setters[0].setBody(
                  e,
                  data.data[0].isDisplay == 'DISPLAY',
                )
              }
              invalidMessage={data.validationMessage[0].body}
            />
          </div>
        )}
        <Form
          data={data.data[1]}
          handler1={data.setters[1]}
          invalidMessage={data.validationMessage[1]}
        />
        <Form
          data={data.data[2]}
          handler1={data.setters[2]}
          invalidMessage={data.validationMessage[2]}
        />
        <Form
          data={data.data[3]}
          handler1={data.setters[3]}
          invalidMessage={data.validationMessage[3]}
        />
        <Form
          data={data.data[4]}
          handler1={data.setters[4]}
          invalidMessage={data.validationMessage[4]}
        />
        <Form
          data={data.data[5]}
          handler1={data.setters[5]}
          invalidMessage={data.validationMessage[5]}
        />
        <Form
          data={data.data[6]}
          handler1={data.setters[6]}
          invalidMessage={data.validationMessage[6]}
        />
        <Form
          data={data.data[7]}
          handler1={data.setters[7]}
          invalidMessage={data.validationMessage[7]}
        />
        <Form
          data={data.data[8]}
          handler1={data.setters[8]}
          invalidMessage={data.validationMessage[8]}
        />
      </div>
    </form>
  );
};

export default Both;
