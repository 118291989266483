import { ReactComponent as SelectedMylistIcon } from 'interfaces/assets/svg/selected_mylist_icon.svg';
import { ReactComponent as MylistIcon } from 'interfaces/assets/svg/star_icon.svg';
import AuthModal from 'interfaces/components/common/Header/AuctionHeader/HeaderTop/AuthModal.jsx';
import { useEffect, useState } from 'react';
import { useAuth } from 'shared/hooks/useAuth.js';
import useModalControl from 'shared/hooks/useModalControl.js';
import { LOGIN } from 'shared/utils/helper/authState.js';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import { styled } from 'styled-components';

import 'interfaces/css/PropertyDetail/action-area.css';

const MylistBtn = styled.button`
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
`;

const MylistDisabledBtn = styled.button`
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  display: inline-flex;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  font-size: 1.25rem;
  width: 100%;
  height: 70px;
  min-width: 92px;
  color: rgba(0, 0, 0, 0.26) !important;
  padding: 0 23px;
  border: 2px solid;
`;

export const MylistBtnBranch = ({ myListBtn, className, children, data }) => {
  const { localStorageUserId } = useLocalStorage;
  const isLoggedIn = () => {
    return localStorageUserId !== null && localStorageUserId !== undefined;
  };

  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [currentAuthStatus, setCurrentAuthStatus] = useState(null);
  const { userId } = useAuth();

  const [isDisabled, setIsDisabled] = useState(false);

  // マイリストをしているかの状態を保持
  const [isMyListBtn, setIsMyListBtn] = useState(false);

  const mylistSelected = () => {
    if (!isLoggedIn) {
      handleOpen();
      setIsMyListBtn(false);
    } else {
      setIsMyListBtn(true);
    }
  };

  const mylistRemove = () => {
    setIsMyListBtn(false);
  };

  const switchAuthStatus = (authStatus) => {
    setCurrentAuthStatus(authStatus);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setIsMyListBtn(false);
      setCurrentAuthStatus(LOGIN);
    } else if (myListBtn) {
      setIsMyListBtn(true);
    }
  }, [myListBtn, userId]);

  // マイリストのボタンのdisabledを管理
  useEffect(() => {
    if (
      ['APPLYING', 'WAIT_START_BID', 'DURING_BIDDING', 'WAIT_OPEN_BID', 'ADDITIONAL_DURING_BIDDING', 'END_BIDDING'].includes(data?.status) &&
      ['TRIAL', 'APPLICATION', 'CANCEL'].includes(data?.aplStatus)
    ) {
      setIsDisabled(true);
    }
  }, [data]);

  return (
    <>
      {isMyListBtn === true ? (
        isDisabled === true ? (
          <MylistDisabledBtn>
            <SelectedMylistIcon className="add-mylist-button-disabled" />
            {children}
          </MylistDisabledBtn>
        ) : (
          <>
            <button
              data-testid="mylist-button"
              onClick={mylistRemove}
              disabled={myListBtn}
              className={className + ' ' + 'v-btn mylist-button'}
              style={{ flex: 1 }}>
              <SelectedMylistIcon className="add-mylist-button" />
              {children}
            </button>
            <AuthModal
              isOpen={isOpen}
              handleClose={handleClose}
              currentAuthStatus={currentAuthStatus}
              switchAuthStatus={switchAuthStatus}
            />
          </>
        )
      ) : isDisabled === true ? (
        <MylistDisabledBtn>
          <MylistIcon className="add-mylist-button-disabled" />
          {children}
        </MylistDisabledBtn>
      ) : (
        <>
          <MylistBtn
            data-testid="mylist-button"
            onClick={mylistSelected}
            className={`${className}` + ' ' + 'v-btn mylist-button'}
            style={{ flex: 1 }}>
            <MylistIcon className="add-mylist-button" />
            {children}
          </MylistBtn>
          <AuthModal
            isOpen={isOpen}
            handleClose={handleClose}
            currentAuthStatus={currentAuthStatus}
            switchAuthStatus={switchAuthStatus}
          />
        </>
      )}
    </>
  );
};
