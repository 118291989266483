import GuidelineBody from 'interfaces/components/common/Guideline/GuidelineBody.jsx';
import Button from 'interfaces/components/common/Button/Button.jsx';

const GuidelineAgreement = ({ data, previousStep, nextStep }) => {
  return (
    <div data-testid="guideline-agreement">
      <GuidelineBody
        governmentName={data?.governmentName}
        procedureType={data?.procedureType}
        data={data}
      />
      <div className="flex justify-center mb-14">
        <Button
          text={
            <p>
              ガイドラインに同意して、
              <br />
              次のステップへ
            </p>
          }
          width="300px"
          height="52px"
          onClick={nextStep}
          className="agreement-guideline-button mr-4"
        />
        <Button
          text="キャンセル"
          width="120px"
          height="52px"
          onClick={previousStep}
          className="cancel-common-button"
        />
      </div>
    </div>
  );
};

export default GuidelineAgreement;
