import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import '../../css/common/breadcrumb.css';
import { ReactComponent as ChevronRight } from '../../assets/svg/chevron-right.svg';

const generateTitle = (breadcrumbs) => {
  const breadcrumbTitle = breadcrumbs.map((b) => b.title);
  if (window.location.pathname.includes('kmanager')) {
    return '運営管理ツール';
  }
  if (window.location.pathname.includes('service')) {
    return 'KSI官公庁オークション（公売・公有財産売却） - 自治体・行政団体が出品する安心なオークション';
  }

  if (breadcrumbs.length == 1) {
    return '官公庁ナビトップ | 官公庁ナビ';
  }
  breadcrumbTitle[0] = '官公庁ナビ';
  return breadcrumbTitle.slice().reverse().join(' | ');
};

const Breadcrumb = ({ breadcrumbs }) => {
  useEffect(() => {
    document.title = generateTitle(breadcrumbs);
  }, [breadcrumbs]);
  return (
    <div className="breadcrumbs-wrap" data-testid="breadcrumb">
      <ul className="breadcrumbs">
        {breadcrumbs &&
          breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumbs.length - 1 === index) {
              return <li key={index}>{breadcrumb.title}</li>;
            } else {
              return (
                <React.Fragment key={index}>
                  {breadcrumb.to === undefined ? (
                    <li key={index}>{breadcrumb.title}</li>
                  ) : (
                    <li>
                      <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
                    </li>
                  )}
                  <li className="breadcrumbs-divider">
                    <span className="icon">
                      <ChevronRight />
                    </span>
                  </li>
                </React.Fragment>
              );
            }
          })}
      </ul>
    </div>
  );
};

export default Breadcrumb;
