import CheckBox from 'interfaces/components/common/Form/kmanager/CheckBox';
import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';
import React from 'react';
import { CautionMessage } from '../CautionMessage';
import FormLabel from './FormLabel.jsx';

const InputCheckBox = React.memo(function InputCheckBox({
  invalidMessage,
  required,
  label,
  check_labels,
  state,
  setState,
  describe,
  cautionMessage,
}) {
  const handleChange = (event) => {
    setState(event);
  };
  return (
    <>
      <div className="kmanager-row">
        <div className="kmanager-col-4">
          <FormLabel label={label} required={required} />
        </div>
        <div className="kmanager-col kmanager-col-wrap">
          <div className="kmanager-col kmanager-col-buttons">
            {check_labels &&
              check_labels.map((label) => {
                return (
                  <>
                    <div className="kmanager-col">
                      <div
                        key={label.id}
                        className="flex form-list"
                        data-testid="input-check-box">
                        <CheckBox
                          id={label.name}
                          name={label.name ?? ''}
                          data={state[label.name] ?? false}
                          handleChange={handleChange}
                        />
                        <label
                          htmlFor={label.name}
                          data-testid="property-info-general-form-input-check-label"
                          className="property-info-general-form-input-check-label">
                          {label.text}
                        </label>
                      </div>
                      <ValidationMessage invalidMessage={invalidMessage} />
                    </div>
                  </>
                );
              })}
          </div>
          <p className={'input-checkbox-describe'}>{describe}</p>
        </div>
      </div>
      <div className="kmanager-row">
        <CautionMessage cautionMessage={cautionMessage} />
      </div>
    </>
  );
});
export default InputCheckBox;
