import { useState } from 'react';

export function useNewEntryInputs() {
  const [auctionName, setAuctionName] = useState('');
  const [name, setName] = useState('');
  const [aplCountAuctionDisplay, setAplCountAuctionDisplay] =
    useState('DISPLAY');
  const [aplCountBidDisplay, setAplCountBidDisplay] = useState('DISPLAY');
  const [bidCountDisplay, setBidCountDisplay] = useState('DISPLAY');
  const [auctionId, setAuctionId] = useState('');
  const [bidEndType, setBidEndType] = useState('MANUAL');
  const [mailCertDocument, setMailCertDocument] = useState('NO_MAIL');
  const [mailReductionDocument, setMailReductionDocument] = useState('MAIL');
  const [depositDeliveryStatus, setDepositDeliveryStatus] = useState({
    paymentCreditCard: false,
    paymentBankTransfer: false,
    noneDeposit: false,
  });
  const [area, setArea] = useState('');
  const [contactOffice, setContactOffice] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactTel, setContactTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [telNum1, setTelNum1] = useState('');
  const [telNum2, setTelNum2] = useState('');
  const [telNum3, setTelNum3] = useState('');

  const newEntryInputs = {
    data: {
      auctionName,
      name,
      aplCountAuctionDisplay,
      aplCountBidDisplay,
      bidCountDisplay,
      auctionId,
      bidEndType,
      mailCertDocument,
      mailReductionDocument,
      depositDeliveryStatus,
      area,
      contactOffice,
      contactEmail,
      contactTel,
      telNum1,
      telNum2,
      telNum3,
    },
    setters: {
      setAuctionName,
      setName,
      setAplCountAuctionDisplay,
      setAplCountBidDisplay,
      setBidCountDisplay,
      setAuctionId,
      setBidEndType,
      setMailCertDocument,
      setMailReductionDocument,
      setDepositDeliveryStatus,
      setArea,
      setContactOffice,
      setContactEmail,
      setContactTel,
      setTelNum1,
      setTelNum2,
      setTelNum3,
    },
  };
  return newEntryInputs;
}
