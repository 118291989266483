import { fetchEmailTemplates } from 'infrastructure/api/storage/email_templates';
import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import FlatButton from 'interfaces/components/common/Button/FlatButton';
import EmailTemplateModal from 'interfaces/components/Email/EmailTemplateModal';
import { useState, useEffect } from 'react';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import useModalControl from 'shared/hooks/useModalControl';
import Handlebars from 'handlebars';

const EmailTemplate = ({ text, template, governmentName }) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const { localStorageProcedureType } = useLocalStorage();
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [parsedEmailTemplate, setParsedEmailTemplate] = useState(null);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const fetchTemplate = async () => {
      if (isOpen) {
        console.log(template);
        try {
          const templateMessage = await fetchEmailTemplates(
            template.templateName,
            localStorageProcedureType === 'ASSET_SALE' ? 'asset' : 'auction',
          );
          setEmailTemplate(templateMessage);
          setReady(true);
        } catch (error) {
          console.error('Failed to fetch or parse JSON:', error);
        }
      }
    };

    if (isOpen) {
      setReady(false); // Reset ready state when modal is opened
      fetchTemplate();
    }
  }, [isOpen, template.templateName, localStorageProcedureType]);

  useEffect(() => {
    if (emailTemplate) {
      const handlebarTemplate = Handlebars.compile(emailTemplate);
      const text = handlebarTemplate(
        parameters(template.templateName.includes('offline')),
      );
      setParsedEmailTemplate(text);
    }
  }, [emailTemplate, template.templateName]);

  const parameters = (isOffline) => ({
    addBidEnd: '2021年 4月 8日 12時00分',
    addBidStart: '2021年 4月 8日 11時00分',
    aplTypeOnline: 'クレジットカード',
    aplTypeOffline: '銀行振込など',
    aplType: isOffline ? '銀行振込など' : 'クレジットカード',
    auctionEnd: '2021年 4月 8日 10時00分',
    bidEnd: '2021年 4月 8日 10時00分',
    bidPrice: '100,000',
    bidStart: '2021年 4月 4日 10時00分',
    contractPrice: '100,000',
    deliveryStyle: '運送業者に依頼',
    deposit: '10,000',
    estimateFee: '100,000',
    identificationNumber: '会員識別番号',
    mailTemplateText: '【※全角1000文字以内】',
    manageID: '1234567890',
    governmentName: governmentName,
    payLimit: '2021年 5月 1日 20時0分',
    propertyTitle: '物件名',
    propertyURL: 'https://kankocho.jp/items/test_ksi',
    topURL: 'https://kankocho.jp/',
    myListURL: 'https://kankocho.jp/mylist/',
    naviURL: 'https://navi.kankocho.jp',
    naviManualURL: 'https://navi.kankocho.jp/manual',
    naviPDFGuidelineURL:
      'https://navi.kankocho.jp/documents/handling-prohibition-property-guideline.pdf',
    naviPDFManualAssetSaleURL:
      'https://navi.kankocho.jp/documents/manual-asset-sale.pdf',
    naviPDFManualAuctionURL:
      'https://navi.kankocho.jp/documents/manual-auction.pdf',
    naviNoticeURL: 'https://navi.kankocho.jp/notice',
    contractURL: 'https://help.kankocho.jp/knowledge/help028',
    nextPurchaseApplicationURL: 'https://help.kankocho.jp/knowledge/help026',
    additionalBidURL: 'https://help.kankocho.jp/knowledge/help025',
    aplFormURL: 'https://navi.kankocho.jp/new-entry',
    manageScheduleURL: 'https://navi.kankocho.jp/auction-schedule',
    cancelPublicPropertyURL: 'https://help.kankocho.jp/knowledge/help040',
    propertyPrefixURL: 'https://kankocho.jp/items/',
  });

  return (
    <div data-testid="email-template">
      <FlatButton text={text} icon={<PencilIcon />} onClick={handleOpen} />
      {ready && (
        <EmailTemplateModal
          isOpen={isOpen}
          title={template.subject ?? 'タイトルなし'}
          description={template.leadText}
          emailTemplateText={parsedEmailTemplate}
          mailTemplateText={template.mailTemplateText}
          maxTextLimit={1000}
          closeModal={handleClose}
        />
      )}
    </div>
  );
};
export default EmailTemplate;
