import { card_provisional_apply_completion_description1 } from 'shared/utils/helper/DummyEditFormData';

import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const AuctionForm = ({ auction, procedureType, governmentType }) => {
  return (
    <div className="pt-9">
      <div className="property-info-general-form-table">
        <FormTextWithCaution
          label={'必要書類提出の案内ページURL'}
          required={
            procedureType == 'ASSET_SALE' ||
            governmentType == 'NATIONAL_TAX_AGENCY'
          }
          caution_description={card_provisional_apply_completion_description1(procedureType)}
          data={auction.data.documentSubmissionUrl}
          handler1={(e) =>
            auction.setters.handleDocumentSubmissionUrl(
              e,
              procedureType == 'ASSET_SALE' ||
                governmentType == 'NATIONAL_TAX_AGENCY',
            )
          }
          invalidMessage={
            auction.validationMessage
              .documentSubmissionUrlRequiredValid
          }
        />
      </div>
    </div>
  );
};

export default AuctionForm;
