import { useState } from 'react';
import {
  validateTelRangeLength,
  validateTelPartRangeLength,
  validateExtensionRangeLength,
  validateHasNoSpace,
  validateEmailLength,
  validateUrlFormatCheck,
  validateEmailFormatCheck,
  validateRangeLength,
  validateRequired,
  validateNumber,
  hasEmoji,
  validateSelected,
  validateNumberRange,
  validateSomeSelected,
  validateDeposit,
} from 'application/validations';


export const UseRealEstateInputsValidation = ({ isEdit = false }) => {
  // 2. 物件情報
  const [printOrderValid, setPrintOrderValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 掲載順位
  const [categoryIdValid, setCategoryIdValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [divisionNameRequiredValid, setDivisionNameRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [manageIdRequiredValid, setManageIdRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [titleRequiredValid, setTitleRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [paymentMethodRequiredValid, setPaymentMethodRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [paymentDeadlineRequiredValid, setPaymentDeadlineRequiredValid] =
    useState(isEdit ? 'TRUE' : null);
  const [resolutionDateValid, setResolutionDateValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [videoUrlValid, setVideoUrlValid] = useState(isEdit ? 'TRUE' : null);
  const [descriptionValid, setDescriptionValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 3. 物件に関するお問い合わせ先
  const [inquiryNameValid, setInquiryNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryDepartmentValid, setInquiryDepartmentValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryEmailValid, setInquiryEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelValid, setInquiryTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum1Valid, setInquiryTelNum1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum2Valid, setInquiryTelNum2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum3Valid, setInquiryTelNum3Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryExtensionValid, setInquiryExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryAcceptValid, setInquiryAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [noteValid, setNoteValid] = useState(isEdit ? 'TRUE' : null);

  // 4. 物件の詳細設定
  const [estimatePriceRequiredValid, setEstimatePriceRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [depositRequiredValid, setDepositRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [qualifiedInvoiceValid, setQualifiedInvoiceValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 5. 落札後の連絡先
  const [contactNameValid, setContactNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactDepartmentValid, setContactDepartmentValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactEmailValid, setContactEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelValid, setContactTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum1Valid, setContactTelNum1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum2Valid, setContactTelNum2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelNum3Valid, setContactTelNum3Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactExtensionValid, setContactExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactAcceptValid, setContactAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  //const [contractedLimitValid, setContractedLimitValid] = useState(isEdit ? 'TRUE' : null);

  // カテゴリー
  function handleCategoryIdValid(id) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    console.log(id);
    invalid = validateSelected(id);
    if (invalid !== 'TRUE') {
      setCategoryIdValid(invalid);
      return;
    }
    setCategoryIdValid(invalid); // エラーメッセージのリセット
  }

  function handleDivisionIdValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setDivisionNameRequiredValid(invalid);
      return;
    }
    setDivisionNameRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handlePrintOrderValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumberRange(e.target.value);
    if (invalid !== 'TRUE') {
      setPrintOrderValid(invalid);
      return;
    }
    setPrintOrderValid(invalid); // エラーメッセージのリセット
  }

  function handleSaleNumberValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setManageIdRequiredValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setManageIdRequiredValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    console.log(invalid);
    if (invalid !== 'TRUE') {
      setManageIdRequiredValid(invalid);
      return;
    }
    setManageIdRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleTitleValid(e) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setTitleRequiredValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setTitleRequiredValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setTitleRequiredValid(invalid);
      return;
    }
    setTitleRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleDepositValid(e, payment) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    invalid = validateDeposit(
      e.target.value,
      payment,
    );
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    setDepositRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handlePaymentMethodValid(paymentMethod) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSomeSelected([paymentMethod]);
    if (invalid !== 'TRUE') {
      setPaymentMethodRequiredValid(invalid);
      return;
    }
    setPaymentMethodRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleEstimatePriceValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 11);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }

    setEstimatePriceRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleQualifiedInvoiceValid() {
    // logInput(e.target.name, e.target.value);
    setQualifiedInvoiceValid("TRUE")
  }

  function handlePaymentDeadlineValid(date) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateSelected(date);
    if (invalid !== 'TRUE') {
      setPaymentDeadlineRequiredValid(invalid);
      return;
    }
    setPaymentDeadlineRequiredValid(invalid); // エラーメッセージのリセット
  }

  //function handlePaymentScheduledDateValid(e) {
  //  // logInput(e.target.name, e.target.value);
  //  let invalid = null;
  //  invalid = Validation.validateCcontractedLimitRequired(e.target.value);
  //  if (invalid !== 'TRUE') {
  //    setContractedLimitValid(invalid);
  //    return;
  //  }
  //  setContractedLimitValid(invalid); // エラーメッセージのリセット
  //}

  function handleVideoUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (e.target.value.length === 0) {
      setVideoUrlValid(invalid);
      return;
    }
    invalid = validateUrlFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 2048);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    setVideoUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleDescriptionValid(text) {
    let invalid = null;
    invalid = validateRangeLength(text, 0, 15000);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    invalid = validateRequired(text);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    invalid = hasEmoji(text);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    setDescriptionValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    setInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setInquiryDepartmentValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryDepartmentValid(invalid);
      return;
    }
    setInquiryDepartmentValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid);
      return;
    }
    invalid = validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid);
      return;
    }
    setInquiryEmailValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryTelValid(telData) {
    //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateTelRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum2Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum3Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryExtensionValid(e) {
    // //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateExtensionRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    setInquiryExtensionValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    setInquiryAcceptValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryNoteValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setNoteValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setNoteValid(invalid);
      return;
    }
    setNoteValid(invalid); // エラーメッセージのリセット
  }

  function handleContactNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    invalid = validateRequired(e.target.value);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    setContactNameValid(invalid); // エラーメッセージのリセット
  }

  function handleContactOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactDepartmentValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactDepartmentValid(invalid);
      return;
    }
    setContactDepartmentValid(invalid); // エラーメッセージのリセット
  }

  function handleContactEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = validateEmailLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleContactAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    setContactAcceptValid(invalid); // エラーメッセージのリセット
  }
  function handleContactTelValid(telData) {
    // // //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateTelRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid); // エラーメッセージのリセット
  }
  function handleContactTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelNum2Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelNum3Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateTelPartRangeLength(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleContactExtensionValid(e) {
    // //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateExtensionRangeLength(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = validateHasNoSpace(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    setContactExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleResolutionDateValid(e) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    setResolutionDateValid(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      handleCategoryIdValid,
      handleDivisionIdValid,
      handlePrintOrderValid,
      handleSaleNumberValid,
      handleTitleValid,
      handleDepositValid,
      handlePaymentMethodValid,
      handleEstimatePriceValid,
      handlePaymentDeadlineValid,
      handleQualifiedInvoiceValid,
      //handlePaymentScheduledDateValid,
      handleVideoUrlValid,
      handleDescriptionValid,
      handleInquiryNameValid,
      handleInquiryOfficeValid,
      handleInquiryEmailValid,
      handleInquiryTelValid,
      handleInquiryTelNum1Valid,
      handleInquiryTelNum2Valid,
      handleInquiryTelNum3Valid,
      handleInquiryExtensionValid,
      handleInquiryAcceptValid,
      handleInquiryNoteValid,
      handleContactNameValid,
      handleContactOfficeValid,
      handleContactEmailValid,
      handleContactAcceptValid,
      handleContactTelValid,
      handleContactTelNum1Valid,
      handleContactTelNum2Valid,
      handleContactTelNum3Valid,
      handleContactExtensionValid,
      handleResolutionDateValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      inquiryAcceptValid,
      inquiryEmailValid,
      inquiryDepartmentValid,
      inquiryNameValid,
      inquiryTelValid,
      inquiryTelNum1Valid,
      inquiryTelNum2Valid,
      inquiryTelNum3Valid,
      inquiryExtensionValid,
      noteValid,
      contactAcceptValid,
      contactEmailValid,
      contactDepartmentValid,
      contactNameValid,
      contactTelValid,
      contactTelNum1Valid,
      contactTelNum2Valid,
      contactTelNum3Valid,
      contactExtensionValid,
      descriptionValid,
      videoUrlValid,
      //contractedLimitValid,
      paymentDeadlineRequiredValid,
      qualifiedInvoiceValid,
      estimatePriceRequiredValid,
      paymentMethodRequiredValid,
      depositRequiredValid,
      titleRequiredValid,
      manageIdRequiredValid,
      printOrderValid,
      divisionNameRequiredValid,
      categoryIdValid,
      resolutionDateValid,
    },
  };

  return validations;
};
