import { useState, useEffect } from 'react';

export function useCarInputs(invitation) {
  // 外部キーなど
  const [id, setId] = useState('');
  const [auctionId, setAuctionId] = useState('');
  const [governmentId, setGovernmentId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [governmentSubCategoryId, setGovernmentSubCategoryId] = useState('');
  const [propertyType, setPropertyType] = useState('CAR'); // 物件種別（固定）
  const [isDraft, setIsDraft] = useState('TRUE'); // 公開設定
  const [status, setStatus] = useState(''); // 物件の状態(APPLY, CLOSEDなど、画面上で更新しない)

  // 2. 物件情報
  const [publicSaleType, setPublicSaleType] = useState(undefined); // 出品形式
  const [isNextBuyApl, setIsNextBuyApl] = useState(undefined); // 次順位買受申込者の決定
  const [printOrder, setPrintOrder] = useState(''); // 掲載順位
  const [categoryNames, setCategoryNames] = useState(''); // カテゴリ
  const [recommendProperties, setRecommendProperties] = useState({
    recommend: false,
  }); // イチ押し設定
  const [divisionId, setDivisionId] = useState(undefined); // 執行機関名称（id）
  const [saleNumber, setSaleNumber] = useState(''); // 売却区分番号
  const [name, setName] = useState(''); // タイトル
  const [paymentMethod, setPaymentMethod] = useState({
    bankTransfer: false,
    registeredMail: false,
    check: false,
    postalMoneyOrder: false,
    carry: false,
  }); // 支払い方法
  const [paymentLimit, setPaymentLimit] = useState(''); // 納付期限
  const [contractedLimit, setContractedLimit] = useState('');
  const [resolutionDate, setResolutionDate] = useState(''); // 議決日
  const [videoUrl, setVideoUrl] = useState(''); // 動画URL

  // 3 登録事項など証明書の記載内容
  const [effectiveDate, setEffectiveDate] = useState({
    year: '',
    month: '',
    day: '',
  }); // 証明書発行日
  const [registerNumber, setRegisterNumber] = useState({
    first: '',
    latter: '',
  }); // 自動車登録番号
  const [displayRegisterNumber, setDisplayRegisterNumber] = useState('DISPLAY'); // 自動車登録番号の表示の可否
  const [registerDate, setRegisterDate] = useState({
    year: '',
    month: '',
    day: '',
  }); // 登録年月日
  const [firstRegisterDate, setFirstRegisterDate] = useState({
    year: '',
    month: '',
    day: '1',
  }); // 初度登録年月
  const [type, setType] = useState(''); // 自動車の種別
  const [purpose, setPurpose] = useState(''); // 用途
  const [purposeOther, setPurposeOther] = useState(''); // 用途（その他）
  const [privateOrBusiness, setPrivateOrBusiness] = useState('PRIVATE'); // 自家用、事業用の別
  const [shape, setShape] = useState(''); // 車体の形状
  const [manufacture, setManufacture] = useState(''); // 車名（メーカー）
  const [manufactureOther, setManufactureOther] = useState(''); // 車名（メーカー）その他
  const [model, setModel] = useState(''); // 型式
  const [seatingCapacity, setSeatingCapacity] = useState(''); // 乗車定員
  const [weight, setWeight] = useState(''); // 車両重量
  const [totalWeight, setTotalWeight] = useState(''); // 車両総重量
  const [productNumber, setProductNumber] = useState({
    first: '',
    latter: '',
  }); // 車台番号
  const [displayProductNumber, setDisplayProductNumber] = useState('DISPLAY'); // 車台番号
  const [engineModel, setEngineModel] = useState(''); // 原動機の型式
  const [length, setLength] = useState(''); // 長さ
  const [width, setWidth] = useState(''); // 幅
  const [height, setHeight] = useState(''); // 高さ
  const [displacement, setDisplacement] = useState(''); // 総排気量
  const [fuelType, setFuelType] = useState(''); // 燃料の種類
  const [frontAxelWeight, setFrontAxelWeight] = useState(''); // 前前軸重
  const [rearAxelWeight, setRearAxelWeight] = useState(''); // 後後軸重
  const [safetyTerm, setSafetyTerm] = useState({
    year: '',
    month: '',
    day: '',
  }); // 自動車検査証有効期限
  const [statusSafetyTerm, setStatusSafetyTerm] = useState('TRUE'); // 自動車検査証有効かどうか(TRUE: あり, FALSE: 有効期限切れ)
  const [formalNumber, setFormalNumber] = useState(''); // 型式指定番号
  const [classificationNumber, setClassificationNumber] = useState(''); // 類型区分番号

  // 4. 基本情報
  const [transmissionType, setTransmissionType] = useState('MT'); // ミッション
  const [handleType, setHandleType] = useState('RIGHT'); // ハンドル
  const [existsCarMaintenanceSheet, setExistsCarMaintenanceSheet] =
    useState('NO_EXISTS'); // 定期点検記録簿
  const [carBodyColor, setCarBodyColor] = useState(''); // 車体の色
  const [carMileage, setCarMileage] = useState(''); // 走行距離
  const [statusCarMileage, setStatusCarMileage] = useState(''); // 走行距離・不明チェック
  const [inventoryLocation, setInventoryLocation] = useState(''); // 引渡時保管場所
  const [mapUrl, setMapUrl] = useState(''); // 引渡時保管場所・地図URL
  const [carStatus, setCarStatus] = useState(''); // 車両等の状況
  const [deliveryTerms, setDeliveryTerms] = useState(''); // 引き渡し条件
  const [other, setOther] = useState(''); // その他
  const [description, setDescription] = useState(''); // 説明

  // 5. 物件に関する音尾合わせ先
  const [inquiryName, setInquiryName] = useState('');
  const [inquiryOffice, setInquiryOffice] = useState('');
  const [inquiryEmail, setInquiryEmail] = useState('');
  const [inquiryTel, setInquiryTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [inquiryTelNum1, setInquiryTelNum1] = useState('');
  const [inquiryTelNum2, setInquiryTelNum2] = useState('');
  const [inquiryTelNum3, setInquiryTelNum3] = useState('');
  const [inquiryExtension, setInquiryExtension] = useState('');
  const [inquiryAccept, setInquiryAccept] = useState('');
  const [inquiryNote, setInquiryNote] = useState('');

  // 6. 物件の詳細設定
  const [depositPayment, setDepositPayment] = useState('BOTH'); // 保証金納付手続き
  const [isApprovalRequired, setIsApprovalRequired] = useState('FALSE'); // 参加申し込み承認
  const [estimatePrice, setEstimatePrice] = useState(''); // 見積価格・予定価格
  const [deposit, setDeposit] = useState(''); // 保証金額
  const [qualifiedInvoice, setQualifiedInvoice] = useState(''); // 適格請求書（カラムが存在しない

  // 7. 落札後の連絡先
  const [contactName, setContactName] = useState('');
  const [contactOffice, setContactOffice] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactTel, setContactTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [contactTelNum1, setContactTelNum1] = useState('');
  const [contactTelNum2, setContactTelNum2] = useState('');
  const [contactTelNum3, setContactTelNum3] = useState('');
  const [contactExtension, setContactExtension] = useState('');
  const [contactAccept, setContactAccept] = useState('');

  useEffect(() => {
    setContactOffice(invitation?.contactOffice);
    setContactEmail(invitation?.contactEmail);
    setContactTel({
      telNum1: invitation?.contactTel?.split('-')?.[0],
      telNum2: invitation?.contactTel?.split('-')?.[1],
      telNum3: invitation?.contactTel?.split('-')?.[2],
    });
  }, [invitation]);

  //const [paymentDate, setPaymentDate] = useState('');
  //const [contractedLimit, setContractedLimit] = useState('');
  //const [isCopied, setIsCopied] = useState('');
  //const [description, setDescription] = useState('');
  //const [resolutionDate, setResolutionDate] = useState('');
  //const [paymentDeadline, setPaymentDeadline] = useState('');

  const setRegisterNumberFirst = (updateParams) => {
    let hashData = registerNumber;
    hashData = { ...hashData, first: updateParams };
    setRegisterNumber(hashData);
  };
  const setRegisterNumberLatter = (updateParams) => {
    let hashData = registerNumber;
    hashData = { ...hashData, latter: updateParams };
    setRegisterNumber(hashData);
  };
  const setProductNumberFirst = (updateParams) => {
    let hashData = productNumber;
    hashData = { ...hashData, first: updateParams };
    setProductNumber(hashData);
  };
  const setProductNumberLatter = (updateParams) => {
    let hashData = productNumber;
    hashData = { ...hashData, latter: updateParams };
    setProductNumber(hashData);
  };

  const propertyInfoGeneralInputs = {
    data: {
      id,
      auctionId,
      governmentId,
      categoryId,
      governmentSubCategoryId,
      propertyType,
      isDraft,
      status,

      publicSaleType,
      isNextBuyApl,
      printOrder,
      categoryNames,
      recommendProperties,
      divisionId,
      saleNumber,
      name,
      paymentMethod,
      paymentLimit,
      contractedLimit,
      resolutionDate,
      videoUrl,

      effectiveDate,
      registerNumber,
      displayRegisterNumber,
      registerDate,
      firstRegisterDate,
      type,
      purpose,
      purposeOther,
      privateOrBusiness,
      shape,
      manufacture,
      manufactureOther,
      model,
      seatingCapacity,
      weight,
      totalWeight,
      productNumber,
      displayProductNumber,
      engineModel,
      length,
      width,
      height,
      displacement,
      fuelType,
      frontAxelWeight,
      rearAxelWeight,
      safetyTerm,
      statusSafetyTerm,
      formalNumber,
      classificationNumber,

      transmissionType,
      handleType,
      existsCarMaintenanceSheet,
      carBodyColor,
      carMileage,
      statusCarMileage,
      inventoryLocation,
      mapUrl,
      carStatus,
      deliveryTerms,
      other,
      description,

      inquiryName,
      inquiryOffice,
      inquiryEmail,
      inquiryTel,
      inquiryTelNum1,
      inquiryTelNum2,
      inquiryTelNum3,
      inquiryExtension,
      inquiryAccept,
      inquiryNote,

      depositPayment,
      isApprovalRequired,
      estimatePrice,
      deposit,
      qualifiedInvoice,

      contactName,
      contactOffice,
      contactEmail,
      contactTel,
      contactTelNum1,
      contactTelNum2,
      contactTelNum3,
      contactExtension,
      contactAccept,
    },
    setters: {
      setId,
      setAuctionId,
      setGovernmentId,
      setCategoryId,
      setGovernmentSubCategoryId,
      setPropertyType,
      setIsDraft,
      setStatus,

      setPublicSaleType,
      setIsNextBuyApl,
      setPrintOrder,
      setCategoryNames,
      setRecommendProperties,
      setDivisionId,
      setSaleNumber,
      setName,
      setPaymentMethod,
      setPaymentLimit,
      setContractedLimit,
      setResolutionDate,
      setVideoUrl,

      setEffectiveDate,
      setRegisterNumber,
      setRegisterNumberFirst,
      setRegisterNumberLatter,
      setDisplayRegisterNumber,
      setRegisterDate,
      setFirstRegisterDate,
      setType,
      setPurpose,
      setPurposeOther,
      setPrivateOrBusiness,
      setShape,
      setManufacture,
      setManufactureOther,
      setModel,
      setSeatingCapacity,
      setWeight,
      setTotalWeight,
      setProductNumber,
      setProductNumberFirst,
      setProductNumberLatter,
      setDisplayProductNumber,
      setEngineModel,
      setLength,
      setWidth,
      setHeight,
      setDisplacement,
      setFuelType,
      setFrontAxelWeight,
      setRearAxelWeight,
      setSafetyTerm,
      setStatusSafetyTerm,
      setFormalNumber,
      setClassificationNumber,

      setTransmissionType,
      setHandleType,
      setExistsCarMaintenanceSheet,
      setCarBodyColor,
      setCarMileage,
      setStatusCarMileage,
      setInventoryLocation,
      setMapUrl,
      setCarStatus,
      setDeliveryTerms,
      setOther,
      setDescription,

      setInquiryName,
      setInquiryOffice,
      setInquiryEmail,
      setInquiryTel,
      setInquiryTelNum1,
      setInquiryTelNum2,
      setInquiryTelNum3,
      setInquiryExtension,
      setInquiryAccept,
      setInquiryNote,

      setDepositPayment,
      setIsApprovalRequired,
      setEstimatePrice,
      setDeposit,
      setQualifiedInvoice,

      setContactName,
      setContactOffice,
      setContactEmail,
      setContactTel,
      setContactTelNum1,
      setContactTelNum2,
      setContactTelNum3,
      setContactExtension,
      setContactAccept,
    },
  };
  return propertyInfoGeneralInputs;
}
