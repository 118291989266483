export class ContactTel {
  #contactTel;

  constructor(contactTel) {
    this.#contactTel = contactTel;
  }

  get() {
    return this.#contactTel === '' ? null : this.#contactTel;
  }
}
