import { useState, useEffect } from 'react';

export function LocalGovernmentManagementTopInputs(data) {
  const [displayPreview, setDisplayPreview] = useState(
    data?.displayPreview ?? 'DISPLAY',
  );
  const [previewUrl, setPreviewUrl] = useState(data?.previewUrl ?? '');
  const [displayInquiry, setDisplayInquiry] = useState(
    data?.displayInquiry ?? 'DISPLAY',
  );
  const [inquiryName, setInquiryName] = useState(data?.inquiryName ?? '');
  const [inquiryEmail, setInquiryEmail] = useState(data?.inquiryEmail ?? '');
  const [inquiryTel, setInquiryTel] = useState(
    data?.inquiryTel ?? {
      telNum1: '',
      telNum2: '',
      telNum3: '',
    },
  );
  const [inquiryExtension, setInquiryExtension] = useState(
    data?.inquiryExtension ?? '',
  );
  const [inquiryAccept, setInquiryAccept] = useState(data?.inquiryAccept ?? '');
  const [displayHomePage, setDisplayHomePage] = useState(
    data?.displayHomePage ?? 'DISPLAY',
  );
  const [homePageName, setHomePageName] = useState(data?.homePageName ?? '');
  const [homePageUrl, setHomePageUrl] = useState(data?.homePageUrl ?? '');
  useEffect(() => {
    setDisplayPreview(data?.displayPreview ?? 'DISPLAY');
    setPreviewUrl(data?.previewUrl ?? '');
    setDisplayInquiry(data?.displayInquiry ?? 'DISPLAY');
    setInquiryName(data?.inquiryName ?? '');
    setInquiryEmail(data?.inquiryEmail ?? '');
    setInquiryTel(
      data?.inquiryTel ?? {
        telNum1: '',
        telNum2: '',
        telNum3: '',
      },
    );
    setInquiryExtension(data?.inquiryExtension ?? '');
    setInquiryAccept(data?.inquiryAccept ?? '');
    setDisplayHomePage(data?.displayHomePage ?? 'DISPLAY');
    setHomePageName(data?.homePageName ?? '');
    setHomePageUrl(data?.homePageUrl ?? '');
  }, [data]);

  const inputs = {
    data: {
      displayPreview,
      previewUrl,
      displayInquiry,
      inquiryName,
      inquiryEmail,
      inquiryTel,
      inquiryExtension,
      inquiryAccept,
      displayHomePage,
      homePageName,
      homePageUrl,
    },
    setters: {
      setDisplayPreview,
      setPreviewUrl,
      setDisplayInquiry,
      setInquiryName,
      setInquiryEmail,
      setInquiryTel,
      setInquiryExtension,
      setInquiryAccept,
      setDisplayHomePage,
      setHomePageName,
      setHomePageUrl,
    },
  };
  return inputs;
}
