import { calcXCoordinate } from 'shared/utils/helper/calcXCoordinate.js';
import { dateFormat } from 'shared/utils/helper/date_format.js';
import 'interfaces/css/common/property-schedule-row.css';

const PropertyScheduleRow = ({ termData, magnification }) => {
  if (termData === undefined || Object.keys(termData).length === 0) {
    return;
  }

  const aplStart = new Date(termData?.aplStart);
  const aplEnd = new Date(termData?.aplEnd);
  const bidStart = new Date(termData?.bidStart);
  const bidEnd = new Date(termData?.bidEnd);
  const paymentDeadline = new Date(termData?.paymentDeadline);

  const aplEndCoordinate = calcXCoordinate(aplEnd);
  const aplStartCoordinate = calcXCoordinate(aplStart);
  const bidEndCoordinate = calcXCoordinate(bidEnd);
  const bidStartCoordinate = calcXCoordinate(bidStart);
  const paymentDeadlineCoordinate = calcXCoordinate(paymentDeadline);

  const aplWidth = aplEndCoordinate - aplStartCoordinate;
  const bidWidth = bidEndCoordinate - bidStartCoordinate;

  const aplTermStyle = {
    width: (aplWidth ? aplWidth : 50) * magnification,
    left: aplStartCoordinate * magnification,
  };
  const bidBidTermStyle = {
    width: (bidWidth ? bidWidth : 50) * magnification,
    left: bidStartCoordinate * magnification,
  };
  const paymentDeadlineStyle = {
    left: paymentDeadlineCoordinate * magnification,
  };

  return (
    <div>
      <div className="property-term-row">
        <div
          className="property-apl-term property-schedule-term"
          style={aplTermStyle}>
          参加申し込み受付期間
          <br />
          {`${dateFormat(termData?.aplStart, 'MM-DD')} ~ ${dateFormat(termData?.aplEnd, 'MM-DD')}`}
        </div>
      </div>
      <div className="property-term-row">
        <div
          className="property-bid-term property-schedule-term"
          style={bidBidTermStyle}>
          入札期間
          <br />
          {`${dateFormat(termData?.bidStart, 'MM-DD')} ~ ${dateFormat(termData?.bidEnd, 'MM-DD')}`}
        </div>
      </div>
      <div className="property-term-row">
        <div
          className="property-schedule-term flex"
          style={paymentDeadlineStyle}>
          <div className="payment-deadline" />
          <div className="flex align-center ml-2">
            買受代金納付期限
            <br />
            {dateFormat(termData?.paymentDeadline, 'MM-DD')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyScheduleRow;
