import { useState, useEffect } from 'react';

export function useGuidelineInputs(data) {
  const [isDisplay0, setIsDisplay0] = useState('DISPLAY');
  const [isDisplay1, setIsDisplay1] = useState('DISPLAY');
  const [isDisplay2, setIsDisplay2] = useState('HIDDEN');
  const [isDisplay3, setIsDisplay3] = useState('HIDDEN');
  const [isDisplay4, setIsDisplay4] = useState('HIDDEN');
  const [isDisplay5, setIsDisplay5] = useState('HIDDEN');
  const [isDisplay6, setIsDisplay6] = useState('HIDDEN');
  const [isDisplay7, setIsDisplay7] = useState('HIDDEN');
  const [isDisplay8, setIsDisplay8] = useState('HIDDEN');

  const [title1, setTitle1] = useState('');
  const [title2, setTitle2] = useState('');
  const [title3, setTitle3] = useState('');
  const [title4, setTitle4] = useState('');
  const [title5, setTitle5] = useState('');
  const [title6, setTitle6] = useState('');
  const [title7, setTitle7] = useState('');
  const [title8, setTitle8] = useState('');

  const [body0, setBody0] = useState('');
  const [body1, setBody1] = useState('');
  const [body2, setBody2] = useState('');
  const [body3, setBody3] = useState('');
  const [body4, setBody4] = useState('');
  const [body5, setBody5] = useState('');
  const [body6, setBody6] = useState('');
  const [body7, setBody7] = useState('');
  const [body8, setBody8] = useState('');

  useEffect(() => {
    setIsDisplay0(data?.[0]?.isDisplay || 'DISPLAY');
    setIsDisplay1(data?.[1]?.isDisplay || 'DISPLAY');
    setIsDisplay2(data?.[2]?.isDisplay || 'HIDDEN');
    setIsDisplay3(data?.[3]?.isDisplay || 'HIDDEN');
    setIsDisplay4(data?.[4]?.isDisplay || 'HIDDEN');
    setIsDisplay5(data?.[5]?.isDisplay || 'HIDDEN');
    setIsDisplay6(data?.[6]?.isDisplay || 'HIDDEN');
    setIsDisplay7(data?.[7]?.isDisplay || 'HIDDEN');
    setIsDisplay8(data?.[8]?.isDisplay || 'HIDDEN');
    setTitle1(data?.[1]?.title || '');
    setTitle2(data?.[2]?.title || '');
    setTitle3(data?.[3]?.title || '');
    setTitle4(data?.[4]?.title || '');
    setTitle5(data?.[5]?.title || '');
    setTitle6(data?.[6]?.title || '');
    setTitle7(data?.[7]?.title || '');
    setTitle8(data?.[8]?.title || '');
    setBody0(data?.[0]?.body || '');
    setBody1(data?.[1]?.body || '');
    setBody2(data?.[2]?.body || '');
    setBody3(data?.[3]?.body || '');
    setBody4(data?.[4]?.body || '');
    setBody5(data?.[5]?.body || '');
    setBody6(data?.[6]?.body || '');
    setBody7(data?.[7]?.body || '');
    setBody8(data?.[8]?.body || '');
  }, [data]);

  const inputs = {
    data: {
      0: {
        itemNo: 0,
        isDisplay: isDisplay0,
        title: '',
        body: body0,
      },
      1: {
        itemNo: 1,
        isDisplay: isDisplay1,
        title: title1,
        body: body1,
      },
      2: {
        itemNo: 2,
        isDisplay: isDisplay2,
        title: title2,
        body: body2,
      },
      3: {
        itemNo: 3,
        isDisplay: isDisplay3,
        title: title3,
        body: body3,
      },
      4: {
        itemNo: 4,
        isDisplay: isDisplay4,
        title: title4,
        body: body4,
      },
      5: {
        itemNo: 5,
        isDisplay: isDisplay5,
        title: title5,
        body: body5,
      },
      6: {
        itemNo: 6,
        isDisplay: isDisplay6,
        title: title6,
        body: body6,
      },
      7: {
        itemNo: 7,
        isDisplay: isDisplay7,
        title: title7,
        body: body7,
      },
      8: {
        itemNo: 8,
        isDisplay: isDisplay8,
        title: title8,
        body: body8,
      },
    },
    setters: {
      0: {
        setIsDisplay: setIsDisplay0,
        setTitle: () => {},
        setBody: setBody0,
      },
      1: {
        setIsDisplay: setIsDisplay1,
        setTitle: setTitle1,
        setBody: setBody1,
      },
      2: {
        setIsDisplay: setIsDisplay2,
        setTitle: setTitle2,
        setBody: setBody2,
      },
      3: {
        setIsDisplay: setIsDisplay3,
        setTitle: setTitle3,
        setBody: setBody3,
      },
      4: {
        setIsDisplay: setIsDisplay4,
        setTitle: setTitle4,
        setBody: setBody4,
      },
      5: {
        setIsDisplay: setIsDisplay5,
        setTitle: setTitle5,
        setBody: setBody5,
      },
      6: {
        setIsDisplay: setIsDisplay6,
        setTitle: setTitle6,
        setBody: setBody6,
      },
      7: {
        setIsDisplay: setIsDisplay7,
        setTitle: setTitle7,
        setBody: setBody7,
      },
      8: {
        setIsDisplay: setIsDisplay8,
        setTitle: setTitle8,
        setBody: setBody8,
      },
    },
  };
  return inputs;
}
