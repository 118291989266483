// データ取得後の整形処理
// フロントから呼ぶ関数
import {
  createRegisterUnconfirmedUserData,
  updateRegisterUnconfirmedUserData,
} from '../../../infrastructure/api/RegisterUnconfirmedUser.js';
import {
  getCurrentAllowPropertyIds,
  getCurrentAllowPropertyIdsUnAuth,
  getPreviousAllowPropertyIds,
  getPreviousAllowPropertyIdsUnAuth,
} from '../../../infrastructure/api/storage/ServiceUser.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import { bodyToSnake } from '../../../shared/utils/helper/convertData.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { MyUserInfoDataCreate } from '../entities/ServiceUser.js';
import { getAuctionUsers } from '../repositories/ServiceUser.js';
import { createAuctionUsersData, confirmNumberData } from 'infrastructure/api/AuctionUser.js';

export async function get(type) {
  return await getAuctionUsers(type);
}

export async function findById(type, id) {
  const users = await getAuctionUsers(type);
  return users.find((user) => user.id === id);
}

export async function findByIds(type, ids) {
  const users = await getAuctionUsers(type);
  return users.filter((user) => ids.includes(user.id));
}

export async function findMaxPriceAuctionUser(type, bidderHistories) {
  const users = await getAuctionUsers(type);

  let maxPriceBidder = undefined;
  let maxPriceUserBidder = undefined;
  let maxAddPriceUserBidder = undefined;

  if (bidderHistories.maxPriceBidder) {
    maxPriceBidder = users.find(
      (user) => user.id === bidderHistories.maxPriceBidder.auctionUserId,
    );
  }

  if (bidderHistories.maxPriceUserBidder) {
    maxPriceUserBidder = users.find(
      (user) => user.id === bidderHistories.maxPriceUserBidder.auctionUserId,
    );
  }

  if (bidderHistories.maxPriceUserAddBidder) {
    maxAddPriceUserBidder = users.find(
      (user) => user.id === bidderHistories.maxPriceUserAddBidder.auctionUserId,
    );
  }

  return {
    maxPriceUser: maxPriceBidder,
    maxMyPriceUser: maxPriceUserBidder,
    maxMyAddPriceUser: maxAddPriceUserBidder,
  };
}

export async function signUp(email, password) {
  let body = {
    email: email,
    raw_password: password,
  }

  try {
    await createAuctionUsersData(body).then((response) => {
      console.log('response', response);
    });
  } catch (error) {
    console.log('error', error);
    throw new CustomError('ユーザー登録に失敗しました', error);
  }
}

export async function putEmailConfirm(email, confirmNumber) {
  let body = {
    email: email,
    code: confirmNumber,
  }

  try {
    await confirmNumberData(body).then((response) => {
      console.log('response', response);
    });
  } catch (error) {
    console.log('error', error);
    throw new CustomError('ユーザー認証に失敗しました', error);
  }
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    const user = new MyUserInfoDataCreate(data);
    response = await createRegisterUnconfirmedUserData(bodyToSnake(user));
  }
  if (type === NAVI) {
    return [];
  }
  if (type === KMANAGER) {
    return [];
  }
  if (response instanceof Error) {
    throw new CustomError('Error create User', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    const user = new MyUserInfoDataCreate(data);
    response = await updateRegisterUnconfirmedUserData(
      bodyToSnake(user),
      user.id,
    );
  }
  if (type === NAVI) {
    return [];
  }
  if (type === KMANAGER) {
    return [];
  }
  if (response instanceof Error) {
    throw new CustomError('Error create User', response);
  }
  return response;
}

export function destroy(type) {
  let response;

  if (type === SERVICE) {
    return [];
  }
  if (type === NAVI) {
    return [];
  }
  if (type === KMANAGER) {
    return [];
  }
  if (response instanceof Error) {
    throw new CustomError('Error create User', response);
  }
  return response;
}

export const getAllowPropertyIds = async (userId) => {
  try {
    return await getCurrentAllowPropertyIds(userId);
  } catch (error) {
    console.warn(
      `Failed to fetch current general categories: ${error.message}`,
    );
    try {
      return await getPreviousAllowPropertyIds(userId);
    } catch (error) {
      console.warn(
        `Failed to fetch previous general categories: ${error.message}`,
      );
      return [];
    }
  }
};

export const getAllowPropertyIdsUnAuth = async () => {
  try {
    return await getCurrentAllowPropertyIdsUnAuth();
  } catch (error) {
    console.warn(
      `Failed to fetch current general categories: ${error.message}`,
    );
    try {
      return await getPreviousAllowPropertyIdsUnAuth();
    } catch (error) {
      console.warn(
        `Failed to fetch previous general categories: ${error.message}`,
      );
      return [];
    }
  }
};

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
