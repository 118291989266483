import { ReactComponent as PencilIcon } from 'interfaces/assets/svg/pencil.svg';
import FlatButton from 'interfaces/components/common/Button/FlatButton.jsx';
import { useNavigate } from 'react-router-dom';
import { convertEmptyCharacters } from 'shared/utils/helper/convertEmptyCharacters.js';
import { PrefectureToJP } from 'shared/utils/helper/convert/prefectureToJp.js';
import { AccountTypes } from 'shared/utils/constants/bank_account.js';

import 'interfaces/css/Organization/organization-table.css';

const OrganizationTable = ({
  tableData,
  procedureType = 'PUBLIC_SALE',
  governmentType,
  isNotEditable = true,
}) => {
  const navigator = useNavigate();

  if (tableData.length === 0) {
    return (
      <div>
        執行機関の登録はありません。新規作成ボタンから作成を行ってください。
      </div>
    );
  }

  return (
    <div className="organization-table" data-testid="organization-table">
      <div className="organization-table-inner">
        <table>
          <thead data-testid="organization-table-header">
            <tr>
              <th>名前</th>
              <th>保証金納付口座</th>
              {procedureType === 'ASSET_SALE' ||
              governmentType === 'NATIONAL_TAX_AGENCY' ? (
                ''
              ) : (
                <th>返還請求書送付先</th>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody
            className="organization-table-body"
            data-testid="organization-table-body">
            {tableData &&
              tableData.map((d) => (
                <tr key={d.id}>
                  <td>{convertEmptyCharacters(d.name)}</td>

                  <td>
                    {convertEmptyCharacters(
                      [
                        d.financialInstitutionName,
                        d.branchName,
                        AccountTypes[d.accountType].jp,
                        d.accountNumber,
                      ].join(' '),
                    )}
                  </td>

                  {/* [toPrefectureString(item.area), item.city, item.address, item.buildName, item.destination].join(' ') */}
                  {procedureType === 'ASSET_SALE' ||
                  governmentType === 'NATIONAL_TAX_AGENCY' ? (
                    ''
                  ) : (
                    <td>
                      {convertEmptyCharacters(
                        [
                          PrefectureToJP(d.prefectureId),
                          d.city,
                          d.address,
                          d.buildName,
                          d.destination,
                        ].join(' '),
                      )}
                    </td>
                  )}
                  <td className="action-button">
                    <FlatButton
                      text="編集"
                      icon={<PencilIcon />}
                      isDisabled={isNotEditable}
                      onClick={() =>
                        navigator(
                          '/navi/organization/' + d.id.toString() + '/edit',
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrganizationTable;
