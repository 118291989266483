import CommonAccordion from 'interfaces/components/common/Accordion/CommonAccordion';
import Button from 'interfaces/components/common/Button';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useContext, useState } from 'react';

import BannerForm from './BannerForm';
import { BannerContext } from '../../pages/Banner/entities/BannerContext';

const Banner = () => {
  const [addBanner, setAddBanner] = useState([]);

  const { banners } = useContext(BannerContext);
  const handleChange = () => {
    setAddBanner(
      // eslint-disable-next-line react/jsx-key
      [
        ...addBanner,
        <CommonAccordion
          key={banners.length + addBanner.length}
          contents={
            <BannerForm index={Number(banners[banners.length - 1]?.id) + 1} />
          }
        />,
      ],
    );
  };

  return (
    <>
      <div
        className="kmanager-banner-preview box-shadow ma-2 pa-2"
        data-testid="kmanager-banner-preview">
        <PageTitle text="プレビュー" />
        <div className="box-shadow pa-3">
          <div
            className="flex flex-wrap"
            style={{ backgroundColor: 'rgb(245, 245, 245)' }}>
            {banners &&
              banners.map((banner) => {
                return (
                  <div key={banner.id} className="mr-4">
                    <img
                      src={`/storage/current/banners/${banner.id}/data.jpg`}
                      alt={banner.title}
                      data-testid="kmanager-banner-image-preview"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div
        className="kmanager-banner-edit box-shadow ma-2 pa-2"
        data-testid="kmanager-banner-edit">
        <div className="flex align-center">
          <PageTitle text="編集" />
          <Button
            className="secondary ml-3"
            text="バナーを追加"
            clickHandler={handleChange}
          />
          <Button className="primary ml-3" text="並び順を保存" />
        </div>
        {banners &&
          banners.map((banner) => {
            console.log(banner);
            return (
              <CommonAccordion
                key={banner.id}
                label={banner.title}
                contents={<BannerForm banner={banner} index={banner.id} />}
              />
            );
          })}
        {addBanner}
        <Button
          className="secondary my-3"
          text="バナーを追加"
          clickHandler={handleChange}
        />
      </div>
    </>
  );
};
export default Banner;
