// データ取得後の整形処理
// フロントから呼ぶ関数
import {
  createManagementUsersData,
  deleteManagementUsersData,
  updateManagementUsersData
} from "../../../infrastructure/api/ManagementUsers.js";
import { compareData } from "../../../shared/utils/helper/compareData.js";
import { KMANAGER, NAVI, SERVICE } from "../../../shared/utils/helper/constants.js";
import { bodyToSnake, getValues } from "../../../shared/utils/helper/convertData.js";
import CustomError from "../../../shared/utils/helper/customError.js";
import { ManagementUserCreate } from "../entities/ManagementUser.js";
import { getManagementUser } from "../repositories/ManagementUser.js";

export async function get(type) {
  return await getManagementUser(type)
}

export async function findById(type, id) {
  const managementUsers = await getManagementUser(type);
  return managementUsers.find(user => user.id === id)
}


export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    return []
  }
  if (type === NAVI) {
    return []
  }
  if (type === KMANAGER) {
    const user = new ManagementUserCreate(data);
    response = await createManagementUsersData(bodyToSnake(getValues(user)));
  }
  if (response instanceof Error) {
    throw new CustomError('Error create managementUser', response);
  }
  return response
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    return []
  }
  if (type === NAVI) {
    return []
  }
  if (type === KMANAGER) {
    const user = new ManagementUserCreate(data);
    response = await updateManagementUsersData(bodyToSnake(user), user.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create managementUser', response);
  }
  return response
}

export function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    return []
  }
  if (type === NAVI) {
    return []
  }
  if (type === KMANAGER) {
    response = deleteManagementUsersData(data.id)
  }
  if (response instanceof Error) {
    throw new CustomError('Error create managementUser', response);
  }
  return response
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]))
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]))
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value)
}
