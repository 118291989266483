import { ReactComponent as RadioBlank } from 'interfaces/assets/svg/radio-button-blank.svg';
import { ReactComponent as RadioMarked } from 'interfaces/assets/svg/radio-button-marked.svg';

const RadioButton = ({ data, state, setState, disabled }) => {
  const radioIcon = (value) => {
    if (state === value) {
      return (
        <i className="radio-button-marked">
          <RadioMarked />
        </i>
      );
    }
    return (
      <i className="radio-button-blank">
        <RadioBlank />
      </i>
    );
  };

  return (
    <div className="d-flex form-list">
      {data?.map((data) => {
        return (
          <div data-testid="radio-button" key={data.id}>
            <div className={'mr-2 d-inline-flex'}>
              <label className="d-flex">
                {radioIcon(data.value)}
                <input
                  type={'radio'}
                  name={'radio'}
                  value={data.value}
                  disabled={disabled}
                  onClick={setState}
                />
                <p>{data.label}</p>
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default RadioButton;
