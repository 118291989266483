import { useEffect, useState } from 'react';
import {
  COMMON_MONTH_DAYS,
  COMMON_YEAR_DATE,
  LEAP_MONTH_DAYS,
  LEAP_YEAR_DATE,
} from 'shared/utils/constants/date.js';
import { calcXCoordinate } from 'shared/utils/helper/calcXCoordinate.js';
import isLeapYear from 'shared/utils/helper/isLeapYear.js';

import ScheduleRow from './ScheduleRow.jsx';
import styled from 'styled-components';

const TodayLine = styled.span`
  width: 2px;
  height: 100%;
  background-color: var(--primary-base);
  z-index: 100;
  position: absolute;
  top: 0;
`;

const ScheduleBody = ({
  today,
  thisYear,
  setTargetYear,
  scrollableRef,
  scheduleType,
  termData,
  magnification,
  title,
}) => {
  const [hasCrossed, setHasCrossed] = useState(false);
  const isLeapThisYear = isLeapYear(thisYear);
  const isLeapNextYear = isLeapYear(thisYear + 1);
  const thisYearDateNum = isLeapThisYear ? LEAP_YEAR_DATE : COMMON_YEAR_DATE;
  const nextYearDateNum = isLeapNextYear ? LEAP_YEAR_DATE : COMMON_YEAR_DATE;
  const xAxisLength = (thisYearDateNum + nextYearDateNum) * magnification;
  const todayXCoordinate = calcXCoordinate(today) * magnification;
  const yearChangePosition = nextYearDateNum * magnification;

  const handleCheckYearChange = () => {
    const currentPosition = scrollableRef.current.scrollLeft;
    if (currentPosition > yearChangePosition && !hasCrossed) {
      setTargetYear(thisYear + 1);
      setHasCrossed(true);
    } else if (currentPosition <= yearChangePosition && hasCrossed) {
      setTargetYear(thisYear);
      setHasCrossed(false);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollableRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleCheckYearChange);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleCheckYearChange);
      }
    };
  }, [hasCrossed, yearChangePosition]);

  useEffect(() => {
    let aplStart;

    // MEMO: termDataの構造による出し分け
    if (termData?.auctionInfo) {
      aplStart = new Date(termData?.auctionInfo.aplStart);
    }
    if (termData?.bidInfo) {
      aplStart = new Date(termData?.bidInfo.aplStart);
    }
    if (termData?.aplStart) {
      aplStart = new Date(termData?.aplStart);
    }

    if (scrollableRef.current) {
      // MEMO: 参加申し込み受付開始日を左端に配置
      scrollableRef.current.scrollLeft =
        calcXCoordinate(aplStart) * magnification;
    }
  }, [magnification, termData]);

  const monthWidth = (isLeapYear, month) => {
    return (
      (isLeapYear ? LEAP_MONTH_DAYS[month] : COMMON_MONTH_DAYS[month]) *
      magnification
    );
  };

  return (
    <div className="schedule-body-wrap" ref={scrollableRef}>
      <div className="schedule-body" style={{ width: xAxisLength }}>
        <TodayLine
          style={{ transform: `translate(${todayXCoordinate}px)` }}
          data-testid="today-line"
        />
        <div className="month-row flex" data-testid="month-row">
          {[...Array(12).keys()].map((month) => (
            <div
              key={month}
              style={{ width: monthWidth(isLeapThisYear, month) }}>
              {month + 1}月
            </div>
          ))}
          {[...Array(12).keys()].map((month) => (
            <div
              key={month}
              style={{ width: monthWidth(isLeapNextYear, month) }}>
              {month + 1}月
            </div>
          ))}
          <div>{/*次の月までの余白を確保するためのdiv*/}</div>
        </div>
        <ScheduleRow
          scheduleType={scheduleType}
          termData={termData}
          magnification={magnification}
          title={title}
        />
      </div>
    </div>
  );
};

export default ScheduleBody;
