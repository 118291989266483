import RadioButton from 'interfaces/components/common/Button/RadioButton.jsx';
import styled from 'styled-components';

import CautionDescription from '../../../Form/navi/CautionDescription';
import Label from '../../../Form/navi/Label';

const FormArea = styled.div`
  display: flex;
  align-items: center;
`;

const TextInput = styled.input`
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  #padding: 8px 0;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border: ${(props) =>
    props.$validation
      ? '2px solid #ff5252 !important'
      : '1px solid rgba(0, 0, 0, .42)'};
  border-radius: 4px;
  padding: 8px 12px;
`;

const FormLandArea = ({
  caution_description,
  dataText,
  dataRadio,
  handlerText,
  handlerRadio,
  label,
  radio_label,
}) => {
  const handleChange = (e) => {
    handlerRadio(e.target.value);
  };

  return (
    <div
      className="property-info-general-form-table-row"
      data-testid={`form-land-area-${label}`}>
      <Label label={label} />
      <div className="property-info-general-form-table-cell">
        <FormArea className={'flex'}>
          <TextInput
            onChange={handlerText}
            type="number"
            value={dataText}
            data-testid={`form-land-area-input-${label}`}
          />
          <span>m²</span>
          <CautionDescription data={caution_description} />
        </FormArea>
        <div className={'flex'}>
          <RadioButton
            data={radio_label}
            handleChange={(e) => handleChange(e)}
            isText={dataRadio}
          />
        </div>
      </div>
    </div>
  );
};
export default FormLandArea;
