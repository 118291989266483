import { useState } from 'react';

export function useMessageInputs(message) {
  const [displayServiceType, setDisplayServiceType] = useState({
    service: ['SERVICE', 'BOTH'].includes(message?.displayServiceType)
      ? 'TRUE'
      : '',
    navi: ['NAVI', 'BOTH'].includes(message?.displayServiceType) ? 'TRUE' : '',
  });
  const [displayProcedureType, setDisplayProcedureType] = useState({
    publicSale: ['PUBLIC_SALE', 'BOTH'].includes(message?.displayProcedureType)
      ? 'TRUE'
      : '',
    assetSale: ['ASSET_SALE', 'BOTH'].includes(message?.displayProcedureType)
      ? 'TRUE'
      : '',
  });
  const [displayGovernmentType, setDisplayGovernmentType] = useState({
    nationalTaxAgency: ['NATIONAL_TAX_AGENCY', 'BOTH'].includes(
      message?.displayGovernmentType,
    )
      ? 'TRUE'
      : '',
    localGovernment: ['LOCAL_GOVERNMENT', 'BOTH'].includes(
      message?.displayGovernmentType,
    )
      ? 'TRUE'
      : '',
  });
  const [openAt, setOpenAt] = useState(message?.openAt);
  const [closeAt, setCloseAt] = useState(message?.closeAt);
  const [title, setTitle] = useState(message?.title);
  const [body, setBody] = useState(message?.body);
  const [severityType, setSeverityType] = useState({
    severityType: message?.severityType === 'EMERGENCY' ? 'TRUE' : '',
  });

  const sampleInputs = {
    type: {
      displayServiceType: 'check',
      displayProcedureType: 'check',
      displayGovernmentType: 'check',
      openAt: 'datetime',
      closeAt: 'datetime',
      title: 'text',
      body: 'text',
      severityType: 'check',
    },
    data: {
      displayServiceType,
      displayProcedureType,
      displayGovernmentType,
      openAt,
      closeAt,
      title,
      body,
      severityType,
    },
    setters: {
      displayServiceType: setDisplayServiceType,
      displayProcedureType: setDisplayProcedureType,
      displayGovernmentType: setDisplayGovernmentType,
      openAt: setOpenAt,
      closeAt: setCloseAt,
      title: setTitle,
      body: setBody,
      severityType: setSeverityType,
    },
  };

  return sampleInputs;
}
