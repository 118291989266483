import AuctionHeaderProvider from 'interfaces/pages/Settings/services/AuctionHeaderProvider.jsx';
import HeaderBottom from './HeaderBottom/HeaderBottom.jsx';
import HeaderTop from './HeaderTop/HeaderTop.jsx';

const AuctionHeader = () => {
  return (
    <header className="header" data-testid="header">
      <HeaderTop />
      <AuctionHeaderProvider>
        <HeaderBottom />
      </AuctionHeaderProvider>
    </header>
  );
};

export default AuctionHeader;
