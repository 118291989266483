export class PostalCode {
  #postalCode;

  constructor(postalCode) {
    this.#postalCode = postalCode;
  }

  get() {
    return this.#postalCode === '' || this.#postalCode.includes('DATA_EMPTY')
      ? null
      : this.#postalCode;
  }
}
