import { useState, useEffect } from 'react';

export function useEstateInputs(invitation) {
  // 外部キーなど
  const [id, setId] = useState('');
  const [auctionId, setAuctionId] = useState('');
  const [governmentId, setGovernmentId] = useState('');
  const [propertyType, setPropertyType] = useState('ESTATE'); // 物件種別（固定）

  // 設定
  const [isDraft, setIsDraft] = useState('TRUE'); // 公開設定
  const [status, setStatus] = useState(''); // 物件の状態(APPLY, CLOSEDなど、画面上で更新しない)

  // 2. 物件情報
  const [publicSaleType, setPublicSaleType] = useState('BID'); // 出品形式
  const [printOrder, setPrintOrder] = useState(''); // 掲載順位
  const [categoryNames, setCategoryNames] = useState(''); // カテゴリ
  const [categoryId, setCategoryId] = useState('');
  const [recommendProperties, setRecommendProperties] = useState({
    recommend: false,
  }); // イチ押し設定
  const [divisionId, setDivisionId] = useState(undefined); // 執行機関名称（id）
  const [saleNumber, setSaleNumber] = useState(''); // 売却区分番号
  const [name, setName] = useState(''); // タイトル
  const [paymentMethod, setPaymentMethod] = useState({
    bankTransfer: false,
    registeredMail: false,
    check: false,
    postalMoneyOrder: false,
    carry: false,
  }); // 支払い方法
  const [paymentLimit, setPaymentLimit] = useState(''); // 納付期限（公売・公有両方
  const [videoUrl, setVideoUrl] = useState(''); // 動画URL
  const [type, setType] = useState(undefined); // 物件の種類
  const [governmentSubCategoryId, setGovernmentSubCategoryId] =
    useState(undefined); // 物件の所在(properties)
  const [resolutionDate, setResolutionDate] = useState(''); // 議決日(公有)
  const [contractedLimit, setContractedLimit] = useState(''); // 契約締結期限(公有)

  // 3. 基本情報
  const [propertyPrint, setPropertyPrint] = useState(''); // 登記簿の表示
  const [locationGovernmentSubCategoryId, setLocationGovernmentSubCategoryId] =
    useState(''); // 所在地
  const [locationText, setLocationText] = useState(''); // 所在地(テキスト)
  const [
    residenceGovernmentSubCategoryId,
    setResidenceGovernmentSubCategoryId,
  ] = useState(''); // 住居表示
  const [residenceLocation, setResidenceLocation] = useState(''); // 住居表示(テキスト)
  const [nearestStation, setNearestStation] = useState(''); // 交通、最寄りの駅など
  const [locationUrl, setLocationUrl] = useState(''); // 所在図
  const [estateStatus, setEstateStatus] = useState(''); // 現況
  const [other, setOther] = useState(''); // その他事項
  const [sketchUrl, setSketchUrl] = useState(''); // 見取り図など
  const [description, setDescription] = useState(''); // 説明

  // 4. 土地の情報
  const [landSpace, setLandSpace] = useState(''); // 土地面積
  const [landSpaceType, setLandSpaceType] = useState(''); // 土地面積種別
  const [landRights, setLandRights] = useState(''); // 土地権利
  const [landShare, setLandShare] = useState({
    first: '',
    latter: '',
  }); // 土地持分
  const [landCategory, setLandCategory] = useState(''); // 地目
  const [cityPlanningArea, setCityPlanningArea] = useState(''); // 都市計画地域
  const [restrictedArea, setRestrictedArea] = useState(''); // 用途地域
  const [buildRatio, setBuildRatio] = useState(''); // 建ぺい率
  const [floorAreaRatio, setFloorAreaRatio] = useState(''); // 容積率
  const [highDistrict, setHighDistrict] = useState(''); // 高度地区
  const [firebreakArea, setFirebreakArea] = useState(''); // 防火地域
  const [shadeRegulation, setShadeRegulation] = useState(''); // 日影規制
  const [lawRegulation, setLawRegulation] = useState(''); // その他法令などの規制
  const [geographical, setGeographical] = useState(''); // 地勢など
  const [neighboringRoad, setNeighboringRoad] = useState(''); // 幅員、接道状況
  const [privateRoad, setPrivateRoad] = useState(''); // 私道の負担など
  const [provideEquipment, setProvideEquipment] = useState(''); // 供給処理施設
  const [landOther, setLandOther] = useState(''); // その他土地に関する物件情報

  // 5. 建物の情報
  const [buildName, setBuildName] = useState(''); // 建物の名称
  const [buildSpace, setBuildSpace] = useState(''); // 建物面積
  const [exclusiveSpace, setExclusiveSpace] = useState(''); // 専有面積
  const [floorSpace, setFloorSpace] = useState(''); // 延床面積
  const [structureType, setStructureType] = useState(''); // 構造
  const [buildDate, setBuildDate] = useState({
    year: '',
    month: '',
    day: '1',
  }); // 建築年月
  const [buildDateOther, setBuildDateOther] = useState(''); // 建築年月（その他を選択した時出るフォーム）
  const [floor, setFloor] = useState(''); // 階建/階
  const [totalHouses, setTotalHouses] = useState(''); // 総戸数
  const [layout, setLayout] = useState(''); // 間取り
  const [buildOther, setBuildOther] = useState(''); // その他建物に関する物件情報

  // 6. 物件に関するお問い合わせ先
  const [inquiryName, setInquiryName] = useState('');
  const [inquiryOffice, setInquiryOffice] = useState('');
  const [inquiryEmail, setInquiryEmail] = useState('');
  const [inquiryTel, setInquiryTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  const [inquiryTelNum1, setInquiryTelNum1] = useState('');
  const [inquiryTelNum2, setInquiryTelNum2] = useState('');
  const [inquiryTelNum3, setInquiryTelNum3] = useState('');
  const [inquiryExtension, setInquiryExtension] = useState('');
  const [inquiryAccept, setInquiryAccept] = useState('');
  const [inquiryNote, setInquiryNote] = useState('');

  // 7. 物件の詳細設定
  const [depositPayment, setDepositPayment] = useState('BOTH'); // 保証金納付手続き
  const [isApprovalRequired, setIsApprovalRequired] = useState('TRUE'); // 参加申し込み承認
  const [estimatePrice, setEstimatePrice] = useState(''); // 見積価格・予定価格
  const [deposit, setDeposit] = useState(''); // 保証金額
  const [qualifiedInvoice, setQualifiedInvoice] = useState(''); // 適格請求書（カラムが存在しない

  // 8. 落札後の連絡先
  const [contactName, setContactName] = useState('');
  const [contactOffice, setContactOffice] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactTel, setContactTel] = useState({
    telNum1: '',
    telNum2: '',
    telNum3: '',
  });
  useEffect(() => {
    setContactOffice(invitation?.contactOffice);
    setContactEmail(invitation?.contactEmail);
    setContactTel({
      telNum1: invitation?.contactTel?.split('-')?.[0],
      telNum2: invitation?.contactTel?.split('-')?.[1],
      telNum3: invitation?.contactTel?.split('-')?.[2],
    });
  }, [invitation]);
  const [contactExtension, setContactExtension] = useState('');
  const [contactAccept, setContactAccept] = useState('');

  //const [paymentDate, setPaymentDate] = useState('');
  //const [contractedLimit, setContractedLimit] = useState('');
  //const [isCopied, setIsCopied] = useState('');
  //const [description, setDescription] = useState('');
  //const [resolutionDate, setResolutionDate] = useState('');
  //const [paymentDeadline, setPaymentDeadline] = useState('');

  return {
    data: {
      // 外部キー
      id,
      auctionId,
      governmentId,
      categoryId,
      propertyType,

      // 公開設定
      isDraft,
      status,

      // 2. 物件情報
      publicSaleType, // 出品形式
      printOrder, // 掲載順位
      categoryNames, // カテゴリ
      recommendProperties, // イチ押し設定
      divisionId, // 執行機関名称（id）
      saleNumber, // 売却区分番号
      name, // タイトル
      paymentMethod, // 支払い方法
      paymentLimit, // 納付期限
      resolutionDate, // 議決日(公有)
      contractedLimit, // 契約締結期限(公有)
      videoUrl, // 動画URL
      type, // 物件の種類
      governmentSubCategoryId, // 物件の所在(properties)

      // 3. 基本情報
      propertyPrint, // 登記簿の表示
      locationGovernmentSubCategoryId, // 所在地
      locationText, // 所在地(テキスト)
      residenceGovernmentSubCategoryId, // 住居表示
      residenceLocation, // 住居表示(テキスト)
      nearestStation, // 交通、最寄りの駅など
      locationUrl, // 所在図
      estateStatus, // 現況
      other, // その他事項
      sketchUrl, // 見取り図など
      description, // 説明

      // 4. 土地の情報
      landSpace, // 土地面積
      landSpaceType, // 土地面積種別
      landRights, // 土地権利
      landShare, // 土地持分
      landCategory, // 地目
      cityPlanningArea, // 都市計画地域
      restrictedArea, // 用途地域
      buildRatio, // 建ぺい率
      floorAreaRatio, // 容積率
      highDistrict, // 高度地区
      firebreakArea, // 防火地域
      shadeRegulation, // 日影規制
      lawRegulation, // その他法令などの規制
      geographical, // 地勢など
      neighboringRoad, // 幅員、接道状況
      privateRoad, // 私道の負担など
      provideEquipment, // 供給処理施設
      landOther, // その他土地に関する物件情報

      // 5. 建物の情報
      buildName, // 建物の名称
      buildSpace, // 建物面積
      exclusiveSpace, // 専有面積
      floorSpace, // 延床面積
      structureType, // 構造
      buildDate, // 建築年月
      buildDateOther, // 建築年月（その他を選択した時出るフォーム）
      floor, // 階建/階
      totalHouses, // 総戸数
      layout, // 間取り
      buildOther, // その他建物に関する物件情報

      // 6. 物件に関するお問い合わせ先
      inquiryName,
      inquiryOffice,
      inquiryEmail,
      inquiryTel,
      inquiryTelNum1,
      inquiryTelNum2,
      inquiryTelNum3,
      inquiryExtension,
      inquiryAccept,
      inquiryNote,

      // 7. 物件の詳細設定
      depositPayment,
      isApprovalRequired,
      estimatePrice,
      deposit,
      qualifiedInvoice,

      // 8. 落札後の連絡先
      contactName,
      contactOffice,
      contactEmail,
      contactTel,
      contactExtension,
      contactAccept,
    },
    setters: {
      // 外部キー
      setId,
      setAuctionId,
      setGovernmentId,
      setCategoryId,
      setPropertyType,

      // 公開設定
      setIsDraft,
      setStatus,

      // 2. 物件情報
      setPublicSaleType, // 出品形式
      setPrintOrder, // 掲載順位
      setCategoryNames, // カテゴリ
      setRecommendProperties, // イチ押し設定
      setDivisionId, // 執行機関名称（id）
      setSaleNumber, // 売却区分番号
      setName, // タイトル
      setPaymentMethod, // 支払い方法
      setPaymentLimit, // 納付期限
      setVideoUrl, // 動画URL
      setType, // 物件の種類
      setGovernmentSubCategoryId, // 物件の所在(properties)
      setResolutionDate, // 議決日(公有)
      setContractedLimit, // 契約締結期限(公有)

      // 3. 基本情報
      setPropertyPrint, // 登記簿の表示
      setLocationGovernmentSubCategoryId, // 所在地
      setLocationText, // 所在地(テキスト)
      setResidenceGovernmentSubCategoryId, // 住居表示
      setResidenceLocation, // 住居表示(テキスト)
      setNearestStation, // 交通、最寄りの駅など
      setLocationUrl, // 所在図
      setEstateStatus, // 現況
      setOther, // その他事項
      setSketchUrl, // 見取り図など
      setDescription, // 説明

      // 4. 土地の情報
      setLandSpace, // 土地面積
      setLandSpaceType, // 土地面積種別
      setLandRights, // 土地権利
      setLandShare, // 土地持分
      setLandCategory, // 地目
      setCityPlanningArea, // 都市計画地域
      setRestrictedArea, // 用途地域
      setBuildRatio, // 建ぺい率
      setFloorAreaRatio, // 容積率
      setHighDistrict, // 高度地区
      setFirebreakArea, // 防火地域
      setShadeRegulation, // 日影規制
      setLawRegulation, // その他法令などの規制
      setGeographical, // 地勢など
      setNeighboringRoad, // 幅員、接道状況
      setPrivateRoad, // 私道の負担など
      setProvideEquipment, // 供給処理施設
      setLandOther, // その他土地に関する物件情報

      // 5. 建物の情報
      setBuildName, // 建物の名称
      setBuildSpace, // 建物面積
      setExclusiveSpace, // 専有面積
      setFloorSpace, // 延床面積
      setStructureType, // 構造
      setBuildDate, // 建築年月
      setBuildDateOther, // 建築年月（その他を選択した時出るフォーム）
      setFloor, // 階建/階
      setTotalHouses, // 総戸数
      setLayout, // 間取り
      setBuildOther, // その他建物に関する物件情報

      // 6. 物件に関するお問い合わせ先
      setInquiryName,
      setInquiryOffice,
      setInquiryEmail,
      setInquiryTel,
      setInquiryTelNum1,
      setInquiryTelNum2,
      setInquiryTelNum3,
      setInquiryExtension,
      setInquiryAccept,
      setInquiryNote,

      // 7. 物件の詳細設定
      setDepositPayment,
      setIsApprovalRequired,
      setEstimatePrice,
      setDeposit,
      setQualifiedInvoice,

      // 8. 落札後の連絡先
      setContactName,
      setContactOffice,
      setContactEmail,
      setContactTel,
      setContactExtension,
      setContactAccept,
    },
  };
}
