import { DATA_EMPTY } from 'domain/Property/entities/constants/property';

import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody';
import CommonTableDefault from 'interfaces/components/common/Table/CommonTableDefault';

const DeliveryRegistrationInfoBody = ({ infos }) => {
  return (
    <div data-testid="delivery-registration-info-body">
      <ArticleDefaultBody>
        <CommonTableDefault>
          {infos.map(
            (info) =>
              info.value !== undefined &&
              info.value !== '' &&
              info.value !== DATA_EMPTY && (
                <tr key={info.id}>
                  <th>{info.label}</th>
                  {info.label === 'メールアドレス' ? (
                    <td>
                      <a href={`mailto:${info.value}`}>{info.value}</a>
                    </td>
                  ) : (
                    <td>{info.value}</td>
                  )}
                </tr>
              ),
          )}
        </CommonTableDefault>
      </ArticleDefaultBody>
    </div>
  );
};

export default DeliveryRegistrationInfoBody;
