// ロジックを含む処理
// フロントから呼ぶ関数

import {
  //createInvitationData,
  updateInvitationData,
  deleteInvitationData,
} from '../../../infrastructure/api/Invitations.js';
import { updateNaviCommonDescriptionsData } from '../../../infrastructure/api/NaviCommonDescriptions.js';
import {
  createNaviInvitationData,
  updateNaviInvitationData,
} from '../../../infrastructure/api/NaviInvitations.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
//import { updateNaviAddDescriptionsData } from "../../../infrastructure/api/NaviAddDescriptions.js";
import {
  InvitationCreate,
  CommonDescriptionCreate,
} from '../entities/Invitation.js';
import { getInvitation } from '../repositories/Invitation.js';

export async function get(type) {
  return await getInvitation(type);
}

export async function findByGovernmentId(type, governmentId) {
  const invitations = await getInvitation(type);
  return invitations.filter(
    (data) => data.governmentId.toString() === governmentId.toString(),
  );
}

export async function findByAuctionId(type, auctionId) {
  const invitations = await getInvitation(type);
  return invitations.filter(
    (invitation) => invitation.auctionId.toString() === auctionId.toString(),
  );
}

export async function findByAuctionIds(type, auctionIds) {
  const invitations = await getInvitation(type);
  return invitations.filter((invitation) =>
    auctionIds.includes(invitation.auctionId.toString()),
  );
}

export async function findById(type, auctionId, governmentId) {
  let invitations = await getInvitation(type);
  return invitations.find(
    (invitation) =>
      invitation.auctionId.toString() === auctionId.toString() &&
      invitation.governmentId.toString() === governmentId.toString(),
  );
}

// api不具合があったため、修正待ち。
export async function createInvitation(type, inputs, governmentId) {
  console.log(inputs);

  let contactTel = Object.values(inputs.data.contactTel).join('-');
  if (contactTel === '--') {
    contactTel = 'DATA_EMPTY';
  }

  let body = {
    auction_id: Number(inputs.data.auctionId),
    government_id: Number(governmentId),
    name: inputs.data.name,
    bid_end_type: inputs.data.bidEndType,
    mail_cert_document: inputs.data.mailCertDocument,
    mail_reduction_document: inputs.data.mailReductionDocument,
    none_deposit:
      inputs.data.depositDeliveryStatus.noneDeposit === 'off'
        ? 'TRUE'
        : 'FALSE',
    payment_credit_card:
      inputs.data.depositDeliveryStatus.paymentCreditCard === 'on'
        ? 'TRUE'
        : 'FALSE',
    payment_bank_transfer:
      inputs.data.depositDeliveryStatus.paymentBankTransfer === 'on'
        ? 'TRUE'
        : 'FALSE',
    sell_real_estate: 'DATA_EMPTY',
    payment_deadline: 0,
    apl_count_auction_display: inputs.data.aplCountAuctionDisplay,
    apl_count_bid_display: inputs.data.aplCountBidDisplay,
    bid_count_display: inputs.data.bidCountDisplay,
    area: inputs.data.area,
    contact_office: inputs.data.contactOffice,
    contact_email: inputs.data.contactEmail,
    contact_tel: contactTel,
    management_registration_status: 'NO_REGISTRATION',
  };

  console.log(body);

  if (type === NAVI) {
    try {
      await createNaviInvitationData(body).then((response) => {
        console.log('response', response);
      });
    } catch (error) {
      console.log('error', error);
      throw new CustomError('参加申込に失敗しました', error);
    }
  }

  //if (type === SERVICE) {
  //   throw new CustomError('Unexpected Request (Invitation)');
  //}
  //if (type === NAVI) {
  //  let invitation = new InvitationCreate(data);
  //  response = await createNaviInvitationsData(invitation);
  //}
  //if (type === KMANAGER) {
  //  let invitation = new InvitationCreate(data);
  //  response = await createInvitationsData(invitation);
  //}
  //if (response instanceof Error) {
  //  throw new CustomError('Error create Invitation', response);
  //}
  //return response;
  return 'hoge';
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === NAVI) {
    let invitation = new InvitationCreate(data);
    response = await updateNaviInvitationData(invitation, invitation.id);
  }
  if (type === KMANAGER) {
    let invitation = new InvitationCreate(data);
    response = await updateInvitationData(invitation, invitation.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Invitation', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Invitation)');
  }
  if (type === KMANAGER) {
    response = await deleteInvitationData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Invitation', response);
  }
  return response;
}

export async function createCommonDescription(type, data) {
  let response;

  if (type === NAVI) {
    let commonDescription = new CommonDescriptionCreate(data);
    response = await updateNaviCommonDescriptionsData(
      commonDescription,
      commonDescription.invitationId,
    );
  }

  if (response instanceof Error) {
    throw new CustomError('Error create Invitation', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
