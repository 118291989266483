import Button from '../Button.jsx';

const ClearButton = ({ inputs, className = 'warning' }) => {
  console.log(inputs);
  const Clear = (e) => {
    e.preventDefault();
    inputs.map((input) => {
      input.init('');
      input.setValidationMessage({ target: { value: '' } });
    });
  };

  return <Button text={'クリア'} clickHandler={Clear} className={className} />;
};

export default ClearButton;
