import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  &:not(:first-child) {
    margin-top: 17px;
  }
`;

const Header = styled.h3`
  ${(props) =>
    props.$important &&
    css`
      color: var(--warning-base);
      caret-color: var(--warning-base);
    `}
`;

const HeaderNormal = styled.h3`
  ${(props) =>
    props.$important &&
    css`
      color: var(--black);
      caret-color: var(--black);
    `}
`;

const ListItem = styled.li`
  text-indent: -1em;
  margin-left: 1em;
`;

const DetailsLink = styled.p`
  text-align: right;
`;

const Attention = ({ title, texts, link, isImportant = false, children, normalHeader = false }) => {
  return (
    <Wrapper>
      { normalHeader
        ? <HeaderNormal $important={isImportant.toString()}>{title}</HeaderNormal>
        : <Header $important={isImportant.toString()}>{title}</Header>
      }
      {texts && (
        <ul>
          {texts.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </ul>
      )}
      {link && (
        <DetailsLink>
          <a href={link}>詳しくはこちら ＞</a>
        </DetailsLink>
      )}
      {children}
    </Wrapper>
  );
};

export default Attention;
