import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { findByGovernmentId as findInvitations } from 'domain/Auction/services/Invitation.js';
import {
  findById as findGovernment,
  //filter as filterGovernment,
} from 'domain/Government/services/Government.js';
import { findByGovernmentId as findGuidelines } from 'domain/Guidelines/services/Guidelines.js';
import { findByGovernmentId as findPublicSaleCautionPages } from 'domain/Setting/services/PublicSaleCaution.js';
import { findByGovernmentId as findAssetSaleCautionPages } from 'domain/Setting/services/AssetSaleCaution.js';
import { findByOpenDate as getAuctionsByOpenDate } from 'domain/Auction/services/Auction.js';
import { findByAuctionIds as getPropertiesByAuctionIds } from 'domain/Property/services/Property';
import { getByGovernmentIdAndProcedureType as getGovernmentTopPage } from 'domain/Setting/services/GovernmentTopPage';
import { findByPropertyIds as getProposalCountByPropertyIds } from 'domain/Applicant/services/Proposal.js';

import { SERVICE, NAVI, KMANAGER } from 'shared/utils/helper/constants.js';
import { GovernmentContext } from '../entities/GovernmentContext.js';
import { PublicSaleType } from 'shared/utils/constants/auction.js';

const GovernmentProvider = ({ type, children }) => {
  //const [governmentId, setGovernmentId] = useState();
  const [allProperties, setAllProperties] = useState([]);
  const [auction, setAuction] = useState({}); // 表示用オークション情報（ステータス・スケジュールなど表示する）
  const [auctionProperties, setAuctionProperties] = useState([]);
  const [bidProperties, setBidProperties] = useState([]);
  const [cautionPages, setCautionPages] = useState([]);
  const [government, setGovernment] = useState({});
  const [governmentTopPages, setGovernmentTopPages] = useState([]);
  const [guidelines, setGuidelines] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [proposalCount, setProposalCount] = useState([]);

  const { government_id } = useParams();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const procedureType = queryParams.get('p');

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      findGovernment(type, government_id).then((result) => {
        setGovernment(result);
      });

      let conditionProcedureType = 'ASSET_SALE';
      if (procedureType === 'au') {
        conditionProcedureType = 'PUBLIC_SALE';
      }

      findGuidelines(type, government_id, conditionProcedureType).then(
        (guidelines) => {
          let auctionGuidelines = guidelines.filter((guideline) => {
            return guideline.publicSaleType === 'AUCTION';
          });
          let bidGuidelines = guidelines.filter((guideline) => {
            return guideline.publicSaleType === 'BID';
          });
          let retGuidelines = [auctionGuidelines, bidGuidelines];

          setGuidelines(retGuidelines);
        },
      );

      if (procedureType === 'au') {
        findPublicSaleCautionPages(type, government_id).then(
          (publicSaleCautionPages) => {
            let auctionCautionPages = publicSaleCautionPages.find(
              (cautionPage) => {
                return cautionPage.publicSaleType === 'AUCTION';
              },
            );
            let bidCautionPages = publicSaleCautionPages.find((cautionPage) => {
              return cautionPage.publicSaleType === 'BID';
            });
            let retCautionPages = [];
            if (auctionCautionPages) {
              retCautionPages.push(auctionCautionPages);
            }
            if (bidCautionPages) {
              retCautionPages.push(bidCautionPages);
            }

            setCautionPages(retCautionPages);
          },
        );
      } else {
        findAssetSaleCautionPages(type, government_id).then(
          (assetSaleCautionPages) => {
            console.log(assetSaleCautionPages);
            let auctionCautionPages = assetSaleCautionPages.find(
              (cautionPage) => {
                return cautionPage.publicSaleType === 'AUCTION';
              },
            );
            let bidCautionPages = assetSaleCautionPages.find((cautionPage) => {
              return cautionPage.publicSaleType === 'BID';
            });
            let retCautionPages = [auctionCautionPages, bidCautionPages];
            setCautionPages(retCautionPages);
          },
        );
      }

      // 現在開催しているオークションの物件情報を取得
      findInvitations(type, government_id).then((invitations) => {
        getAuctionsByOpenDate(type).then((resultAuctions) => {
          let sortAuctions = Array.from(resultAuctions);
          sortAuctions.sort((a, b) => (a.aplStart > b.aplStart ? 1 : -1));
          let auction = sortAuctions[0];
          let invitation = invitations.find(
            (invitation) => invitation.auctionId === auction.id,
          );

          auction = { ...auction, ...getAuctionStatus(auction, invitation) };
          setAuction(auction);
          setInvitations([invitations]);

          getGovernmentTopPage(type, government_id, auction.procedureType).then(
            (result) => {
              setGovernmentTopPages(result);
            },
          );

          let auctionIds = sortAuctions.map((auction) => auction.id);
          getPropertiesByAuctionIds(type, auctionIds).then(
            (resultProperties) => {
              let properties = resultProperties.filter(
                (property) => property.governmentId === government_id,
              );

              properties.forEach((property) => {
                property.auction = sortAuctions.find(
                  (tmpAuction) => tmpAuction.id === property.auctionId,
                );

                let targetDeadline = '';
                if (property.status === 'APPLYING') {
                  targetDeadline = property.auction.aplEnd;
                }
                if (property.status === 'WAIT_START_BID') {
                  targetDeadline = property.auction.bidStart;
                }
                if (property.status === 'DURING_BIDDING') {
                  targetDeadline = property.auction.bidEnd;
                }
                if (property.status === 'ADDITIONAL_DURING_BIDDING') {
                  targetDeadline = property.auction.addBidDuration;
                }
                property.deadline = targetDeadline;

                let tags = [];
                tags.push(
                  property.auction.procedureType === 'ASSET_SALE'
                    ? '公有財産売却'
                    : 'インターネット公売',
                );
                tags.push(
                  property.publicSaleType === 'AUCTION'
                    ? 'せり売形式'
                    : '入札形式',
                );

                let invitation = invitations.find(
                  (invitation) =>
                    invitation.auctionId === property.auction.id &&
                    invitation.governmentId === property.governmentId,
                );

                if (invitation) {
                  let tag = '';
                  if (
                    invitation.paymentCreditCard === 'TRUE' ||
                    invitation.paymentBankTransfer === 'TRUE'
                  ) {
                    tag = '保証金あり';
                    if (invitation.paymentCreditCard === 'TRUE') {
                      tag += '(クレカ納付OK)';
                    }
                  } else {
                    tag = '保証金なし';
                  }
                  tags.push(tag);
                }

                property.tags = tags;
              });

              setAllProperties(properties);
              let auctionProperties = properties.filter(
                (property) => property.publicSaleType === 'AUCTION',
              );

              setAuctionProperties(auctionProperties);

              let bidProperties = properties.filter(
                (property) => property.publicSaleType === 'BID',
              );
              setBidProperties(bidProperties);

              let propertyIds = properties.map((property) => property.id);
              let auctionPropertyIds = properties
                .filter((property) => property.publicSaleType === 'AUCTION')
                .map((property) => property.id);
              let bidPropertyIds = properties
                .filter((property) => property.publicSaleType === 'BID')
                .map((property) => property.id);

              let proposalCount = { auctionCount: 0, bidCount: 0 };
              getProposalCountByPropertyIds(type, propertyIds).then(
                (proposals) => {
                  proposals.forEach((proposal) => {
                    if (auctionPropertyIds.includes(proposal.propertyId)) {
                      proposalCount.auctionCount++;
                    }
                    if (bidPropertyIds.includes(proposal.propertyId)) {
                      proposalCount.bidCount++;
                    }
                  });
                  setProposalCount(proposalCount);
                },
              );
            },
          );
        });
      });
    }, [government_id]);
  }

  if (type === NAVI) {
    useEffect(() => {}, []);
  }

  if (type === KMANAGER) {
    useEffect(() => {}, []);
  }

  return (
    <GovernmentContext.Provider
      value={{
        allProperties,
        auction,
        auctionProperties,
        bidProperties,
        cautionPages,
        government,
        governmentTopPages,
        guidelines,
        invitations,
        procedureType,
        proposalCount,
      }}>
      {children}
    </GovernmentContext.Provider>
  );
};

function getAuctionStatus(auction, invitation) {
  let auctionStatus = '';
  let bidStatus = '';

  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;

  if (
    auction.publicSaleType === PublicSaleType.AUCTION.string ||
    auction.publicSaleType === PublicSaleType.BOTH.string
  ) {
    if (auction.openStart <= nowString && nowString <= auction.aplTerm) {
      auctionStatus = '参加申込受付期間';
    } else if (auction.aplTerm <= nowString && nowString <= auction.bidStart) {
      auctionStatus = '入札開始待ち';
    } else if (
      auction.bidStart <= nowString &&
      nowString <= auction.auctionEnd
    ) {
      auctionStatus = '入札期間中（せり売形式）';
    } else if (
      auction.auctionEnd <= nowString &&
      nowString <= invitation.callUpButtonDownAuctionDate
    ) {
      auctionStatus = '開札準備中（せり売形式）';
    } else if (
      invitation.callUpButtonDownAuctionDate <= nowString &&
      nowString <= invitation.bidEndButtonDownAuctionDate
    ) {
      auctionStatus = '開札結果（せり売形式）';
    } else if (
      invitation.bidEndButtonDownAuctionDate <= nowString &&
      nowString <= auction.openEnd
    ) {
      auctionStatus = '入札終了（せり売形式）';
    }
  }

  if (
    auction.publicSaleType === PublicSaleType.BID.string ||
    auction.publicSaleType === PublicSaleType.BOTH.string
  ) {
    if (auction.openStart <= nowString && nowString <= auction.aplTerm) {
      bidStatus = '参加申込受付期間';
    } else if (auction.aplTerm <= nowString && nowString <= auction.bidStart) {
      bidStatus = '入札開始待ち';
    } else if (auction.bidStart <= nowString && nowString <= auction.bidEnd) {
      bidStatus = '入札期間中（入札形式）';
    } else if (
      auction.bidEnd <= nowString &&
      nowString <= invitation.callUpButtonDownBidDate
    ) {
      bidStatus = '開札準備中（入札形式）';
    } else if (
      invitation.callUpButtonDownBidDate <= nowString &&
      nowString <= invitation.bidEndButtonDownBidDate
    ) {
      bidStatus = '開札結果（入札形式）';
    } else if (
      invitation.bidEndButtonDownBidDate <= nowString &&
      nowString <= auction.openEnd
    ) {
      bidStatus = '入札終了（入札形式）';
    }
  }

  return { auctionStatus: auctionStatus, bidStatus: bidStatus };
}

export default GovernmentProvider;
