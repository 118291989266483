import {API_BASE_URL} from "../../config/envConstants.js";
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

export const createNaviGovernmentUsersData = async (body) => {
  return await apiRequest('post', `${API_BASE_URL}/api/v1/navi/government_users`, body);
};
export const updateNaviGovernmentUsersData = async (body, id) => {
  return await apiRequest('put', `${API_BASE_URL}/api/v1/navi/government_users/${id}`, body);
};
export const deleteNaviGovernmentUsersData = async (id) => {
  return await apiRequest('delete', `${API_BASE_URL}/api/v1/navi/government_users/${id}`);
};