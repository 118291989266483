import Breadcrumb from './Breadcrumb';
import Icon from 'interfaces/components/common/Header/Icon';
import URL_LINKS from 'interfaces/pages/Dashboard/entities/externalLinks';
import { ReactComponent as BeginnerIcon } from 'interfaces/assets/svg/beginner_icon.svg';
import { ReactComponent as HelpIcon } from 'interfaces/assets/svg/help_icon.svg';
import { ReactComponent as MyListIcon } from 'interfaces/assets/svg/my_list_icon.svg';
import { ReactComponent as SearchIcon } from 'interfaces/assets/svg/search_icon.svg';
import 'interfaces/css/common/header-bottom.css';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive';

const HeaderBottom = () => {
  const widthOver720 = useMediaQuery(mediaQuery.widthOver720);

  return (
    <div className="header-bottom">
      <Breadcrumb />
      <div className="header-bottom-icon-box">
        <Icon
          svg={<SearchIcon />}
          testId="icon-search"
          label="探す"
          link="/search"
        />
        <Icon
          svg={<MyListIcon />}
          testId="icon-my-list"
          label="マイリスト"
          link="/mylist"
        />
        <Icon
          svg={<HelpIcon />}
          testId="icon-help"
          label="ヘルプ"
          link={URL_LINKS.KNOWLEDGE_URL}
          isTargetBlank={true}
        />
        {/* {!responsive.isWidthOver720 && ( */}
        {widthOver720 ? (
          <></>
        ) : (
          <div className="ml-2">
            <Icon
              svg={<BeginnerIcon title="beginner" />}
              testId="icon-beginner"
              label="初めての方へ"
              link={URL_LINKS.GUIDE_URL}
              isTargetBlank={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderBottom;
