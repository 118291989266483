import ArticleDefaultBody from 'interfaces/components/common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import ArticleDefaultTitle from 'interfaces/components/common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import ItemFloatModal from 'interfaces/components/common/ItemFloatModal/ItemFloatModal.jsx';
import SimpleSchedule from 'interfaces/components/common/SimpleSchedule/SimpleSchedule.jsx';
import Slider from 'interfaces/components/common/Slider/Slider.jsx';
import Switcher from 'interfaces/components/common/Switcher/ServiceSwitcher.jsx';
import propertyStatusToNum from 'interfaces/components/Property/service/PropertyCard/helper/propertyStatusToNum.js';

import ActionArea from './ActionArea/ActionArea.jsx';
import BasicInfo from './BasicInfo/BasicInfo.jsx';
import BidHistory from './BidHistory/BidHistory.jsx';
import CommonInfo from './CommonInfo/CommonInfo.jsx';
import Contact from './Contact/Contact.jsx';
import HeroAreaModal from './HeroArea/HeroAreaModal.jsx';
import HeroAreaPhoneModal from './HeroArea/HeroAreaPhoneModal.jsx';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive.jsx';

const PropertyDetailSwitcher = ({
  property,
  isContainBlockList,
  inquiryData,
  sliderItems,
}) => {
  const labels = ['物件情報', '入札履歴'];
  const isWidthOver720 = useMediaQuery(mediaQuery.widthOver720);
  const isPublicSale =
    property?.publicSaleType === 'AUCTION' &&
    propertyStatusToNum(property?.status) >= 3; // 入札受付中以降

  return (
    <Switcher labels={isPublicSale ? labels : ['物件情報']}>
      <div className="v-window no-bg v-item-group theme--light v-tabs-items">
        <div className="v-window__container">
          <div className="v-window-item no-bg v-window-item--active">
            <ItemFloatModal>
              {!isWidthOver720 && (
                <HeroAreaModal
                  data={property}
                  isContainBlockList={isContainBlockList}
                />
              )}
              {!isWidthOver720 && (
                <HeroAreaPhoneModal
                  data={property}
                  isContainBlockList={isContainBlockList}
                />
              )}
            </ItemFloatModal>

            {property?.status !== 'CANCEL' && (
              <>
                <ArticleDefaultTitle title="簡易スケジュール" />
                <div className="common-article-default-description text-body-2">
                  <p className="mb-4"></p>
                </div>

                <SimpleSchedule data={property} scheduleType="PROPERTY" />
              </>
            )}

            <BasicInfo data={property} />

            {property?.status !== 'CANCEL' && (
              <CommonInfo
                data={property?.commonDescription}
                title={'共通説明文'}
              />
            )}

            {property?.status !== 'CANCEL' && property?.propertyType === 'REAL_ESTATE' && (
              <CommonInfo data={property?.description} title={'詳細情報'} />
            )}

            {property?.status !== 'CANCEL' && (
              <CommonInfo data={property?.addDescription} title={'追加説明文'} />
            )}

            <Contact
              data={inquiryData}
              governmentId={property?.governmentId}
              procedureType={property?.procedureType}
            />

            <ActionArea
              data={property}
              isContainBlockList={isContainBlockList}
              itemsBottomActionAreaStyle={`large-padding ${isWidthOver720 ? 'no-wrap' : ''}`}
            />

            <section>
              <section className="mb-10">
                <header className="flex justify-space-between align-center mb-2">
                  <ArticleDefaultTitle title="この行政機関の他の出品物" />
                </header>
                <ArticleDefaultBody>
                  <Slider properties={sliderItems} />
                </ArticleDefaultBody>
              </section>
            </section>
            <section>
              <section className="mb-10">
                <header className="flex justify-space-between align-center mb-2">
                  <ArticleDefaultTitle title="各行政機関のイチ押し物件" />
                </header>
                <ArticleDefaultBody>
                  <Slider
                    properties={sliderItems}
                    isShowGovernmentName={true}
                  />
                </ArticleDefaultBody>
              </section>
            </section>
          </div>
        </div>
      </div>
      {isPublicSale ? <BidHistory data={property} /> : null}
    </Switcher>
  );
};

export default PropertyDetailSwitcher;
