import SelectBox from 'interfaces/components/common/SelectBox';
import { useEffect, useState } from 'react';
import { pulldown_prefecture } from 'shared/utils/helper/Pulldown';

import CautionDescription from '../../../Form/navi/CautionDescription';
import FormText from '../../../Form/navi/FormText';
import Label from '../../../Form/navi/Label';

const FormPrefecturePullDownAndTextWithCaution = ({
  caution_description,
  dataText = '',
  dataSelected,
  handlerPrefecture = () => {},
  handlerText = () => {},
  invalidTextMessage = 'TRUE',
  label,
  maxCharacters = undefined,
  required,
  width = '100',
}) => {
  const [selectedPrefecture, setSelectedPrefecture] = useState(null);

  const prefectureText = (prefectureId) => {
    let prefecture = pulldown_prefecture.find(
      (data) => data?.id.toString() === prefectureId?.toString(),
    );
    return prefecture?.text;
  };

  useEffect(() => {
    setSelectedPrefecture(dataSelected);
  }, [dataSelected]);

  return (
    <div className="property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div
        className="property-info-general-form-table-cell"
        data-testid={`form-pulldown-with-caution-${label}`}>
        <SelectBox
          listItemData={pulldown_prefecture}
          formStateHandler={handlerPrefecture}
          name={'month'}
          selectedData={prefectureText(selectedPrefecture)}
          placeholder="都道府県を選択"
        />
        <div className="pt-2"></div>
        <FormText
          className={`property-input-text-size-${width}`}
          data={dataText}
          handler1={handlerText}
          invalidMessage={invalidTextMessage}
          maxCharacters={maxCharacters}
          label={label}
        />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormPrefecturePullDownAndTextWithCaution;
