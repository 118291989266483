import Button from "interfaces/components/common/Button";
import ButtonList from "interfaces/components/common/ButtonList";
import PageTitle from "interfaces/components/common/PageTitle";
import KmanagerTableRowHeaderData from "interfaces/components/common/Table/KmanagerTableRowHeaderData";
import { useNavigate } from "react-router-dom";
//import {bodyToSnake, createBody} from "../../api/convertData";
//import {get_category_label} from "../../helper/test_helper/select_box";

import { create } from 'domain/Government/services/Government';
//import { putApiData } from "../../api/request";

import 'interfaces/css/common/kmanager-table.css'
import { ServiceTypes } from "../../../../shared/utils/constants/service";

const TOP_PATH = "/kmanager/gov_list";


//const GovernmentConfirm = ({ type = 'create', data, cancel, governmentId }) => {
const GovernmentConfirm = ({ type = 'create', data, cancel }) => {
  const navigation = useNavigate();
  //const inputBody = createBody(data);
  //const formDataAdd = (inputBody) => {
  //  let snakeBody = bodyToSnake(inputBody)
  //  snakeBody['location'] = get_category_label(Number(snakeBody['location']))[0].label;
  //  return snakeBody
  //}

  // 更新処理はapi作成完了後修正
  const Put = async () => {
    console.log('put');
    // 一覧へ戻すのみ
    navigation(TOP_PATH);
//    let url = `governments`
//    if (governmentId){
//      url = `governments/${governmentId}`
//    }
//    await putApiData(url, formDataAdd(inputBody))
//      .then(() => {
//        navigation(TOP_PATH)
//      })
//      .catch((err) => {
//        // TODO: エラーハンドリング
//        console.log(err)
//      })
  }
  const Create = async () => {
    console.log('create');
    const government = {}
    data.map((datum) => {
      government[datum.column] = datum.state
    })

    try {
      await create(ServiceTypes.KMANAGER.string, government)
      navigation(TOP_PATH);
    } catch (error) {
      console.log(error)
    }
  }

  const CANCEL_BUTTON_DATA = {
    text: 'キャンセル',
    clickHandler: cancel,
    className: 'edit',
  };

  const CREATE_BUTTON_DATA = {
    text: '作成',
    clickHandler: Create,
    className: 'primary',
  };

  const EDIT_BUTTON_DATA = {
    text: '編集',
    clickHandler: Put,
    className: 'primary',
  };

  const BUTTONS_DATA = {
    cancel: CANCEL_BUTTON_DATA,
    create: CREATE_BUTTON_DATA,
    edit: EDIT_BUTTON_DATA,
  }

  const TitleText = () => {
    if (type === 'create') {
      return '下記内容で作成します。よろしければ作成ボタンをクリックしてください'
    } else if (type === 'edit') {
      return '下記内容で編集します。よろしければ編集ボタンをクリックしてください'
    }
  }

  return (
    <div className="kmanager-col-12 pa-5 box-shadow kmanager-confirm" style={{ maxWidth: '1000px' }}>
      {/*TODO: 赤文字*/}
      <PageTitle text={TitleText()}/>
      <div className="kmanager-table-wrap">
        <table className="kmanager-table kmanager-table-hover" data-testid="kmanager-government-confirm-table">
          <tbody>
            {
              data.map((data, index) => {
                return (<KmanagerTableRowHeaderData key={index} data={data}/>)
              })
            }
          </tbody>
        </table>
      </div>
      <ButtonList>
        <div className="d-flex justify-center mt-10">
          <Button text={BUTTONS_DATA['cancel'].text} clickHandler={BUTTONS_DATA['cancel'].clickHandler}
            className={BUTTONS_DATA['cancel'].className}/>
          <Button text={BUTTONS_DATA[type].text} clickHandler={BUTTONS_DATA[type].clickHandler}
            className={BUTTONS_DATA[type].className}/>
        </div>
      </ButtonList>
    </div>
  );
};

export default GovernmentConfirm;
