import Switcher from "interfaces/components/common/Switcher";
import { useState } from 'react';

import AuctionForm from "./AuctionForm.jsx";
import BidForm from "./BidForm.jsx";

const IndividualForm = ({auction, bid, governmentType}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const labels = [
    'せり売形式',
    '入札形式'
  ];

  return (
    <form data-testid="card-provisional-apply-completion-form">
      <Switcher labels={labels} activeTabIndex={activeTabIndex} setActiveTab={setActiveTabIndex}/>
      { activeTabIndex === 0 && <AuctionForm input={auction}/> }
      { activeTabIndex === 1 && <BidForm input={bid} governmentType={governmentType}/> }
    </form>
  );
}

export default IndividualForm