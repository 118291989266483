
export class MovableBidFeeType {
  #movableBidFeeType

  constructor(movableBidFeeType) {
    this.#movableBidFeeType = movableBidFeeType;
  }

  get() {
    return this.#movableBidFeeType
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
