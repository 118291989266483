import Button from 'interfaces/components/common/Button/Button.jsx';
import Modal2 from 'interfaces/components/common/Modal/Modal2.jsx';
import { useEffect, useState } from 'react';

import AfterWinningBid from './AfterWinningBid.jsx';
import ArticleDefaultTitle from '../ArticleDefaultTitle/ArticleDefaultTitle.jsx';

import { styled } from 'styled-components';

const ModalBase = styled.div`
  background-color: var(--white);
  border-radius: 5px;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
`;

const AfterWinningBidModal = ({
  label,
  governmentName,
  procedureType,
  afterWinningBid = undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setModalData(afterWinningBid);
  }, [afterWinningBid]);

  const handleOpen = () => {
    setIsOpen(true);
    document.documentElement.style.overflowY = 'hidden';
  };
  const handleClose = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'scroll';
  };

  return (
    <div>
      <a onClick={handleOpen}>{label}</a>
      <Modal2
        isOpen={isOpen}
        closeClickOutside={true}
        handleClose={handleClose}
        isScrollModal={true}>
        <ModalBase>
          <div className="modal-content">
            <div className="pt-4 pl-4 pr-4">
              <ArticleDefaultTitle
                title={`${governmentName} (${modalData?.publicSaleType === 'AUCTION' ? 'せり売形式' : '入札形式'}) ${procedureType} - 落札後の注意事項`}
                className="gray"
              />
              {modalData ? (
                <AfterWinningBid
                  data={modalData}
                  procedureType={procedureType}
                />
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="close-button-wrap">
            <Button
              text="閉じる"
              height="44px"
              width="100px"
              onClick={handleClose}
            />
          </div>
        </ModalBase>
      </Modal2>
    </div>
  );
};
export default AfterWinningBidModal;
