export class ContactExtension {
  #contactExtension;

  constructor(contactExtension) {
    this.#contactExtension = contactExtension;
  }

  get() {
    return this.#contactExtension === '' ||
      this.#contactExtension === 'DATA_EMPTY'
      ? null
      : this.#contactExtension;
  }
}
