import axios from 'axios';

import { API_BASE_URL } from 'config/envConstants.js';
import { apiRequest } from 'shared/utils/helper/apiRequest.js';

export const fetchManagementUsersData = async () => {
  return await axios.get(`${API_BASE_URL}/api/v1/management/management-users`);
};

export const createManagementUsersData = async (body) => {
  console.log(body);
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/kmanager/management_users`,
    body,
  );
};

export const updateManagementUsersData = async (body, id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.put(
    `${API_BASE_URL}/api/v1/management/management-users/${id}`,
    body,
    { headers: headers },
  );
};

export const deleteManagementUsersData = async (id) => {
  const headers = {
    'Content-Type': 'application/json',
  };
  return await axios.delete(
    `${API_BASE_URL}/api/v1/management/management-users/${id}`,
    {
      headers: headers,
    },
  );
};
