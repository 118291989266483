// ロジックを含む処理
// フロントから呼ぶ関数

import { PROCEDURE_NO } from 'shared/utils/constants/queryParam.js';

import {
  createAuctionsData,
  updateAuctionsData,
  deleteAuctionsData,
} from '../../../infrastructure/api/Auctions.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
import { AuctionCreate } from '../entities/Auction.js';
import { getAuction } from '../repositories/Auction.js';

export async function get(type) {
  return await getAuction(type);
}

export async function findById(type, id) {
  const auctions = await getAuction(type);
  return auctions.find((auction) => auction.id.toString() === id.toString());
}

export async function findByIds(type, ids) {
  const Auction = await getAuction(type);
  return Auction.filter((data) => ids.includes(data.id));
}

export async function getAuctionSchedule(type, procedureType) {
  const auctions = await getAuction(type);
  return auctions.filter(
    (auction) =>
      auction.governmentTypeGroup !== 'NATIONAL_TAX_AGENCY' &&
      auction.procedureType === procedureType,
  );
}

// 開催中のオークションを取得
export async function getAuctionProgressSchedule(type) {
  const auctions = await getAuction(type);
  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;
  return auctions.filter(
    (auction) => auction.aplStart <= nowString && nowString <= auction.openEnd,
  );
}
// 開催中のオークションを取得
export async function getAuctionProgressScheduleForGovernment(type) {
  const auctions = await getAuction(type);
  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;
  return auctions.filter(
    (auction) => auction.govAplStart <= nowString && nowString <= auction.openEnd,
  );
}

export async function findBySearchParams(type, searchParams) {
  const auctions = await getAuction(type);
  let ret = auctions;

  // 条件による絞り込み
  let condProcedureType = searchParams.get(PROCEDURE_NO);
  if ([undefined, null, ''].includes(condProcedureType) === false) {
    ret = ret.filter((auction) => condProcedureType === auction.procedureType);
  }

  return ret;
}

export async function findByOpenDate(type) {
  const auctions = await getAuction(type);

  let now = new Date();
  let year = now.getFullYear();
  let month = (now.getMonth() + 1).toString().padStart(2, '0');
  let day = now.getDate().toString().padStart(2, '0');
  let hours = now.getHours().toString().padStart(2, '0');
  let minutes = now.getMinutes().toString().padStart(2, '0');
  let nowString = `${year}-${month}-${day} ${hours}:${minutes}`;

  return auctions.filter(
    (auction) => auction.aplStart <= nowString && nowString <= auction.openEnd,
  );
}

export async function create(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === KMANAGER) {
    let auction = new AuctionCreate(data);
    response = await createAuctionsData(auction);
  }
  if (response instanceof Error) {
    throw new CustomError('Error create Auction', response);
  }
  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === KMANAGER) {
    let auction = new AuctionCreate(data);
    response = await updateAuctionsData(auction, auction.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Auction', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === NAVI) {
    throw new CustomError('Unexpected Request (Auction)');
  }
  if (type === KMANAGER) {
    response = await deleteAuctionsData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Auction', response);
  }
  return response;
}

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((item) => item[column] === value);
}
