import SelectBox from 'interfaces/components/common/NaviSelectBox';

import CautionDescription from './CautionDescription.jsx';
import Label from './Label.jsx';

const FormPullDownWithCaution = ({
  label,
  listItemData,
  caution_description,
  formStateHandler,
  required = true,
  selectedData = { text: '', value: '' },
  width = '100%',
}) => {
  return (
    <div
      data-testid="property-info-general-form-pulldown"
      className="property-info-general-form-table-row">
      <Label label={label} required={required} />
      <div
        className="property-info-general-form-table-cell"
        data-testid={`form-pulldown-with-caution-${label}`}>
        <SelectBox
          width={width}
          hasInitialValue={selectedData !== ''}
          formStateHandler={formStateHandler}
          listItemData={listItemData}
          selectedData={selectedData}
        />
        <CautionDescription data={caution_description} />
      </div>
    </div>
  );
};
export default FormPullDownWithCaution;
