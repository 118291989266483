import Button from 'interfaces/components/common/Button/Button';
import Modal2 from 'interfaces/components/common/Modal/Modal2';
import TextCounter from 'interfaces/components/common/TextCounter';
import { useError } from 'shared/hooks/useError.js';
import useTextCounter from 'shared/hooks/useTextCounter.js';

import styled from 'styled-components';
import 'interfaces/css/common/memo-modal.css';

const ModalLabel = styled.div`
  line-height: 2rem;
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  padding: 16px 24px 10px;
  background-color: #e0e0e0;
`;

const MemoModal = ({
  isOpen,
  label,
  closeModal,
  maxTextLimit,
  initialText = '',
}) => {
  const { text, count, isOverCount, isWithinLimit, changeHandler } =
    useTextCounter(maxTextLimit, initialText);
  const { setError } = useError();

  const submitHandler = (e) => {
    e.preventDefault();

    if (!isWithinLimit(text, maxTextLimit)) {
      return;
    }

    try {
      // TODO: CreateのAPIを叩く
      console.log('ok');
    } catch (e) {
      setError(e);
    }
  };

  return (
    <Modal2
      isOpen={isOpen}
      handleClose={closeModal}
      modalSize={'S'}
      padding={false}
      className="navi_memo-modal-wrap">
      <ModalLabel
        className="memo-modal-header-label"
        data-testid="memo-modal-header-label">
        {label}
      </ModalLabel>
      <form
        id="memo-form"
        className="memo-modal-form"
        onSubmit={submitHandler}
        data-testid="memo-modal-form">
        <textarea
          rows="12"
          value={text}
          onChange={changeHandler}
          className="memo-modal-textarea"
        />
        <TextCounter count={count} isOverCount={isOverCount} />
      </form>
      <div className="memo-modal-button-wrapper">
        <Button
          text="キャンセル"
          className="common-button-bg-whitesmoke"
          onClick={closeModal}
        />
        <Button
          form="memo-form"
          type="submit"
          text="保存"
          className="ml-2"
          isDisabled={isOverCount}
        />
      </div>
    </Modal2>
  );
};
export default MemoModal;
