// react/src/shared/utils/helper/naviValidators.js
import {
  validateEmptyString,
  validateEmailFormatCheck,
  validateRangeLength,
  notInclude,
  validateNumber,
  hasEmoji,
  validateLength,
  validatePrefecture,
  validateSelected,
  includesPrefecture,
  validateImageSizeCheck,
  isValidImageExtension,
  isFullwidthKatakana,
  validateInclude,
  validateExtensionFormat,
} from '../../../application/validations';

export function validateOtherHasEmoji(name) {
  return hasEmoji(name);
}
// キャラクター名「使用する」にした場合  	    文字数が1文字以上、20文字以下であるか
export function validateCharacterNameUseRangeLength(name) {
  return validateRangeLength(name, 1, 20);
}

// キャラクター名「使用する」にした場合	    絵文字・機種依存文字が含まれていない
export function validateCharacterNameUseHasEmoji(name) {
  return hasEmoji(name);
}

// キャラクター名「使用しない」にした場合 	文字数が20文字以下であるか
export function validateCharacterNameNoneLength(name) {
  return validateRangeLength(name, 1, 20);
}

// キャラクター名「使用しない」にした場合    	絵文字・機種依存文字が含まれていない
export function validateCharacterNameNoneHasEmoji(name) {
  return hasEmoji(name);
}

// キャラクター画像ファイル	    拡張子が.jpg .jpeg .png .gif であるか
export function validateCharacterImageValidExtension(image_file) {
  return isValidImageExtension({ name: image_file });
}

// キャラクター画像ファイル	    画像のサイズが5MB以下であるか
export function validateCharacterImageSize(image_file) {
  return validateImageSizeCheck(image_file);
}

// お問い合せ先「使用する」にした場合	文字数が1文字以上50文字以内であるか
export function validateInquiryDisplayRangeLength1(contact) {
  return validateRangeLength(contact, 1, 50);
}

// お問い合せ先「使用する」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryDisplayHasEmoji1(contact) {
  return hasEmoji(contact);
}

// お問い合せ先「使用しない」にした場合	文字数が50文字以内であるか
export function validateInquiryHiddenLength1(contact) {
  return validateRangeLength(contact, 0, 50);
}

// お問い合せ先「使用しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryHiddenHasEmoji1(contact) {
  return hasEmoji(contact);
}

// ホームページ名「使用する」にした場合	文字数が1文字以上50文字以内であるか
export function validateHpNameDisplayRangeLength(website) {
  return validateRangeLength(website, 1, 50);
}

// ホームページ名「使用する」にした場合	絵文字・機種依存文字が含まれていない
export function validateHpNameDisplayHasEmoji(website) {
  return hasEmoji(website);
}

// ホームページ名「使用しない」にした場合	文字数が50文字以内であるか
export function validateHpNameHiddenLength(website) {
  return validateRangeLength(website, 0, 50);
}

// ホームページ名「使用しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateHpNameHiddenHasEmoji(website) {
  return hasEmoji(website);
}

// 危険負担	文字数が1000文字以内であるか
export function validateImportantRiskBearingLength(burden_risk) {
  return validateRangeLength(burden_risk, 0, 1000);
}

// 危険負担	絵文字・機種依存文字が含まれていない
export function validateImportantRiskBearingHasEmoji(burden_risk) {
  return hasEmoji(burden_risk);
}

// 契約不適合責任	文字数が1000文字以内であるか
export function validateImportantNonConformingContractsLiabilityLength(
  nonconformity,
) {
  return validateRangeLength(nonconformity, 0, 1000);
}

// 契約不適合責任	絵文字・機種依存文字が含まれていない
export function validateImportantNonConformingContractsLiabilityHasEmoji(
  nonconformity,
) {
  return hasEmoji(nonconformity);
}

// 引き渡し条件	文字数が1000文字以内であるか
export function validateImportantTermsDeliveryLength(handover_conditions) {
  return validateRangeLength(handover_conditions, 0, 1000);
}

// 引き渡し条件	絵文字・機種依存文字が含まれていない
export function validateImportantTermsDeliveryHasEmoji1(handover_conditions) {
  return hasEmoji(handover_conditions);
}

// 執行機関の引き渡し義務	文字数が1000文字以内であるか
export function validateImportantObligationDeliveryLength(obligation) {
  return validateRangeLength(obligation, 0, 1000);
}

// 執行機関の引き渡し義務	絵文字・機種依存文字が含まれていない
export function validateImportantObligationDeliveryHasEmoji(obligation) {
  return hasEmoji(obligation);
}

// 返品、交換	文字数が1000文字以内であるか
export function validateImportantReturnItemsLength(exchanges) {
  return validateRangeLength(exchanges, 0, 1000);
}

// 返品、交換	絵文字・機種依存文字が含まれていない
export function validateImportantReturnItemsHasEmoji(exchanges) {
  return hasEmoji(exchanges);
}

// 保管費用	文字数が1000文字以内であるか
export function validateImportantStorageCostLength(storage_cost) {
  return validateRangeLength(storage_cost, 0, 1000);
}

// 保管費用	絵文字・機種依存文字が含まれていない
export function validateImportantStorageCostHasEmoji(storage_cost) {
  return hasEmoji(storage_cost);
}

// 落札者（最高価申込者）決定後、公売保証金が変換される場合	文字数が1000文字以内であるか
export function validateImportantReturnDepositLength(highest_price_applicant) {
  return validateRangeLength(highest_price_applicant, 0, 1000);
}

// 落札者（最高価申込者）決定後、公売保証金が変換される場合	絵文字・機種依存文字が含まれていない
export function validateImportantReturnDepositHasEmoji(
  highest_price_applicant,
) {
  return hasEmoji(highest_price_applicant);
}

// その他	文字数が1000文字以内であるか
export function validateImportantOtherLength1(other) {
  return validateRangeLength(other, 0, 1000);
}

// お問い合わせ先「表示する」にした場合	文字数が1文字以上50文字以内であるか
export function validateInquiryDisplayRangeLength2(contact) {
  return validateRangeLength(contact, 1, 50);
}

// お問い合わせ先「表示する」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryDisplayHasEmoji2(contact) {
  return hasEmoji(contact);
}

// お問い合わせ先「表示しない」にした場合	文字数が50文字以内であるか
export function validateInquiryHiddenLength2(contact) {
  return validateRangeLength(contact, 0, 50);
}

// お問い合わせ先「表示しない」にした場合	絵文字・機種依存文字が含まれていない
export function validateInquiryHiddenHasEmoji2(contact) {
  return hasEmoji(contact);
}

// 住所(続き)*	文字数が1文字50文字以内であるか
export function validateInvoiceAddressRequiredRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// 住所(続き)*	絵文字・機種依存文字が含まれていない
export function validateInvoiceAddressRequiredHasEmoji(address) {
  return hasEmoji(address);
}

// あて名ご名義人*	文字数が1文字以上50文字以内であるか
export function validateInvoiceWrittenDestinationRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 50);
}

// あて名ご名義人*	絵文字・機種依存文字が含まれていない
export function validateInvoiceWrittenDestinationRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// ご担当部署*	文字数が1文字以上50文字以内であるか
export function validateInvoiceDepartmentRequiredRangeLength(pic) {
  return validateRangeLength(pic, 1, 50);
}

// ご担当部署*	絵文字・機種依存文字が含まれていない
export function validateInvoiceDepartmentRequiredHasEmoji(pic) {
  return hasEmoji(pic);
}

// システム利用料振込人名義	文字数が50文字以内であるか
export function validateInvoicePayerNameLength(name) {
  return validateRangeLength(name, 0, 50);
}

// システム利用料振込人名義	絵文字・機種依存文字が含まれていない
export function validateInvoicePayerNameHasEmoji(name) {
  return hasEmoji(name);
}

// 市区町村	文字数が50文字以内であるか
export function validateCertDocumentCityLength(city) {
  return validateRangeLength(city, 0, 50);
}

// 市区町村	絵文字・機種依存文字が含まれていない
export function validateCertDocumentCityHasEmoji(city) {
  return hasEmoji(city);
}

// 住所（続き）	文字数が50文字以内であるか
export function validateCertDocumentAddressLength(address) {
  return validateRangeLength(address, 0, 50);
}

// 住所（続き）	絵文字・機種依存文字が含まれていない
export function validateCertDocumentAddressHasEmoji1(address) {
  return hasEmoji(address);
}

// あて先	文字数が50文字以内であるか
export function validateCertDocumentDestinationLength(contact) {
  return validateRangeLength(contact, 0, 50);
}

// あて先	絵文字・機種依存文字が含まれていない
export function validateCertDocumentDestinationHasEmoji1(contact) {
  return hasEmoji(contact);
}

// あて名ご名義人	文字数が50文字以内であるか
export function validateCertDocumentWrittenDestinationLength(name) {
  return validateRangeLength(name, 0, 50);
}

// あて名ご名義人	絵文字・機種依存文字が含まれていない
export function validateCertDocumentWrittenDestinationHasEmoji(name) {
  return hasEmoji(name);
}

// 画像ファイル	画像のサイズが5MB以下であるか
export function validateImageSize(image_file) {
  return validateImageSizeCheck(image_file);
}

// 必須チェック
export function validateRequired(value) {
  return validateEmptyString(value);
}

// メールフォーマットチェック
export function validateEmailFormat(email) {
  return validateEmailFormatCheck(email);
}

// メールアドレス 文字数が128文字以内であるか
export function validateEmailLength(email) {
  return validateRangeLength(email, 0, 128);
}

// 空白が含まれていないか
export function validateHasNoSpace(input) {
  return notInclude(input, [' ']);
}

// 半角数字以外が含まれていないか
export function validateOnlyNumber(number) {
  return validateNumber(number);
}

export { validateLength, validateRangeLength };

// 電話番号(3分割）	文字数が4文字以内であるか
export function validateTelLength(tel_number) {
  return validateRangeLength(tel_number, 0, 4);
}

// 電話番号(3分割）	空白が含まれていないか
export function validateTelSpaceMessage(tel_number) {
  return notInclude(tel_number, [' ']);
}

// 電話番号(3分割）	半角数字以外が含まれていないか
export function validateTelNotInclude(tel_number) {
  return validateNumber(tel_number);
}

// 電話番号(3分割）	文字数が合計で10文字以上11文字以下であるか
export function validateTelRangeLength(tel_number) {
  return validateRangeLength(tel_number, 10, 11);
}

// 内線	文字数が10文字以内であるか
export function validateExtensionLength(extension) {
  return validateRangeLength(extension, 0, 10);
}

// 内線	空白が含まれていないか
export function validateExtensionSpaceMessage(extension) {
  return notInclude(extension, [' ']);
}

// 内線	「,」「-」「.」「/」と半角数字以外が含まれていないか
export function validateExtensionNotInclude(extension) {
  return validateExtensionFormat(extension);
}

// 本文	文字数が12000文字以内であるか
export function validateBodyLength1(body) {
  return validateRangeLength(body, 0, 12000);
}

// URL	文字列に「https://」が含まれているか
export function validateUrlValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// URL	文字数が2048文字以内であるか
export function validateUrlLength(url) {
  return validateRangeLength(url, 0, 2048);
}
// URL	文字数が1文字以上2048文字以内であるか
export function validateUrlRequiredLength(url) {
  return validateRangeLength(url, 1, 2048);
}

// 権利移転手続きについての説明	文字数が300文字以内であるか
export function validateManagementTransLength(description) {
  return validateRangeLength(description, 0, 300);
}

// 動産の説明「表示する」にした場合	文字数が1文字以上500文字以内であるか
export function validateMovableDescriptionDisplayRangeLength(description) {
  return validateRangeLength(description, 1, 500);
}

// 不動産の説明「表示する」にした場合	文字数が1文字以上1000文字以内であるか
export function validateEstateDescriptionDisplayRangeLength2(description) {
  return validateRangeLength(description, 1, 1000);
}

// 不動産の説明「表示しない」にした場合	文字数が1000文字以内であるか
export function validateEstateDescriptionHiddenLength2(description) {
  return validateRangeLength(description, 0, 1000);
}

// 案内ページ名「表示する」にした場合	文字数が1文字以上30文字以内であるか
export function validateDocumentNameDisplayRangeLength(name) {
  return validateRangeLength(name, 1, 30);
}

// 建物	文字数が50文字以内であるか
export function validateBuildLength(build) {
  return validateRangeLength(build, 0, 50);
}

// 行政機関共通設定	文字数が1000文字以内であること
export function validateGovernmentCommonSettingLength(email) {
  return validateRangeLength(email, 0, 1000);
}

// 行政機関共通設定	絵文字・機種依存文字が含まれていない
export function validateGovernmentCommonSettingHasEmoji(email) {
  return hasEmoji(email);
}

// 市区町村*	文字数が1文字以上50文字以内であるか
export function validateCityRequiredRangeLength(address) {
  return validateRangeLength(address, 1, 50);
}

// 市区町村*	絵文字・機種依存文字が含まれていない
export function validateCityRequiredHasEmoji(city) {
  return hasEmoji(city);
}

// 都道府県*	都道府県が選択されている
export function validatePrefectureRequiredRequired(prefecture) {
  return validatePrefecture(prefecture);
}


// 備考	文字数が50文字以内であるか
export function validateNoteLength(note) {
  return validateRangeLength(note, 0, 50);
}

// 備考	絵文字・機種依存文字が含まれていない
export function validateNoteHasEmoji(note) {
  return hasEmoji(note);
}

// 電話受付時間	文字数が50文字以内であるか
export function validateReferenceAcceptLength(tel_time) {
  return validateRangeLength(tel_time, 0, 50);
}

// 電話受付時間	文字数が100文字以内であるか
export function validateInquiryAcceptLength(tel_time) {
  return validateRangeLength(tel_time, 0, 100);
}

// 電話受付時間	絵文字・機種依存文字が含まれていない
export function validateReferenceAcceptHasEmoji(tel_time) {
  return hasEmoji(tel_time);
}

// 担当部署名	文字数が50文字以内であるか
export function validateDepartmentLength(pic_name) {
  return validateRangeLength(pic_name, 0, 50);
}

// 担当部署名	絵文字・機種依存文字が含まれていない
export function validateDepartmentHasEmoji1(pic_name) {
  return hasEmoji(pic_name);
}

// 出品行政機関名	文字数が30文字以内であるか
export function validateInquiryNameLength(name) {
  return validateRangeLength(name, 0, 30);
}

// 出品行政機関名	絵文字・機種依存文字が含まれていない
export function validateInquiryNameHasEmoji(name) {
  return hasEmoji(name);
}

// （公有: 契約締結期限）	日付が選択されている
export function validateCcontractedLimitRequired(data) {
  return validateSelected(data);
}

// （公有: 売払代金納付期限）買受代金納付期限*	日付が選択されている
export function validatePaymentDeadlineRequiredRequired1(data) {
  return validateSelected(data);
}

// （公有: 議決日）	文字数が30文字以内であるか
export function validateRresolutionDateLength(public_data) {
  return validateRangeLength(public_data, 0, 30);
}


// タイトル*	文字数が1文字以上50文字以内であるか
export function validateTitleRequiredRangeLength(title) {
  return validateRangeLength(title, 1, 50);
}


// タイトル*	絵文字・機種依存文字が含まれていない
export function validateTitleRequiredHasEmoji(title) {
  return hasEmoji(title);
}


// 説明	文字数が500文字以内であるか
export function validateDescriptionLength1(discription) {
  return validateRangeLength(discription, 0, 500);
}

// 参加公売（参加申込開始日）*	公告が選択されている
export function validateOpenStartRequiredRequired(data) {
  return validateSelected(data);
}

// 公告名*	文字数が1文字以上30文字以内であるか
export function validateInvitationNameRequiredRangeLength(name) {
  return validateRangeLength(name, 1, 30);
}

// 公告名*	絵文字・機種依存文字が含まれていない
export function validateInvitationNameRequiredHasEmoji(name) {
  return hasEmoji(name);
}

// 地域*	文字数が1文字以上30文字以内であるか
export function validateAreaRequiredRangeLength(area) {
  return validateRangeLength(area, 1, 30);
}

// 地域*	文字列に都道府県が含まれている
export function validateAreaRequiredInclude(area) {
  return includesPrefecture(area);
}

// 地域*	絵文字・機種依存文字が含まれていない
export function validateAreaRequiredHasEmoji(area) {
  return hasEmoji(area);
}

// 担当部署	文字数が1文字以上30文字以内であるか
export function validateDepartmentRangeLength(department_in_charge) {
  return validateRangeLength(department_in_charge, 1, 30);
}

// 担当部署	絵文字・機種依存文字が含まれていない
export function validateDepartmentHasEmoji2(department_in_charge) {
  return hasEmoji(department_in_charge);
}


// 絵文字・機種依存文字が含まれていない
export function validateHasEmoji(text) {
  return hasEmoji(text);
}

// 文字列が全角のカタカナであるか
export function validateFullKatakana(text) {
  return isFullwidthKatakana(text);
}






// 必要書類提出の案内ページURL*	  文字列の先頭が「https://」であるか
export function validateDocumentSubmissionUrlRequiredValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 必要書類提出の案内ページURL*	  文字数が2048文字以内であるか
export function validateDocumentSubmissionUrlRequiredLength(url) {
  return validateRangeLength(url, 0, 2048);
}

// 共同入札案内ページURL	 文字列の先頭が「https://」であるか
export function validateJointBiddingUrlValidUrl1(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 共同入札案内ページURL	 文字数が2048文字以内であるか
export function validateJointBiddingUrlLength1(url) {
  return validateRangeLength(url, 0, 2048);
}

// 銀行振込など案内ページURL*	 文字列の先頭が「https://」であるか
export function validateBankTransferUrlRequiredValidUrl(url) {
  return validateInclude(url, ['https://', 'http://']);
}

// 銀行振込など案内ページURL*	 文字数が2048文字以内であるか
export function validateBankTransferUrlRequiredLength(url) {
  return validateRangeLength(url, 0, 2048);
}
