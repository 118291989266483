import { useEffect, useState } from "react";

import ListBox from "./ListBox.jsx";
import ListSelector from "./ListSelector";
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

export const SelectBox = ({
  handler,
  name = 'name',
  listItemData,           // format: {id: '', text: 'リストに表示する値', value: 'DBに保存する値'}
  selectedTextData,       // textの値を保存する
  width = "350px",
  isInvalid,
}) => {
  const [showListBox, setShowListBox] = useState(false);
  const { ref } = useCloseClickOutside(() => setShowListBox(false));
  const [selectedText, setSelectedText] = useState("");

  const selectBoxClickHandler = () => {
    handler({target: {value: ""}});
    setShowListBox(!showListBox);
  }

  // 初期化
  useEffect(() => {
    setSelectedText(selectedTextData)
  }, [selectedTextData]);

  return (
    <div style={{ position: "relative" }} ref={ref}>
      <ListSelector
        content={selectedText}
        isInvalid={isInvalid}
        onClick={selectBoxClickHandler}
        width={width}
      />
      {showListBox &&
        <ListBox
          data={listItemData}
          handler={handler}
          name={name}
          setSelectedValue={setSelectedText}
          setShowListBox={setShowListBox}
          width={width}
        />
      }
    </div>
  );
}

export default SelectBox;