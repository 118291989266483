// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.memo-modal-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

.memo-modal-form {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.memo-modal-textarea {
  display: block;
  padding: 6px 12px 0;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
  width: 100%;
  line-height: 1.75rem;
  resize: vertical;
}

.memo-modal-button-wrapper .common-button {
  min-width: 64px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 16px;
}

.memo-modal-button-wrapper .common-button[type=submit] {
  color: var(--white);
}

.memo-modal-button-wrapper .common-button[type=submit]:disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0,0,0,.12);
}

.navi .navi_memo .new-style_modal {
  max-width: 500px!important;
  width: 100%;
}
.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-header-label {
  color: rgba(0, 0, 0, .87);
}
.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-button-wrapper .common-button {
  background-color: #ff7f00;
  font-size: .875rem;
  letter-spacing: .0892857143em;
}
.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-button-wrapper .common-button-bg-whitesmoke {
  background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/interfaces/css/common/memo-modal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,4CAA4C;AAC9C;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,qCAAqC;EACrC,kBAAkB;EAClB,WAAW;EACX,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,iCAAiC;AACnC;;AAEA;EACE,0BAA0B;EAC1B,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,6BAA6B;AAC/B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".memo-modal-button-wrapper {\n  display: flex;\n  justify-content: flex-end;\n  padding: 8px;\n}\n\n.memo-modal-form {\n  padding: 20px;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n}\n\n.memo-modal-textarea {\n  display: block;\n  padding: 6px 12px 0;\n  border: 1px solid rgba(0, 0, 0, 0.38);\n  border-radius: 4px;\n  width: 100%;\n  line-height: 1.75rem;\n  resize: vertical;\n}\n\n.memo-modal-button-wrapper .common-button {\n  min-width: 64px;\n  padding-left: 8px;\n  padding-right: 8px;\n  font-size: 16px;\n}\n\n.memo-modal-button-wrapper .common-button[type=submit] {\n  color: var(--white);\n}\n\n.memo-modal-button-wrapper .common-button[type=submit]:disabled {\n  color: rgba(0, 0, 0, 0.26);\n  background-color: rgba(0,0,0,.12);\n}\n\n.navi .navi_memo .new-style_modal {\n  max-width: 500px!important;\n  width: 100%;\n}\n.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-header-label {\n  color: rgba(0, 0, 0, .87);\n}\n.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-button-wrapper .common-button {\n  background-color: #ff7f00;\n  font-size: .875rem;\n  letter-spacing: .0892857143em;\n}\n.navi .navi_memo .new-style_modal .navi_memo-modal-wrap .memo-modal-button-wrapper .common-button-bg-whitesmoke {\n  background-color: #f5f5f5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
