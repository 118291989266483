import Button from 'interfaces/components/common/Button/Button';
import styled from 'styled-components';
import { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useError } from 'shared/hooks/useError';
import useModalControl from 'shared/hooks/useModalControl.js';

import EditUserInfoBody from './EditUserInfo/EditUserInfoBody';
import { applicantInfoToJson } from './EditUserInfo/helper/userInfo';

import { EditUserInfoContext } from 'interfaces/pages/EditUserInfo/entities/EditUserInfoContext';
import { EditUserFormData } from './EditUserInfo/EditUserFormData';
import 'interfaces/css/EditUserInfo/edit-user-info.css';
import Modal2 from 'interfaces/components/common/Modal/Modal2';

const APPLICANT = 'APPLICANT';
const DELEGATOR = 'CLIENT';

export const initUserInfo = {
  corpName: '',
  corpNameKana: '',
  familyName: '',
  firstName: '',
  familyNameKana: '',
  firstNameKana: '',
  sex: 'MALE', // MALE \ FEMALE
  birthDate: '',
  postalCode1: '',
  postalCode2: '',
  prefectureId: null,
  address: '',
  houseTel: '',
  mobileTel: '',
};

const EditUserInfoBodyCaption = () => {
  return (
    <p className="edit-user-info-body-caption">
      ※参加者情報は必ず、法人の場合は商業登記簿または個人の場合は住民登録などに登記、登録されている内容どおりに入力してください。落札後、物件の権利移転（物件の引き渡し）は、参加申し込み時に入力された情報によって行います。登記、登録されている内容と相違がある場合、物件の権利移転登記などはできません。
    </p>
  );
};

const DeleteModalContents = styled.div``;

const DeleteMessage = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  padding: 16px 24px 10px;
`;

const DeleteButtonArea = styled.div`
  padding: 8px;
`;

const EditUserInfo = () => {
  const location = useLocation();
  const { auctionUser, preregistrationApplicantInformation } =
    useContext(EditUserInfoContext);

  const { setError } = useError();
  const navigation = useNavigate();

  let userInfo = EditUserFormData({ isEdit: location.state.isEdit });

  const [isValid, setIsValid] = useState(false);

  // フォームの初期化
  useEffect(() => {
    if (preregistrationApplicantInformation === undefined) {
      return;
    }
    userInfo.init.setCorpName(preregistrationApplicantInformation.corpName);
    userInfo.init.setCorpNameKana(
      preregistrationApplicantInformation.corpNameKana,
    );
    userInfo.init.setFamilyName(preregistrationApplicantInformation.familyName);
    userInfo.init.setFirstName(preregistrationApplicantInformation.firstName);
    userInfo.init.setFamilyNameKana(
      preregistrationApplicantInformation.familyNameKana,
    );
    userInfo.init.setFirstNameKana(
      preregistrationApplicantInformation.firstNameKana,
    );
    userInfo.init.setSex(preregistrationApplicantInformation.sex);
    userInfo.init.setBirthDate(preregistrationApplicantInformation.birthDate);
    //userInfo.init.setPostalCode(preregistrationApplicantInformation.postalCode);
    if (preregistrationApplicantInformation.postalCode.includes('-')) {
      userInfo.init.setPostalCode1(
        preregistrationApplicantInformation.postalCode.split('-')[0],
      );
      userInfo.init.setPostalCode2(
        preregistrationApplicantInformation.postalCode.split('-')[1],
      );
    }
    userInfo.init.setLocationGovernmentSubCategoryId(
      preregistrationApplicantInformation.locationGovernmentSubCategoryId,
    );
    userInfo.init.setAddress(preregistrationApplicantInformation.address);
    userInfo.init.setHouseTel(preregistrationApplicantInformation.houseTel);
    userInfo.init.setMobileTel(preregistrationApplicantInformation.mobileTel);
  }, [preregistrationApplicantInformation]);

  useEffect(() => {
    const notRequiredKeys = [];
    if (
      userInfo.validationMessage.houseTelValid === 'TRUE' ||
      userInfo.validationMessage.mobileTelValid === 'TRUE'
    ) {
      notRequiredKeys.push('houseTelValid', 'mobileTelValid');
    }
    const validationResults = Object.entries(userInfo.validationMessage).map(
      ([key, validationMessage]) => {
        const isValid = notRequiredKeys.includes(key)
          ? validationMessage === null || validationMessage === 'TRUE'
          : validationMessage === 'TRUE';
        return { key, validationMessage, isValid };
      },
    );

    const allValid = validationResults.every(({ isValid }) => isValid);
    setIsValid(allValid);
  }, [userInfo]);

  const handleUpdate = async () => {
    let jsonData;
    try {
      if (auctionUser?.type === 'CLIENT') {
        jsonData = applicantInfoToJson(userInfo, DELEGATOR);
      } else {
        jsonData = applicantInfoToJson(userInfo, APPLICANT);
      }
      console.log(jsonData);
      //await putPreregistrationInfo(userId, jsonData);
    } catch (e) {
      setError(e);
    }
  };

  //const handleDelete = async () => {
  //  let body;
  //  try {
  //    if (auctionUser?.type === 'CLIENT') {
  //      body = { type: DELEGATOR };
  //    } else {
  //      body = { type: APPLICANT };
  //    }
  //    console.log(body);
  //  } catch (e) {
  //    setError(e);
  //  }
  //};

  const { isOpen, handleOpen, handleClose } = useModalControl();

  return (
    <div className="kservice_edit-user-info">
      <div className="inner">
        <div className="edit-user-info-title">
          {preregistrationApplicantInformation?.type === 'APPLICANT'
            ? '参加者'
            : '委任者'}
          情報の登録
        </div>
        <EditUserInfoBody
          prefix={<EditUserInfoBodyCaption />}
          membershipId={auctionUser?.membershipId}
          userInfo={userInfo}
          //handleUserInfoChange={handleUserInfoChange}
          type={preregistrationApplicantInformation?.type}
        />
        <div className="flex justify-center edit-user-info-buttons">
          <Button
            text={location.state.isEdit ? '更新する' : '登録する'}
            width="120px"
            height="52px"
            onClick={handleUpdate}
            isDisabled={!isValid}
          />
          {location.state.isEdit ? (
            <>
              <Button
                text="削除する"
                width="120px"
                height="52px"
                onClick={handleOpen}
                className="delete-common-button ml-4"
              />
              <div>
                <Modal2
                  className="auth-modal"
                  handleClose={handleClose}
                  isOpen={isOpen}
                  maxHeight="initial"
                  modalSize="480px"
                  scroll="initial">
                  <DeleteModalContents>
                    <DeleteMessage>参加者情報削除しますか？</DeleteMessage>
                    <hr />
                    <DeleteButtonArea>
                      <center>
                        <Button
                          className="cancel-common-button"
                          onClick={handleClose}
                          text="キャンセル"
                          height="44px"
                          width="130px"
                        />
                        <Button
                          text="OK"
                          height="44px"
                          width="90px"
                          className="ml-4"
                        />
                      </center>
                    </DeleteButtonArea>
                  </DeleteModalContents>
                </Modal2>
              </div>
            </>
          ) : (
            <></>
          )}
          <Button
            text="戻る"
            width="90px"
            height="52px"
            onClick={() => navigation('/mypage')}
            className="cancel-common-button ml-4"
          />
        </div>
      </div>
    </div>
  );
};

export default EditUserInfo;
