import Button from 'interfaces/components/common/Button';
import ErrorMessage from 'interfaces/components/common/ErrorMessage';
import PageTitle from 'interfaces/components/common/PageTitle';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import InputPassword from './InputPassword';
import InputUserName from './InputUserName';
import 'interfaces/css/Login/login-form.css';
import { ServiceTypes } from 'shared/utils/constants/service';
import { login, resetPassword } from 'domain/Auth/services/Login';

const Login = () => {
  const navigation = useNavigate();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [initialPassword, setInitialPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const TEXT_FAIL_LOGIN = 'ユーザー名かパスワードが異なります。';

  useEffect(() => {
    setIsDisabled(!(userName && password));
  }, [userName, password]);

  const handlePopstate = () => {
    navigation('/kmanager/login');
  };

  useEffect(() => {
    if (localStorage.getItem('kmanagerUserId') !== null) {
      navigation('/kmanager');
    }
    window.addEventListener('popstate', handlePopstate, false);
  }, []);

  async function handleClick() {
    setMessage('');

    if (isDisabled) {
      return;
    }
    try {
      const requiredNewPassword = await login(
        userName,
        password,
        ServiceTypes.KMANAGER.string,
      );
      console.log(requiredNewPassword);

      if (requiredNewPassword) {
        setPassword('');
        setConfirmPassword(requiredNewPassword);
        return;
      }
      navigation('/kmanager');
    } catch (error) {
      setMessage(TEXT_FAIL_LOGIN);
    }
    navigation('/kmanager');
  }

  const handleUpdate = () => {
    console.log('update');
    try {
      resetPassword(userName, initialPassword, password, ServiceTypes.KMANAGER.string);
      navigation('/kmanager');
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <div className="kmanager-login-div" data-testid="kmanager-login-div">
      <div className="kmanager-login-form" data-testid="kmanager-login-form">
        {confirmPassword ? (
          <>
            <PageTitle text="初回パスワード更新" />
            {message && <ErrorMessage text={message} />}
            <InputPassword
              state={initialPassword}
              setState={setInitialPassword}
            />
            <p style={{ fontSize: '.875rem', lineHeight: '1.5rem' }}>
              安全のためパスワードを更新してください。
              <br />
              パスワードは
              <span style={{ fontWeight: 'bold' }}>
                アルファベットの小文字と大文字、数字をすべて使い8桁以上の組み合わせ
              </span>
              で作成してください。
            </p>
            <InputPassword
              state={password}
              setState={setPassword}
              text="新規パスワード"
            />
            <Button
              className="secondary full-width"
              text="更新"
              clickHandler={handleUpdate}
            />
          </>
        ) : (
          <>
            <PageTitle text="ログイン" />
            {message && <ErrorMessage text={message} />}
            <InputUserName state={userName} setState={setUserName} />
            <InputPassword state={password} setState={setPassword} />
            <Button
              className="secondary full-width"
              text="ログイン"
              clickHandler={handleClick}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default Login;
