import { useState } from 'react';
//import * as Validation from 'shared/utils/helper/naviValidators';
// import {logInput} from "../../utils/log/logInputs.js";
import {
  validateRequired,
  hasEmoji,
  notInclude,
  validateDecimal,
  validateDeposit,
  validateEmailFormatCheck,
  validateEmptyString,
  validateExtensionFormat,
  validateInclude,
  validateNumber,
  validateNumberRange,
  validateRangeLength,
  validateSelected,
  validateSomeSelected,
} from 'application/validations';

export const UseEstateInputsValidation = ({ isEdit = false }) => {
  // 2. 物件情報
  const [printOrderValid, setPrintOrderValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 掲載順位
  const [categoryNamesValid, setCategoryNamesValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // カテゴリ
  const [categoryIdValid, setCategoryIdValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // カテゴリ
  const [recommendPropertiesValid, setRecommendPropertiesValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // イチ押し設定
  const [divisionIdValid, setDivisionIdValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 執行機関名称（id）
  const [saleNumberValid, setSaleNumberValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 売却区分番号
  const [nameValid, setNameValid] = useState(isEdit ? 'TRUE' : null); // タイトル
  const [paymentMethodValid, setPaymentMethodValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 支払い方法
  const [paymentLimitValid, setPaymentLimitValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 納付期限
  const [videoUrlValid, setVideoUrlValid] = useState(isEdit ? 'TRUE' : null); // 動画URL
  const [typeValid, setTypeValid] = useState(isEdit ? 'TRUE' : null); // 物件の種類
  const [governmentSubCategoryIdValid, setGovernmentSubCategoryIdValid] =
    useState(isEdit ? 'TRUE' : null); // 物件の所在(properties)
  const [resolutionDateValid, setResolutionDateValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 物件の所在(properties)
  const [contractedLimitValid, setContractedLimitValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 契約締結期限

  // 3. 基本情報
  const [propertyPrintValid, setPropertyPrintValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 登記簿の表示
  const [
    locationGovernmentSubCategoryIdValid,
    setLocationGovernmentSubCategoryIdValid,
  ] = useState(isEdit ? 'TRUE' : null); // 所在地
  const [locationTextValid, setLocationTextValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 所在地(テキスト)
  const [
    residenceGovernmentSubCategoryIdValid,
    setResidenceGovernmentSubCategoryIdValid,
  ] = useState(isEdit ? 'TRUE' : null); // 住居表示
  const [residenceLocationValid, setResidenceLocationValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 住居表示(テキスト)
  const [nearestStationValid, setNearestStationValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 交通、最寄りの駅など
  const [locationUrlValid, setLocationUrlValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 所在図
  const [estateStatusValid, setEstateStatusValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 現況
  const [otherValid, setOtherValid] = useState(isEdit ? 'TRUE' : null); // その他事項
  const [sketchUrlValid, setSketchUrlValid] = useState(isEdit ? 'TRUE' : null); // 見取り図など
  const [descriptionValid, setDescriptionValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 説明

  // 4. 土地の情報
  const [landSpaceValid, setLandSpaceValid] = useState(isEdit ? 'TRUE' : null); // 土地面積
  const [landSpaceTypeValid, setLandSpaceTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 土地面積種別
  const [landRightsValid, setLandRightsValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 土地権利
  const [landShareValid, setLandShareValid] = useState(isEdit ? 'TRUE' : null); // 土地持分
  const [landCategoryValid, setLandCategoryValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 地目
  const [cityPlanningAreaValid, setCityPlanningAreaValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 都市計画地域
  const [restrictedAreaValid, setRestrictedAreaValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 用途地域
  const [buildRatioValid, setBuildRatioValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 建ぺい率
  const [floorAreaRatioValid, setFloorAreaRatioValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 容積率
  const [highDistrictValid, setHighDistrictValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 高度地区
  const [firebreakAreaValid, setFirebreakAreaValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 防火地域
  const [shadeRegulationValid, setShadeRegulationValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 日影規制
  const [lawRegulationValid, setLawRegulationValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // その他法令などの規制
  const [geographicalValid, setGeographicalValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 地勢など
  const [neighboringRoadValid, setNeighboringRoadValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 幅員、接道状況
  const [privateRoadValid, setPrivateRoadValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 私道の負担など
  const [provideEquipmentValid, setProvideEquipmentValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 供給処理施設
  const [landOtherValid, setLandOtherValid] = useState(isEdit ? 'TRUE' : null); // その他土地に関する物件情報

  // 5. 建物の情報
  const [buildNameValid, setBuildNameValid] = useState(isEdit ? 'TRUE' : null); // 建物の名称
  const [buildSpaceValid, setBuildSpaceValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 建物面積
  const [exclusiveSpaceValid, setExclusiveSpaceValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 専有面積
  const [floorSpaceValid, setFloorSpaceValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 延床面積
  const [structureTypeValid, setStructureTypeValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 構造
  const [buildDateValid, setBuildDateValid] = useState(isEdit ? 'TRUE' : null); // 建築年月
  const [buildDateOtherValid, setBuildDateOtherValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 建築年月（その他を選択した時出るフォーム）
  const [floorValid, setFloorValid] = useState(isEdit ? 'TRUE' : null); // 階建/階
  const [totalHousesValid, setTotalHousesValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // 総戸数
  const [layoutValid, setLayoutValid] = useState(isEdit ? 'TRUE' : null); // 間取り
  const [buildOtherValid, setBuildOtherValid] = useState(
    isEdit ? 'TRUE' : null,
  ); // その他建物に関する物件情報

  // 6. 物件に関する音尾合わせ先
  const [inquiryNameValid, setInquiryNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryOfficeValid, setInquiryOfficeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryEmailValid, setInquiryEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum1Valid, setInquiryTelNum1Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum2Valid, setInquiryTelNum2Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelNum3Valid, setInquiryTelNum3Valid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryTelValid, setInquiryTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryExtensionValid, setInquiryExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryAcceptValid, setInquiryAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [inquiryNoteValid, setInquiryNoteValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 7. 物件の詳細設定
  const [estimatePriceRequiredValid, setEstimatePriceRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [depositRequiredValid, setDepositRequiredValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [qualifiedInvoiceValid, setQualifiedInvoiceValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 8. 落札後の連絡先
  const [contactNameValid, setContactNameValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactOfficeValid, setContactOfficeValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactEmailValid, setContactEmailValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactTelValid, setContactTelValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactExtensionValid, setContactExtensionValid] = useState(
    isEdit ? 'TRUE' : null,
  );
  const [contactAcceptValid, setContactAcceptValid] = useState(
    isEdit ? 'TRUE' : null,
  );

  // 2. 物件情報
  function handlePrintOrderValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    // 1~9999のいずれかが入力されているか否か検証
    invalid = validateNumberRange(e.target.value);
    if (invalid !== 'TRUE') {
      setPrintOrderValid(invalid);
      return;
    }
    setPrintOrderValid(invalid); // エラーメッセージのリセット
  } // 掲載順位

  function handleCategoryNamesValid() {
    let invalid = null;
    setCategoryNamesValid(invalid);
  } // カテゴリ

  function handleCategoryIdValid(id) {
    let invalid = null;
    invalid = validateSelected(id);
    if (invalid !== 'TRUE') {
      setCategoryIdValid(invalid);
      return;
    }
    setCategoryIdValid(invalid);
  } // カテゴリ

  function handleRecommendPropertiesValid() {
    let invalid = null;
    setRecommendPropertiesValid(invalid);
  } // イチ押し設定

  function handleDivisionIdValid(e) {
    let invalid = null;
    invalid = validateSelected(e.target.value);
    if (invalid !== 'TRUE') {
      setDivisionIdValid(invalid);
      return;
    }
    setDivisionIdValid(invalid);
  } // 執行機関名称（id）

  function handleSaleNumberValid(e) {
    let invalid = null;
    // 文字数が1文字以上30文字以内
    invalid = validateRangeLength(e.target.value, 1, 30);
    if (invalid !== 'TRUE') {
      setSaleNumberValid(invalid);
      return;
    }
    // 空文字ではない
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setSaleNumberValid(invalid);
      return;
    }
    // 絵文字・機種依存文字が含まれていない
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setSaleNumberValid(invalid);
      return;
    }
    setSaleNumberValid(invalid);
  } // 売却区分番号

  function handleNameValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setNameValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setNameValid(invalid);
      return;
    }
    setNameValid(invalid);
  } // タイトル

  function handlePaymentMethodValid(e) {
    let invalid = null;
    invalid = validateSomeSelected(Object.values(e));
    if (invalid !== 'TRUE') {
      setPaymentMethodValid(invalid);
      return;
    }
    setPaymentMethodValid(invalid);
  } // 支払い方法

  function handlePaymentLimitValid(date) {
    let invalid = null;
    invalid = validateSelected(date);
    if (invalid !== 'TRUE') {
      setPaymentLimitValid(invalid);
      return;
    }
    setPaymentLimitValid(invalid);
  } // 納付期限

  function handleVideoUrlValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (e.target.value.length === 0) {
      setVideoUrlValid(invalid);
      return;
    }

    invalid = validateInclude(e.target.value, ['https://', 'http://']);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 2048);
    if (invalid !== 'TRUE') {
      setVideoUrlValid(invalid);
      return;
    }
    setVideoUrlValid(invalid); // エラーメッセージのリセット
  }

  function handleTypeValid(e) {
    let invalid = null;
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setTypeValid(invalid);
      return;
    }
    setTypeValid(invalid);
  } // 物件の種類

  function handleGovernmentSubCategoryIdValid(e) {
    let invalid = null;
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setGovernmentSubCategoryIdValid(invalid);
      return;
    }
    setGovernmentSubCategoryIdValid(invalid);
  } // 物件の所在(properties)

  function handleResolutionDateValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setResolutionDateValid(invalid);
      return;
    }
    setResolutionDateValid(invalid);
  } // 物件の種類

  function handleContractedLimitValid() {
    let invalid = null;
    setContractedLimitValid(invalid);
  } // 納付期限

  // 3. 基本情報
  function handlePropertyPrintValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 2000);
    if (invalid !== 'TRUE') {
      setPropertyPrintValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setPropertyPrintValid(invalid);
      return;
    }
    setPropertyPrintValid(invalid);
  } // 登記簿の表示

  function handleLocationGovernmentSubCategoryIdValid(e) {
    let invalid = null;
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setLocationGovernmentSubCategoryIdValid(invalid);
      return;
    }
    setLocationGovernmentSubCategoryIdValid(invalid);
  } // 所在地

  function handleLocationTextValid(e) {
    let invalid = null;
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setLocationTextValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setLocationTextValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLocationTextValid(invalid);
      return;
    }

    setLocationTextValid(invalid);
  } // 所在地(テキスト)

  function handleResidenceGovernmentSubCategoryIdValid() {
    let invalid = null;
    setResidenceGovernmentSubCategoryIdValid(invalid);
  } // 住居表示

  function handleResidenceLocationValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setResidenceLocationValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setResidenceLocationValid(invalid);
      return;
    }
    setResidenceLocationValid(invalid);
  } // 住居表示(テキスト)

  function handleNearestStationValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 400);
    if (invalid !== 'TRUE') {
      setNearestStationValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setNearestStationValid(invalid);
      return;
    }
    setNearestStationValid(invalid);
  } // 交通、最寄りの駅など

  function handleLocationUrlValid(e) {
    let invalid = null;
    invalid = validateInclude(e.target.value, ['https://', 'http://']);
    if (invalid !== 'TRUE') {
      setLocationUrlValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 2048);
    if (invalid !== 'TRUE') {
      setLocationUrlValid(invalid);
      return;
    }
    setLocationUrlValid(invalid);
  } // 所在図

  function handleEstateStatusValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 400);
    if (invalid !== 'TRUE') {
      setEstateStatusValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setEstateStatusValid(invalid);
      return;
    }
    setEstateStatusValid(invalid);
  } // 現況

  function handleOtherValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 200);
    if (invalid !== 'TRUE') {
      setOtherValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setOtherValid(invalid);
      return;
    }
    setOtherValid(invalid);
  } // その他事項

  function handleSketchUrlValid() {
    let invalid = null;
    setSketchUrlValid(invalid);
  } // 見取り図など

  function handleDescriptionValid(text) {
    let invalid = null;
    invalid = validateRangeLength(text, 0, 15000);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    invalid = validateRequired(text);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    invalid = hasEmoji(text);
    if (invalid !== 'TRUE') {
      setDescriptionValid(invalid);
      return;
    }
    setDescriptionValid(invalid);
  } // 説明

  // 4. 土地の情報
  function handleLandSpaceValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setLandSpaceValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setLandSpaceValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 2);
    if (invalid !== 'TRUE') {
      setLandSpaceValid(invalid);
      return;
    }
    setLandSpaceValid(invalid);
  } // 土地面積

  function handleLandSpaceTypeValid() {
    let invalid = 'TRUE';
    setLandSpaceTypeValid(invalid);
  } // 土地面積種別

  function handleLandRightsValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setLandRightsValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLandRightsValid(invalid);
      return;
    }
    setLandRightsValid(invalid);
  } // 土地権利

  function handleLandShareValid(values) {
    let invalid = null;
    invalid = validateNumber(values.first);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    invalid = validateRangeLength(values.first, 0, 10);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    invalid = validateDecimal(values.first, 0);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    invalid = validateNumber(values.latter);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    invalid = validateRangeLength(values.latter, 0, 10);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    invalid = validateDecimal(values.latter, 0);
    if (invalid !== 'TRUE') {
      setLandShareValid(invalid);
      return;
    }
    setLandShareValid(invalid);
  } // 土地持分

  function handleLandCategoryValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setLandCategoryValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLandCategoryValid(invalid);
      return;
    }
    setLandCategoryValid(invalid);
  } // 地目

  function handleCityPlanningAreaValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setCityPlanningAreaValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setCityPlanningAreaValid(invalid);
      return;
    }
    setCityPlanningAreaValid(invalid);
  } // 都市計画地域

  function handleRestrictedAreaValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setRestrictedAreaValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setRestrictedAreaValid(invalid);
      return;
    }
    setRestrictedAreaValid(invalid);
  } // 用途地域

  function handleBuildRatioValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildRatioValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 4);
    if (invalid !== 'TRUE') {
      setBuildRatioValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 1);
    if (invalid !== 'TRUE') {
      setBuildRatioValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildRatioValid(invalid);
      return;
    }
    setBuildRatioValid(invalid);
  } // 建ぺい率

  function handleFloorAreaRatioValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setFloorAreaRatioValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 4);
    if (invalid !== 'TRUE') {
      setFloorAreaRatioValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 1);
    if (invalid !== 'TRUE') {
      setFloorAreaRatioValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setFloorAreaRatioValid(invalid);
      return;
    }
    setFloorAreaRatioValid(invalid);
  } // 容積率

  function handleHighDistrictValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setHighDistrictValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 1);
    if (invalid !== 'TRUE') {
      setHighDistrictValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 1);
    if (invalid !== 'TRUE') {
      setHighDistrictValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setHighDistrictValid(invalid);
      return;
    }
    setHighDistrictValid(invalid);
  } // 高度地区

  function handleFirebreakAreaValid() {
    let invalid = 'TRUE'; // validation不要
    setFirebreakAreaValid(invalid);
  } // 防火地域

  function handleShadeRegulationValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 250);
    if (invalid !== 'TRUE') {
      setShadeRegulationValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setShadeRegulationValid(invalid);
      return;
    }
    setShadeRegulationValid(invalid);
  } // 日影規制

  function handleLawRegulationValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setLawRegulationValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLawRegulationValid(invalid);
      return;
    }
    setLawRegulationValid(invalid);
  } // その他法令などの規制

  function handleGeographicalValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 200);
    if (invalid !== 'TRUE') {
      setGeographicalValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setGeographicalValid(invalid);
      return;
    }
    setGeographicalValid(invalid);
  } // 地勢など

  function handleNeighboringRoadValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 400);
    if (invalid !== 'TRUE') {
      setNeighboringRoadValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setNeighboringRoadValid(invalid);
      return;
    }
    setNeighboringRoadValid(invalid);
  } // 幅員、接道状況

  function handlePrivateRoadValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 400);
    if (invalid !== 'TRUE') {
      setPrivateRoadValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setPrivateRoadValid(invalid);
      return;
    }
    setPrivateRoadValid(invalid);
  } // 私道の負担など

  function handleProvideEquipmentValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 1000);
    if (invalid !== 'TRUE') {
      setProvideEquipmentValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setProvideEquipmentValid(invalid);
      return;
    }
    setProvideEquipmentValid(invalid);
  } // 供給処理施設

  function handleLandOtherValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 2000);
    if (invalid !== 'TRUE') {
      setLandOtherValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLandOtherValid(invalid);
      return;
    }
    setLandOtherValid(invalid);
  } // その他土地に関する物件情報

  // 5. 建物の情報
  function handleBuildNameValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setBuildNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildNameValid(invalid);
      return;
    }
    setBuildNameValid(invalid);
  } // 建物の名称

  function handleBuildSpaceValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildSpaceValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setBuildSpaceValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 2);
    if (invalid !== 'TRUE') {
      setBuildSpaceValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildSpaceValid(invalid);
      return;
    }
    setBuildSpaceValid(invalid);
  } // 建物面積

  function handleExclusiveSpaceValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setExclusiveSpaceValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setExclusiveSpaceValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 2);
    if (invalid !== 'TRUE') {
      setExclusiveSpaceValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setExclusiveSpaceValid(invalid);
      return;
    }
    setExclusiveSpaceValid(invalid);
  } // 専有面積

  function handleFloorSpaceValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setFloorSpaceValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setFloorSpaceValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 2);
    if (invalid !== 'TRUE') {
      setFloorSpaceValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setFloorSpaceValid(invalid);
      return;
    }
    setFloorSpaceValid(invalid);
  } // 延床面積

  function handleStructureTypeValid() {
    let invalid = 'TRUE';
    setStructureTypeValid(invalid);
  } // 構造

  function handleBuildDateValid() {
    let invalid = 'TRUE';
    setBuildDateValid(invalid);
  } // 建築年月

  function handleBuildDateOtherValid() {
    let invalid = null;
    setBuildDateOtherValid(invalid);
  } // 建築年月（その他を選択した時出るフォーム）

  function handleFloorValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setFloorValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setFloorValid(invalid);
      return;
    }
    setFloorValid(invalid);
  } // 階建/階

  function handleTotalHousesValid(e) {
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setTotalHousesValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 4);
    if (invalid !== 'TRUE') {
      setTotalHousesValid(invalid);
      return;
    }
    invalid = validateDecimal(e.target.value, 2);
    if (invalid !== 'TRUE') {
      setTotalHousesValid(invalid);
      return;
    }
    invalid = validateEmptyString(e.target.value);
    if (invalid !== 'TRUE') {
      setTotalHousesValid(invalid);
      return;
    }
    setTotalHousesValid(invalid);
  } // 総戸数

  function handleLayoutValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setLayoutValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setLayoutValid(invalid);
      return;
    }
    setLayoutValid(invalid);
  } // 間取り

  function handleBuildOtherValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 20000);
    if (invalid !== 'TRUE') {
      setBuildOtherValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setBuildOtherValid(invalid);
      return;
    }
    setBuildOtherValid(invalid);
  } // その他建物に関する物件情報

  // 6. 物件に関する音尾合わせ先
  function handleInquiryNameValid(e) {
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryNameValid(invalid);
      return;
    }
    setInquiryNameValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 30);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryOfficeValid(invalid);
      return;
    }
    setInquiryOfficeValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 128);
    if (invalid !== 'TRUE') {
      setInquiryEmailValid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryEmailValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryTelValid(telData) {
    //logInput(e.target.name, e.target.value);
    console.log('handleinquirytelvalid');
    let tel = Object.values(telData).join('');
    let invalid = null;

    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    invalid = validateRangeLength(tel, 10, 11);
    if (invalid !== 'TRUE') {
      setInquiryTelValid(invalid);
      return;
    }
    setInquiryTelValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum1Valid(tel) {
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum1Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum2Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum2Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryTelNum3Valid(tel) {
    //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(tel, 0, 4);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
      return;
    }
    setInquiryTelNum3Valid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryExtensionValid(e) {
    // //logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = notInclude(e.target.value, [' ']);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    invalid = validateExtensionFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryExtensionValid(invalid);
      return;
    }
    setInquiryExtensionValid(invalid); // エラーメッセージのリセット
  }
  function handleInquiryAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 100);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryAcceptValid(invalid);
      return;
    }
    setInquiryAcceptValid(invalid); // エラーメッセージのリセット
  }

  function handleInquiryNoteValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setInquiryNoteValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setInquiryNoteValid(invalid);
      return;
    }
    setInquiryNoteValid(invalid); // エラーメッセージのリセット
  }

  // 7. 物件の詳細設定
  function handleEstimatePriceValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 11);
    if (invalid !== 'TRUE') {
      setEstimatePriceRequiredValid(invalid);
      return;
    }
    setEstimatePriceRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleDepositValid(e, payment) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateNumber(e.target.value);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    console.log(e.target.value, payment);
    invalid = validateDeposit(e.target.value, payment);
    if (invalid !== 'TRUE') {
      setDepositRequiredValid(invalid);
      return;
    }
    setDepositRequiredValid(invalid); // エラーメッセージのリセット
  }

  function handleQualifiedInvoiceValid() {
    // logInput(e.target.name, e.target.value);
    setQualifiedInvoiceValid("TRUE")
  }

  // 8. 落札後の連絡先
  function handleContactNameValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 1, 50);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactNameValid(invalid);
      return;
    }
    setContactNameValid(invalid); // エラーメッセージのリセット
  }

  function handleContactOfficeValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactOfficeValid(invalid);
      return;
    }
    setContactOfficeValid(invalid); // エラーメッセージのリセット
  }

  function handleContactEmailValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateEmailFormatCheck(e.target.value);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    invalid = validateRangeLength(e.target.value, 0, 128);
    if (invalid !== 'TRUE') {
      setContactEmailValid(invalid);
      return;
    }
    setContactEmailValid(invalid); // エラーメッセージのリセット
  }

  function handleContactTelValid(telData) {
    //logInput(e.target.name, e.target.value);
    let tel = Object.values(telData).join('');
    let invalid = null;
    //invalid = Validation.validateTelLength(e.target.value);
    //if (invalid !== "TRUE") {
    //    setContactTelValid(invalid);
    //    return
    //}
    invalid = notInclude(tel, [' ']);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateNumber(tel);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    invalid = validateRangeLength(tel, 10, 11);
    if (invalid !== 'TRUE') {
      setContactTelValid(invalid);
      return;
    }
    setContactTelValid(invalid); // エラーメッセージのリセット
  }

  function handleContactExtensionValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 10);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = notInclude(e.target.value, [' ']);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    invalid = validateExtensionFormat(e.target.value);
    if (invalid !== 'TRUE') {
      setContactExtensionValid(invalid);
      return;
    }
    setContactExtensionValid(invalid); // エラーメッセージのリセット
  }

  function handleContactAcceptValid(e) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    invalid = validateRangeLength(e.target.value, 0, 50);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    invalid = hasEmoji(e.target.value);
    if (invalid !== 'TRUE') {
      setContactAcceptValid(invalid);
      return;
    }
    setContactAcceptValid(invalid); // エラーメッセージのリセット
  }

  //function handlePaymentDeadlineValid(e) {
  //    // logInput(e.target.name, e.target.value);
  //    let invalid = null;
  //    invalid = Validation.validatePaymentDeadlineRequiredRequired1(e.target.value);
  //    if (invalid !== "TRUE") {
  //        setPaymentDeadlineRequiredValid(invalid);
  //        return
  //    }
  //    setPaymentDeadlineRequiredValid(invalid); // エラーメッセージのリセット
  //}

  //function handlePaymentScheduledDateValid(e) {
  //    // logInput(e.target.name, e.target.value);
  //    let invalid = null;
  //    invalid = Validation.validateCcontractedLimitRequired(e.target.value);
  //    if (invalid !== "TRUE") {
  //        setContractedLimitValid(invalid);
  //        return
  //    }
  //    setContractedLimitValid(invalid); // エラーメッセージのリセット
  //}

  const validations = {
    // validationの処理
    setters: {
      // 2. 物件情報
      handlePrintOrderValid, // 掲載順位
      handleCategoryNamesValid, // カテゴリ
      handleCategoryIdValid, // カテゴリ
      handleRecommendPropertiesValid, // イチ押し設定
      handleDivisionIdValid, // 執行機関名称（id）
      handleSaleNumberValid, // 売却区分番号
      handleNameValid, // タイトル
      handlePaymentMethodValid, // 支払い方法
      handlePaymentLimitValid, // 納付期限
      handleVideoUrlValid, // 動画URL
      handleTypeValid, // 物件の種類
      handleGovernmentSubCategoryIdValid, // 物件の所在(properties)
      handleResolutionDateValid, // 議決日
      handleContractedLimitValid, // 契約締結期限

      // 3 登録事項など証明書の記載内容
      handlePropertyPrintValid, // 登記簿の表示
      handleLocationGovernmentSubCategoryIdValid, // 所在地
      handleLocationTextValid, // 所在地(テキスト)
      handleResidenceGovernmentSubCategoryIdValid, // 住居表示
      handleResidenceLocationValid, // 住居表示(テキスト)
      handleNearestStationValid, // 交通、最寄りの駅など
      handleLocationUrlValid, // 所在図
      handleEstateStatusValid, // 現況
      handleOtherValid, // その他事項
      handleSketchUrlValid, // 見取り図など
      handleDescriptionValid, // 説明

      // 4. 土地の情報
      handleLandSpaceValid, // 土地面積
      handleLandSpaceTypeValid, // 土地面積種別
      handleLandRightsValid, // 土地権利
      handleLandShareValid, // 土地持分
      handleLandCategoryValid, // 地目
      handleCityPlanningAreaValid, // 都市計画地域
      handleRestrictedAreaValid, // 用途地域
      handleBuildRatioValid, // 建ぺい率
      handleFloorAreaRatioValid, // 容積率
      handleHighDistrictValid, // 高度地区
      handleFirebreakAreaValid, // 防火地域
      handleShadeRegulationValid, // 日影規制
      handleLawRegulationValid, // その他法令などの規制
      handleGeographicalValid, // 地勢など
      handleNeighboringRoadValid, // 幅員、接道状況
      handlePrivateRoadValid, // 私道の負担など
      handleProvideEquipmentValid, // 供給処理施設
      handleLandOtherValid, // その他土地に関する物件情報

      // 5. 建物の情報
      handleBuildNameValid, // 建物の名称
      handleBuildSpaceValid, // 建物面積
      handleExclusiveSpaceValid, // 専有面積
      handleFloorSpaceValid, // 延床面積
      handleStructureTypeValid, // 構造
      handleBuildDateValid, // 建築年月
      handleBuildDateOtherValid, // 建築年月（その他を選択した時出るフォーム）
      handleFloorValid, // 階建/階
      handleTotalHousesValid, // 総戸数
      handleLayoutValid, // 間取り
      handleBuildOtherValid, // その他建物に関する物件情報

      // 6. 物件に関する音尾合わせ先
      handleInquiryNameValid,
      handleInquiryOfficeValid,
      handleInquiryEmailValid,
      handleInquiryTelValid,
      handleInquiryTelNum1Valid,
      handleInquiryTelNum2Valid,
      handleInquiryTelNum3Valid,
      handleInquiryExtensionValid,
      handleInquiryAcceptValid,
      handleInquiryNoteValid,

      // 7. 物件の詳細設定
      handleDepositValid,
      handleEstimatePriceValid,
      handleQualifiedInvoiceValid,

      // 8. 落札後の連絡先
      handleContactNameValid,
      handleContactOfficeValid,
      handleContactEmailValid,
      handleContactAcceptValid,
      handleContactTelValid,
      handleContactExtensionValid,
    },
    // invalidの時のメッセージ
    validationMessage: {
      // 2. 物件情報
      printOrderValid, // 掲載順位
      categoryNamesValid, // カテゴリ
      categoryIdValid, // カテゴリ
      recommendPropertiesValid, // イチ押し設定
      divisionIdValid, // 執行機関名称（id）
      saleNumberValid, // 売却区分番号
      nameValid, // タイトル
      paymentMethodValid, // 支払い方法
      paymentLimitValid, // 納付期限
      videoUrlValid, // 動画URL
      typeValid, // 物件の種類
      governmentSubCategoryIdValid, // 物件の所在(properties)
      resolutionDateValid,
      contractedLimitValid,

      // 3 登録事項など証明書の記載内容
      propertyPrintValid, // 登記簿の表示
      locationGovernmentSubCategoryIdValid, // 所在地
      locationTextValid, // 所在地(テキスト)
      residenceGovernmentSubCategoryIdValid, // 住居表示
      residenceLocationValid, // 住居表示(テキスト)
      nearestStationValid, // 交通、最寄りの駅など
      locationUrlValid, // 所在図
      estateStatusValid, // 現況
      otherValid, // その他事項
      sketchUrlValid, // 見取り図など
      descriptionValid, // 説明

      // 4. 土地の情報
      landSpaceValid, // 土地面積
      landSpaceTypeValid, // 土地面積種別
      landRightsValid, // 土地権利
      landShareValid, // 土地持分
      landCategoryValid, // 地目
      cityPlanningAreaValid, // 都市計画地域
      restrictedAreaValid, // 用途地域
      buildRatioValid, // 建ぺい率
      floorAreaRatioValid, // 容積率
      highDistrictValid, // 高度地区
      firebreakAreaValid, // 防火地域
      shadeRegulationValid, // 日影規制
      lawRegulationValid, // その他法令などの規制
      geographicalValid, // 地勢など
      neighboringRoadValid, // 幅員、接道状況
      privateRoadValid, // 私道の負担など
      provideEquipmentValid, // 供給処理施設
      landOtherValid, // その他土地に関する物件情報

      // 5. 建物の情報
      buildNameValid, // 建物の名称
      buildSpaceValid, // 建物面積
      exclusiveSpaceValid, // 専有面積
      floorSpaceValid, // 延床面積
      structureTypeValid, // 構造
      buildDateValid, // 建築年月
      buildDateOtherValid, // 建築年月（その他を選択した時出るフォーム）
      floorValid, // 階建/階
      totalHousesValid, // 総戸数
      layoutValid, // 間取り
      buildOtherValid, // その他建物に関する物件情報

      // 6. 物件に関する音尾合わせ先
      inquiryAcceptValid,
      inquiryEmailValid,
      inquiryOfficeValid,
      inquiryNameValid,
      inquiryTelValid,
      inquiryTelNum1Valid,
      inquiryTelNum2Valid,
      inquiryTelNum3Valid,
      inquiryExtensionValid,
      inquiryNoteValid,

      // 7. 物件の詳細設定
      estimatePriceRequiredValid,
      depositRequiredValid,
      qualifiedInvoiceValid,

      // 8. 落札後の連絡先
      contactAcceptValid,
      contactEmailValid,
      contactOfficeValid,
      contactNameValid,
      contactTelValid,
      contactExtensionValid,
    },
  };

  return validations;
};
