import PropertyListSelector from 'interfaces/components/common/PropertyInfoForm/PropertyListSelector';
import { useEffect, useState } from 'react';
import { useCloseClickOutside } from 'shared/hooks/useCloseClickOutside';

import PropertyCategoriesListBox from './PropertyCategoriesListBox.jsx';

export const PropertyCategoriesSelectBox = ({
  listItemData,
  hasInitialValue = true,
  formStateHandler,
  width = '350px',
  selectedData = '',
  setSelectedId = () => {},
  selectedName,
  setSelectedName = () => {},
  isCategory = false,
  isDisplay = true,
  isInvalid = false,
}) => {
  const [showListBox, setShowListBox] = useState(false);
  const selectBoxClickHandler = () => {
    setSelectedId(0);
    setShowListBox(!showListBox);
  };
  const { ref } = useCloseClickOutside(() => setShowListBox(false));

  useEffect(() => {
    setSelectedName(selectedData);
    if (hasInitialValue && selectedData === '') {
      setSelectedName(listItemData.length !== 0 ? listItemData[0]?.text : '');
    }
  }, []);

  return (
    <div
      className={isCategory ? 'select-box-category' : 'select-box'}
      style={{ position: 'relative' }}>
      <span style={{ display: isDisplay ? 'block' : 'none' }}>
        <PropertyListSelector
          content={selectedName}
          onClick={selectBoxClickHandler}
          width={width}
          isInvalid={isInvalid}
        />
        {showListBox && (
          <div ref={ref}>
            <PropertyCategoriesListBox
              data={listItemData}
              setShowListBox={setShowListBox}
              setSelectedName={setSelectedName}
              setSelectedId={setSelectedId}
              formStateHandler={formStateHandler}
              width={width}
            />
          </div>
        )}
      </span>
    </div>
  );
};

export default PropertyCategoriesSelectBox;
