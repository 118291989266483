import {
  bank_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2
} from "shared/utils/helper/DummyEditFormData";

import FormTextWithCaution from "../../Form/navi/FormTextWithCaution";

const BothForm = ({input, governmentType}) => {
  return (
    <form data-testid="card-provisional-apply-completion-form">
      <div className="pt-3">
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            label={"銀行振込など案内ページURL"}
            required={true}
            caution_description={bank_provisional_apply_completion_description1}
            data={input.data.bankTransferUrl}
            handler1={input.setters.handleBankTransferUrl}
            invalidMessage={input.validationMessage.bankTransferUrlRequiredValid}
          />
          <FormTextWithCaution
            label={"共同入札案内ページURL"}
            caution_description={card_provisional_apply_completion_description2(governmentType)}
            data={input.data.jointBiddingUrl}
            handler1={input.setters.handleJointBiddingUrl}
            invalidMessage={input.validationMessage.jointBiddingUrlValid}
          />
        </div>
      </div>
    </form>
  );
}

export default BothForm