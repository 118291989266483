import {camelToSnake} from "./camelToSnake.js";

export function createBody(inputs) {
  const body = {};
  inputs.map((input) => {
    body[input.column] = input.state
  })
  return body
}

export function bodyToSnake(body) {
  const body_snake = {}
  Object.keys(body).map((key) => {
    body_snake[camelToSnake(key)] = body[key]
  })
  return body_snake
}

export function getValues(body) {
  const body_values = {}
  Object.keys(body).map((key) => {
    body_values[key] = (body[key].get() === "DATA_EMPTY" || body[key].get() === "") ? null : body[key].get()
  })
  return body_values
}