import { useContext, useEffect, useState } from 'react';
//import { useNavigate, useParams } from 'react-router-dom';

import { ActionAreaResponsive } from './PropertyDetail/ActionArea/ActionAreaResponsive';
import DeliveryRegistrationInfo from './PropertyDetail/DeliveryRegistrationInfo/DeliveryRegistrationInfo';
import DeliveryStyleSelector from './PropertyDetail/DeliveryStyleSelector/DeliveryStyleSelector';
//import FetchDeliveryRegistrationInfo from 'interfaces/components/common/FetchDeliveryRegistrationInfo/FetchDeliveryRegistrationInfo';
//import PageLoading from 'interfaces/components/common/PageLoading/PageLoading';
import { dummyPropertyCardData } from './PropertyDetail/dummy_property_card_data.js';
import PropertyDetailAnnounce from './PropertyDetail/PropertyDetailAnnounce';
import PropertyDetailHead from './PropertyDetail/PropertyDetailHead';
import PropertyDetailItem from './PropertyDetail/PropertyDetailItem';
import PropertyDetailSwitcher from './PropertyDetail/PropertyDetailSwitcher';

// storage呼び出し（providerへ）
//import { getPropertyDetail } from '../read_storage/property_detail.js';
//import getBlockList from '../read_storage/blocklist.js';

// TODO: API疎通したら消す
import './PropertyDetail/css/property-detail.css';
import { PropertyDetailContext } from '../../../pages/PropertyDetail/entities/PropertyDetailContext.js';
import { mediaQuery, useMediaQuery } from 'shared/hooks/useResponsive';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage.js';

export const createInquiryData = (property) => {
  return [
    {
      id: 1,
      title: '出品行政機関',
      value: property?.inquiryName,
      image_url: `/image_storage/${property?.governmentId}/emblem.jpg`,
    },
    {
      id: 2,
      title: '担当部署',
      value: property?.inquiryOffice,
    },
    {
      id: 3,
      title: 'メールアドレス',
      value: property?.inquiryEmail,
    },
    {
      id: 4,
      title: '電話',
      value: property?.inquiryTel,
      extension: property?.inquiryExtension,
    },
    {
      id: 5,
      title: '受付時間',
      value: property?.inquiryAccept,
    },
    {
      id: 6,
      title: '備考',
      value: property?.inquiryNote,
    },
  ];
};

const PropertyDetail = () => {
  const [inquiryData, setInquiryData] = useState([]);
  const [sliderItems, setSliderItems] = useState([]);
  const [isShippingRegistration, setIsShippingRegistration] = useState(false);
  const { localStorageUserId } = useLocalStorage();

  const { property, isBlocklist } = useContext(PropertyDetailContext);
  const isPc = useMediaQuery(mediaQuery.pc);

  useEffect(() => {
    setSliderItems(dummyPropertyCardData);
    setInquiryData([]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setInquiryData(createInquiryData(property));
    setIsShippingRegistration(
      !['', undefined, null].includes(property?.deliveryType),
    );
  }, [property]);

  const isContainBlockList = isBlocklist;

  return (
    //!property ? (
    //  <PageLoading />
    //) : (
    <div className="kservice_property-detail">
      <div className="common-side-margin-wrap no-bg mx-auto grow v-sheet theme--light mx-auto">
        <div className="inner">
          <div className="grow pb-10">
            <PropertyDetailHead data={property} />

            {/* 物件情報上のアナウンス情報 */}
            <PropertyDetailAnnounce
              data={property}
              isContainBlockList={isContainBlockList}
              userId={localStorageUserId}
            />

            <div>
              {/* 落札後の連絡先 */}
              <DeliveryRegistrationInfo
                property={property}
                isShippingRegistration={isShippingRegistration}
               isContainBlockList={isContainBlockList}
              />
              <DeliveryStyleSelector
                data={property}
                isShippingRegistration={isShippingRegistration}
                isContainBlockList={isContainBlockList}
              />
            </div>

            {/* 物件情報 */}
            {isPc && (
              <PropertyDetailItem
                data={property}
                isContainBlockList={isContainBlockList}
              />
            )}
            {!isPc && (
              <ActionAreaResponsive
                data={property}
                isContainBlockList={isContainBlockList}
              />
            )}

            <PropertyDetailSwitcher
              property={property}
              isContainBlockList={isContainBlockList}
              inquiryData={inquiryData}
              sliderItems={sliderItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
