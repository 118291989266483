import { ValidationMessage } from 'interfaces/components/common/ValidationMessage.jsx';
import React, { useState } from 'react';
import { MyDateTimePicker } from 'interfaces/components/Form/MyDatetimePicker.jsx';
import FormLabel from './FormLabel.jsx';

const InputDateTime = React.memo(function InputDateTime({
  invalidMessage,
  required,
  label,
  state,
  setState,
  placeholder,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="kmanager-row">
      <div className="kmanager-col-4">
        <FormLabel label={label} required={required} />
      </div>
      <div className="kmanager-col form-area flex-column">
        <div style={{ position: 'relative' }} className={'form-input'}>
          <MyDateTimePicker
            state={state}
            setState={setState}
            placeholder={placeholder}
            open={open}
            setOpen={setOpen}
          />
        </div>
        <ValidationMessage invalidMessage={invalidMessage} />
      </div>
    </div>
  );
});
export default InputDateTime;
