
export class UseEmblem {
  #useEmblem

  constructor(useEmblem) {
    this.#useEmblem = useEmblem;
  }

  get() {
    return this.#useEmblem
  }

  get isValid() {
    return [
    ].every(result => result === 'TRUE');
  }
}
