import { apiRequest } from 'shared/utils/helper/apiRequest.js';
import { API_BASE_URL } from '../../config/envConstants.js';

export const createNaviDivisionsData = async (body) => {
  console.log(body);

  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/navi/organization`,
    body,
  );
};

export const updateNaviDivisionsData = async (body, id) => {
  console.log(body);
  return await apiRequest(
    'put',
    `${API_BASE_URL}/api/v1/navi/organization/${id}`,
    body,
  );
};
export const deleteNaviDivisionsData = async (id) => {
  return await apiRequest(
    'delete',
    `${API_BASE_URL}/api/v1/navi/organization/${id}`,
  );
};
