import Deadline from './Deadline.jsx';

import {
  PropertyInfo,
  PropertyInfoLabel,
  PropertyInfoValue,
} from 'interfaces/components/common/PropertyInfo/index';

const DuringBiddingCardBodyBottom = ({ data }) => {
  const isAuction = data.publicSaleType === 'AUCTION';

  return (
    <div data-testid="during-bidding-card-body-bottom">
      {isAuction && (
        <>
          <hr
            role="separator"
            aria-orientation="horizontal"
            className="divider"
          />
          <PropertyInfo className="flex justify-space-between align-center py-1">
            <PropertyInfoLabel className="ma-0">入札件数</PropertyInfoLabel>
            <PropertyInfoValue
              className="ma-0 text-right text-caption"
              data-testid="property-bids">
              {data.bidCount}
            </PropertyInfoValue>
          </PropertyInfo>
        </>
      )}
      <Deadline data={data} />
    </div>
  );
};

export default DuringBiddingCardBodyBottom;
