import { useNavigate } from 'react-router-dom';

import { ReactComponent as PencilIcon } from '../../assets/svg/pencil.svg';
import FlatButton from '../common/Button/FlatButton.jsx';

import 'interfaces/css/LocalGovernmentManagement/local-government-management-table.css';
import PreviewButton from 'interfaces/components/LocalGovernmentManagement/PreviewButton.jsx';

const LocalGovernmentManagementTable = ({
  tableData,
  governmentType,
  procedureType,
  isNotEditable,
}) => {
  const navigation = useNavigate();
  return (
    <div
      className="local-government-management-table"
      data-testid="local-government-management-table">
      <div className="local-government-management-table-inner">
        <table className="table-hover">
          <thead data-testid="local-government-management-table-header">
            <tr>
              <th>ページ名</th>
              <th>掲載内容</th>
              <th>対応状況</th>
              <th>プレビュー</th>
              <th></th>
            </tr>
          </thead>
          <tbody data-testid="local-government-management-table-body">
            {tableData &&
              tableData.map((d) => (
                <tr
                  key={d.id}
                  data-testid="local-government-management-table-row">
                  <td>{d.pageName}</td>
                  <td>{d.listingDetails}</td>
                  <td style={{ textAlign: 'center' }}>
                    {d.isRegistered ? (
                      <span style={{ color: 'blue', fontWeight: 'bold' }}>
                        登録済
                      </span>
                    ) : (
                      <span style={{ color: '#f44336', fontWeight: 'bold' }}>
                        未登録
                      </span>
                    )}
                  </td>
                  <td
                    className="action-buttons"
                    style={{ textAlign: 'center' }}>
                    {d.isRegistered &&
                    !(d.link === 'send-documents-to' || d.link === 'emblem') ? (
                      <PreviewButton governmentType={governmentType} />
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    <FlatButton
                      isDisabled={isNotEditable}
                      text="編集"
                      icon={<PencilIcon />}
                      onClick={() =>
                        navigation(
                          `/navi/local-government-management/${d.link}`,
                          { state: { governmentType, procedureType } },
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LocalGovernmentManagementTable;
