import { useState, useEffect } from 'react';
// import { logInput } from "../../utils/log/logInputs.js";
import * as Validation from 'shared/utils/helper/naviValidators';

export const GuidelinePublicSaleForm = (isEdit) => {
  const [guidelineBodyValid0, setGuidelineBodyValid0] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid0(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid1, setGuidelineBodyValid1] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid1(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid1, setGuidelineTitleRequiredValid1] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid1(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid2, setGuidelineBodyValid2] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid2(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid2, setGuidelineTitleRequiredValid2] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid2(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid3, setGuidelineBodyValid3] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid3(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid3, setGuidelineTitleRequiredValid3] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid3(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid4, setGuidelineBodyValid4] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid4(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid4, setGuidelineTitleRequiredValid4] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid4(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid5, setGuidelineBodyValid5] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid5(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid5, setGuidelineTitleRequiredValid5] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid5(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid6, setGuidelineBodyValid6] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid6(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid6, setGuidelineTitleRequiredValid6] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid6(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid7, setGuidelineBodyValid7] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid7(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid7, setGuidelineTitleRequiredValid7] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid7(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineBodyValid8, setGuidelineBodyValid8] = useState(
    isEdit ? 'TRUE' : null,
  );
  useEffect(() => {
    setGuidelineBodyValid8(isEdit ? 'TRUE' : null);
  }, [isEdit]);
  const [guidelineTitleRequiredValid8, setGuidelineTitleRequiredValid8] =
    useState(isEdit ? 'TRUE' : null);
  useEffect(() => {
    setGuidelineTitleRequiredValid8(isEdit ? 'TRUE' : null);
  }, [isEdit]);

  function handleGuidelineTitleRequiredValid1(e, required) {
    console.log('handleGuidelineTitleRequiredValid1', e);
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid1(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid1(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid2(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid2(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid2(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid3(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid3(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid3(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid4(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid4(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid4(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid5(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid5(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid5(invalid); // エラーメッセージのリセット
  }

  function handleGuidelineTitleRequiredValid6(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid6(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid6(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid7(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
    }
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid7(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid7(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineTitleRequiredValid8(e, required) {
    // // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
    }
    console.log(e.target.value.length);
    if (e.target.value.length > 0) {
      invalid = Validation.validateTitleRequiredRangeLength(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
      invalid = Validation.validateTitleRequiredHasEmoji(e.target.value);
      if (invalid !== 'TRUE') {
        setGuidelineTitleRequiredValid8(invalid);
        return;
      }
    }
    setGuidelineTitleRequiredValid8(invalid); // エラーメッセージのリセット
  }

  function handleGuidelineBodyValid0(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid0(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateRangeLength(text, 0, 2000);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid0(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid0(invalid);
        return;
      }
    }
    setGuidelineBodyValid0(invalid); //
  }

  function handleGuidelineBodyValid1(text, required) {
    console.log(text);
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid1(invalid);
        return;
      }
    }
    setGuidelineBodyValid1(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid2(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid2(invalid);
        return;
      }
    }
    setGuidelineBodyValid2(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid3(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid3(invalid);
        return;
      }
    }
    setGuidelineBodyValid3(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid4(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid4(invalid);
        return;
      }
    }
    setGuidelineBodyValid4(invalid); // エラーメッセージのリセット
  }

  function handleGuidelineBodyValid5(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid5(invalid);
        return;
      }
    }
    setGuidelineBodyValid5(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid6(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid6(invalid);
        return;
      }
    }
    setGuidelineBodyValid6(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid7(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid7(invalid);
        return;
      }
    }
    setGuidelineBodyValid7(invalid); // エラーメッセージのリセット
  }
  function handleGuidelineBodyValid8(text, required) {
    // logInput(e.target.name, e.target.value);
    let invalid = null;
    if (required) {
      invalid = Validation.validateRequired(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
    }
    if (text.length > 0) {
      invalid = Validation.validateBodyLength1(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
      invalid = Validation.validateHasEmoji(text);
      if (invalid !== 'TRUE') {
        setGuidelineBodyValid8(invalid);
        return;
      }
    }
    setGuidelineBodyValid8(invalid); // エラーメッセージのリセット
  }

  const validations = {
    // validationの処理
    setters: {
      0: {
        setTitle: () => {},
        setBody: handleGuidelineBodyValid0,
      },
      1: {
        setTitle: handleGuidelineTitleRequiredValid1,
        setBody: handleGuidelineBodyValid1,
      },
      2: {
        setTitle: handleGuidelineTitleRequiredValid2,
        setBody: handleGuidelineBodyValid2,
      },
      3: {
        setTitle: handleGuidelineTitleRequiredValid3,
        setBody: handleGuidelineBodyValid3,
      },
      4: {
        setTitle: handleGuidelineTitleRequiredValid4,
        setBody: handleGuidelineBodyValid4,
      },
      5: {
        setTitle: handleGuidelineTitleRequiredValid5,
        setBody: handleGuidelineBodyValid5,
      },
      6: {
        setTitle: handleGuidelineTitleRequiredValid6,
        setBody: handleGuidelineBodyValid6,
      },
      7: {
        setTitle: handleGuidelineTitleRequiredValid7,
        setBody: handleGuidelineBodyValid7,
      },
      8: {
        setTitle: handleGuidelineTitleRequiredValid8,
        setBody: handleGuidelineBodyValid8,
      },
    },
    // invalidの時のメッセージ
    validationMessage: {
      0: {
        title: null,
        body: guidelineBodyValid0,
      },
      1: {
        title: guidelineTitleRequiredValid1,
        body: guidelineBodyValid1,
      },
      2: {
        title: guidelineTitleRequiredValid2,
        body: guidelineBodyValid2,
      },
      3: {
        title: guidelineTitleRequiredValid3,
        body: guidelineBodyValid3,
      },
      4: {
        title: guidelineTitleRequiredValid4,
        body: guidelineBodyValid4,
      },
      5: {
        title: guidelineTitleRequiredValid5,
        body: guidelineBodyValid5,
      },
      6: {
        title: guidelineTitleRequiredValid6,
        body: guidelineBodyValid6,
      },
      7: {
        title: guidelineTitleRequiredValid7,
        body: guidelineBodyValid7,
      },
      8: {
        title: guidelineTitleRequiredValid8,
        body: guidelineBodyValid8,
      },
    },
  };
  return validations;
};
