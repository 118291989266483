import {API_BASE_URL} from "../../config/envConstants.js";
import { apiRequest } from '../../shared/utils/helper/apiRequest.js';

export const createNaviGuidelinesData = async (body) => {
  console.log(body);
  return await apiRequest(
    'post',
    `${API_BASE_URL}/api/v1/navi/guidelines`,
    body,
  );
};
export const updateNaviGuidelinesData = async (body) => {
  console.log(body);
  return await apiRequest(
    'put',
    `${API_BASE_URL}/api/v1/navi/guidelines`,
    body,
  );
};

export const deleteNaviGuidelinesData = async (id) => {
  return await apiRequest(
    'delete',
    `${API_BASE_URL}/api/v1/navi/guidelines/${id}`,
  );
};