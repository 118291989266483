import axios from 'axios';
import apiHeaders from 'shared/utils/helper/apiHeaders.js';
import { API_BASE_URL } from "config/envConstants.js";

export const createAuctionUsersData = async (body) => {
  return await axios.post(`${API_BASE_URL}/api/v1/service/auction_users`,
    body, {headers: apiHeaders()}
  );
};

export const confirmNumberData = async (body) => {
  return await axios.put(`${API_BASE_URL}/api/v1/service/auction_users/confirm`,
    body, {headers: apiHeaders()}
  );
};