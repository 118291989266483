import { findById as getGuidelines } from 'domain/Setting/services/Guidelines.js';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../../shared/hooks/useAuth.js';
import { useLocalStorage } from '../../../../shared/hooks/useLocalStorage';
import {
  FORGET_PASSWORD,
  LOGIN,
  SIGN_UP,
} from '../../../../shared/utils/helper/authState.js';
import {
  SERVICE,
  NAVI,
  KMANAGER,
} from '../../../../shared/utils/helper/constants.js';
import { breadcrumbs } from '../entities/breadcrumbs.js';
import { LocalGovernmentManagementGuidelinesContext } from '../entities/LocalGovernmentManagementGuidelinesContext.js';

const LocalGovernmentManagementGuidelinesProvider = ({ type, children }) => {
  const { governmentType, procedureType } = useAuth();
  const authState = {
    LOGIN: LOGIN,
    SIGN_UP: SIGN_UP,
    FORGET_PASSWORD: FORGET_PASSWORD,
  };
  const [guidelines, setGuidelines] = useState([]);
  const [auctionGuidelines, setAuctionGuidelines] = useState(null);
  const [bidGuidelines, setBidGuidelines] = useState(null);
  const { localStorageGovernmentId } = useLocalStorage();
  const { governmentId } = useParams();

  // データの取得、加工等を行う
  if (type === SERVICE) {
    useEffect(() => {
      getGuidelines(type, governmentId).then((result) => {
        setGuidelines(result);
      });
    }, [governmentId]);
  }

  if (type === NAVI) {
    useEffect(() => {
      getGuidelines(type, localStorageGovernmentId).then((result) => {
        setGuidelines(result);
        const auctions = result.filter(
          (item) => item.publicSaleType === 'AUCTION',
        );
        console.log(auctions);
        setAuctionGuidelines(
          auctions.map((auction) => ({
            itemNo: auction.data.itemNo,
            title: auction.data.title,
            body: auction.data.body,
            isDisplay: auction.isDisplay,
          })),
        );
        const bids = result.filter((item) => item.publicSaleType === 'BID');
        setBidGuidelines(
          bids.map((bid) => ({
            itemNo: bid.data.itemNo,
            title: bid.data.title,
            body: bid.data.body,
            isDisplay: bid.isDisplay,
          })),
        );
      });
    }, [localStorageGovernmentId]);
  }

  if (type === KMANAGER) {
    useEffect(() => {
      getGuidelines(type).then((result) => {
        setGuidelines(result);
      });
    }, []);
  }

  return (
    <LocalGovernmentManagementGuidelinesContext.Provider
      value={{
        authState,
        breadcrumbs,
        governmentId,
        governmentType,
        procedureType,
        guidelines,
        auctionGuidelines,
        bidGuidelines,
      }}>
      {children}
    </LocalGovernmentManagementGuidelinesContext.Provider>
  );
};

export default LocalGovernmentManagementGuidelinesProvider;
