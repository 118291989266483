import { LocalGovernmentManagementTopInputs } from 'interfaces/components/LocalGovernmentManagement/LocalGovernmentManagementTopInputs';

// validation
import { LocalGovernmentManagementTopForm } from '../LocalGovernmentManagementTopForm.jsx';

export function validateInputsLocalGovernmentManagementTopForm(data) {
  const formInputs = LocalGovernmentManagementTopInputs(data);
  const validate = LocalGovernmentManagementTopForm(data !== null);

  function handleChangePreviewUrl(e, required) {
    validate.setters.handlePreviewUrlValid(e, required);
    formInputs.setters.setPreviewUrl(e.target.value);
  }

  function handleChangeInquiryEmail(e) {
    validate.setters.handleInquiryEmailValid(e);
    formInputs.setters.setInquiryEmail(e.target.value);
  }

  function handleChangeInquiryTel(e, telData) {
    const val = {
      telNum1: e.telNum1,
      telNum2: e.telNum2,
      telNum3: e.telNum3,
    };
    validate.setters.handleInquiryTelValid(e, { ...telData, ...val });
    formInputs.setters.setInquiryTel({ ...telData, ...val });
  }

  function handleChangeInquiryExtension(e) {
    validate.setters.handleInquiryExtensionValid(e);
    formInputs.setters.setInquiryExtension(e.target.value);
  }

  function handleChangeInquiryAccept(e) {
    validate.setters.handleInquiryAcceptValid(e);
    formInputs.setters.setInquiryAccept(e.target.value);
  }

  function handleChangeHomePageName(e, isRequired) {
    validate.setters.handleHomePageNameValid(e, isRequired);
    formInputs.setters.setHomePageName(e.target.value);
  }

  function handleChangeHomePageUrl(e, isRequired) {
    validate.setters.handleHomePageUrlValid(e, isRequired);
    formInputs.setters.setHomePageUrl(e.target.value);
  }

  function handleChangeInquiryName(e, isRequired) {
    validate.setters.handleInquiryNameValid(e, isRequired);
    formInputs.setters.setInquiryName(e.target.value);
  }

  function handleChangeDisplayPreview(e) {
    formInputs.setters.setDisplayPreview(e.target.value);
  }

  function handleChangeDisplayInquiry(e) {
    formInputs.setters.setDisplayInquiry(e.target.value);
  }

  function handleChangeDisplayHomePage(e) {
    formInputs.setters.setDisplayHomePage(e.target.value);
  }

  const validateInputs = {
    data: formInputs.data,
    init: formInputs.setters,
    setters: {
      handleChangeDisplayHomePage,
      handleChangeDisplayInquiry,
      handleChangeDisplayPreview,
      handleChangePreviewUrl,
      handleChangeInquiryEmail,
      handleChangeInquiryTel,
      handleChangeInquiryExtension,
      handleChangeInquiryAccept,
      handleChangeHomePageName,
      handleChangeHomePageUrl,
      handleChangeInquiryName,
    },
    validationMessage: validate.validationMessage,
  };

  return validateInputs;
}
