import {
  //APL_TYPE_CANCEL,
  APL_TYPE_CONFISCATION,
  condition,
  DURING_BIDDING,
  FINAL_APPLY,
  TEMPORARY_APPLY,
} from 'domain/Property/entities/constants/property.js';

import { useEffect, useState } from 'react';
//import { useLocalStorage } from 'shared/hooks/useLocalStorage.js';
import useModalControl from 'shared/hooks/useModalControl.js';
//import { AuthContext } from 'shared/context/AuthContext.js';

import { LOGIN } from 'shared/utils/helper/authState.js';

import { AddBidded } from './AddBidded.jsx';
import { BiddedAuction } from './BiddedAuction.jsx';
import { BiddedBid } from './BiddedBid.jsx';
import { BlockList } from './BlockList.jsx';
import { NotAddBid } from './NotAddBid.jsx';
import { NotAplStatus } from './NotAplStatus.jsx';
import { NotBid } from './NotBid.jsx';
import { NotLogin } from './NotLogin.jsx';
import { TmpApply } from './TmpApply.jsx';
import { Message } from './WaitOpenBid.jsx';

export const AnnounceMessage = ({
  data,
  isContainBlockList,
  setIsNoMessage,
  userId,
}) => {
  const { isOpen, handleOpen, handleClose } = useModalControl();
  const [currentAuthStatus, setCurrentAuthStatus] = useState(null);
  //const { isLoggedIn } = useContext(AuthContext);
  //const { localStorageUserId } = useLocalStorage();

  const isLoggedIn = () => {
    return ![null, ''].includes(userId);
  };

  const handleClick = (status) => {
    switchAuthStatus(status);
    if (!isLoggedIn() || data?.status === DURING_BIDDING) {
      handleOpen();
    }
  };

  const switchAuthStatus = (authStatus) => {
    setCurrentAuthStatus(authStatus);
  };

  useEffect(() => {
    if (!isLoggedIn()) {
      setCurrentAuthStatus(LOGIN);
    }
  }, [data, userId]);

  // 状態：未ログイン
  if (!isLoggedIn()) {
    console.log('-- NotLogin --');
    return (
      <NotLogin
        data={data}
        switchAuthStatus={switchAuthStatus}
        handleClick={handleClick}
        handleClose={handleClose}
        currentAuthStatus={currentAuthStatus}
        isOpen={isOpen}
        setIsNoMessage={setIsNoMessage}
      />
    );
  }

  // 状態：オークション公開中止
  if (data?.status === 'CANCEL') {
    console.log('-- Cancel --');
    //return <Cancel data={data} setIsNoMessage={setIsNoMessage} />;
    return <Message content={'この物件の公開は中止となりました。　'} />;
  }

  // 状態：以下ログイン状態
  // 状態：ブロックリストに登録されている
  if (isContainBlockList) {
    console.log('-- BlockList --');
    return (
      <BlockList
        data={data}
        isContainBlockList={isContainBlockList}
        setIsNoMessage={setIsNoMessage}
      />
    );
  }

  //状態：未参加申し込み
  if (condition.includes(data?.aplStatus)) {
    console.log('-- NotAplStatus --');
    return <NotAplStatus data={data} setIsNoMessage={setIsNoMessage} />;
  }

  //状態：仮申し込み中
  if (data?.aplStatus === TEMPORARY_APPLY) {
    console.log('-- TmpApply --');
    return <TmpApply data={data} setIsNoMessage={setIsNoMessage} />;
  }

  //状態：申込完了
  if (data?.aplStatus === FINAL_APPLY) {
    //状態：未入札
    if (
      condition.includes(data?.myBidPrice) &&
      data?.status !== 'ADDITIONAL_DURING_BIDDING'
    ) {
      console.log('-- NotBid --');
      return <NotBid data={data} setIsNoMessage={setIsNoMessage} />;
    }

    //状態：入札済（せり形式）
    if (
      data?.publicSaleType === 'AUCTION' &&
      data?.status !== 'ADDITIONAL_DURING_BIDDING' &&
      !condition.includes(data?.myBidPrice)
    ) {
      console.log('-- BiddedAuction --');
      return <BiddedAuction data={data} setIsNoMessage={setIsNoMessage} />;
    }

    //状態：入札済（入札形式）
    if (
      data?.publicSaleType === 'BID' &&
      data?.status !== 'ADDITIONAL_DURING_BIDDING' &&
      !condition.includes(data?.myBidPrice)
    ) {
      console.log('-- BiddedBid --');
      return <BiddedBid data={data} setIsNoMessage={setIsNoMessage} />;
    }

    //状態：追加入札
    if (data?.status === 'ADDITIONAL_DURING_BIDDING') {
      //状態：追加入札していない
      if (condition.includes(data?.myAdditionalPrice)) {
        console.log('-- NotAddBid --');
        return <NotAddBid data={data} setIsNoMessage={setIsNoMessage} />;
      }

      //状態：追加入札済
      if (!condition.includes(data?.myAdditionalPrice)) {
        console.log('-- AddBidded --');
        return <AddBidded data={data} setIsNoMessage={setIsNoMessage} />;
      }
    }
  }

  //状態：申し込み取消
  if (
    data?.aplStatus === APL_TYPE_CONFISCATION ||
    data?.aplStatus === 'CANCEL'
  ) {
    console.log('-- Message --');
    return (
      <Message content={'あなたの参加申し込みは、取り消しとなっています。'} />
    );
  }

  console.log('-- setIsNoMessage --');
  setIsNoMessage(true);
};
