import {
  card_provisional_apply_completion_description1,
  card_provisional_apply_completion_description2,
} from 'shared/utils/helper/DummyEditFormData';

import FormTextWithCaution from '../../Form/navi/FormTextWithCaution';

const BothForm = ({ input, procedureType, governmentType }) => {
  return (
    <form data-testid="card-provisional-apply-completion-form">
      <div className="pt-3">
        <div className="property-info-general-form-table">
          <FormTextWithCaution
            label={'必要書類提出の案内ページURL'}
            required={
              procedureType == 'ASSET_SALE' ||
              governmentType == 'NATIONAL_TAX_AGENCY'
            }
            caution_description={card_provisional_apply_completion_description1(procedureType)}
            data={input.data.documentSubmissionUrl}
            handler1={(e) =>
              input.setters.handleDocumentSubmissionUrl(
                e,
                procedureType == 'ASSET_SALE' ||
                  governmentType == 'NATIONAL_TAX_AGENCY',
              )
            }
            handler2={(e) =>
              input.setters.handleDocumentSubmissionUrl(
                e,
                procedureType == 'ASSET_SALE' ||
                  governmentType == 'NATIONAL_TAX_AGENCY',
              )
            }
            invalidMessage={
              input.validationMessage
                .documentSubmissionUrlRequiredValid
            }
          />
          <FormTextWithCaution
            label={'共同入札案内ページURL'}
            caution_description={card_provisional_apply_completion_description2(
              governmentType,
            )}
            data={input.data.jointBiddingUrl}
            handler1={input.setters.handleJointBiddingUrl}
            invalidMessage={
              input.validationMessage.jointBiddingUrlValid
            }
          />
        </div>
      </div>
    </form>
  );
};

export default BothForm;
