import { useEffect, useState } from 'react';
import {
  switchInputClassName,
  switchInputMessage,
} from 'shared/utils/helper/inputHelper.js';
import { styled } from 'styled-components';
import 'interfaces/css/MyPage/reset-form-input.css';

const ResetFormInputWrap = styled.div`
  margin-bottom: 16px;
`;

const Label = styled.p`
  text-align: left;
  font-size: 0.875rem !important;
  color: var(--text-base);
  line-height: 1.375rem;
  margin-bottom: 4px;
  font-weight: 700 !important;
`;

const ResetFormInput = ({
  label,
  type,
  value,
  handleChange,
  validateMessage,
  caption,
}) => {
  const [borderClassName, setBorderClassName] = useState('normal-border');
  const [isFocus, setIsFocus] = useState(false);
  const [message, setMessage] = useState(caption);
  const isVisibleMessage = validateMessage !== 'NO_MESSAGE';

  useEffect(() => {
    switchInputClassName(isFocus, validateMessage, setBorderClassName);
  }, [isFocus, validateMessage]);

  useEffect(() => {
    switchInputMessage(validateMessage, setMessage, caption, isVisibleMessage);
  }, [validateMessage]);

  return (
    <ResetFormInputWrap className="mypage">
      <Label>{label}</Label>
      <input
        className={`reset-form-input ${borderClassName}`}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      />
      <div
        className={`reset-form-input-message ${validateMessage ? 'validate-message' : 'caption-message'}`}>
        {message}
      </div>
    </ResetFormInputWrap>
  );
};

export default ResetFormInput;
