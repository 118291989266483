import 'interfaces/css/PropertyDetail/action-item.css';
import 'interfaces/css/PropertyDetail/action-area.css';
import 'interfaces/css/common/hero-area.css';

import { FINAL_APPLY } from 'domain/Property/entities/constants/property.js';

import { useEffect, useState } from 'react';
import { calc_dead_line } from 'shared/utils/helper/calc_dead_line.js';

import { AuctionBtnBranch } from './AuctionBtnBranch.jsx';
import { AuctionItem } from '../HeroArea/AuctionItem.jsx';
import { MylistBtnBranch } from '../HeroArea/MylistBtnBranch.jsx';

import { styled } from 'styled-components';

const ButtonSpace = styled.div`
  margin-left: 12px;
`;

const ActionArea = ({
  data,
  isContainBlockList,
  children,
  itemsBottomActionAreaStyle,
}) => {
  const [deadline, setDeadline] = useState('');
  const [isRemainingAreaDisable, setIsRemainingAreaDisable] = useState(false);
  const [isMyListBtn, setIsMyListBtn] = useState(false);

  useEffect(() => {
    if (
      data?.remainingLabel === '入札締切まであと' ||
      data?.remainingLabel === '追加入札締切まであと' ||
      data?.remainingLabel === '申込締切まであと' ||
      data?.remainingLabel === '入札開始まであと'
    ) {
      setIsRemainingAreaDisable(true);
    }
    if (data?.aplStatus === FINAL_APPLY) {
      setIsMyListBtn(true);
    }
    if (data?.deadline) {
      setDeadline(calc_dead_line(data?.deadline));
      const intervalId = setInterval(() => {
        setDeadline(calc_dead_line(data?.deadline));
      }, 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [data]);

  return (
    <article
      className={`items-bottom-action-area mt-15 ${itemsBottomActionAreaStyle ?? ''}`}>
      <section className="common-article-default mb-10">
        <div className="common-article-default-body rounded px-6 py-8 mb-16">
          <div className="items-action-area-prices flex grow wrap is--pc">
            <div className="items-action-area-prices__main-list is--pc">
              {<AuctionItem data={data} />}
            </div>
            {isRemainingAreaDisable && deadline && (
              <div className="items-action-area-remaining mb-6">
                <div className="v-subheader pa-0 label theme--light">
                  {data?.remainingLabel}
                </div>
                <div className={'action-area-bid-deadline'}>{deadline}</div>
              </div>
            )}
          </div>
          <div className="action-area-flex flex">
            <AuctionBtnBranch
              data={data}
              isContainBlockList={isContainBlockList}
            />
            {data?.status !== 'CANCEL' && (
              <>
                <ButtonSpace />
                <MylistBtnBranch
                  data={data}
                  myListBtn={isMyListBtn}
                  className={'action-area-flex-item action-area-mylist-button'}>
                  <span>マイリストに登録</span>
                </MylistBtnBranch>
              </>
            )}
          </div>
          {children}
        </div>
      </section>
    </article>
  );
};
export default ActionArea;
