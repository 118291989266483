import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ArticleDefaultTitle from '../../common/ArticleDefaultTitle/ArticleDefaultTitle.jsx';
import ArticleDefaultBody from '../../common/ArticleDefaultBody/ArticleDefaultBody.jsx';
import Slider from '../../common/Slider/Slider.jsx';
import Button from 'interfaces/components/common/Button/Button.jsx';
import AuthButton from 'interfaces/components/common/Header/AuctionHeader/HeaderTop/AuthButton.jsx';
import { DashboardContext } from 'interfaces/pages/Dashboard/entities/DashboardContext';
import { EmptyData } from 'shared/utils/constants/emptyData';
//import { useBanner } from '../../../hooks/useBanner.js';

const Wrapper = styled.div`
  max-width: calc(100% - 200px * 2); /* 両サイドバー分をマイナス */
  min-width: 350px;
  overflow: hidden;
`;

const Banners = styled.div`
  color: var(--text-base);
  flex-wrap: wrap;
  justify-content: space-between;
  border-color: #fff;
  box-shadow: none;
  background: none;
  max-width: 100%;
  display: flex;
  --flex-grid: 12;
  border-radius: 0;
`;

const BannerSpacer = styled.div`
  position: relative;
  opacity: 0;
  height: 0;
  margin-bottom: 0;
`;

const NotOnScheculeMessage = styled.div`
  font-size: 24px;
  margin-bottom: 10px;
`;

const TopPageBody = ({ properties, promotionProperties }) => {
  const { authState } = useContext(DashboardContext);
  const navigate = useNavigate();

  const handleClickSchedule = () => {
    console.log('年間スケジュール');
    navigate('/schedules');
  };

  const handleClickBeginner = () => {
    console.log('初めての方へ');
    window.open('https://www.pages.kankocho.jp/guide', '_blank');
  };
  const banners = [
    {
      id: '7',
      title: 'テスト',
      destinationUrl: 'http://localhost:3000',
      displayDeadline: 'NULL',
      displayTarget: 'NEW_WINDOW',
      displayOrder: '0',
      activeStatus: '1',
      createdUser: 'fee7a229-28b4-41af-a5b8-5dfcb5bc48cc',
      createdAt: '2024-02-22 11:41:30',
      updatedUser: 'fee7a229-28b4-41af-a5b8-5dfcb5bc48cc',
      updatedAt: '2024-02-26 16:47:12',
    },
  ];

  return (
    <Wrapper data-testid="top-page-body">
      <section className="mb-10">
        <article>
          <Banners>
            {banners &&
              banners?.map((banner, index) => {
                return (
                  <Link
                    key={index}
                    to={banner.destinationUrl}
                    target={
                      banner.displayTarget === 'SAME_WINDOW'
                        ? '_self'
                        : '_blank'
                    }
                    data-testid={`top-page-body-banner-${banner.id}`}>
                    画像がここに入る
                  </Link>
                );
              })}
            {[...Array(3)].map((_, i) => {
              return <BannerSpacer key={i} />;
            })}
          </Banners>
        </article>
      </section>

      <div>
        {EmptyData.includes(promotionProperties) ||
        promotionProperties.length === 0 ? (
          <ArticleDefaultBody>
            <center>
              <NotOnScheculeMessage>
                ただいまKSI官公庁オークションは開催しておりません。
              </NotOnScheculeMessage>
              <NotOnScheculeMessage>次の開催はから</NotOnScheculeMessage>
              <NotOnScheculeMessage>
                詳しくは年間スケジュールをご覧ください
              </NotOnScheculeMessage>
              <div className="mb-4">
                <Button
                  text={'年間スケジュール'}
                  onClick={handleClickSchedule}
                  isOutline={true}
                  className={'login-button'}
                  width={'300px'}
                />
              </div>
              <NotOnScheculeMessage>
                メールアドレスの登録で開催をお知らせします！
              </NotOnScheculeMessage>
              <div className="mb-2">
                <AuthButton
                  text={'新規ユーザー登録（無料）'}
                  authStatus={authState?.SIGN_UP}
                  width={'300px'}
                />
              </div>
              <Button
                text={'初めての方へ'}
                onClick={handleClickBeginner}
                width={'300px'}
              />
            </center>
          </ArticleDefaultBody>
        ) : (
          promotionProperties.map((promotionProperty, index) => (
            <React.Fragment key={index}>
              {promotionProperties?.promotion?.theme}
              <header
                className="flex justify-space-between align-center mb-2"
                data-testid={`top-page-body-promotion-property-${index}`}>
                <ArticleDefaultTitle
                  title={promotionProperty?.promotion?.theme}
                />
              </header>
              <ArticleDefaultBody>
                {
                  <Slider
                    properties={properties.filter((property) => {
                      return promotionProperty.promotion.propertyIdList?.includes(
                        property.id,
                      );
                    })}
                  />
                }
              </ArticleDefaultBody>
            </React.Fragment>
          ))
        )}
      </div>
    </Wrapper>
  );
};

export default TopPageBody;
