import Button from 'interfaces/components/common/Button/Button.jsx';
import Toggle from 'interfaces/components/common/Toggle/Toggle.jsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BothForm from './BothForm.jsx';
import IndividualForm from './IndividualForm.jsx';
import { update } from 'domain/Setting/services/CreditCardPage';
import { ServiceTypes } from 'shared/utils/constants/service.js';

const CreditForm = ({
  auction,
  bid,
  individualSetting,
  setIndividualSetting,
  setIsOpen,
  procedureType,
  governmentType,
}) => {
  // TODO: ログイン情報から行政機関IDを取得する
  //  const GOVERNMENT_ID = "2";
  //  const PROCEDURE_TYPE = "PUBLIC_SALE";

  const navigate = useNavigate();

  async function performUpdate(e) {
    e.preventDefault();
    auction.data.publicSaleType = 'AUCTION';
    bid.data.publicSaleType = 'BID';
    auction.data.procedureType = procedureType;
    bid.data.procedureType = procedureType;
    try {
      await update(ServiceTypes.NAVI.string, auction.data);
      if (individualSetting) {
        await update(ServiceTypes.NAVI.string, bid.data);
      }
      setIsOpen(true);
    } catch (error) {
      console.error(error);
    }
  }

  const backToLocalGovernmentManagement = () => {
    navigate('/navi/local-government-management');
  };

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    const validateMessages = (
      auction,
      bid,
      procedureType,
      individualSetting,
      governmentType,
    ) => {
      const auctionValidationMessages = auction.validationMessage;
      const bidValidationMessages = bid.validationMessage;
      if (
        procedureType === 'ASSET_SALE' ||
        governmentType === 'NATIONAL_TAX_AGENCY'
      ) {
        // 特定の条件では、全てのバリデーションがTRUEである必要がある
        return (
          (auctionValidationMessages.jointBiddingUrlValid === null ||
            auctionValidationMessages.jointBiddingUrlValid === 'TRUE') &&
          auctionValidationMessages.documentSubmissionUrlRequiredValid ===
            'TRUE' &&
          bidValidationMessages.documentSubmissionUrlRequiredValid === 'TRUE' &&
          (bidValidationMessages.jointBiddingUrlValid === null ||
            bidValidationMessages.jointBiddingUrlValid === 'TRUE')
        );
      }

      if (individualSetting) {
        const auctionValid =
          auctionValidationMessages.documentSubmissionUrlRequiredValid ===
          'TRUE';
        const bidValid =
          bidValidationMessages.documentSubmissionUrlRequiredValid === 'TRUE';
        const jointBiddingValid =
          bidValidationMessages.jointBiddingUrlValid === 'TRUE';

        return auctionValid && (bidValid || jointBiddingValid);
      } else {
        const auctionOrJointBiddingValid =
          auctionValidationMessages.documentSubmissionUrlRequiredValid ===
            'TRUE' || auctionValidationMessages.jointBiddingUrlValid === 'TRUE';
        const bidOrJointBiddingValid =
          bidValidationMessages.documentSubmissionUrlRequiredValid === 'TRUE' ||
          bidValidationMessages.jointBiddingUrlValid === 'TRUE';

        return auctionOrJointBiddingValid || bidOrJointBiddingValid;
      }
    };

    setIsValid(
      validateMessages(
        auction,
        bid,
        procedureType,
        individualSetting,
        governmentType,
      ),
    );
  }, [auction, bid, procedureType, individualSetting, governmentType]);

  return (
    <div className="new-style_navi_local-government-management-top-form">
      {governmentType !== 'NATIONAL_TAX_AGENCY' && (
        <>
          <Toggle
            message={'「せり売形式」と「入札形式」で個別に情報を登録する'}
            handler={() => setIndividualSetting(!individualSetting)}
          />
          <div className="sub-message">
            ※
            参加者が仮申し込みを完了した際の表示を「せり売形式」と「入札形式」の物件で個別に設定したい場合にご利用ください。
            <br />
          </div>
        </>
      )}
      {!individualSetting && (
        <BothForm
          input={auction}
          procedureType={procedureType}
          governmentType={governmentType}
        />
      )}
      {individualSetting && (
        <IndividualForm
          auction={auction}
          bid={bid}
          procedureType={procedureType}
          governmentType={governmentType}
        />
      )}
      <div className="pt-9">
        <div className="property-info-general-form-button-box">
          <div className="pr-1 pl-1">
            <Button
              text={'登録'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_primary-button'
              }
              onClick={performUpdate}
              isDisabled={!isValid}
            />
          </div>
          <div className="pr-1 pl-1">
            <Button
              text={'キャンセル'}
              isOutline={false}
              height={'44px'}
              className={
                'property-info-general-form-button new-style_navi_normal-button'
              }
              onClick={backToLocalGovernmentManagement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditForm;
