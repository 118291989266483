import Stepper from 'interfaces/components/Guidelines/GuidelineAgreement/Stepper.jsx';
import StepTitle from 'interfaces/components/Guidelines/GuidelineAgreement/StepTitle.jsx';

//import { useApplicationInfo } from '../../../hooks/useApplicationInfo.js';
import { useEffect, useState } from 'react';
import {
  assetSaleSteps,
  publicSaleSteps,
  publicSaleStepsWithCard,
} from 'shared/utils/constants/application_information_registration_step.js';

import PropertyInfos from '../../Shipping/PointsToNoteAgreement/PropertyInfos.jsx';

const assetSaleTitle = [
  { label: '誓約書およびガイドライン同意' },
  { label: '申し込み情報の入力' },
  { label: '情報の確認' },
  { label: '受付完了' },
]

const RegistrationInfoPageHeading = ({
  locationState,
  requestSteps = [],
  step,
}) => {
  //const { applicationInfo } = useApplicationInfo();
  // mock data
  // proposalの情報
  const applicationInfo = {
    applicantInfo: {
      aplType: 'OFFLINE',
    },
  };

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (requestSteps.length > 0) {
      setSteps(requestSteps);
      return;
    }

    // fix: 国税ではASSET_SALEは存在する？
    if (locationState.data.procedureType === 'ASSET_SALE') {
      setSteps(assetSaleSteps);
      return;
    }

    if (applicationInfo.applicantInfo.aplType === 'ONLINE') {
      setSteps(publicSaleStepsWithCard);
      return;
    }

    setSteps(publicSaleSteps);
  }, [locationState, applicationInfo, requestSteps]);

  return (
    <div>
      <div className="government-label">
        {locationState.data?.governmentName}
      </div>
      <div className="stepper no-bg theme-light">
        <Stepper steps={steps} active={step} />
      </div>
      {step !== 4 && <PropertyInfos info={locationState} />}
      <StepTitle data={{ num: step, title: locationState.data.procedureType === 'ASSET_SALE' ? assetSaleTitle[step - 1]?.label : steps[step - 1]?.label }} />
    </div>
  );
};

export default RegistrationInfoPageHeading;
