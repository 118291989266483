// ロジックを含む処理
// フロントから呼ぶ関数

import {
  SEARCH_PROPERTY_CATEGORY_ID,
  SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
  SEARCH_STATUS,
  DEPOSIT_FLAG,
  OPEN_WAY,
} from 'shared/utils/constants/queryParam';

import { createNaviAddDescriptionsData } from '../../../infrastructure/api/NaviAddDescriptions.js';
import { updateNaviGuidelinesData } from '../../../infrastructure/api/NaviGuidelines.js';
import {
  createPropertiesData,
  updatePropertiesData,
  deletePropertiesData,
} from '../../../infrastructure/api/Properties.js';
import { createNaviPropertiesData } from 'infrastructure/api/NaviProperties.js';
import { compareData } from '../../../shared/utils/helper/compareData.js';
import {
  KMANAGER,
  NAVI,
  SERVICE,
} from '../../../shared/utils/helper/constants.js';
import CustomError from '../../../shared/utils/helper/customError.js';
//import { AddDescriptionsCreate, PropertyCreate } from '../entities/Property.js';
import { AddDescriptionsCreate } from '../entities/Property.js';
import { getProperty } from '../repositories/Property.js';
import { isEmpty } from 'shared/utils/constants/emptyData.js';

export async function get(type) {
  return await getProperty(type);
}

export async function findById(type, id) {
  const property = await getProperty(type);
  return property.find((data) => data.id === id);
}

export async function findByAuctionId(type, auctionId) {
  const properties = await getProperty(type);
  return properties.filter(
    (property) => property?.auctionId?.toString() === auctionId.toString(),
  );
}

export async function findByAuctionIdAndGovernmentId(
  type,
  auctionId,
  governmentId,
) {
  const properties = await getProperty(type);
  return properties.filter(
    (property) =>
      property?.auctionId?.toString() === auctionId.toString() &&
      property?.governmentId?.toString() === governmentId.toString(),
  );
}

export async function findByIds(type, ids) {
  const Property = await getProperty(type);
  return Property.filter((data) => ids.includes(data.id));
}

export async function findByAuctionIds(type, auctionIds) {
  const properties = await getProperty(type);
  let ret = properties;

  if ([undefined, null, ''].includes(auctionIds) === false) {
    ret = ret.filter((property) => auctionIds.includes(property.auctionId));
  }

  return ret;
}

export async function findBySearchParams(type, searchParams, auctionIds) {
  const properties = await getProperty(type);
  let ret = properties;

  if ([undefined, null, ''].includes(auctionIds) === false) {
    ret = ret.filter((property) => auctionIds.includes(property.auctionId));
  }

  // カテゴリーによる検索
  let condCategory = searchParams.get('category');
  if ([undefined, null].includes(condCategory)) {
    ret = ret.filter((property) => property.propertyType === 'REAL_ESTATE');
  } else {
    ret = ret.filter((property) => property.propertyType === condCategory);
  }

  // 物件のカテゴリーによる検索
  let condCategoryId = searchParams.get(SEARCH_PROPERTY_CATEGORY_ID);
  if ([undefined, null, ''].includes(condCategoryId) === false) {
    ret = ret.filter((property) =>
      condCategoryId.includes(property.categoryId),
    );
  }

  let condSearchStatus = searchParams.get(SEARCH_STATUS);
  if ([undefined, null, ''].includes(condSearchStatus) === false) {
    console.log('cond search status');
    ret = ret.filter((property) => property.status === condSearchStatus);
  }

  let condDeposit = searchParams.get(DEPOSIT_FLAG);
  if ([undefined, null, ''].includes(condDeposit) === false) {
    ret = ret.filter((property) =>
      condSearchStatus === 'NODEPOSIT'
        ? ['NONE'].includes(property.depositPayment)
        : ['CREDIT_CARD', 'BOTH'].includes(property.depositPayment),
    );
  }

  let condPublicSaleType = searchParams.get(OPEN_WAY);
  if ([undefined, null, ''].includes(condPublicSaleType) === false) {
    ret = ret.filter(
      (property) => property.publicSaleType === condPublicSaleType,
    );
  }

  // 条件による絞り込み
  if (condCategory === 'ESTATE') {
    let condGovernmentSubCategoryId = searchParams.get(
      SEARCH_GOVERNMENT_SUB_CATEGORY_ID,
    );
    if (condGovernmentSubCategoryId !== '') {
      ret = ret.filter((property) =>
        condGovernmentSubCategoryId.includes(property.governmentSubCategoryId),
      );
    }
  }

  return ret;
}

export async function create(
  type,
  inputs,
  propertyType,
  isDeliveryRequestDisplayed,
) {
  let contactTel = Object.values(inputs.data.contactTel).join('-');
  if (contactTel === '--') {
    contactTel = 'DATA_EMPTY';
  }
  let inquiryTel = Object.values(inputs.data.inquiryTel).join('-');
  if (inquiryTel === '--') {
    inquiryTel = 'DATA_EMPTY';
  }

  let body = {
    arrangement: inputs.data.arrangement,
    carrier_name: inputs.data.carrierName,
    carrier_name_list: inputs.data.carrierNameList,
    carrier_tel: inputs.data.carrierTel,
    contact_accept: inputs.data.contactAccept,
    contact_email: isEmpty(inputs.data.contactEmail)
      ? 'DATA_EMPTY'
      : inputs.data.contactEmail,
    contact_extension: isEmpty(inputs.data.contactExtension)
      ? 'DATA_EMPTY'
      : inputs.data.contactExtension,
    contact_office: inputs.data.contactOffice,
    contact_name: inputs.data.contactName,
    contact_tel: contactTel,
    delivery_date: inputs.data.deliveryDate,
    delivery_text: inputs.data.deliveryText,
    delivery_time: inputs.data.deliveryTime,
    delivery_type: inputs.data.deliveryType,
    deposit_payment: inputs.data.depositPayment,
    description: inputs.data.description,
    fix_resolution_date: inputs.data.fixResolutionDate,
    inquiry_accept: inputs.data.inquiryAccept,
    inquiry_email: isEmpty(inputs.data.inquiryEmail)
      ? 'DATA_EMPTY'
      : inputs.data.inquiryEmail,
    inquiry_extension: isEmpty(inputs.data.inquiryExtension)
      ? 'DATA_EMPTY'
      : inputs.data.inquiryExtension,
    inquiry_name: inputs.data.inquiryName,
    inquiry_note: inputs.data.inquiryNote,
    inquiry_office: inputs.data.inquiryOffice,
    inquiry_tel: inquiryTel,
    is_recommend: inputs.data.recommendProperties.recommend, // boolean
    name: inputs.data.name,
    packing: inputs.data.packing,
    property_type: propertyType,
    public_sale_type: inputs.data.publicSaleType,
    receiver: inputs.data.receiver,
    receiver_name: inputs.data.receiverName,
    resolution_date: inputs.data.resolutionDate,
    sale_number: inputs.data.saleNumber,
    status: inputs.data.status,
    video_url: isEmpty(inputs.data.videoUrl)
      ? 'DATA_EMPTY'
      : inputs.data.videoUrl,

    car_property: inputs.data.carProperty,
    estate_property: inputs.data.estateProperty,
  };

  // 数値型処理
  if (inputs.data.auctionId) {
    body.auction_id = Number(inputs.data.auctionId); // int
  }
  if (inputs.data.auctionUserId) {
    body.auction_user_id = Number(inputs.data.auctionUserId); // int
  }
  if (inputs.data.bidEndPrice) {
    body.bid_end_price = Number(inputs.data.bidEndPrice); // numeric
  }
  if (inputs.data.price) {
    body.bid_price = Number(inputs.data.price); // numeric
  }
  if (inputs.data.categoryId) {
    body.category_id = Number(inputs.data.categoryId); // int
  }
  if (inputs.data.deposit) {
    body.deposit = Number(inputs.data.deposit); // int
  }
  if (inputs.data.depositProxy) {
    body.deposit_proxy = Number(inputs.data.depositProxy); // numeric
  }
  if (inputs.data.depositRefundScheduled) {
    body.deposit_refund_scheduled = Number(inputs.data.depositRefundScheduled); // numeric
  }
  if (inputs.data.depositRefundClaim) {
    body.deposit_refund_claim = Number(inputs.data.depositRefundClaim); // numeric
  }
  if (inputs.data.divisionId) {
    body.division_id = Number(inputs.data.divisionId); // int
  }
  if (inputs.data.estimatePrice) {
    body.estimate_price = Number(inputs.data.estimatePrice); // numeric
  }
  if (inputs.data.governmentId) {
    body.government_id = Number(inputs.data.governmentId); // int
  }
  if (inputs.data.governmentSubCategoryId) {
    body.government_sub_category_id = Number(
      inputs.data.governmentSubCategoryId,
    ); // int
  }
  if (inputs.data.nextAuctionUserId) {
    body.next_auction_user_id = Number(inputs.data.nextAuctionUserId); // int
  }
  if (inputs.data.nextPrice) {
    body.next_price = Number(inputs.data.nextPrice); // numeric
  }
  if (inputs.data.printOrder) {
    body.print_order = Number(inputs.data.printOrder); // int
  }

  // boolean 処理
  if (inputs.data.isApplyReduction) {
    body.is_apply_reduction =
      inputs.data.isApplyReduction === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }
  if (inputs.data.isApprovalRequired) {
    body.is_approval_required =
      inputs.data.isApprovalRequired === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }
  if (inputs.data.isCopied) {
    body.is_copied = inputs.data.isCopied === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }
  if (inputs.data.isDraft) {
    body.is_draft = inputs.data.isDraft === 'TRUE' ? 'DRAFT' : 'RELEASE'; // boolean
  }
  if (inputs.data.isExtensionSurveyTerm) {
    body.is_extension_survey_term =
      inputs.data.isExtensionSurveyTerm === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }
  if (inputs.data.isLock) {
    body.is_lock = inputs.data.isLock === 'TRUE' ? 'LOCK' : 'UNLOCK'; // boolean
  }
  if (inputs.data.isNextBuyApl) {
    body.is_next_buy_apl = inputs.data.isNextBuyApl === 'TRUE' ? 'YES' : 'NO'; // boolean
  }
  if (inputs.data.isNotClaimable) {
    body.is_not_claimable =
      inputs.data.isNotClaimable === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }
  if (inputs.data.isPaid) {
    body.is_paid = inputs.data.isPaid === 'TRUE' ? 'TRUE' : 'FALSE'; // boolean
  }

  // timestamp 処理
  if (inputs.data.contractedLimit) {
    body.contracted_limit = inputs.data.contractedLimit; // timestamp
  }
  if (inputs.data.paymentDeadline) {
    body.payment_limit = new Date(inputs.data.paymentDeadline).getTime(); // timestamp
  }
  if (inputs.data.paymentScheduledDate) {
    body.payment_scheduled_date = inputs.data.paymentScheduledDate; // timestamp
  }
  if (inputs.data.registrationDate) {
    body.registration_date = inputs.data.registrationDate; // timestamp
  }

  body.is_delivery_request_displayed = isDeliveryRequestDisplayed;

  // payment method
  let paymentMethod = [];

  paymentMethod.push(
    inputs.data.paymentMethod.bankTransfer === true ? '1' : '0',
  );
  paymentMethod.push(
    inputs.data.paymentMethod.registeredMail === true ? '1' : '0',
  );
  paymentMethod.push(inputs.data.paymentMethod.check === true ? '1' : '0');
  paymentMethod.push(
    inputs.data.paymentMethod.postalMoneyOrder === true ? '1' : '0',
  );
  paymentMethod.push(inputs.data.paymentMethod.carry === true ? '1' : '0');
  body.payment = paymentMethod.join(':');

  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }

  if (type === NAVI) {
    try {
      console.log('body', body);
      await createNaviPropertiesData(body).then((response) => {
        console.log('response', response);
      });
    } catch (error) {
      console.log('error', error);
      throw new CustomError('Error create Property', error);
    }
  }

  if (type === KMANAGER) {
    response = await createPropertiesData(body);
  }

  if (response instanceof Error) {
    throw new CustomError('Error create Property', response);
  }

  return response;
}

export async function update(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await updatePropertiesData(data, data.id);
  }
  if (type === KMANAGER) {
    response = await updatePropertiesData(data, data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error update Property', response);
  }
  return response;
}

export async function destroy(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    response = await deletePropertiesData(data.id);
  }
  if (type === KMANAGER) {
    response = await deletePropertiesData(data.id);
  }
  if (response instanceof Error) {
    throw new CustomError('Error delete Property', response);
  }
  return response;
}

export async function createAddDescription(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const addDescription = new AddDescriptionsCreate(data);
    response = await createNaviAddDescriptionsData(addDescription);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error create AddDescription', response);
  }
  return response;
}

export async function updateAddDescription(type, data) {
  let response;

  if (type === SERVICE) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (type === NAVI) {
    const addDescription = new AddDescriptionsCreate(data);
    response = await updateNaviGuidelinesData(addDescription);
  }
  if (type === KMANAGER) {
    throw new CustomError('Unexpected Request (Property)');
  }
  if (response instanceof Error) {
    throw new CustomError('Error update AddDescription', response);
  }
  return response;
}

// function createPropertyArray(data) {
//   let properties = []
//   data.forEach((property) => {
//     properties.push(new Property(property))
//   })
//   return properties
// }

export function sortASC(data, column) {
  return data.sort((a, b) => compareData(a[column], b[column]));
}

export function sortDESC(data, column) {
  return data.sort((a, b) => compareData(b[column], a[column]));
}

export function filter(data, column, value) {
  return data.filter((property) => property[column] === value);
}

export function sortByBiddersASC(data) {
  return data.sort((a, b) => compareData(a.bidders.length, b.bidders.length));
}

export function sortByBiddersDESC(data) {
  return data.sort((a, b) => compareData(b.bidders.length, a.bidders.length));
}

export function sortFromCommonDataASC(data, column) {
  return data.sort((a, b) =>
    compareData(a.commonData[column], b.commonData[column]),
  );
}

export function sortFromCommonDataDESC(data, column) {
  return data.sort((a, b) =>
    compareData(b.commonData[column], a.commonData[column]),
  );
}

export function filterFromCommonData(data, column, value) {
  return data.filter((property) => property.commonData[column] === value);
}

export function filterByGovernmentAuction(data, governmentId, auctionId) {
  const filteredByGovernment = filterFromCommonData(
    data,
    'governmentId',
    governmentId,
  );
  return filterFromCommonData(filteredByGovernment, 'auctionId', auctionId);
}
