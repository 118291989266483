import { useState } from 'react';

export function useBannerInputs(banner) {
  const [title, setTitle] = useState(banner?.title || '');
  const [destinationUrl, setDestinationUrl] = useState(
    banner?.destinationUrl || '',
  );
  const [displayDeadline, setDisplayDeadline] = useState(
    banner?.displayDeadline > 0 ? 'DISPLAY' : 'HIDDEN',
  );
  const [deadline, setDeadline] = useState(
    banner?.displayDeadline > 0 ? banner?.displayDeadline : '',
  );
  const [displayTarget, setDisplayTarget] = useState(
    banner?.displayTarget || 'SAME_WINDOW',
  );
  const [displayOrder, setDisplayOrder] = useState(banner?.displayOrder || '');
  const [activeStatus, setActiveStatus] = useState(
    banner?.activeStatus === 'TRUE' ? 'ACTIVE' : 'NO_ACTIVE',
  );

  const bannerInputs = {
    type: {
      title: 'text',
      destinationUrl: 'text',
      displayDeadline: 'radio',
      deadline: 'date',
      displayTarget: 'radio',
      displayOrder: 'text',
      activeStatus: 'radio',
    },
    data: {
      title,
      destinationUrl,
      displayDeadline,
      deadline,
      displayTarget,
      displayOrder,
      activeStatus,
    },
    setters: {
      title: setTitle,
      destinationUrl: setDestinationUrl,
      displayDeadline: setDisplayDeadline,
      deadline: setDeadline,
      displayTarget: setDisplayTarget,
      displayOrder: setDisplayOrder,
      activeStatus: setActiveStatus,
    },
  };

  return bannerInputs;
}
