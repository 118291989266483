import {
  MobileDateTimePicker,
  DateTimePickerToolbar,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import { dateFormat } from 'shared/utils/helper/date_format.js';

export const MyDateTimePicker = ({
  state,
  setState,
  placeholder,
  open,
  setOpen,
  outline = false,
  isInvalid = false,
}) => {
  const slotProps = (date, setOpen, { placeholder = '' }) => {
    const inputValue = () => {
      const formatDate = dateFormat(date, 'Y-M-D hh:mm');
      if (formatDate === 'DATA_EMPTY') {
        return '';
      }
      return formatDate;
    };

    return {
      inputAdornment: {
        position: 'start',
      },
      textField: {
        readOnly: true,
        onClick: () => {
          setState(state);
          setOpen(true)
        },
        InputProps: {
          placeholder: placeholder,
          value: inputValue(),
        },
        sx: !outline
          ? {
              maxWidth: '500px',
              '& .MuiOutlinedInput-root': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: isInvalid ? '2px solid #ff5252' : 'none',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: isInvalid ? '2px solid #ff5252' : 'none',
                },
              },
              '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                color: '#f78c23',
              },
              '& .MuiTypography-overline': {
                display: 'none',
              },
              '& [role="tab"][aria-selected="true"]': {
                backgroundColor: '#f78c23',
              },
            }
          : {
              maxWidth: '500px',
              '& .MuiOutlinedInput-root': {
                padding: '8px 12px',
                backgroundColor: '#fff',
                '.MuiOutlinedInput-notchedOutline': {
                  border: isInvalid ? '2px solid #ff5252' : '1px solid rgba(0, 0, 0, .42)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: isInvalid ? '2px solid #ff5252' : '1px solid rgba(0, 0, 0, .42)',
                },
              },
              '& .MuiInputAdornment-root .MuiSvgIcon-root': {
                color: '#f78c23',
              },
              '& .MuiTypography-overline': {
                display: 'none',
              },
              '& [role="tab"][aria-selected="true"]': {
                backgroundColor: '#f78c23',
              },
            },
      },
    };
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      dateFormats={{
        dayOfMonth: 'd日',
        dayOfMonthFull: 'dd日',
        fullDate: 'yyyy年MM月dd日',
        fullTime: 'HH:mm:ss',
        fullTime12h: 'hh:mm:ss a',
        fullTime24h: 'HH:mm:ss',
        hours12h: 'hh',
        hours24h: 'HH',
        keyboardDate: 'yyyy/MM/dd',
        keyboardDateTime: 'yyyy/MM/dd HH:mm',
        keyboardDateTime12h: 'yyyy/MM/dd hh:mm a',
        keyboardDateTime24h: 'yyyy/MM/dd HH:mm',
        meridiem: 'a',
        minutes: 'mm',
        month: 'MM月',
        monthShort: 'MMM',
        normalDate: 'yyyy年MM月dd日',
        normalDateWithWeekday: 'yyyy年MM月dd日 (EEE)',
        seconds: 'ss',
        shortDate: 'MM/dd',
        weekday: 'EEEE',
        weekdayShort: 'EEE',
        year: 'yyyy年',
      }}>
      <MobileDateTimePicker
        value={state ? DateTime.fromJSDate(new Date(state)) : null}
        onChange={(e) => setState(e.toJSDate())}
        slotProps={slotProps(state, setOpen, {
          placeholder: placeholder,
        })}
        slots={{
          toolbar: typeof state === 'string' ? null : DateTimePickerToolbar,
        }}
        open={open}
        onClose={() => setOpen(false)}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        dayOfWeekFormatter={(date) => dateFormat(new Date(date), 'weekdayJS')}
        ampm={false}
        dateFormats={{ monthAndYear: 'yyyy年 MM月' }}
      />
    </LocalizationProvider>
  );
};
