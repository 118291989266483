import LargePrimaryButton from 'interfaces/components/common/Button/LargePrimaryButton.jsx';
import PrimaryButton from 'interfaces/components/common/Button/PrimaryButton.jsx';
import SmallLightButton from 'interfaces/components/common/Button/SmallLightButton.jsx';
import ListBox from 'interfaces/components/common/ListBox.jsx';
import PropertyListSearchInput from 'interfaces/components/Property/List/PropertyListSearchInput.jsx';
import PropertyListSelector from 'interfaces/components/Property/List/PropertyListSelector.jsx';
import { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import 'interfaces/css/PropertyList/property-list-setting-header.css';
import { PropertyListContext } from '../../../pages/PropertyList/entities/PropertyListContext';

const propertyTypes = [
  {
    id: 1,
    text: '物件種別を選択',
  },
  {
    id: 2,
    text: '物件情報（一般）',
  },
  {
    id: 3,
    text: '物件情報（自動車）',
  },
];

const nationalPropertyType = [
  {
    id: 1,
    text: '物件種別を選択',
  },
  {
    id: 2,
    text: '物件情報（一般）',
  },
  {
    id: 3,
    text: '物件情報（自動車）',
  },
  {
    id: 4,
    text: '物件情報（不動産）',
  },
];

const PropertyListSettingHeader = () => {
  const [showListBox, setShowListBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState('物件種別を選択');
  const { auction_id } = useParams();
  const [searchManageId, setSearchManageId] = useState('');
  const [searchTitle, setSearchTitle] = useState('');
  const refManageId = useRef();
  const refTitle = useRef();
  const { setSearchParam, governmentType, auction } =
    useContext(PropertyListContext);

  const search = useLocation().search;
  let queryParams = new URLSearchParams(search);
  let openWay = queryParams.get('open-way');

  const selectBoxClickHandler = () => {
    setShowListBox(!showListBox);
  };

  let navigate = useNavigate();

  const handleSearch = () => {
    setSearchParam({ manageId: searchManageId, title: searchTitle });
  };

  const handleManageIdParam = (e) => {
    setSearchManageId(e);
  };

  const handleTitleParam = (e) => {
    setSearchTitle(e);
  };

  const handleDeleteParam = () => {
    refManageId.current.value = '';
    refTitle.current.value = '';
    setSearchParam({ manageId: '', title: '' });
  };

  const handleNewButton = () => {
    if (selectedValue === '物件情報（自動車）') {
      navigate(`/navi/object/${auction_id}/create/car?open-way=${openWay}`);
    } else if (selectedValue === '物件情報（不動産）') {
      navigate(`/navi/object/${auction_id}/create/estate?open-way=${openWay}`);
    } else if (selectedValue === '物件情報（一般）') {
      navigate(
        `/navi/object/${auction_id}/create/real-estate?open-way=${openWay}`,
      );
    }
    return null;
  };

  const disableUpdate = () => {
    const aplStart = Date.parse(auction.aplStart);
    const today = new Date();
    return aplStart <= today;
  };

  return (
    <span className="property-list">
      <div
        className="property-list-setting-header"
        data-testid="property-list-setting-header">
        <LargePrimaryButton
          disabled={disableUpdate()}
          style={{ marginTop: '20px' }}>
          共通説明文登録
        </LargePrimaryButton>
        <div className="setting-header">
          <div className="actions-left">
            <PropertyListSearchInput
              inputRef={refManageId}
              label="区分番号"
              style={{ width: '200px' }}
              handler={handleManageIdParam}
            />
            <PropertyListSearchInput
              inputRef={refTitle}
              label="タイトル"
              style={{ width: '200px' }}
              handler={handleTitleParam}
            />
            <SmallLightButton text="絞り込む" onClick={handleSearch} />
            <SmallLightButton text="解除" onClick={handleDeleteParam} />
          </div>
          <div className="actions-right">
            {showListBox && (
              <ListBox
                data={
                  governmentType === 'NATIONAL_TAX_AGENCY' || openWay === 'BID'
                    ? nationalPropertyType
                    : propertyTypes
                }
                name={''}
                setShowListBox={setShowListBox}
                setSelectedValue={setSelectedValue}
                width={'350px'}
              />
            )}
            <PropertyListSelector
              content={selectedValue}
              onClick={selectBoxClickHandler}
              width={'350px'}
            />
            <PrimaryButton
              onClick={handleNewButton}
              disabled={selectedValue === '物件種別を選択'}>
              新規作成
            </PrimaryButton>
          </div>
        </div>
      </div>
    </span>
  );
};
export default PropertyListSettingHeader;
