import { PluginComponent } from 'react-markdown-editor-lite';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`;

const ToggleButton = styled.button`
  flex: 1;
  padding: 5px;
  border: ${(props) => (props.active ? '1px solid gray' : '1px solid gray')};
  border-bottom: ${(props) => (props.active ? 'none' : '1px solid gray')};
  border-top: ${(props) => (props.active ? '1px solid gray' : 'none')};
  background-color: ${(props) => (props.active ? '#fff' : '#f5f5f5')};
  color: ${(props) => (props.active ? 'black' : 'gray')};
  cursor: pointer;
  white-space: nowrap;
`;

export default class HandlePreview extends PluginComponent {
  static pluginName = 'toggle_preview';
  static align = 'left';

  constructor(props) {
    super(props);

    this.handleToggleView = this.handleToggleView.bind(this);
    this.state = {
      md: true,
      html: false,
    };
  }

  handleToggleView(e) {
    e.preventDefault();

    const currentView = this.editor.getView();
    this.editor.setView({
      md: !currentView.md,
      html: !currentView.html,
    });

    this.setState((prevState) => ({
      md: !prevState.md,
      html: !prevState.html,
    }));
  }

  componentDidMount() {
    this.editor.on('toggle_view', this.handleToggleView);
  }

  componentWillUnmount() {
    this.editor.off('toggle_view', this.handleToggleView);
  }

  render() {
    const { md, html } = this.state;
    return (
      <Container className="handle-preview-container">
        <ToggleButton
          onClick={this.handleToggleView}
          active={md.toString()}
          className="handle-preview-button">
          編集する
        </ToggleButton>
        <ToggleButton
          onClick={this.handleToggleView}
          active={html.toString()}
          className="handle-preview-button">
          プレビュー
        </ToggleButton>
      </Container>
    );
  }
}
