import { useOrganizationInputs } from './hook/useOrganizationInputs';
import { useOrganizationInputsValidation } from './hook/useOrganizationInputsValidation';

export function organizationFormData(isEdit = false) {
  const formInputs = useOrganizationInputs();
  const validate = useOrganizationInputsValidation(isEdit);

  function handleDivisionName(e) {
    validate.setters.divisionName(e);
    formInputs.setters.setDivisionName(e.target.value);
  }

  function handleDivisionNameKana(e) {
    validate.setters.divisionNameKana(e);
    formInputs.setters.setDivisionNameKana(e.target.value);
  }

  function handleContactName(e) {
    validate.setters.contactName(e);
    formInputs.setters.setContactName(e.target.value);
  }

  // const handleSetContactTel = (e, telData) => {
  //   console.log(telData);
  //   const val = {
  //     telNum1: e.telNum1,
  //     telNum2: e.telNum2,
  //     telNum3: e.telNum3,
  //   };
  //   formInputs.setters.setContactTel({ ...telData, ...val });
  // };

  function handleContactTel(tel) {
    validate.setters.contactTel(tel);
    formInputs.setters.setContactTel({
      ...formInputs.data.contactTel,
    });
  }

  function handleChangeTelNum1(tel) {
    validate.setters.telNum1(tel);
    formInputs.setters.setTelNum1(tel);
  }

  function handleChangeTelNum2(tel) {
    validate.setters.telNum2(tel);
    formInputs.setters.setTelNum2(tel);
  }

  function handleChangeTelNum3(tel) {
    validate.setters.telNum3(tel);
    formInputs.setters.setTelNum3(tel);
  }

  function handleContactExtension(e) {
    validate.setters.contactExtension(e);
    formInputs.setters.setContactExtension(e.target.value);
  }

  function handleContactEmail(e) {
    validate.setters.contactEmail(e);
    formInputs.setters.setContactEmail(e.target.value);
  }

  function handleUseCreditCard(e) {
    formInputs.setters.setUseCreditCard(e.target.value);
  }

  function handleFinancialInstitutionName(e) {
    validate.setters.financialInstitutionName(e);
    formInputs.setters.setFinancialInstitutionName(e.target.value);
  }

  function handleBranchName(e) {
    validate.setters.branchName(e);
    formInputs.setters.setBranchName(e.target.value);
  }

  function handleAccountType(e) {
    formInputs.setters.setAccountType(e.target.value);
  }

  function handleAccountNumber(e) {
    validate.setters.accountNumber(e);
    formInputs.setters.setAccountNumber(e.target.value);
  }

  function handleAccountName(e) {
    validate.setters.accountName(e);
    formInputs.setters.setAccountName(e.target.value);
  }

  function handleAccountNameKana(e) {
    validate.setters.accountNameKana(e);
    formInputs.setters.setAccountNameKana(e.target.value);
  }

  function handlePostalCode(e) {
    formInputs.setters.setPostalCode(e.target.value);
  }

  function handlePrefectureId(e) {
    formInputs.setters.setPrefectureId(e.target.value);
  }

  function handleCity(e) {
    validate.setters.city(e);
    formInputs.setters.setCity(e.target.value);
  }

  function handleAddress(e) {
    validate.setters.address(e);
    formInputs.setters.setAddress(e.target.value);
  }

  function handleBuildName(e) {
    validate.setters.buildName(e);
    formInputs.setters.setBuildName(e.target.value);
  }

  function handleDestination(e) {
    validate.setters.destination(e);
    formInputs.setters.setDestination(e.target.value);
  }

  function handleWrittenDestination(e) {
    validate.setters.writtenDestination(e);
    formInputs.setters.setWrittenDestination(e.target.value);
  }

  const handleSetPostalCode = (postalCode) => {
    validate.setters.postalCode(postalCode);
    formInputs.setters.setPostalCode({ ...formInputs.data.postalCode });
  };

  const handleSetPostalCode1 = (postData) => {
    validate.setters.postalCode1(postData);
    formInputs.setters.setPostalCode1(postData);
  };

  const handleSetPostalCode2 = (postData) => {
    validate.setters.postalCode2(postData);
    formInputs.setters.setPostalCode2(postData);
  };

  return {
    data: formInputs.data,
    setters: {
      handleDivisionName,
      handleDivisionNameKana,
      handleContactName,
      handleContactTel,
      handleChangeTelNum1,
      handleChangeTelNum2,
      handleChangeTelNum3,
      handleContactExtension,
      handleContactEmail,
      handleUseCreditCard,
      handleFinancialInstitutionName,
      handleBranchName,
      handleAccountType,
      handleAccountNumber,
      handleAccountName,
      handleAccountNameKana,
      handlePostalCode,
      handlePrefectureId,
      handleCity,
      handleAddress,
      handleBuildName,
      handleDestination,
      handleWrittenDestination,
      handleSetPostalCode,
      handleSetPostalCode1,
      handleSetPostalCode2,
    },
    init: formInputs.setters,
    validationMessage: validate.validationMessage,
  };
}
